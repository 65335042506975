import React from 'react'
import styled, { css } from 'styled-components'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'

export const SelaxFooter = () => {
  return (
    <Footer id="footer">
      <ElementSection>
        <ElementContainer>
          <ElementRow>
            <ElementColumn>
              <ColumnWrap>
                <WidgetWrap>
                  <Element>
                    <div className="elementor-widget-container">
                      <div className="elementor-text-editor elementor-clearfix">
                        <P>
                          Copyright Selax Brokerage © 2020. All rights reserved.
                        </P>
                      </div>
                    </div>
                  </Element>
                </WidgetWrap>
              </ColumnWrap>
            </ElementColumn>
            <ElementColumn>
              <ColumnWrap>
                <WidgetWrap alignEnd>
                  <Element>
                    <div className="elementor-widget-container">
                      <List>
                        <Item after>
                          <A href="https://www.facebook.com/SELAX-Brokerage-General-Agency-103892999014603">
                            <FaFacebookF />
                            <span className="elementor-icon-list-text">
                              Facebook
                            </span>
                          </A>
                        </Item>
                        <Item>
                          <A href="https://twitter.com/SELAXBrokerage">
                            <FaTwitter />
                            <span className="elementor-icon-list-text">
                              Twitter
                            </span>
                          </A>
                        </Item>
                      </List>
                    </div>
                  </Element>
                </WidgetWrap>
              </ColumnWrap>
            </ElementColumn>
            <ElementColumn>
              <ColumnWrap>
                <WidgetWrap alignEnd>
                  <Element>
                    <div className="elementor-widget-container">
                      <List>
                        <Item after>
                          <A href="https://telaxbrokerage.com/privacy-policy/">
                            <span className="elementor-icon-list-text">
                              Privacy Policy
                            </span>
                          </A>
                        </Item>
                        <Item after>
                          <span className="elementor-icon-list-text">
                            877-352-9242
                          </span>
                        </Item>
                        <Item>
                          <span className="elementor-icon-list-text">
                            sales@selaxbrokerage.com
                          </span>
                        </Item>
                      </List>
                    </div>
                  </Element>
                </WidgetWrap>
              </ColumnWrap>
            </ElementColumn>
          </ElementRow>
        </ElementContainer>
      </ElementSection>
    </Footer>
  )
}

const Footer = styled.footer`
  font-family: Arial, sans-serif;
`

const ElementSection = styled.section`
  background-color: #2b6777;
`

const ElementContainer = styled.div`
  max-width: 1760px;
`

const ElementRow = styled.div`
  width: 100%;
  display: flex;
`

const ElementColumn = styled.div`
  width: 33%;
`

const Element = styled.div`
  text-align: left;
  color: #ffffff;
  font-size: 16px;
  font-weight: 200;
`

const A = styled.a`
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }

  svg {
    height: 20px
    width: 20px
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-right: calc(-35px / 2) !important;
  margin-left: calc(-35px / 2) !important;
`

const Item = styled.li`
  margin-right: calc(35px / 2) !important;
  margin-left: calc(35px / 2) !important;
  justify-content: flex-end;
  text-align: right;
  display: flex;
  font-size: inherit;
  align-items: center;
  position: relative;

  ${(props) =>
    props.after &&
    css`
      &:after {
        position: relative;
        right: calc(-35px / 2);
        border-left-style: solid;
        border-left-width: 1px;
        content: '';
        height: 60%;
        border-color: #ffd664;
      }
    `}
`

const ColumnWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`

const WidgetWrap = styled.div`
  padding: 10px;
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${(props) =>
    props.alignEnd &&
    css`
      justify-content: flex-end;
    `}
`

const P = styled.p`
  margin: 0 0 20px;
`
