import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { inject, observer } from 'mobx-react'
import { Button } from '../theme/button/Button'

import { FaSpinner } from 'react-icons/fa'

@inject('store')
@observer
class LoadingButton extends React.Component {
  render() {
    let classNames = ''
    if (this.props.className) {
      classNames += this.props.className
    }
    if (
      this.props.store.sessionData.actionLoader === true ||
      this.props.isLoading
    ) {
      return (
        <Button type="button" disabled={true} className={classNames}>
          <FaSpinner />
        </Button>
      )
    } else {
      return (
        <Button
          type="submit"
          disabled={this.props.disabled}
          className={classNames}
        >
          {this.props.text}
        </Button>
      )
    }
  }
}

LoadingButton.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.any,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string
}

export default withRouter(LoadingButton)
