import React from 'react'
import PropTypes from 'prop-types'
import { ChaliceHeader } from './ChaliceHeader'
import { EnterpriseHeader } from './EnterpriseHeader'
// Custom headers
import { WorthHeader } from '../worth/WorthHeader'
import { EarthlinkHeader } from '../earthlink/EarthlinkHeader'
import { ElBizHeader } from '../earthlink/ElBizHeader'
import { FfrHeader } from '../ffr/FfrHeader'
import { HoustonFirstHeader } from '../houstonfirst/HrHeader'
import { SelaxHeader } from '../selax/SelaxHeader'

export const Header = (props) => {
  if (props.isEnterprise) {
    if (props.siteData.slug === 'worth-media') {
      return <WorthHeader {...props} />
    } else if (props.siteData.slug === 'ffr') {
      return <FfrHeader {...props} />
    } else if (props.siteData.slug === 'earthlink') {
      return <EarthlinkHeader {...props} />
    } else if (props.siteData.slug === 'earthlink-biz') {
      return <ElBizHeader {...props} />
    } else if (props.siteData.slug === 'houston-first') {
      return <HoustonFirstHeader {...props} />
    } else if (props.siteData.slug === 'selax-brokerage-agency') {
      return <SelaxHeader {...props} />
    } else {
      return <EnterpriseHeader {...props} />
    }
  } else {
    return <ChaliceHeader {...props} />
  }
}

Header.propTypes = {
  logo: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  navItems: PropTypes.array,
  siteData: PropTypes.object,
  isEnterprise: PropTypes.bool,
  containerClassName: PropTypes.string
}
