import { observable } from 'mobx'

/**
 * Author: Hanamantraya R
 * Class Name: ObjMembershipPlans
 * Description: Observation Class to create object of class for membership plan.
 */
export default class {
  id = null;
  @observable plan_name = null;
  @observable price = null;
  @observable interval_type = null;
  @observable interval_count = null;
  @observable currency_type = null;
  @observable plan_description = null;
  @observable memb_products = null;

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: Object(json)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (json) {
    this.id = json.id
    this.plan_name = json.plan_name
    this.price = json.price
    this.interval_type = json.interval_type
    this.interval_count = json.interval_count
    this.currency_type = json.currency_type
    this.plan_description = json.plan_description
    this.memb_products = json.memb_products
  }

  /**
   * @Author: Hanamantraya R
   * @Method: jsonToArray
   * @Param: Object(json)
   * @Return: Array
   * Description: Return array of objects
   */
  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(json)
   * @Return: Object
   * Description: Return  objects
   */
  static constructFromJSON (json) {
    return new this(json)
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(entries)
   * @Return: Object
   * Description: Update the details of particular record
   */
  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
