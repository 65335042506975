import { observable } from 'mobx'

/**
 * Author: Hanamantraya R
 * Class Name: ObjSSO
 * Description: Observation Class to create object of class for SSO.
 */
export default class {
  id = null;
  @observable parter_name = null;
  @observable parter_site = null;
  @observable product_name = null;
  @observable sso_auth_domain = null;
  @observable prod_description = null;
  @observable client_id = null;
  @observable client_secret_key = null;
  @observable is_active = null;

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: Object(params)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (json) {
    this.id = json.id
    this.parter_name = json.parter_name
    this.parter_site = json.parter_site
    this.product_name = json.product_name
    this.sso_auth_domain = json.sso_auth_domain
    this.prod_description = json.prod_description
    this.client_id = json.client_id
    this.client_secret_key = json.client_secret_key
    this.is_active = parseInt(json.is_active) !== 0
  }

  /**
   * @Author: Hanamantraya R
   * @Method: jsonToArray
   * @Param: Object(json)
   * @Return: Array
   * Description: Return array of objects
   */
  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(json)
   * @Return: Object
   * Description: Return  objects
   */
  static constructFromJSON (json) {
    return new this(json)
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(entries)
   * @Return: Object
   * Description: Update the details of particular record
   */
  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
