import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import services from '../../services'
import APIURL from  '../../../constants/APIURL'

const BannerAd = ({ banner }) => {
  const divRef = useRef(null)
  const [isMobile, setIsMobile] = useState(false);

  const resize = () => {
    setIsMobile(window.innerWidth <= 760);
  }

  useEffect(() => {
    if (banner?.type === 'HTML' && banner?.custom_html) {
      const slotHtml = document
        .createRange()
        .createContextualFragment(banner.custom_html) // Create a 'tiny' document and parse the html string
      divRef.current.innerHTML = '' // Clear the container
      divRef.current.appendChild(slotHtml) // Append the new content
    }
    resize();
    if (banner?.id) {
      services.put_v2(APIURL.API_BANNER_VIEW(banner.id))
    }
  }, [banner])

  const recordClick = (id) => {
    services.put_v2(APIURL.API_BANNER_CLICK(id))
  }

  let media_image = null
  if (isMobile && banner.mobile_media?.media) {
    media_image = banner.mobile_media.media
  } else {
    media_image = banner.media?.media
  }

  if (banner?.type === 'Image') {
    return (
      <a onClick={() => {recordClick(banner.id)}} href={banner.url} target="_blank" rel="noreferrer">
        <BannerImage
          src={media_image}
          alt={
            banner.media?.description ||
            banner.media?.name ||
            'banner advertisement image'
          }
        />
      </a>
    )
  }

  if (banner?.type === 'HTML') {
    return <div ref={divRef} />
  }

  return null
}

BannerAd.propTypes = {
  placement: PropTypes.oneOf(['Marketplace', 'Footer', 'Product'])
}

const BannerImage = styled.img`
  width: 100%;
  max-height: 500px;
`

export default BannerAd
