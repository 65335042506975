import { observable } from 'mobx'
import dayjs from 'dayjs'

export default class {
  id = null;
  @observable created_at = null;
  @observable question = null;
  @observable answer = null;
  @observable product_ids = null;

  constructor (json) {
    this.id = json.id
    this.created_at = dayjs(json.created_at).format('MM/DD/YYYY')
    this.question = json.question
    this.answer = json.answer
    this.product_ids = json.product_product_faq_maps
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
