export const getImageUrl = (image) => {
  if (image?.media_center) {
    return image.media_center.media
  } else if (image?.urls?.length > 0) {
    return image.urls[0]
  } else return null
}

export const hasImage = (image) => {
  if (image?.media_center || image?.urls?.length > 0) return true
  return false
}

export const getOpacity = (image) => {
  return image?.opacity === 0 ? 0 : image?.opacity ? image.opacity : 1
}
