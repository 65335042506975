import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  FaSearch,
  FaArrowLeft,
  FaBars,
  FaUser,
  FaSignOutAlt,
  FaTimes,
  FaPlus,
  FaMinus
} from 'react-icons/fa'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { EnvelopeIcon, EventIcon, ListIcon, DotsIcon } from './WorthSvgs'

const NAVBAR_HEIGHT = 187
const AUTHBAR_HEIGHT = 25
const TOTAL_HEIGHT = NAVBAR_HEIGHT + AUTHBAR_HEIGHT
const TOTAL_MOBILE_HEIGHT = 52 + AUTHBAR_HEIGHT

export const WorthHeader = ({
  user,
  organization,
  logo,
  isLoading,
  pricingTab,
  siteData,
  loginUrl = '/idp/login',
  loginText = 'Login',
  signupUrl = '/signup',
  signupText = 'Join the Network',
  allowSignup = true,
  allowBilling = true,
  navItems = []
}) => {
  const [isOpen, setOpen] = useState(false)
  const isMobile = useMediaQuery({ query: `(max-width: 1168px)` })

  const loginLink = !user && <AuthLink href={loginUrl}>{loginText}</AuthLink>
  const joinLink = !user && allowSignup && (
    <AuthLink href={signupUrl}>{signupText}</AuthLink>
  )
  const profileLink = user && allowSignup && (
    <AuthLink href={'/profile/'}>
      <FaUser />
      Account
    </AuthLink>
  )
  const logoutLink = user && (
    <AuthLink href={'/logout/'}>
      <FaSignOutAlt />
      Logout
    </AuthLink>
  )
  return (
    <Header>
      <TopBar isActive={isOpen && isMobile}>
        <AuthBar>
          <AuthContainer>
            <div>
              <BackLink href="https://worth.com">
                <FaArrowLeft /> Back to worth.com
              </BackLink>
            </div>
            <AuthNav>
              {loginLink}
              {joinLink}
              {profileLink}
              {logoutLink}
            </AuthNav>
          </AuthContainer>
        </AuthBar>
        <NavContainer>
          <HeaderToggle onClick={() => setOpen(!isOpen)}>
            {!isOpen && <FaBars />}
            {isOpen && <FaTimes />}
          </HeaderToggle>
          <SearchLink>
            <FaSearch />
          </SearchLink>
          <SearchForm>
            <form
              action="https://www.worth.com/"
              method="get"
              role="search"
              data-hs-cf-bound="true"
            >
              <input type="search" name="s" id="s" placeholder="Search" />
              <button type="submit">
                <FaSearch />
              </button>
            </form>
          </SearchForm>
          <SubscribeLink
            href="https://www.worth.com/newsletters/"
            order="1"
            width="200px"
          >
            <SubscribeCover>
              <img
                width="60"
                height="85"
                alt=""
                data-src="https://www.worth.com/wp-content/uploads/2022/12/Worthy-100-Cover1024_1-e1670342646654-60x85.jpg"
                src="https://www.worth.com/wp-content/uploads/2022/12/Worthy-100-Cover1024_1-e1670342646654-60x85.jpg"
              />
            </SubscribeCover>
            <SuscribeHeading>Subscribe</SuscribeHeading>
          </SubscribeLink>
          <HeaderLink
            href="https://www.worth.com/newsletters/"
            order="2"
            width="200px"
            mobileDisplay="none"
          >
            <HeaderIcon>
              <EnvelopeIcon />
            </HeaderIcon>
            Newsletters
          </HeaderLink>
          <HeaderLink
            href="https://www.worth.com/newsletters/"
            order="4"
            mobileDisplay="flex"
            desktopDisplay="none"
          >
            Newsletters
          </HeaderLink>
          <Logo order="3">
            <img alt="" src={siteData.logo_media_center.media} />
          </Logo>
          <HeaderLink
            order="4"
            href="https://www.worth.com/events/"
            width="133px"
            mobileDisplay="none"
          >
            <HeaderIcon>
              <EventIcon />
            </HeaderIcon>
            Events
          </HeaderLink>
          <HeaderLink
            order="5"
            href="https://www.worth.com/worth-watching/"
            width="133px"
            mobileDisplay="none"
          >
            <HeaderIcon>
              <img
                alt=""
                src="https://www.worth.com/wp-content/themes/worth/images/video-512-small.png"
              />
            </HeaderIcon>
            Watch
          </HeaderLink>
          <HeaderLink
            order="6"
            href="https://www.worth.com/lists/"
            width="133px"
            mobileDisplay="none"
          >
            <HeaderIcon>
              <ListIcon />
            </HeaderIcon>
            Lists
          </HeaderLink>
          <Separator order="7" />
          <NavCommunities isMobile={isMobile}>
            <ul>
              <li>
                <a href="https://www.worth.com/women-and-worth/">
                  Women &amp; Worth
                </a>
              </li>
              <li>
                <a href="https://www.worth.com/investors-and-entrepreneurs/">
                  Investors &amp; Entrepreneurs
                </a>
              </li>
              <li>
                <a href="https://www.worth.com/cities/">Cities</a>
              </li>
              <li>
                <a href="https://www.worth.com/families-of-worth/">
                  Families of Worth
                </a>
              </li>
              <li>
                <a href="https://www.worth.com/home/worthy-100-2022/">
                  Worthy 100: 2022
                </a>
              </li>
            </ul>
          </NavCommunities>
          <Nav order="8">
            <ul id="menu-topmenu">
              <li>
                <a href="https://www.worth.com/finance/">Finance</a>
              </li>
              <Menu
                isMobile={isMobile}
                href="https://www.worth.com/business/"
                text="Business"
              >
                <SubMenu>
                  <li>
                    <a href="https://www.worth.com/real-estate/">Real Estate</a>
                  </li>
                  <li>
                    <a href="https://www.worth.com/wealthofknowledgeseries/">
                      Wealth Of Knowledge
                    </a>
                  </li>
                </SubMenu>
              </Menu>
              <Menu
                isMobile={isMobile}
                href="https://www.worth.com/advisor-directory/"
                text="Top Leading Advisors"
              >
                <SubMenu>
                  <li>
                    <a href="https://www.worth.com/advisor-directory/">
                      Advisor Directory
                    </a>
                  </li>
                  <li>
                    <a href="https://www.worth.com/advisors/">
                      Leading Advisors Content
                    </a>
                  </li>
                  <li>
                    <a href="https://www.worth.com/worth-advisor-programs/">
                      Worth Advisor Programs
                    </a>
                  </li>
                  <li>
                    <a href="https://www.worth.com/become-a-leading-advisor/">
                      Become a Leading Advisor
                    </a>
                  </li>
                </SubMenu>
              </Menu>
              <Menu
                isMobile={isMobile}
                href="https://www.worth.com/lifestyle/"
                text="Lifestyle"
              >
                <SubMenu>
                  <li>
                    <a href="https://www.worth.com/health/">Health</a>
                  </li>
                  <li>
                    <a href="https://www.worth.com/impact/">Impact</a>
                  </li>
                  <li>
                    <a href="https://www.worth.com/innovation/">Innovation</a>
                  </li>
                </SubMenu>
              </Menu>
              <li>
                <a href="https://www.worth.com/worth-books-become-an-author/">
                  Worth Books
                </a>
              </li>
              <li>
                <a href="https://www.worth.com/digital/">Digital Edition</a>
              </li>
              <li>
                <a href="https://www.worth.com/partner-content/">
                  Partner Content
                </a>
              </li>
            </ul>
          </Nav>
          <Separator order="10" />
        </NavContainer>
      </TopBar>
    </Header>
  )
}

WorthHeader.propTypes = {
  logo: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  navItems: PropTypes.array,
  siteData: PropTypes.object,
  isWhiteLabel: PropTypes.bool
}

export default WorthHeader

const Header = styled.div`
  height: ${TOTAL_HEIGHT}px;
  transition: height 0.2s ease;
  margin-bottom: 30px;
  display: block;

  ${(props) => props.theme.media.largeDesktop`
    height: ${TOTAL_MOBILE_HEIGHT}px;
  `}
`

const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 30px;
  background: #fff;

  ${(props) => props.theme.media.largeDesktop`
    padding: 0;
    align-items: flex-start;
    justify-content: normal;
  `}
`

const TopBar = styled.div`
  position: absolute;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  box-shadow: 0 8px 39px 0 rgb(0 0 0 / 8%);

  ${(props) => props.theme.media.largeDesktop`
    height: ${TOTAL_MOBILE_HEIGHT}px;
    overflow: hidden;
  `}

  ${(props) =>
    props.isActive &&
    css`
      ${(props) => props.theme.media.largeDesktop`
      height: 100vh;
    `}
    `}
`

const AuthBar = styled.div`
  background-color: #000;
  width: 100%;
  height: ${AUTHBAR_HEIGHT}px;
`

const AuthLink = styled.a`
  font-size: 13px;
  color: #fff;
  transition: opacity 0.2s ease;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:visited,
  &:active,
  &:hover {
    color: #fff;
    opacity: 0.7;
    transition: opacity 0.2s ease;
    text-decoration: none;
  }

  svg {
    stroke-width: 1px;
    margin-right: 5px;
  }
`

const BackLink = styled(AuthLink)``

const AuthContainer = styled(NavContainer)`
  height: ${AUTHBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
`

const AuthNav = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const LinkCommon = css`
  ${(props) => props.theme.media.largeDesktop`
    width: 100px;
    padding-right: 20px;
    text-align: right;
    color: #555555;
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 52px;
  `}
  ${(props) =>
    props.order &&
    css`
      order: ${(props) => props.order};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${(props) => props.width};
    `}
  display: ${(props) => props.desktopDisplay || 'flex'};
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  transition: opacity 0.2s ease;
  text-decoration: none;

  &:hover {
    color: #000;
    opacity: 0.5;
    text-decoration: none;
    transition: opacity 0.2s ease;
  }

  ${(props) =>
    props.mobileDisplay &&
    props.theme.media.largeDesktop`
    display: ${(props) => props.mobileDisplay};
  `}
`

const Logo = styled.a`
  margin: 0 auto;
  ${LinkCommon}

  img {
    margin: 20px 0;
    height: 75px;

    ${(props) => props.theme.media.largeDesktop`
      height: 28px;
      margin: 10px 0;
    `}
  }
`

const HeaderLink = styled.a`
  ${LinkCommon}
`

const HeaderIcon = styled.span`
  margin-right: ${(props) => props.marginRight || '10px'};
  display: flex;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    border: 0px;
    outline: none;
    display: block;
  }
`

const HeaderNavLabel = styled.span`
  display: inline-flex;
  align-items: center;
  font-weight: bold;

  ${(props) => props.theme.media.largeDesktop`
    display: none;
  `}
}

`

const HeaderNavLabelMobile = styled.div`
  display: none;
  padding: 0 20px;
  color: #000a12;
  font-family: 'Montserrat', 'Helvetica', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 50px;
  border-bottom: 1px solid #d6d6d6;

  ${(props) =>
    props.showMenu &&
    css`
      background: #000;
      color: #fff;
    `}
  ${(props) => props.theme.media.largeDesktop`
    display: block;
  `}
`

const HeaderToggle = styled.a`
  display: none;
  width: 50px;
  height: 50px;
  margin-right: 50px;
  align-items: center;
  justify-content: center;
  order: 1;
  color: #000;

  ${(props) => props.theme.media.largeDesktop`
    display: flex;
  `}
`

const SearchLink = styled.a`
  order: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  color: #917e52;
  font-size: 20px;

  ${(props) => props.theme.media.largeDesktop`
    display: none;
  `}
`

const SearchForm = styled.div`
  padding: 0 20px;
  position: static;
  display: none;
  visibility: visible;
  opacity: 1;
  height: 51px;
  background: #f1f1f1;
  width: 100%;
  order: 5;

  ${(props) => props.theme.media.largeDesktop`
    display: block;
  `}

  form {
    position: static;
    display: flex;
    text-align: center;
    width: 100%;
  }

  input {
    width: 100% !important;
    font-family: Montserrat, sans-serif !important;
    line-height: 50px;
    color: #000;
    font-size: 18px;
    min-width: 90%;
    background: none !important;
    font-weight: 400;
    text-transform: none;
    border: none;
    border-bottom: 1px solid #fff;
    background-size: 23px;
    outline: 0;
    padding: 0 !important;
  }

  button {
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    color: #555;
    font-size: 20px !important;
    border: none;
    width: 50px !important;
    height: 50px !important;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
  }
`

const SubscribeLink = styled(HeaderLink)`
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 100px solid #e5edff;
    border-right: 70px solid transparent;
    height: 0;
    width: 100px;
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 200px;
  background: #000;
  box-shadow: 0 6px 7px 0 rgb(0 0 0 / 12%) !important;
  text-align: center;
  transition: height 0.2s ease;

  ${(props) => props.theme.media.largeDesktop`
    display: none;
  `}
`

const SubscribeCover = styled.div`
  position: relative;
  z-index: 1;
  height: 85px;
  width: 60px;
  background: #c8c8c8;
`

const SuscribeHeading = styled.span`
  position: relative;
  z-index: 1;
  margin-left: 15px;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  transition: margin-left 0.2s ease;
`

const Separator = styled.div`
  ${(props) =>
    props.order &&
    css`
      order: ${(props) => props.order};
    `}
  position: relative;
  display: block;
  width: 100%;

  :after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 0;
    border-bottom: 1px solid #ececec;
  }
`

const NavCommon = css`
  ${(props) =>
    props.order &&
    css`
      order: ${(props) => props.order};
    `}
  display: block;
  color: #555555;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  width: calc(100% - 45px);
  padding-left: 45px;
  transition: all 0.2s ease;
  text-align: center;
`

const Nav = styled.nav`
  ${NavCommon}

  ${(props) => props.theme.media.largeDesktop`
    width: 100%;
    padding-left: 0;
    padding-bottom: 65px;
    text-align: left;
  `}

  ul {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    ${(props) => props.theme.media.largeDesktop`
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
    `}
  }

  li {
    ${(props) => props.theme.media.largeDesktop`
      width: 100%;
    `}
  }

  a {
    display: inline-flex;
    align-items: center;
    padding: 13px 20px;
    color: #555555;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: bold;
    line-height: 19px;

    ${(props) => props.theme.media.largeDesktop`
      width: 100%;
      border-bottom: 1px solid #D6D6D6;

      padding: 0 20px;
      color: #000A12;
      font-family: "Montserrat", "Helvetica", sans-serif;
      font-size: 22px;
      font-weight: 700;
      line-height: 50px;
    `}

    :hover {
      opacity: 0.5;
      transition: all 0.2s ease;
      text-decoration: none;
    }
  }
`

const Em = styled.em`
  color: #d6d6d6;
  transform: translateY(1px);
  display: inline-block;

  ${(props) => props.theme.media.largeDesktop`
    display: none;
  `}
`

const NavCommunities = ({ isMobile, children }) => {
  const [showMenu, setShowMenu] = useState(!isMobile)
  const toggle = () => setShowMenu(!showMenu)
  return (
    <NavCommunitiesBlock showMenu={showMenu} order="11">
      <HeaderNavLabel>
        <HeaderIcon marginRight="6px">
          <DotsIcon />
        </HeaderIcon>
        Find Your Community
      </HeaderNavLabel>
      <Em>&nbsp;/</Em>
      <HeaderNavLabelMobile onClick={toggle} showMenu={showMenu}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <span>Communities </span>
          <Plus isOpen={showMenu} />
        </div>
      </HeaderNavLabelMobile>
      {children}
    </NavCommunitiesBlock>
  )
}

const NavCommunitiesBlock = styled(Nav)`
  color: #555555;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 15px;
  padding-bottom: 0 !important;

  ${(props) => props.theme.media.largeDesktop`
    order: 8;
  `}

  a {
    font-size: 12px;
    line-height: 15px;
    display: block;
    padding: 6px 10px;
    transition: all 0.2s ease;
    font-weight: 500;

    ${(props) => props.theme.media.largeDesktop`
      padding: 0 40px;
      color: #000A12;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      line-height: 50px;
      font-weight: normal;
      background: #fff;
      width: 100%;
      border-bottom: 1px solid #D6D6D6;
    `}
  }

  ${(props) => props.theme.media.largeDesktop`
    ul {
      ${(props) =>
        !props.showMenu &&
        css`
          display: none;
        `}
      ${(props) =>
        props.showMenu &&
        css`
          display: block;
        `}
    }
  `}
`

const Plus = ({ isOpen }) => {
  const icon = isOpen ? <FaMinus /> : <FaPlus />
  return <PlusWrapper>{icon}</PlusWrapper>
}

const PlusWrapper = styled.span`
  svg {
    height: 12px;
    width: 12px;
    stroke-width: 1px;
  }
`

const Children = styled.span`
  display: block;
  width: 6px;
  height: 6px;
  border-right: 1px solid #555555;
  border-bottom: 1px solid #555555;
  transform: rotate(45deg);
  position: relative;
  left: 10px;
  top: -1px;

  ${(props) => props.theme.media.largeDesktop`
    display: none;
  `}
`

const Menu = ({ href, text, children }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 1168px)` })
  const [showMenu, setShowMenu] = useState(false)
  const toggle = () => isMobile && setShowMenu(!showMenu)
  return (
    <>
      <MobileMenuLi showMenu={showMenu} isMobile={isMobile}>
        <a href="#" onClick={toggle}>
          {text}
          <Plus isOpen={showMenu} />
        </a>
        {children}
      </MobileMenuLi>
      <MenuLi>
        <a href={isMobile && href} onClick={toggle}>
          {text}
          <Children onClick={toggle} />
        </a>
        {children}
      </MenuLi>
    </>
  )
}

const MobileMenuLi = styled.li`
  display: none;

  ${(props) => props.theme.media.largeDesktop`
    display: flex;
  `}

  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  vertical-align: baseline;
  flex-direction: column;

  ul {
    position: relative;
    display: none;
  }

  ${(props) =>
    props.showMenu &&
    css`
      background: #000;
      color: #fff;

      & > a {
        color: #fff;
      }

      ul {
        position: relative !important;
        display: block;
      }
    `}

  a {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    text-decoration: none;
  }
  padding: 0;
  margin: 0;
  justify-content: space-between;
`

const MenuLi = styled.li`
  display: flex;

  ${(props) => props.theme.media.largeDesktop`
    display: none;
  `}

  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  vertical-align: baseline;

  ul {
    display: none !important;
  }
  :hover {
    ul {
      display: block !important;
    }
  }

  a {
    display: flex;
    justify-content: space-between;
    padding: 13px 20px;
    text-decoration: none;
  }
`

const SubMenu = styled.ul`
  line-height: 19px;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  padding: 10px;
  font-weight: 500;
  font-size: 13px;
  display: list-item;
  margin: 0;

  a {
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;

    ${(props) => props.theme.media.largeDesktop`
      padding: 0 40px !important;
      color: #000A12 !important;
      font-size: 16px !important;
      line-height: 50px !important;
      font-weight: normal !important;
      background: #fff;
      width: 100%;
      border-bottom: 1px solid #D6D6D6;
    `}
  }
`
