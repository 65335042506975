import { observable } from 'mobx'

/**
 * Author: Hanamantraya R
 * Class Name: ObjRssFeeds
 * Description: Observation Class to create object of class for RSS feeds.
 */
export default class {
  id = null;
  @observable name = null;
  @observable event_date = null;
  @observable event_type = null;
  @observable source_type = null;
  @observable description_short = null;
  @observable link = null;
  @observable media_url = null;
  @observable event_published = null;
  @observable event_order = null;
  @observable is_public = null;
  @observable is_registered = null;
  @observable is_paid = null;

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: Object(params)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (json) {
    this.id = json.id
    this.name = json.name
    this.event_date = json.event_date
    this.event_type = json.event_type
    this.source_type = json.source_type
    this.description_short = json.description_short
    this.link = json.link
    this.media_url = json.media_url
    this.event_published = json.event_published
    this.event_order = json.event_order
    this.is_public = json.is_public
    this.is_registered = json.is_registered
    this.is_paid = json.is_paid
  }

  /**
   * @Author: Hanamantraya R
   * @Method: jsonToArray
   * @Param: Object(json)
   * @Return: Array
   * Description: Return array of objects
   */
  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(json)
   * @Return: Object
   * Description: Return  objects
   */
  static constructFromJSON (json) {
    return new this(json)
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(entries)
   * @Return: Object
   * Description: Update the details of particular record
   */
  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
