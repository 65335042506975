export default {
  REQ_PLAN_NAME: 'Please enter plan name',
  ERR_PLAN_NAME: 'Plan Name must be at least 2 characters and max 100 characters',
  REQ_PLAN_AMOUNT: 'Please enter plan Amount',
  ERR_PLAN_AMOUNT: 'Please enter valid plan Amount (Amount in integer)',
  REQ_PLAN_DESC: 'Please enter plan description',
  REQ_PROFILE_FIRST_NAME: 'Please enter your first name',
  REQ_PROFILE_FIRST_NAME_MIN_LEN: 'Please enter at least 2 letters',
  ERR_FIRST_NAME: 'Invalid first name.',
  REQ_PROFILE_LAST_NAME: 'Please enter your last name',
  REQ_PROFILE_LAST_NAME_MIN_LEN: 'Last name requires at least 2 letters',
  ERR_LAST_NAME: 'Invalid last name.',
  REQ_PROFILE_PHONE_NUMBER: 'Please enter your phone number',
  ERR_PROFILE_PHONE_NUMBER: 'Invalid phone number. (ex. XXX-XXX-XXXX, XXXXXXXXXX)',
  ERR_PROFILE_IMG: 'Please select image file (png, jpg, jpeg, gif)',
  REQ_ADVISOR_FIRST_NAME: 'Please enter your first name',
  REQ_ADVISOR_FIRST_NAME_MIN_LEN: 'Please enter at least 2 letters',
  ERR_ADVISOR_FIRST_NAME: 'Invalid first name. Please enter letters only',
  REQ_ADVISOR_LAST_NAME: 'Please enter your last name',
  REQ_ADVISOR_LAST_NAME_MIN_LEN: 'Last name required minimum 2 letters',
  ERR_ADVISOR_LAST_NAME: 'Invalid last name. Please enter letters only',
  REQ_ADVISOR_PHONE_NO: 'Please enter your phone number',
  ERR_ADVISOR_PHONE_NO: 'Invalid phone number. (ex. XXX-XXX-XXXX, XXXXXXXXXX)',
  REQ_LOGIN_EMAIL: 'Please enter your email id',
  ERR_LOGIN_EMAIL: 'Invalid email address',
  REQ_LOGIN_PASSWORD: 'Please enter your password',
  REQ_ROLE_TYPE: 'Please enter role type',
  REQ_USER_ID: 'Please select user',
  REQ_PRODUCT_ID: 'Please select product',
  ERR_ROLE_TYPE: 'Role type must be minimum 2 and maximum 30 character allowed',
  REQ_ROLE_DESC: 'Please enter role description',
  ERR_ROLE_DESC: 'Role description allowed maximum 250 characters',
  REQ_LEFT_PENEL_OPT_NAME: 'Please enter left panel option name',
  ERR_LEFT_PENEL_OPT_NAME: 'Left penel option name must be minimum 2 and maximum 30 character allowed',
  REQ_LEFT_PENEL_OPT_DESC: 'Please enter your description',
  REQ_LEFT_PENEL_OPT_LOGO: 'Please select the left panel option logo',
  ERR_LEFT_PENEL_OPT_LOGO: 'Please select image file (png, jpg, jpeg, gif)',
  REQ_USER_ROLE_TYPE: 'Please select role type',
  REQ_USER_FIRST_NAME: 'Please enter your first name',
  REQ_USER_FIRST_NAME_MIN_LEN: 'Please enter at least 2 letters',
  ERR_USER_FIRST_NAME: 'Invalid first name. Please enter letters only',
  REQ_USER_LAST_NAME: 'Please enter your last name',
  REQ_USER_LAST_NAME_MIN_LEN: 'Last name required minimum 2 letters',
  ERR_USER_LAST_NAME: 'Invalid last name. Please enter letters only',
  REQ_USER_EMAIL: 'Please enter an email',
  ERR_USER_EMAIL: 'Invalid email address',
  REQ_USER_PHONE_NO: 'Please enter your phone number',
  ERR_USER_PHONE_NO: 'Invalid phone number. (ex. XXX-XXX-XXXX, XXXXXXXXXX)',
  ERR_CHANGE_PASSWORD_REQ: 'Please enter new password',
  ERR_CHANGE_PASSWORD_CONFIRM_REQ: 'Please enter confirm password',
  ERR_CHANGE_PASSWORD_NEW_PWD: 'Password should not be less than 6 characters. Password should contain at least one upper case, one lower case, one number and one special character.',
  ERR_CHANGE_PASSWORD_NOT_MATCH: 'Passwords do not match',
  REQ_SSO_PARTNER_NAME: 'Please enter partner name',
  REQ_SSO_PARTNER_SITE: 'Please enter partner site',
  REQ_SSO_PARTNER_PRODUCT_NAME: 'Please enter product name',
  REQ_SSO_PARTNER_AUTH_DOMAIN: 'Please enter SSO authenticate domain name',
  ERR_SSO_PARTNER_NAME: 'Invalid partner name. Please enter characters only',
  ERR_SSO_PARTNER_PRODUCT_NAME: 'Invalid product name. Please enter characters only',
  ERR_SSO_PARTNER_SITE: 'Invalid partner site URL. Please enter valid URL',
  ERR_WIDGET_NAME_REQ: 'Please enter crm widget name',
  ERR_WIDGET_SIZE_REQ: 'Please enter crm widget size',
  ERR_WIDGET_HEIGHT_REQ: 'Please enter crm widget height',
  ERR_EVENT_NAME_REQ: 'Please enter event name',
  ERR_EVENT_TYPE_REQ: 'Please select event type',
  ERR_EVENT_DATE_REQ: 'Please select event date',
  ERR_EVENT_SOURCE_TYPE_REQ: 'Please enter source type',
  ERR_EVENT_DESC_REQ: 'Please enter the description',
  ERR_EVENT_LINK_REQ: 'Please enter event link',
  ERR_EVENT_MEDIA_URL_REQ: 'Please enter media URL',
  ERR_ENROL_FNAME_REQ: 'Please enter first name',
  ERR_ENROL_LNAME_REQ: 'Please enter last name',
  ERR_ENROL_EMAIL_REQ: 'Please enter email id',
  ERR_SURVEY_QUES_ORDER: 'Please enter a valid number',
  ERR_SURVEY_QUES: 'Please enter a valid question',
  ERR_ENROL_PHONE_REQ: 'Please enter phone number',
  ERR_ENROL_PHONE_ERR: 'Invalid phone number. Ex. (XXX) XX XXXX or XXXXXXXXX',
  ERR_PAGE_NAME_REQ: 'Please enter page name',
  ERR_PAGE_NAME_ERR: 'Minimum 2 and Maximum 250 character allows',
  ERR_PAGE_PATH_REQ: 'Please enter page path',
  ERR_PAGE_PATH_ERR: 'Invalid page path. Please enter only alphabets, hyphen and underscore',
  ERR_PARTNER_NAME_REQ: 'Please enter partner name',
  ERR_PARTNER_WEBSITE_REQ: 'Please enter partner website',
  ERR_PARTNER_WEBSITE_ERR: 'Please enter valid URL (ex. http://xyz.com or http://www.xyz.com)',
  ERR_PARTNER_IMAGE_REQ: 'Please select a partner image',
  ERR_PARTNER_IMAGE_ERR: 'Invalid file format. Executable files are allowed png,jpg,jpeg,jpe,gif',
  ERR_PARTNER_DESC_REQ: 'Please enter partner description',
  ERR_PRODUCT_MEDIA_CAROUSEL_NAME: 'Please enter valid media carousel name',
  ERR_PRODUCT_MEDIA_TYPE_REQ: 'Please enter type',
  ERR_PRODUCT_MEDIA_TYPE_ERR: 'Please enter valid URL (ex. http://xyz.com or http://www.xyz.com)',
  ERR_PRODUCT_MEDIA_IMG: 'Please select a valid image',
  ERR_SELECT_BANNER: 'Please select a image',
  ERR_PRODUCT_MEDIA_ID_REQ: 'Please enter a Product',
  ERR_PRODUCT_MEDIA_DOCS: 'Please input valid document types',
  ERR_PRODUCT_MEDIA_ORDER_REQ: 'Please enter a valid order (Positive Integer).',
  ERR_BANNER_DISPLAY: 'Please select display',
  ERR_PROD_TAG_NAME_REQ: 'Please enter product tag name',
  ERR_PROD_TAG_GROUP_REQ: 'Please enter group name',
  ERR_PROD_NAME_REQ: 'Please enter product name',
  ERR_PROD_PARTNER_REQ: 'Please select partner name',
  ERR_PROD_SIZE_REQ: 'Please select product size',
  ERR_PROD_PRICE_REQ: 'Please enter product price',
  ERR_PROD_PRICE_ERR: 'Please enter valid product amount (Amount in integer)',
  ERR_PROD_PRICE_MIN_ERR: 'product price should be >= 0',
  ERR_PROD_PRICE_MAX_ERR: 'Product price should be minimum 1 and maximux 10 digit',
  ERR_PROD_DESC_REQ: 'Please enter product description',
  ERR_PROD_CAT_REQ: 'Please enter product category',
  ERR_PROD_SLUG_ID_REQ: 'Please select product logo',
  ERR_PROD_SLUG_REQ: 'Please enter valid slug',
  ERR_PROD_ACTION_TYPE_REQ: 'Please select action type',
  ERR_PROD_URL_ERR: 'Please enter valid URL (ex. http://xyz.com or http://www.xyz.com)',
  ERR_PROD_DOMAIN_ERR: 'Please enter valid domain (ex. example-company.com)',
  ERR_PROD_IMG_REQ: 'Please select a product image',
  ERR_PROD_IMG_ERR: 'Invalid file format. Executable files are allowed png,jpg,jpeg,jpe,gif,svg',
  ERR_PROD_DOC_ERR: 'Invalid file format. It must be a pdf,doc,docx',
  ERR_PROSITE_EMAIL_REQ: 'Please enter your email',
  ERR_PROSITE_SITE_ID_REQ: 'Please enter your site id',
  ERR_REDTAIL_USERNAME_REQ: 'Please enter your username',
  ERR_REDTAIL_PASSWORD_REQ: 'Please enter your password',
  ERR_WIDGET_NAME_VALIDATOR: 'Please enter widget name',
  ERR_WIDGET_SIZE_VALIDATOR: 'Please enter widget size',
  ERR_WIDGET_LOGO_VALIDATOR: 'Please select widget logo',
  ERR_WIDGET_SLUGID_VALIDATOR: 'Please select widget slug id',
  ERR_USER_FIRST_NAME_VALIDATOR: 'Please enter first name',
  ERR_USER_LAST_NAME_VALIDATOR: 'Please enter last name',
  ERR_USER_FIRM_NAME_VALIDATOR: 'Please enter firm name',
  ERR_USER_RIA_NAME_VALIDATOR: 'Please enter RIA name',
  ERR_USER_ROLE_VALIDATOR: 'Please select user role',
  ERR_USER_PARTNER_VALIDATOR: 'Please select a partner',
  ERR_USER_ADDRESS1_VALIDATOR: 'Please enter street address 1',
  ERR_USER_ADDRESS2_VALIDATOR: 'Please enter street address 2',
  ERR_USER_CITY_NAME_VALIDATOR: 'Please enter city name',
  ERR_USER_STATE_NAME_VALIDATOR: 'Please enter state name',
  ERR_USER_ZIP_CODE_VALIDATOR: 'Please enter zip code',
  ERR_USER_BROKER_DEALER_VALIDATOR: 'Please enter Broker Dealer(s)',
  ERR_USER_CUSTODIANS_VALIDATOR: 'Please enter Custodian(s)',
  ERR_USER_AUN_VALIDATOR: 'Please enter AUN',
  ERR_USER_ARCH_VALIDATOR: 'Please enter User Archetype',
  ERR_USER_GDC_VALIDATOR: 'Please enter 12 month trailing GDC',
  ERR_USER_NUM_HOUSEHOLDS_VALIDATOR: 'Please enter number of households',
  ERR_USER_NUM_EMPLOYEES_VALIDATOR: 'Please enter number of employees',
  ERR_USER_NUM_EMPLOYEES_INVALID_VALIDATOR: 'Please enter a valid number',
  ERR_USER_INVALID_ZIP_CODE_VALIDATOR: 'Please enter ZIP',
  ERR_USER_INVALID_PHONE_VALIDATOR: 'Invalid phone number. (Only use numbers and hyphens)',
  ERR_USER_REG_DETAILS_VALIDATOR: 'Please enter details',
  ERR_SIGNUP_ARCHE_TYPE_VALIDATOR: 'Please select the type',
  ERR_SIGNUP_COMPANY_NAME_VALIDATOR: 'Please enter company name',
  ERR_SIGNUP_COMPANY_REVENUE_VALIDATOR: 'Please enter your revenue',
  ERR_SIGNUP_PASSWORD_VALIDATOR: 'Enter your password',
  ERR_SIGNUP_CONF_PASS_VALIDATOR: 'Type your password again',
  ERR_SIGNUP_PASS_MATCH_VALIDATOR: 'Passwords must match',
  ERR_RSS_FEED_NAME_VALIDATOR: 'Please enter rss feed name',
  ERR_RSS_FEED_ENTER_LINK_VALIDATOR: 'Please enter rss feed link',
  ERR_RSS_FEED_URL_VALIDATOR: 'Please enter valid URL (ex. http://xyz.com or http://www.xyz.com)',
  ERR_ROLE_NAME_VALIDATOR: 'Please enter role name',
  ERR_ROLE_DESCRIPTION_VALIDATOR: 'Please enter role description',
  ERR_PRODUCT_TAG_NAME_VALIDATOR: 'Invalid product tag name.',
  ERR_PRODUCT_GROUP_NAME_VALIDATOR: 'Invalid product group name.',
  ERR_NEW_PASS_OLD_PASS: 'Old password and new password should not same.',
  ERR_SOMETHING_WENT_WRONG: 'Ouch. Something went wrong!',
  ERR_CARD_NAME: 'Please enter name on card',
  ERR_INVALID_CARD_NAME: 'Invalid card name',
  ERR_VALID_CARD_DETAILS: 'Please enter the valid card details',
  ERR_SELECT_PRODUCT: 'Please select the products',
  ERR_SELECT_PAYMENT_CARD: 'Please select the payment card',
  ERR_VALID_NUMBER_STAFF: 'Please enter a valid number for total staff in your practice.',
  ERR_VALID_NUMBER_NEW_HOUSEHOLD: 'Please enter a valid number for new households you like to add each year.',
  ERR_NUMBER_WEB_MARKETING_SAVVY: 'Please select a number for web-marketing savvy.',
  ERR_SELECT_GROUP_BENEFITS: 'Please select whether you would consider group benfits.',
  ERR_BUYING_SELLING_PRACTICE: 'Please select an option for buying or selling a practice.',
  ERR_OFFICE_SUPPLY_EXPENDITURE: 'Please enter a valid number how much you spend on office supplies.',
  ERR_TECHNOLOGY_EXPENDITURE: 'Please enter a valid number how much you spend on technology programs.',
  ERR_HOW_CAN_WE_HELP: 'Please enter the purpose of this request.',
  ERR_USER_NOT_SELECTED: 'Please select the user',
  ERR_TAG_NAME_REQ: 'Please enter tag name',
  ERR_STAR_RATING_REQ: 'Please enter a valid rating',
  ERR_STAR_RATING_VALUE: 'Rating must be in between 0 to 5',
  ERR_STAR_RATING_DECIMAL_VALUE: 'Count step for rating is 0.5 only',
  ERR_SELECT_PRODUCT_VALIDATOR: 'Product id does not match',
  ERR_FAQ_QUESTION_REQ: 'Please enter question',
  ERR_FAQ_ANSWER_REQ: 'Please enter answer',
  TERMS_AND_CONDITIONS: 'Please accept the terms and conditions',
  ERR_CLAIMS_EXPLANATION: 'Field can not be blank',
  ERR_RISE_TO_CLAIMS_EXPLANATION: 'Field can not be blank',
  ERR_PII_PHI_COUNT: 'Please make a selection',
  ERR_CC_CUSTOMER_COUNT: 'Please make a selection',
  ERR_INDUSTRY: 'Please make a selection',
  ERR_EFFECTIVE_DATE: 'Please select a date',
  ERR_INVALID_DATE: 'Date must be today or later.',
  ERR_SAVINGS: 'Please enter the estimated savings.',
  ERR_ORGANIZATION: 'Please enter a valid name for your organization.',
  ERR_MISSING_VALUE: 'Required field missing value.'
}
