import React from 'react'
import MediaInput from '../../../site/MediaInput'
import { getImageUrl } from '../utils'
import styled from 'styled-components'

export const MediaInputPB = ({
  setCurrentComponent,
  name,
  image,
  label,
  handleChange = (e) => {
    if (e === null) {
      setCurrentComponent((prev) => {
        const temp = { ...prev }
        temp.props[name].media_center = null
        temp.props[name].urls = []
        return temp
      })
    } else {
      setCurrentComponent((prev) => {
        const temp = { ...prev }
        if (temp?.props[name]) {
          temp.props[name].media_center = e
        } else {
          temp.props[name] = {
            media_center: e,
            opacity: 1,
            urls: []
          }
        }
        return temp
      })
    }
  },
  currentComponent = {}
}) => {
  const imageUrl = getImageUrl(image)
  return (
    <Wrapper>
      {label}
      <MediaInput
        key={image}
        onlyImages={true}
        multiple={false}
        handleChange={handleChange}
        showDimensions={false}
        currentUrl={imageUrl}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``
