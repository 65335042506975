import React from 'react'
import styled, { css } from 'styled-components'

export const Button = (props) => (
  <ButtonStyle {...props}>{props.children}</ButtonStyle>
)

export const Link = (props) => (
  <ButtonStyle as="a" {...props}>
    {props.children}
  </ButtonStyle>
)

const ButtonStyle = styled.button`
  color: ${(props) =>
    props.theme.colors.primary_text || props.theme.colors.white};
  background-color: ${(props) =>
    props.theme.colors.primary || props.theme.colors.lightBlue};
  ${(props) =>
    props.danger &&
    css`
      background-color: ${(props) => props.theme.colors.danger};
    `}
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border-radius: ${(props) => props.borderRadius || '4px'};
  border: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  padding: 20px 21px;
  font-size: 14px;
  font-size: var(--chalice-cta-font-size);
  line-height: 18px;
  transition: all 0.25s;

  ${(props) =>
    props.small &&
    css`
      line-height: 12px;
      font-size: 12px;
      padding: 10px;
    `}

  &:active,
  &:focus {
    text-decoration: none !important;
    color: ${(props) =>
      props.theme.colors.primary_text || props.theme.colors.white} !important;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.9;
    transition: all 0.25s;
    color: ${(props) =>
      props.theme.colors.primary_text || props.theme.colors.white};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5 !important;
    `}

  ${(props) =>
    props.hollow &&
    css`
      color: ${(props) => props.theme.colors.primary};
      background-color: transparent;
      border: ${(props) => props.theme.colors.primary} solid 1px;

      &:hover {
        text-decoration: none;
        opacity: 0.9;
        transition: all 0.25s;
        color: ${(props) => props.theme.colors.black};
      }

      ${ props => props.active && css`
        color: ${(props) => props.theme.colors.white} !important;
        background-color: ${(props) => props.theme.colors.primary};
        border: ${(props) => props.theme.colors.primary} solid 1px;
      `}
    `}

  ${(props) =>
    props.info &&
    css`
      color: ${(props) => props.theme.colors.black};
      background-color: ${(props) => props.theme.colors.white};
      border: ${(props) => props.theme.colors.white} solid 1px;

      &:hover {
        color: ${(props) => props.theme.colors.black};
        text-decoration: none;
        transition: all 0.25s;
        opacity: 0.8;
      }

      &:active,
      &:focus {
        color: ${(props) => props.theme.colors.black} !important;
        background-color: ${(props) => props.theme.colors.white};
        border: ${(props) => props.theme.colors.white} solid 1px;
      }
    `}
`
