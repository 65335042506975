import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { pageView } from '../utils'
import { inject, observer } from 'mobx-react'
// import Logo from '/assets/images/logo.png'

const IMG =
  'https://www.chalicenetwork.com/home-assets/images/home/main_logo@2x.png'

const LOGO_LD = `
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      "url": "https://www.chalicenetwork.com/",
      "logo": "${IMG}"
    }
`

const CORPORATE_LD = `
  {
    "@context": "http://schema.org",
    "@type": "Organization",
    "url": "https://www.chalicenetwork.com/",
    "contactPoint": [
      { "@type": "ContactPoint",
        "telephone": "+1-800-535-6981",
        "contactType": "Sales"
      }
    ]
  }
`

const SOCIAL_LD = `
  {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Chalice Financial Network",
    "url": "https://www.chalicenetwork.com/",
    "sameAs": [
      "https://www.facebook.com/chalicenetwork/",
      "https://www.linkedin.com/company/chalicenetwork/",
      "https://twitter.com/ChaliceNetwork"
    ]
  }
`

const PageTitle = inject('store')(
  observer(
    ({ store, includeTitle, title, description, imageUrl, keywords }) => {
      const { siteData } = store
      const genTitle = () => {
        if (includeTitle) {
          return `${siteData.get('name')} - ${title}`
        } else {
          return title
        }
      }
      useEffect(() => {
        pageView(genTitle())
      }, [])

      const t = genTitle()
      const meta = []
      meta.push({ name: 'title', content: t })
      meta.push({ name: 'og:title', content: t })
      meta.push({ name: 'twitter:title', content: t })
      meta.push({ name: 'og:type', content: 'website' })
      if (description) {
        meta.push({ name: 'description', content: description })
        meta.push({ name: 'og:description', content: description })
        meta.push({ name: 'twitter:description', content: description })
      }
      if (keywords) {
        meta.push({
          name: 'keywords',
          content: keywords
        })
      }
      if (imageUrl) {
        meta.push({
          name: 'og:image',
          content: imageUrl
        })
      } else {
        meta.push({
          name: 'og:image',
          content: siteData.get('logo_media_center')?.media
        })
      }
      return (
        <Head>
          <title>
            {t} | {siteData.get('name')}
          </title>
          {meta.map((m) => (
            <meta key={m.name} name={m.name} content={m.content} />
          ))}
          {siteData.get('slug') === 'www' && (
            <>
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: LOGO_LD }}
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: CORPORATE_LD }}
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: SOCIAL_LD }}
              />
            </>
          )}
        </Head>
      )
    }
  )
)

PageTitle.propTypes = {
  title: PropTypes.string,
  includeTitle: PropTypes.bool,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  keywords: PropTypes.node
}

export default PageTitle
