import { observable } from 'mobx'

export default class {
  id = null;
  @observable type = null;
  @observable documents = [];
  @observable order = null;
  @observable media_carousel_name = null;

  constructor (json) {
    this.id = json.id
    this.type = json.type
    this.documents = json.product_documents
    this.order = json.order
    this.media_carousel_name = json.media_carousel_name
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
