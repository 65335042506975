import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion, useScroll } from 'framer-motion'
import { inject, observer } from 'mobx-react'
import { useQuery } from 'react-query'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { toast } from 'react-toastify'

import services from '/components/services'
import { APIURL } from '/constants'
import { Button } from '/components/theme/button/Button'

const TermsForm = ({ content, setShowModal }) => {
  const contentRef = useRef(null)
  /*
  const { scrollYProgress } = useScroll({
    container: contentRef
  })
  */
  const accept = async () => {
    const res = await services.post_v2(APIURL.API_LEGAL_ACCEPT('tos'))
    if (res.statusCode === 200) {
      setShowModal(false)
    } else {
      toast.error(
        'Unable to accept terms at this time. Please try again later!'
      )
    }
  }
  return (
    <DialogOverlay isOpen={true} style={{ zIndex: 100002 }}>
      <DialogContentStyled
        aria-label="Modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Wrapper>
          <H2>Terms of Service</H2>
          {/*<Progress style={{ scaleX: scrollYProgress }} />*/}
          <Message ref={contentRef}>
            <div
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          </Message>
          <ActionRow>
            <Button onClick={accept}>Accept Terms</Button>
          </ActionRow>
        </Wrapper>
      </DialogContentStyled>
    </DialogOverlay>
  )
}

const LazyForm = () => {
  const [showModal, setShowModal] = useState(true)
  const { data, isLoading } = useQuery('tos', () =>
    services.get_v2(APIURL.API_LEGAL('tos'))
  )
  if (isLoading) {
    return null
  }
  return (
    <div>
      {showModal && (
        <AnimatePresence>
          <TermsForm content={data.response} setShowModal={setShowModal} />
        </AnimatePresence>
      )}
    </div>
  )
}

export const AcceptTerms = inject('store')(
  observer(({ store }) => {
    const { userData } = store.sessionData

    // Is the profile still loading?
    if (store.profileLoading) {
      return null
    }
    // User is not an admin
    if (!userData.needs_to_accept_terms) {
      return null
    }

    return <LazyForm />
  })
)

const Wrapper = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const H2 = styled.h2`
  flex: 0 1 auto;
`

const Message = styled(motion.div)`
  position: relative;
  flex: 1 1 auto;
  overflow-y: scroll;
  min-height: 300px;
  padding: 10px;
  border: ${(props) => props.theme.colors.lightGrey} solid 1px;
`

const ActionRow = styled.div`
  flex: 0 1 auto;
  padding: 10px 0;
  display: flex;

  ${(props) => props.theme.media.phone`
    text-align: center;
    justify-content: center;
  `}
`

const MotionDialogContent = motion(DialogContent)

const DialogContentStyled = styled(MotionDialogContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0;
  width: 50vw;
  height: 80vh;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 15px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;

  ${(props) => props.theme.media.phone`
    width: 90vw;
    height: 90vh;
    margin: 2vh auto;
    padding: 0;
    top: 0;
  `}
`

const Progress = styled(motion.div)`
  display: flex;
  flex: 0 1 auto;
  height: 10px;
  background: ${(props) => props.theme.colors.primary};
  transform-origin: 0%;
`
