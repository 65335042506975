import dayjs from 'dayjs'

const currentYear = dayjs().format('YYYY')
let activation_key = ''
let client_id = ''
let client_domain = ''
let quick_books_client_id = ''
let quick_books_accounts_url = ''
let smartx_client_id = ''
let smartx_domain = ''
let vestwell_client_id = ''
let sso_sl_host = ''
let sso_sl_domain = ''
let vestwell_domain = ''
let environment = ''
let orion_url = ''
let benefithub_url = ''
const intercom_app = process.env.NEXT_PUBLIC_INTERCOM_ID
const orion_client_id = '1692'
const orion_advisor_url = 'https://rep.orionadvisor.com/sso.html'
const calendly_widget_link =
  'https://assets.calendly.com/assets/external/widget.js'

let defaultDomain = 'chalicenetwork.com'
if (typeof window !== 'undefined') {
  const host_name = document.domain
  if (host_name.indexOf('staging.chalicenetwork.com') !== -1) {
    sso_sl_host = 'https://sandbox.successionlink.com/'
    sso_sl_domain = 'sandbox.successionlink.com'
    defaultDomain = 'staging.chalicenetwork.com'
  } else if (host_name.indexOf('.local.com') !== -1) {
    sso_sl_host = 'http://successionlink.local.com:5000/'
    sso_sl_domain = 'successionlink.local.com'
    defaultDomain = 'local.com'
  } else {
    sso_sl_host = 'https://successionlink.com/'
    sso_sl_domain = 'successionlink.com'
  }

  if (
    host_name.indexOf('staging.chalicenetwork.com') !== -1 ||
    host_name.indexOf('chalicefintech.local.com') !== -1
  ) {
    quick_books_client_id = 'Q0GOvwn5stDnphf8Bp5YKW0lpM8CAYR25ahOznlY7e190CRLOs'
    activation_key =
      'kG2C6D5C3tA1A1B1G2H4B1C1B1D7A5oqvB3C3A5A4C2E4A4H3A2C2B3D6B3F2F4puE3J-7lyE-13E6F1H5lC-7G-7bfe1pF2vuxj1trB-13ilF3bmn=='
    client_id = '97789787'
    client_domain = 'txact-staging.chalicealts.com'
    smartx_client_id =
      'sk_ch_ce17cb7bf8f36c6d75e7f43704ad98cbd69932d0397e892cb32ded233329929a'
    smartx_domain = 'smartx.smartxadvisory.com'
    quick_books_accounts_url = 'https://sandbox.qbo.intuit.com/'
    vestwell_client_id =
      'sk_ch_2dca1b4b331c3c2b955e262810256399e7dbf079e45c15bb7d07bea419cd99b6'
    vestwell_domain = 'sso-test-login.vestwell.com'
    environment = 'development'
    orion_url = 'https://testapi.orionadvisor.com/api'
    benefithub_url = 'https://passport.benefithub.info/saml/post/ac'
  } else {
    activation_key =
      'EB3C3E3G2tA6B4C3A3C7E1F1E1E4A3owB-16wB-11qzC-7H-7usjzciD3i1kh=='
    client_id = '97'
    client_domain = 'txact.chalicealts.com'
    quick_books_client_id = 'Q0xrUUHwanghXzj78tOVjdVAXDrPrKdm7lTa5vNATSTGKEqUAm'
    quick_books_accounts_url = 'https://qbo.intuit.com/'
    environment = 'production'
    smartx_client_id =
      'sk_ch_ce17cb7bf8f36c6d75e7f43704ad98cbd69932d0397e892cb32ded233329929a'
    smartx_domain = 'smartx.smartxadvisory.com'
    vestwell_client_id =
      'sk_ch_2dca1b4b331c3c2b955e262810256399e7dbf079e45c15bb7d07bea419cd99b6'
    vestwell_domain = 'sso-test-login.vestwell.com'
    orion_url = 'https://api.orionadvisor.com/api'
    benefithub_url = 'https://passport.benefithub.info/saml/post/ac'
  }
}

const recaptchaKey = '6Le7iZIUAAAAAC3uhU9HNiZ_-bocsbB5TdXBjGj2'

export default {
  ENVIRONMENT: environment,
  FLASH_MSG_INTERVAL: 20000,
  INTERCOM_APP: intercom_app,
  SSO_REDTAIL_INTERVAL: 250000,
  MAIANTAINANCE_INTERVAL: 60000,
  RECAPTCHA_KEY: recaptchaKey,
  SALE_FORCE_KEY: 'hanm212@gmail.com',
  CURRENCY_TYPE: [{ id: 'USD', name: 'USD', value: 'USD' }],
  VENTURE_CLIENT_ID: client_id,
  VENTURE_DOMAIN_AUTH: client_domain,
  QUICKBOOKS_CLIENT_ID: quick_books_client_id,
  SMARTX_CLIENT_ID: smartx_client_id,
  SMARTX_DOMAIN: smartx_domain,
  QUICKBOOKS_ACCOUNT_URL: `${quick_books_accounts_url}login`,
  VEESTWELL_CLIENT_ID: vestwell_client_id,
  VESTWELL_DOMAIN: vestwell_domain,
  // SUBSCRIPTION_TIME: {'Month': 'Month'},
  ORION_URL: orion_url,
  ORION_CLIENT_ID: orion_client_id,
  ORION_ADVISOR_URL: orion_advisor_url,
  BENEFIT_URL: benefithub_url,
  CURRENCY_CENTS: 100,
  HTTP_ERROR_500: 500,
  SUB_STEP_MEMBERSHIP_PLAN: 'sub_membership_plan',
  SUB_STEP_ORDER_DETAIL: 'sub_order_detail',
  SUB_STEP_FINISH: 'sub_finish',
  CALENDLY_WIDGET_LINK: calendly_widget_link,
  CONFERENCE_PRICE: 29900,
  CONFERENCE_PRICE_EARLY: 19900,
  CONFERENCE_PRICE_MEMBER: 0,
  CONFERENCE_PRICE_MEMBER_EARLY: 0,
  EARLYBIRD: 10000,
  // MEMBER_EARLYBIRD: 34900,
  // MEMBER_NOT_EARLY: 30000,
  MEMBERSHIP_PRICE: 10000,
  ADVISOR_ROLE: 1,
  SUPER_ADMIN_ROLE: 2,
  ENTERPRISE_ADMIN_ROLE: 3,
  ANY_ROLE: [1, 2, 3],
  ADMINS: [2, 3],
  FOOTER_MSG: `Copyright © ${currentYear} Chalice Financial Network`,
  LOGIN_FOOTER_MSG: `Copyright © ${currentYear}. Terms and Condition. Privacy`,
  PAGE_SIZE: [5, 10, 25, 50, 100, 250],
  DEFAULT_PER_PAGE: 10,
  SIGNUP_IFRAME:
    'https://analytics.clickdimensions.com/chalicefintechcom-az2tx/pages/n0lbbvjxeeipcaanojzahg.html',
  PAGE_PATH_REGEXP: /^([A-Z]|[a-z])([A-Z]|[a-z]|_|-)*$/,
  PASSWORD_REGEXP:
    /^^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
  REGRET_URL:
    /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  REGRET_DOMAIN_URL:
    /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i,
  PHONE_REGEXP: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  PHONE_NUMBER_REGEXP: /^[1-9][0-9-]*$/,
  NAME_REGEXP: /^[A-Za-z]+[A-Za-z \s]*$/,
  ALPHA_NEMERIC_REGEXP: /^[A-Za-z1-9]+[A-Za-z0-9 \s_-]*$/,
  ZIP_REGEXP: /^[0-9]+$/,
  EMAIL_REGEXP:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  MEMBERSHIP_DECLARATION:
    'General membership fee is to gain access to the Chalice Financial Network and entire suite of products and services fully integrated into the Chalice FinTech Platform. This is annual subscription paid monthly at',
  MENU_ACTIVE_CLASS: 'active',
  SSO_TYPE_VENTURE_CO_AUTH: 1,
  SSO_TYPE_SSO_URL: 2,
  SSO_TYPE_CUSTOM_WEALTH_FORGE: 3,
  SSO_TYPE_CUSTOM_VESTWELL: 4,
  SSO_TYPE_AUTH: 5,
  SSO_TYPE_PROSITE: 6,
  SSO_TYPE_QUICKBOOK: 7,
  SSO_TYPE_REDTAIL: 8,
  SSO_TYPE_ORION: 9,
  SSO_TYPE_BENEFITHUB: 10,
  CONTAINER_OPTIONs: [
    { id: 1, name: 'One Column' },
    { id: 2, name: 'Two Column' },
    { id: 3, name: 'Three Column' }
  ],
  TEXT_EDITOR_ACTIVATION_KEY: activation_key,
  SUBSCRIPTION_TIME: [{ id: 'Month', name: 'Month', value: 'month' }],
  ERROR_FEBRIDDEN: 403,
  STRIPE_MEMB_PLAN_INTERVAL_COUNT: [
    { id: 1, name: 1, value: 1 },
    { id: 12, name: 12, value: 12 }
  ],
  SUCCESS: 'success',
  FAILURE: 'failure',
  PASSWORD_EXPIRED: 1,
  PASSWORD_NOT_EXPIRED: 0,
  IS_ROUTE_PAGE: true,
  USER_ACTIVE: 1,
  CHAT_SCRIPT:
    '<script src="https://pochatcentralus.crm.powerobjects.net/chatbox/pochat.js.aspx?t=Yz0lMkZZJTJCU2xoUXRzVWFkUUtxRHBPUEc5ZyUzRCUzRCZiPURNUnhBelNkS2tPTFRHR3lXVGlDZ3clM0QlM0Q%3D"></script>',
  QB_FILTERS: [
    { label: 'Last 30 days', filter_type: 'last_30_days' },
    { label: 'This month', filter_type: 'this_month' },
    { label: 'This quarter', filter_type: 'this_quarter' },
    { label: 'This year', filter_type: 'this_year' },
    { label: 'Last month', filter_type: 'last_month' },
    { label: 'Last quarter', filter_type: 'last_quarter' },
    { label: 'Last year', filter_type: 'last_year' }
  ],
  PAYMENT_CARD_IMG: [
    { src: '/assets/images/card-images/visa-card.png', label: 'Visa' },
    { src: '/assets/images/card-images/master-card.png', label: 'Visa' },
    { src: '/assets/images/card-images/discover-card.png', label: 'Visa' },
    {
      src: '/assets/images/card-images/american-express-card.png',
      label: 'Visa'
    }
  ],
  ENROLL_REQUEST: {
    header_name:
      'Please provide the following information to schedule an enrollment consultation with {}.',
    confirmation_msg: 'You have already applied for the {}.'
  },
  SSO_SL_DOMAIN: sso_sl_domain,
  SSO_SL_HOST_LOGIN_N_REDIRECT: `${sso_sl_host}idp/login?next=`,
  SSO_SL_REDIRECT_TO_ACCOUNT: `${sso_sl_host}account/`,
  SSO_SL_HOST_LOGIN: `${sso_sl_host}idp/login?override_next=/caex/login`,
  ENROLMENT_TYPES: [
    { id: 'grouphealthinsurance', name: 'Group Health Insurance' },
    { id: 'payrollprocessinghr', name: 'Payroll Processing & HR' },
    { id: 'free401kplan', name: 'Free401(k)Plan' },
    { id: 'freeepli', name: 'Free EPLI' },
    { id: 'bookkeepingsvc', name: 'Book Keeping Svc' },
    { id: 'finreportingandanalysis', name: 'Fin Reporting & Analysis' },
    { id: 'budgetingforecasting', name: 'Budgeting & Forecasting' },
    { id: 'complimentarywebsite', name: 'Complimentary Website' },
    { id: 'leadgenseoppc', name: 'Lead Gen SEO & PPC' },
    { id: 'leadgen', name: 'Lead Gen Opt. Soc. Media' },
    { id: 'qbacctsoftware', name: 'QB Acct. Software' },
    {
      id: 'branddevcollateralcreation',
      name: 'Brand Dev & Collateral Creation'
    },
    { id: 'marketingstraexeplan', name: 'Marketing Stra. & Exe Plan' },
    { id: 'orionadvisor', name: 'Orion Advisor' },
    { id: 'emoneyadvisor', name: 'eMoney Advisor' }
  ],
  USER_ARCHE_TYPES: [
    {
      id: '100000000',
      name: 'Start My Business',
      category: 'wirehouse-breakaway'
    },
    {
      id: '100000001',
      name: 'Grow My Business',
      category: 'practice-marketing'
    },
    {
      id: '100000002',
      name: 'Run My Business Efficiently',
      category: 'practice-technology'
    },
    { id: '100000003', name: 'Protect And Secure My Business', category: '' },
    {
      id: '100000004',
      name: 'Sell My  Business and Retire',
      category: 'mna-sell'
    }
  ],
  brokerDealers: [
    { id: '100000000', name: 'Cambridge' },
    { id: '100000010', name: 'Cetera' },
    { id: '100000001', name: 'Chalice Capital Partners' },
    { id: '100000020', name: 'Charles Schwab' },
    { id: '100000030', name: 'Commonwealth' },
    { id: '100000040', name: 'D.A. Davidson' },
    { id: '100000003', name: 'David Notes & Co' },
    { id: '100000050', name: 'Edward Jones' },
    { id: '100000060', name: 'Fidelity' },
    { id: '100000070', name: 'FiNet' },
    { id: '100000080', name: 'FSC' },
    { id: '100000081', name: 'IFG' },
    { id: '100000002', name: 'Item' },
    { id: '100000090', name: 'JP Morgan' },
    { id: '100000100', name: 'LPL Financial' },
    { id: '100000110', name: 'Merrill Lynch' },
    { id: '100000120', name: 'Morgan Stanley' },
    { id: '100000210', name: 'None' },
    { id: '100000004', name: 'Oppenheimer' },
    { id: '100000200', name: 'Other' },
    { id: '100000130', name: 'Pershing' },
    { id: '100000140', name: 'Raymond James' },
    { id: '100000150', name: 'RBC' },
    { id: '100000160', name: 'Securian' },
    { id: '100000170', name: 'Stifel Nicolaus' },
    { id: '100000180', name: 'UBS' },
    { id: '100000190', name: 'Wells Fargo Advisors' }
  ],
  currentCustodians: [
    { id: '100000003', name: 'Merrill Lynch' },
    { id: '100000004', name: 'UBS' },
    { id: '100000005', name: 'Wells Fargo' },
    { id: '100000006', name: 'Morgan Stanley' },
    { id: '100000007', name: 'LPL Financial' },
    { id: '100000008', name: 'Stifel Nicolaus' },
    { id: '100000009', name: 'Edward Jones' },
    { id: '100000000', name: 'RBC' },
    { id: '100000001', name: 'Raymond James' },
    { id: '100000002', name: 'TCA' },
    { id: '1', name: 'Charles Schwab' },
    { id: '2', name: 'Fidelity' },
    { id: '3', name: 'TDAmeritrade' },
    { id: '4', name: 'Pershing' },
    { id: '5', name: 'Interactive Brokers' },
    { id: '6', name: 'Shareholder Services' },
    { id: '7', name: 'eTrade' },
    { id: '8', name: 'Trade PMR' },
    { id: '9', name: 'Other' }
  ],
  USER_MEMBERSHIP_TYPE: {
    paid_user: 100000000,
    registed_user: 100000001
  },
  HELP_PATH: 'https://help.chalicefn.com',
  NUM_EMPLOYEES: [
    { id: '1-25', name: '1-25' },
    { id: '26-50', name: '26-50' },
    { id: '51-250', name: '51-250' },
    { id: '251-1000', name: '251-1000' },
    { id: '1001+', name: '1001+' }
  ],
  RECORDS_COUNT: [
    { id: 'No records', name: 'None' },
    { id: '<100,000', name: '<100K' },
    { id: '100,000 - 500,000', name: '100K-500K' },
    { id: '500,000 - 1,000,000', name: '500K-1M' },
    { id: '>1,000,000', name: '>1M' }
  ],
  PREVIOUS_CLAIMS: [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' }
  ],
  STATES: [
    { id: 'AL', name: 'Alabama' },
    { id: 'AK', name: 'Alaska' },
    { id: 'AS', name: 'American Samoa' },
    { id: 'AZ', name: 'Arizona' },
    { id: 'AR', name: 'Arkansas' },
    { id: 'CA', name: 'California' },
    { id: 'CO', name: 'Colorado' },
    { id: 'CT', name: 'Connecticut' },
    { id: 'DE', name: 'Delaware' },
    { id: 'DC', name: 'District Of Columbia' },
    { id: 'FM', name: 'Federated States Of Micronesia' },
    { id: 'FL', name: 'Florida' },
    { id: 'GA', name: 'Georgia' },
    { id: 'GU', name: 'Guam' },
    { id: 'HI', name: 'Hawaii' },
    { id: 'ID', name: 'Idaho' },
    { id: 'IL', name: 'Illinois' },
    { id: 'IN', name: 'Indiana' },
    { id: 'IA', name: 'Iowa' },
    { id: 'KS', name: 'Kansas' },
    { id: 'KY', name: 'Kentucky' },
    { id: 'LA', name: 'Louisiana' },
    { id: 'ME', name: 'Maine' },
    { id: 'MH', name: 'Marshall Islands' },
    { id: 'MD', name: 'Maryland' },
    { id: 'MA', name: 'Massachusetts' },
    { id: 'MI', name: 'Michigan' },
    { id: 'MN', name: 'Minnesota' },
    { id: 'MS', name: 'Mississippi' },
    { id: 'MO', name: 'Missouri' },
    { id: 'MT', name: 'Montana' },
    { id: 'NE', name: 'Nebraska' },
    { id: 'NV', name: 'Nevada' },
    { id: 'NH', name: 'New Hampshire' },
    { id: 'NJ', name: 'New Jersey' },
    { id: 'NM', name: 'New Mexico' },
    { id: 'NY', name: 'New York' },
    { id: 'NC', name: 'North Carolina' },
    { id: 'ND', name: 'North Dakota' },
    { id: 'MP', name: 'Northern Mariana Islands' },
    { id: 'OH', name: 'Ohio' },
    { id: 'OK', name: 'Oklahoma' },
    { id: 'OR', name: 'Oregon' },
    { id: 'PW', name: 'Palau' },
    { id: 'PA', name: 'Pennsylvania' },
    { id: 'PR', name: 'Puerto Rico' },
    { id: 'RI', name: 'Rhode Island' },
    { id: 'SC', name: 'South Carolina' },
    { id: 'SD', name: 'South Dakota' },
    { id: 'TN', name: 'Tennessee' },
    { id: 'TX', name: 'Texas' },
    { id: 'UT', name: 'Utah' },
    { id: 'VT', name: 'Vermont' },
    { id: 'VI', name: 'Virgin Islands' },
    { id: 'VA', name: 'Virginia' },
    { id: 'WA', name: 'Washington' },
    { id: 'WV', name: 'West Virginia' },
    { id: 'WI', name: 'Wisconsin' },
    { id: 'WY', name: 'Wyoming' }
  ],
  BROKER_FEE_LABEL: {
    broker_fee: 'Broker Fee',
    merchant_fee: 'Merchant Fee'
  },

  CONSULATION_STATUS_LIST: [
    'confirmation_email_sent',
    'request_documentation',
    'discussion_scheduled',
    'prospect_unresponsive',
    'approved',
    'underwriting',
    'close_won',
    'close_lost'
  ],

  CONSULATION_STATUSES: {
    confirmation_email_sent: 'Confirmation Email Sent',
    close_won: 'Closed Won',
    close_lost: 'Closed Lost',
    underwriting: 'Underwriting',
    approved: 'Qualified',
    prospect_unresponsive: 'Unresponsive',
    discussion_scheduled: 'Meeting Set',
    request_documentation: 'Attempting to Contact'
  },

  PORTAL_ALL_ACCESS_ROLE: 2,
  PARTNER_PRODUCTS: {
    'Oasis Outsourcing Inc.': {
      54: 'EPLI',
      52: '401k',
      51: 'Insurance & Benefits',
      53: 'Payroll Processing and HR'
    },
    'Compass CFO Solutions': {
      55: 'Bookkeeper Services',
      57: 'CFO Services',
      56: 'Controller Services'
    }
  },

  PARTNERS: {
    1: 'Oasis Outsourcing Inc.',
    2: 'Compass CFO Solutions'
  },

  PRICING_TIERS: {
    0: 'Free',
    1: 'Basic',
    2: 'Advantage',
    3: 'VIP'
  },

  CHALICE_YEARLY_MEMBERSHIP_AMOUNT: {
    1: 240,
    2: 480,
    3: 960
  },

  REFERRAL_CREDIT_AMOUNT: 100,
  MIN_EFFECTIVE_DATE: new Date(2018, 1, 1),

  MEMBERSHIP_TIER: {
    free: 0,
    'cn-monthly-25': 1,
    'cn-yearly-240': 1,
    'cn-monthly-25': 1,
    'cn-yearly-240': 1,

    'sl-monthly-85': 1,
    'sl-yearly-850': 1,
    'sl-yearly-lite': 1,
    'sl-monthly-50-contract': 1,
    'sl-monthly-50': 1,
    'sl-monthly-80': 1,
    'sl-monthly-premier': 1,
    'sl-yearly-480-fix': 1,

    'cn-monthly-50': 2,
    'cn-yearly-480': 2,
    'cn-monthly-100': 3,
    'cn-yearly-960': 3,
    'sl-yearly-plus': 3,
    'sl-plus-yearly-960': 3,
    'sl-plus-monthly-100': 3,
    'sl-plus-monthly-200': 3,
    100: 3
  },

  MEMBERSHIP_TIER_NAMEs: {
    free: 'Free',
    'cn-monthly-25': 'Basic',
    'cn-yearly-240': 'Basic',

    'sl-monthly-85': 'Basic',
    'sl-yearly-850': 'Basic',
    'sl-yearly-lite': 'Basic',
    'sl-monthly-50-contract': 'Basic',
    'sl-monthly-50': 'Basic',
    'sl-monthly-80': 'Basic',
    'sl-monthly-premier': 'Basic',
    'sl-yearly-480-fix': 'Basic',

    'cn-monthly-50': 'Advantage',
    'cn-yearly-480': 'Advantage',
    'cn-monthly-100': 'VIP',
    'cn-yearly-960': 'VIP',
    'sl-yearly-plus': 'VIP',
    'sl-plus-yearly-960': 'VIP',
    'sl-plus-monthly-100': 'VIP',
    'sl-plus-monthly-200': 'VIP',
    100: 'VIP'
  },

  ORGANIZATION_USER_PERMISSIONS: [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' }
  ],

  PRODUCT_DETAILS_TOOLTIP: {
    organization:
      'Your organization admin did not give you permission to request consultations'
  },

  ADMIN_SUBSCRIPTION_TYPES: [
    { id: 0, val: 'free', title: 'Free' },
    { id: 1, val: 'payment_required', title: 'Payment Required' }
  ],

  ADMIN_TIERS: [
    { id: 0, val: 0, title: 'Free' },
    { id: 1, val: 1, title: 'Basic' },
    { id: 2, val: 2, title: 'Advantage' },
    { id: 3, val: 3, title: 'VIP' }
  ],

  MARKETS: {
    advisor: 'Advisory Firm',
    insurance: 'Insurance Agency',
    cpa: 'Tax Firm',
    other: 'Other'
  },

  NUM_STAFF: {
    5: '1-5',
    10: '5-10',
    20: '10-20',
    21: '20+'
  },

  SL_INTERESTS: {
    buy: 'Buy',
    merge: 'Merge',
    sell: 'Sell',
    none: 'No'
  },

  SUPPLY_SPENDING: {
    1000: '$0-$1,000',
    5000: '$1,000-$5,000',
    10000: '$5,000-$10,000',
    10001: '$10,000+'
  },

  SOFTWARE_SERVICES: {
    riskalyze: 'Riskalyze',
    redtail: 'Redtail CRM',
    orion: 'Orion',
    bizequity: 'BizEquity',
    qb: 'QuickBooks'

    // 10000: '$5,000-$10,000',
    // 10001: '$10,000+'
  },

  LEAD_GENERATION: {
    yes: 'Help me please',
    little_bit: 'Just a little bit',
    no: 'No'
  },

  MARKETPLACE_SECTIONS: [
    'employeebenefits',
    'marketing',
    'technology-solutions',
    'mna',
    'investment-solutions',
    'insurancesolutions',
    'backoffice',
    'compliance',
    'lifestyle',
    'professionaldevelopment'
  ],

  DEFAULT_DOMAIN: defaultDomain
}
