import React from 'react'

import { EnterpriseFooter } from './EnterpriseFooter'
import { ChaliceFooter } from './ChaliceFooter'
import { FfrFooter } from '../ffr/FfrFooter'
import { EarthlinkFooter } from '../earthlink/EarthlinkFooter'
import { ElBizFooter } from '../earthlink/ElBizFooter'
import { HrFooter } from '../houstonfirst/HrFooter'
import { SelaxFooter } from '../selax/SelaxFooter'

export const Footer = (props) => {
  if (props.isEnterprise) {
    if (props.siteData.slug === 'ffr') {
      return <FfrFooter {...props} />
    } else if (props.siteData.slug === 'earthlink') {
      return <EarthlinkFooter {...props} />
    } else if (props.siteData.slug === 'earthlink-biz') {
      return <ElBizFooter {...props} />
    } else if (props.siteData.slug === 'houston-first') {
      return <HrFooter {...props} />
    } else if (props.siteData.slug === 'selax-brokerage-agency') {
      return <SelaxFooter {...props} />
    } else {
      return <EnterpriseFooter {...props} />
    }
  } else {
    return <ChaliceFooter {...props} />
  }
}
