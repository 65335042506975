import React, { useState } from 'react'
import styled from 'styled-components'

import useMarketo from '/components/marketplace/enroll-request/Marketo'
import { Modal } from '/components/admin/Modal'

const PaddedForm = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 100% !important;
  }

  .mktoFormRow {
    label {
      display: none;
    }
    input {
      width: 100% !important;
      font-size: 1rem;
    }
  }
  .mktoFormCol {
    width: 100% !important;
  }
  .mktoButtonRow {
    width: 100% !important;
  }
  .mktoButtonWrap {
    margin: 0 !important;
  }
  .mktoFieldWrap {
    margin-bottom: 10px;
    width: 100% !important;
  }
  .mktoOffset,
  .mktoGutter {
    display: none;
  }

  button {
    border-radius: 5px;
    border: none;
    width: 100% !important;
    margin: 10px 0 0 0 !important;
    padding: 10px 0 !important;
    background-color: #df9310;
    font-size: 1.2rem;
    color: white;
  }
`

const MarketoForm = () => {
  const [loaded, setLoaded] = useState(false)

  const fId = '5918'
  useMarketo({
    baseUrl: '//go.prosites.com',
    munchkinId: '991-DCO-170',
    formId: fId,
    isLoadedCallback: () => {
      setLoaded(true)
    }
  })

  if (!loaded) {
    return 'Loading...'
  }

  return <form id={`mktoForm_${fId}`} />
}

export const Integration = ({ siteData }) => {
  const [showModal, setShowModal] = useState(false)
  if (typeof window !== 'undefined') {
    window.showMarketoModal = () => {
      setShowModal(true)
    }
  }
  if (siteData.slug !== 'cpassolutions') {
    return null
  }
  return (
    <Modal
      visible={showModal}
      title={'Speak to a Rep'}
      toggle={() => setShowModal(!showModal)}
      maxWidth="400px"
    >
      <PaddedForm>
        <MarketoForm />
      </PaddedForm>
    </Modal>
  )
}
