import React, { useEffect, useRef, useState } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { APIURL } from '../../../constants'
import { PageContentLoader } from '../../loaders'
import services from '../../services'
import { toast } from 'react-toastify'
import { usePrevious } from '../../../hooks/usePrevious'

toast.configure()

const ChooseMedia = ({ setCurrentMedia, toggle, handleChange, onlyImages }) => {
  const [disabledButtons, setDisabledButtons] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(1)
  const modalRef = useRef(null)

  const prevSearch = usePrevious(searchText)

  const queryClient = useQueryClient()

  const fetchMedia = ({ pageParam = page, search = searchText }) => {
    return services.get_v2(
      `${APIURL.API_ADMIN_MEDIA_CENTER}?size=25&page=${pageParam}&search_text=${search}`
    )
  }

  const {
    error,
    data,
    isFetching,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery('media', fetchMedia, {
    getNextPageParam: (lastPage, pages) => page
  })

  const handleGetMoreMedia = (p) => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage({ pageParam: p, search: searchText })
    }
  }

  const handleEndOfContent = () => {
    setTimeout(() => {
      if (
        data.pages[data.pages.length - 1].next &&
        modalRef?.current &&
        modalRef.current.scrollTop ===
          modalRef.current.scrollHeight - modalRef.current.offsetHeight
      ) {
        handleGetMoreMedia(page + 1)
        setPage((prev) => prev + 1)
      }
    }, 500)
  }

  if (error) return null

  const nameShortener = (name) => {
    if (name.length < 10) {
      return name
    }
    return name.slice(0, 8) + '...'
  }

  const getPreviewImage = (media) => {
    if (media?.type === 'application') {
      if (media?.extension === 'pdf') {
        return '/assets/images/pdf.png'
      }
      return '/assets/images/asset.png'
    }
    return media?.media_thumbnail || media?.media
  }

  const handleMediaClick = (media) => {
    setDisabledButtons(true)
    if (onlyImages && media.extension === 'pdf') {
      toast.error('please select an image.')
      setDisabledButtons(false)
      return
    }
    setCurrentMedia(media)
    handleChange(media)
    setTimeout(() => {
      toggle()
      setDisabledButtons(false)
    }, 300)
  }

  useEffect(() => {
    if (data && searchText !== prevSearch) {
      queryClient.setQueriesData('media', (data) => ({
        pages: [data.pages[0]],
        pageParams: [data.pageParams[0]]
      }))
      setPage(1)
      refetch()
    }
  }, [searchText])

  return (
    <Wrapper onScroll={handleEndOfContent} ref={modalRef}>
      <p>Search for media:</p>
      <Searchbar
        type="text"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value)
        }}
        placeholder="Type to search through media"
      />
      {isFetching && !isFetchingNextPage ? <LoaderWrapper>
        <PageContentLoader />
      </LoaderWrapper> : <TileWrapper>
        {data?.pages.map((group, i) => (
          <React.Fragment key={i}>
            {group?.results?.map((media) => (
              <Preview
                imageUrl={() => getPreviewImage(media)}
                key={media.id}
                disabled={disabledButtons}
                onClick={() => handleMediaClick(media)}
              >
                <p>{nameShortener(media?.name || 'media')}</p>
              </Preview>
            ))}
          </React.Fragment>
        ))}
      </TileWrapper>}
      {isFetching && isFetchingNextPage && (
        <Spinner>
          <img alt={'Page container loader'} src="/assets/images/loader.gif" />
        </Spinner>
      )}
    </Wrapper>
  )
}

export default ChooseMedia

// STYLING
const Wrapper = styled.div`
  padding: 30px;
  max-height: 72vh;
  overflow-y: scroll;

  p {
    color: ${(props) => props.theme.colors.grey};
    font-size: 14px;
    margin: 0 0 10px 0;
  }
`

const LoaderWrapper = styled.div`
  padding: 200px;
  img {
    top: calc(50%);
  }
`

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0;
  img {
    width: 75px;
  }
`

const Searchbar = styled.input`
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  padding: 8px;
  margin-bottom: 25px;
`

const TileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const Preview = styled.button`
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 3px;
  overflow: hidden;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  border: 1px solid transparent;
  transition: all 0.3s ease;
  z-index: 1;

  p {
    display: none;
    margin-top: 70px;
    color: ${(props) => props.theme.colors.white};
  }

  &:after {
    content: '';
    background-image: url(${(props) => props.imageUrl});
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.primary};
    &:after {
      box-shadow: rgba(0, 0, 0, 0.7) 0px -70px 36px -28px inset;
      opacity: 1;
    }
    p {
      display: inline-box;
    }
  }
`
