import { observable } from 'mobx'
import dayjs from 'dayjs'

/**
 * Author: Hanamantraya R
 * Class Name: ObjSSO
 * Description: Observation Class to create object of class for Users.
 */
export default class {
  id = null;
  @observable first_name = null;
  @observable last_name = null;
  @observable email = null;
  @observable stripe_customer_id = null;
  @observable phone = null;
  @observable phone_work = null;
  @observable role_id = null;
  @observable enterprises = [];
  @observable firm_status = null;
  @observable last_payment_date = null;
  @observable membership_number = null;
  @observable memb_start_from = null;
  @observable memb_end_at = null;
  @observable arche_type = null;
  @observable firm_name = null;
  @observable street_address1 = null;
  @observable street_address2 = null;
  @observable city = null;
  @observable state = null;
  @observable zip = null;
  @observable reg_date = null;
  @observable membership_status = null;
  @observable organization_admin = null;
  @observable can_access_billing = null;
  @observable can_request_consultation = null;

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: Object(params)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (json) {
    this.id = json.id
    this.first_name = json.first_name
    this.last_name = json.last_name
    this.email = json.email
    this.stripe_customer_id = json.stripe_customer_id
    this.phone = json.phone
    this.phone_work = json.phone_work
    this.role_id = json.role_id
    this.enterprises = json.groups || []
    this.firm_status = json.firm_status ? json.firm_status : 'NONE'
    this.last_payment_date = json.last_payment_date ? dayjs(json.last_payment_date).format('MM-DD-YYYY') : 'NONE'
    this.membership_number = json.membership_number ? json.membership_number : 'NONE'
    this.memb_start_from = json.memb_start_from ? dayjs(json.memb_start_from).format('MM-DD-YYYY') : 'NONE'
    this.memb_end_at = json.memb_end_at ? dayjs(json.memb_end_at).format('MM-DD-YYYY') : 'NONE'
    this.arche_type = json.arche_type
    this.status = json.status
    this.reg_date = json.reg_date
    this.membership_status = json.membership_status
    this.organization_admin = json.organization_admin
    this.can_access_billing = json.can_access_billing
    this.can_request_consultation = json.can_request_consultation
  }

  /**
   * @Author: Hanamantraya R
   * @Method: jsonToArray
   * @Param: Object(json)
   * @Return: Array
   * Description: Return array of objects
   */
  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(json)
   * @Return: Object
   * Description: Return  objects
   */
  static constructFromJSON (json) {
    return new this(json)
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(entries)
   * @Return: Object
   * Description: Update the details of particular record
   */
  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
