import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { FiMenu } from 'react-icons/fi'
import { Img } from 'react-image'

import { PAGEURL, CONSTS } from '/constants'
import { WPENGINE_HOST, PORTAL_HOST } from '/components/theme/ffr/theme'
import { SearchBar } from '../header/SearchBar'
import { Container } from './common'

const mobileNavVariants = {
  open: {
    height: 'auto',
    opacity: 1,
    transitionEnd: {
      display: 'flex'
    }
  },
  closed: {
    height: 0,
    opacity: 0,
    transitionEnd: {
      display: 'none'
    }
  }
}

const DropDown = ({ text, children }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })
  const [open, setOpen] = useState(false)
  const props = isMobile
    ? { onClick: () => setOpen(!open) }
    : { onMouseOver: () => setOpen(true), onMouseOut: () => setOpen(false) }

  return (
    <NavItem {...props}>
      <NavLink href="#" dropdown>
        {text}
      </NavLink>
      <DropDownWrapper open={open}>{children}</DropDownWrapper>
    </NavItem>
  )
}

export const FfrHeader = ({
  user,
  idpData,
  organization,
  logo,
  isLoading,
  pricingTab,
  siteData,
  loginUrl = '/idp/login',
  loginText = 'Login',
  signupUrl = '/signup',
  signupText = 'Join the Network',
  allowSignup = true,
  allowBilling = true,
  navItems = [],
  containerClassName = 'container'
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })
  const [isOpen, setOpen] = useState(false)
  const animate = isOpen ? 'open' : 'closed'
  const variants = isMobile ? mobileNavVariants : {}
  return (
    <Header id="chalice-header">
      <Container>
        <Navbar>
          <LogoWrap>
            <a href={WPENGINE_HOST}>
              <Img
                className="header-logo"
                src={siteData.logo_media_center.media}
                alt={siteData.name}
              />
            </a>
          </LogoWrap>
          <NavControl onClick={() => setOpen(!isOpen)}>
            <FiMenu />
          </NavControl>
          <NavbarContent initial="open" animate={animate} variants={variants}>
            <NavbarNav>
              <NavItem>
                <NavLink href={WPENGINE_HOST}>Dashboard</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${WPENGINE_HOST}/carriers/`}>Carriers</NavLink>
              </NavItem>
              <NavItem active>
                <NavLink href="/marketplace/" target="_self" active>
                  Resources
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${WPENGINE_HOST}/payouts/`}>Payouts</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={PORTAL_HOST} target="_self">
                  Accounting
                </NavLink>
              </NavItem>
              <DropDown text="Events">
                <DropDownItem
                  href={`${WPENGINE_HOST}/featured-events/ffr-spring-symposium/`}
                >
                  FFR Symposium
                </DropDownItem>
                <DropDownItem
                  href={`${WPENGINE_HOST}/featured-events/ffr-tax-institute/`}
                >
                  Tax Institute
                </DropDownItem>
                <DropDownItem
                  href={`${WPENGINE_HOST}/featured-events/ffr-university/`}
                >
                  FFR University
                </DropDownItem>
                <DropDownDivider />
                <DropDownItem href={`${WPENGINE_HOST}/events/`}>
                  View All Events
                </DropDownItem>
              </DropDown>
              <NavItem>
                <NavLink href={`${WPENGINE_HOST}/videos/`}>Videos</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${WPENGINE_HOST}/team/`}>Team</NavLink>
              </NavItem>
              {user && (
                <DropDown text="Profile">
                  <DropDownItem href={`${WPENGINE_HOST}/profile/`}>
                    Update Profile
                  </DropDownItem>
                  {idpData &&
                    idpData['https://secure.ffrmembers.com/roles']?.includes(
                      'Ownership Dashboard'
                    ) && (
                      <DropDownItem
                        href={
                          'https://portal.ffrmembers.com/ownership_dashboard/'
                        }
                      >
                        Ownership Dashboard
                      </DropDownItem>
                    )}
                  {(user.role_id === CONSTS.SUPER_ADMIN_ROLE ||
                    user.role_id === CONSTS.ENTERPRISE_ADMIN_ROLE) && (
                    <DropDownItem href={PAGEURL.ADMIN_DASHBOARD}>
                      Admin Dashboard
                    </DropDownItem>
                  )}
                  <DropDownItem href={PAGEURL.LOGOUT}>Logout</DropDownItem>
                </DropDown>
              )}
              {!user && (
                <DropDown text="Profile">
                  <DropDownItem href={PAGEURL.LOGIN}>Login+</DropDownItem>
                </DropDown>
              )}
            </NavbarNav>
            {siteData.search_enabled && <SearchBar showButton={false} />}
          </NavbarContent>
        </Navbar>
      </Container>
    </Header>
  )
}

const Header = styled.header`
  position: fixed;
  background-color: #fff;
  padding: 0px 0;
  transition: 0.4s;

  left: 0;
  top: 0;
  width: 100%;
  padding: 0px 0;
  transition: 0.4s;
  background: #fff;
  border-bottom: 1px solid #eee;
`

const NavControl = styled.button`
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: none;

  ${(props) => props.theme.media.phone`
    display: block;
  `}
`

const NavbarContent = styled(motion.div)`
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
  align-items: center;

  ${(props) => props.theme.media.phone`
    display: ${(props) => (props.animate === 'open' ? 'flex' : 'none')};
    background-color: #fff;
    border: 1px solid #e6f0fa;
    margin-top: 20px;
    flex-basis: 100%;
    flex-grow: 1;
  `}
`

const NavbarNav = styled.ul`
  text-align: center;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-right: auto !important;
  margin-left: auto !important;

  ${(props) => props.theme.media.phone`
    text-align: center !important;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    padding: 25px 40px 20px;
  `}
`

const NavItem = styled.li`
  position: relative;
  padding: 0 8px;
  list-style-type: none;
`

const NavLink = styled.a`
  display: block;
  padding: 0.5rem 0.4rem;
  position: relative;

  font-weight: 400;
  font-family: 'pt-serif', serif;
  letter-spacing: 0px;
  font-size: 16px;
  color: #0e0e0e;

  &:hover {
    text-decoration: none;
    color: #175d98;
  }

  ${(props) =>
    props.active &&
    css`
      color: #175d98;

      &:after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 1px;
        border-top: 1px solid #2c79b0;
        width: 25px;
      }
    `}

  ${(props) =>
    props.dropdown &&
    css`
      &:after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    `}
`

const DropDownItem = styled.a`
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'pt-serif', serif;
  transition: all 0.25s;

  &:hover {
    text-decoration: none;
    background-color: #f8f9fa;
    transition: all 0.25s;
  }
`
const DropDownDivider = styled.div`
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
`

const DropDownWrapper = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;

  ${(props) => props.theme.media.phone`
    position: relative;
  `}
`

const Navbar = styled.nav`
  padding: 15px 0;
  flex-flow: row nowrap;
  justify-content: flex-start;

  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${(props) => props.theme.media.phone`
    justify-content: space-between;
  `}
`

const LogoWrap = styled.div`
  width: 180px;

  img {
    width: 100%;
    padding: 5px 0;
  }
`
