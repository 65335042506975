import styled from 'styled-components'

export const BooleanInput = ({
  value,
  name,
  setCurrentComponent,
  label,
  onChange = (e) =>
    setCurrentComponent((prev) => {
      const temp = { ...prev }
      temp.props[name] = !temp.props[name]
      return temp
    })
}) => {
  return (
    <RadioWrapper>
      <input
        type="checkbox"
        name={name}
        id="yes"
        checked={value}
        onChange={onChange}
      />
      <p>{label}</p>
    </RadioWrapper>
  )
}

const RadioWrapper = styled.div`
  display: flex;
  gap: 5px;
`
