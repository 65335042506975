import { APIURL } from '../../constants'
import services from '../services'

export const addLog = async (key, value, type) => {
  try {
    const data = { key, value, type }
    await services.post_v2(APIURL.API_ANALYTICS, data)
  } catch (e) {
    console.error(`Add log failed with error: ${e}`)
  }
}

export const pageView = async (data, host) => {
  try {
    await services.post_v2(APIURL.API_PAGE_VIEW, data, {}, host)
  } catch (e) {
    console.error(`Add log failed with error: ${e}`)
  }
}
