import React, { useEffect } from 'react'
import { Link } from '../button/Button'
import styled from 'styled-components'

import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaYoutubeSquare,
  FaInstagramSquare
} from 'react-icons/fa'
import { BannersContainer } from '../banner-ads/BannersContainer'

const launchIntercom = (e) => {
  if (typeof Intercom !== 'undefined') {
    window.Intercom('show')
  }
}

const LiLink = ({ href, children }) => (
  <FooterMainLi>
    <FooterMainA href={href}>{children}</FooterMainA>
  </FooterMainLi>
)

const ProductLinks = () => (
  <ul className="list-unstyled">
    <LiLink href="https://www.chalicenetwork.com/marketplace/">
      Marketplace
    </LiLink>
    <LiLink href="https://www.chalicepay.com">Chalice Pay</LiLink>
    <LiLink href="https://www.chaliceconnect.com">Chalice Connect</LiLink>
  </ul>
)

const SupportLinks = () => (
  <ul className="list-unstyled">
    <LiLink href="/press/">Press</LiLink>
    <LiLink href="/events/">Events</LiLink>
    <LiLink href="https://help.chalicefn.com">Help Center</LiLink>
    <LiLink href="/privacy-policy/">Privacy Policy</LiLink>
    <LiLink href="/terms-of-service/">Terms & Conditions</LiLink>
    <FooterMainLi>
      <FooterMainA as="button" onClick={launchIntercom}>
        Contact Us
      </FooterMainA>
    </FooterMainLi>
  </ul>
)

const SubscribeForm = (props) => {
  let mounted = false
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.async = true
    script.onload = () => {
      if (!mounted) {
        window.hbspt.forms.create({
          portalId: '7233754',
          formId: 'b6d9090a-1462-442d-b1f5-c28aed4eeceb',
          target: '#subscribe-form'
        })
      }
      mounted = true
    }
    script.onerror = () => {
      console.error('Something went wrong trying to load the newsletter form.')
    }
    document.body.appendChild(script)
    return () => document.body.removeChild(script)
  })

  return <FormGroup id="subscribe-form"></FormGroup>
}

export const ChaliceFooter = (props) => {
  const renderFooterTop = (children) => {
    if (children) {
      return <FlexCenter>{children}</FlexCenter>
    } else {
      return (
        <FlexCenter>
          <div>
            <SubHeader>Get Started Now</SubHeader>
            <p>
              Wherever you are in your business journey, Chalice has the tools
              and resources to help you overcome the most important challenges
              you face every day: getting more customers and growing your
              revenue.
            </p>
          </div>
          <Right>
            <Link href="/pricing">See Plans &amp; Pricing</Link>
          </Right>
        </FlexCenter>
      )
    }
  }

  return (
    <Footer>
      <div className="container">
        <FooterCta>
          {renderFooterTop(props.children)}
          <BannersContainer banners={props.banners} placement="Footer" />
        </FooterCta>
        <FooterMain>
          <div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <MainH2>Contact</MainH2>
              <P>
                <FooterMainA href="tel:877-424-2542">877-424-2542</FooterMainA>
                <br />
                <FooterMainA href="mailto:support@chalicenetwork.com">
                  support@chalicenetwork.com
                </FooterMainA>
              </P>
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <MainH2>Products</MainH2>
              <ProductLinks />
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <MainH2>Support</MainH2>
              <SupportLinks />
            </div>
            <div className="col-md-3 col-sm-12 col-xs-12">
              <MainH2>Newsletter Signup</MainH2>
              <SubscribeForm />
              <Socials>
                <a
                  href={
                    props.facebook_url ||
                    'https://business.facebook.com/chalicenetwork'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookSquare />
                </a>
                <a
                  href={
                    props.twitter_url || 'https://twitter.com/ChaliceNetwork'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitterSquare />
                </a>
                <a
                  href={
                    props.instagram_url ||
                    'https://instagram.com/chalicenetwork/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagramSquare />
                </a>
                <a
                  href={
                    props.linkedin_url ||
                    'https://linkedin.com/company/chalicenetwork/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
                <a
                  href={
                    props.youtube_url ||
                    'https://www.youtube.com/chalicenetwork'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutubeSquare />
                </a>
              </Socials>
            </div>
          </div>
        </FooterMain>
      </div>
      <SubFooter>
        <div className="container">
          <div className="col-12">
            <SubFooterP>
              Chalice Network © 2023. All rights reserved.
            </SubFooterP>
          </div>
          {props.disclaimer && (
            <div
              className="col-12"
              dangerouslySetInnerHTML={{ __html: props.disclaimer }}
            />
          )}
        </div>
      </SubFooter>
    </Footer>
  )
}

const FooterMainLi = styled.li`
  padding-bottom: 10px;
`

const FooterMainA = styled.a`
  color: ${(props) => props.theme.colors.white};
  opacity: 0.5;
  position: relative;
  display: inline-block;
  transition: all 0.5s;
  border: 0;
  background-color: transparent;
  outline: none;
  text-align: left;
  padding: 0;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    opacity: 1;
    transition: all 0.5s;

    &:before {
      transform: scaleX(1);
      transform-origin: left center;
    }
  }
  &:before {
    content: '';
    height: 2px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.lightBlue};
    display: block;
    position: relative;
    bottom: -24px;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform 0.5s cubic-bezier(0.694, 0, 0.335, 1);
  }
`

const FormGroup = styled.div`
  position: relative;

  label {
    display: none;
  }

  input[type='text'],
  input[type='email'] {
    padding: 17px 18px;
    width: 100%;
    font-size: 13px;
    border: 0;
    border-radius: 3px;
    color: #000;

    @media only screen and (max-width: 990px) {
      padding-right: 121px;
    }

    &:placeholder {
      color: #999;
    }
  }

  input[type='submit'],
  button {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) =>
      props.theme.colors.primary || props.theme.colors.lightBlue};
    padding: 14px 27px 13px;
    font-size: 16px;
    margin-top: 5px;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.42857143;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    border: 0;
    transition: all 0.25s;

    &:hover {
      text-decoration: none;
      opacity: 0.9;
      transition: all 0.25s;
      color: ${(props) => props.theme.colors.white};
    }

    @media only screen and (max-width: 990px) {
      position: absolute;
      right: 5px;
      padding: 14px 27px 13px;
      top: 4px;
      font-size: 16px;
      margin: 0;
      width: auto;
    }
  }

  ul {
    list-style-type: none;

    li {
      color: ${(props) => props.theme.colors.white};
    }
  }
`

const FlexCenter = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 990px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SubHeader = styled.h2`
  font-size: 2.15rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
`

const Right = styled.div`
  text-align: right;
`

const Footer = styled.footer`
  background-color: ${(props) => props.theme.colors.darkBlue};
  color: ${(props) => props.theme.colors.white};
`

const FooterCta = styled.div`
  padding: 80px 0 96px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.25);
`

const FooterMain = styled.div`
  padding: 50px 0 72px;
`

const MainH2 = styled.h2`
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 42px;
  text-transform: uppercase;
`

const P = styled.p`
  line-height: 30px;
`

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 30px 0;

  a {
    color: ${(props) => props.theme.colors.white};
    display: block;
    text-decoration: none;

    svg {
      height: 30px;
      width: 30px;
    }
  }
`

const SubFooter = styled.div`
  background-color: #021b34;
  padding: 30px 0 23px;
`

const SubFooterP = styled.p`
  margin-bottom: 0;
  font-size: 0.75rem !important;
`
