import React from 'react'
import PropTypes from 'prop-types'
import { PlaceholderTile } from './Tile/Tile'
import Section from './Section'
import classNames from 'classnames'
import styles from './Marketplace.module.scss'

const MarketplaceLoading = ({ siteData }) => (
  <div className={styles.content_padded_marketplace}>
    <div className={styles.marketplace_filter}>
      <div className="container-fluid">
        <div className="col-sm-12 col-md-6" id="search-filter">
        </div>
        <div className="col-sm-12 col-md-6">
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row mt-sm" id="marketplace-tiles">
        <div className="col-sm-12 col-md-12">
          <div id="tiles" className={styles.tiles}>
            <div
              className={classNames(
                'col-sm-12 col-md-12',
                styles.bottom_marketplace
              )}>
              <Section key='section-all'>
                { new Array(10).fill(0).map((r, i) => (
                  <PlaceholderTile key={i} />
                ))}
              </Section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

MarketplaceLoading.propTypes = {
  siteData: PropTypes.object
}

export default MarketplaceLoading
