import APIURL from '../constants/APIURL'
import axios from 'axios'
const localStorage = typeof window !== 'undefined' && window.localStorage
const isClient = typeof window !== 'undefined'

const config = {
  baseURL: APIURL.BASE_URL,
  withCredentials: true,
  responseType: 'json',

  transformResponse: [(data) => data],
  maxRedirects: 5, // default
  // maxContentLength: 200,
  validateStatus: (status) => {
    return status >= 200 && status < 500 // default
  },
  xsrfCookieName: 'XSRF-TOKEN', // default
  xsrfHeaderName: 'X-XSRF-TOKEN', // default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 0
}

const fileDownloadConfig = {
  baseURL: APIURL.BASE_URL,
  method: 'GET',
  responseType: 'blob'
}

const config2 = { ...config }
const fileDownloadConfig2 = { ...fileDownloadConfig }
// fileDownloadConfig2.baseURL = 'https://' + APIURL.BASE_URL_V2 + '/api-internal/'
const apiInstance = axios.create(config)
const apiInstance_v2 = axios.create(config2)

const instanceFileDownloading = axios.create(fileDownloadConfig)
const instanceFileDownloading_v2 = axios.create(fileDownloadConfig2)

function getFormData(fb_data) {
  const formData = new FormData()
  for (const key in fb_data) {
    if (fb_data[key] instanceof window.FileList) {
      for (let i = 0; i < fb_data[key].length; i++) {
        formData.append(
          fb_data[key][i].name.replace(
            /(.png|.jpg|.jpeg|.doc|.docx|.pdf)$/gi,
            `-${fb_data[key][i].category}`
          ),
          fb_data[key][i]
        )
      }
    } else {
      formData.append(key, fb_data[key])
    }
  }
  return formData
}

function post(apiURL, payload) {
  if (localStorage.access_token) {
    apiInstance.defaults.headers.common['Authorization'] =
      localStorage.access_token
  }

  const formData = getFormData(payload)
  return apiInstance
    .post(apiURL, formData)
    .then((response) => {
      return handleResponse(response)
    })
    .catch((error) => {
      return handleError(error)
    })
}

function post_v2(
  apiURL,
  payload,
  headers = {},
  hostname = null,
  cookie = null
) {
  if (localStorage.access_token) {
    headers['Authorization'] = localStorage.access_token
  }
  if (hostname) {
    headers['X-Enterprise-Domain'] = hostname
  }
  if (cookie) {
    headers['Cookie'] = cookie
  }
  let url = ''
  if (isClient) {
    url +=
      process.env.NEXT_PUBLIC_API_URL_PROTOCOL +
      window.location.hostname +
      '/api-internal/'
  } else {
    url +=
      process.env.NEXT_PUBLIC_SERVER_API_URL_PROTOCOL +
      process.env.CUSTOM_HOST +
      '/api-internal/'
  }
  return apiInstance_v2
    .post(url + apiURL, payload)
    .then((response) => {
      return handleResponse(response)
    })
    .catch((error) => {
      return handleError(error)
    })
}

function put_v2(apiURL, payload, options = {}) {
  if (!options.headers) {
    options.headers = {}
  }
  const headers = options.headers
  if (localStorage.access_token) {
    headers['Authorization'] = localStorage.access_token
  }
  let url = ''
  if (isClient) {
    url +=
      process.env.NEXT_PUBLIC_API_URL_PROTOCOL +
      window.location.hostname +
      '/api-internal/'
  } else {
    url +=
      process.env.NEXT_PUBLIC_SERVER_API_URL_PROTOCOL +
      process.env.CUSTOM_HOST +
      '/api-internal/'
  }
  return apiInstance_v2
    .put(url + apiURL, payload, options)
    .then((response) => {
      return handleResponse(response)
    })
    .catch((error) => {
      return handleError(error)
    })
}

function delete_v2(apiURL) {
  const options = {}
  options.headers = {}
  const headers = options.headers
  if (localStorage.access_token) {
    headers['Authorization'] = localStorage.access_token
  }
  let url = ''
  if (isClient) {
    url +=
      process.env.NEXT_PUBLIC_API_URL_PROTOCOL +
      window.location.hostname +
      '/api-internal/'
  } else {
    url +=
      process.env.NEXT_PUBLIC_SERVER_API_URL_PROTOCOL +
      process.env.CUSTOM_HOST +
      '/api-internal/'
  }
  return apiInstance_v2
    .delete(url + apiURL)
    .then((response) => {
      return handleResponse(response)
    })
    .catch((error) => {
      return handleError(error)
    })
}

function get(apiURL, options = {}) {
  if (localStorage.access_token) {
    apiInstance.defaults.headers.common['Authorization'] =
      localStorage.access_token
  }
  return apiInstance
    .get(apiURL, options)
    .then((response) => {
      return handleResponse(response)
    })
    .catch((error) => {
      return handleError(error)
    })
}

function get_v2(apiURL, options = {}, hostname = null, cookie = null) {
  if (!options.headers) {
    options.headers = {}
  }
  const headers = options.headers
  if (localStorage.access_token) {
    headers['Authorization'] = localStorage.access_token
  }
  if (hostname) {
    headers['X-Enterprise-Domain'] = hostname
  }
  if (cookie) {
    headers['Cookie'] = cookie
  }
  let url = ''
  if (isClient) {
    url +=
      process.env.NEXT_PUBLIC_API_URL_PROTOCOL +
      window.location.hostname +
      '/api-internal/'
  } else {
    url +=
      process.env.NEXT_PUBLIC_SERVER_API_URL_PROTOCOL +
      process.env.CUSTOM_HOST +
      '/api-internal/'
  }
  return apiInstance_v2
    .get(url + apiURL, options)
    .then((response) => {
      return handleResponse(response)
    })
    .catch((error) => {
      return handleError(error)
    })
}

function getFile(apiURL) {
  if (localStorage.access_token) {
    instanceFileDownloading.defaults.headers.common['Authorization'] =
      localStorage.access_token
  }
  return instanceFileDownloading
    .get(apiURL)
    .then((response) => {
      return handleResponse(response)
    })
    .catch((error) => {
      return handleError(error)
    })
}

function getFile_v2(apiURL, options = {}) {
  if (!options.headers) {
    options.headers = {}
  }
  const headers = options.headers
  if (localStorage.access_token) {
    headers['Authorization'] = localStorage.access_token
  }
  let url = ''
  if (isClient) {
    url +=
      process.env.NEXT_PUBLIC_API_URL_PROTOCOL +
      window.location.hostname +
      '/api-internal/'
  } else {
    url +=
      process.env.NEXT_PUBLIC_SERVER_API_URL_PROTOCOL +
      process.env.CUSTOM_HOST +
      '/api-internal/'
  }
  return instanceFileDownloading_v2
    .get(url + apiURL, options)
    .then((response) => {
      return handleResponse(response)
    })
    .catch((error) => {
      return handleError(error)
    })
}

function handleResponse(response) {
  let resData = {}
  if (typeof response.data === 'object') {
    if (response.status === 404) {
      resData = {
        status: 'failure',
        statusCode: 404,
        message: response.statusText
      }
    } else {
      resData = response.data
    }
  } else {
    resData = JSON.parse(response.data)
  }
  return resData
}

function handleError(error) {
  if (error.message === 'Network Error') {
    return { status: 'failure', statusCode: 500, message: error.message }
  } else if (error.message && !error.status) {
    return { status: 'failure', statusCode: 503, message: error.message }
  } else if (error.response.data) {
    return error.response.data
  } else if (error.response) {
    return {
      status: 'failure',
      statusCode: error.response.status,
      message: error.message
    }
  } else if (error.request) {
    return {
      status: 'failure',
      statusCode: error.status,
      message: error.message
    }
  } else {
    return {
      status: 'failure',
      statusCode: error.status,
      message: error.message
    }
  }
}

export default {
  post,
  post_v2,
  put_v2,
  getFile,
  getFile_v2,
  get,
  get_v2,
  delete_v2
}
