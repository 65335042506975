import React, { useState } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { FaArrowRight } from 'react-icons/fa'
import { motion, AnimatePresence } from 'framer-motion'

import { PAGEURL, CONSTS } from '/constants'

const mobileVariants = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'linear'
    }
  },
  closed: {
    opacity: 0,
    x: '100%',
    transition: {
      type: 'linear',
      delay: 0.5
    }
  }
}

const navVariant = {
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.1, staggerDirection: -1 }
  }
}

const linkVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

export const MobileMenu = ({ top, right, user, subNavItems }) => {
  const [showMobileMenu, setMobileMenu] = useState(false)
  const isAdmin =
    user?.role_id === CONSTS.SUPER_ADMIN_ROLE ||
    user?.role_id === CONSTS.ENTERPRISE_ADMIN_ROLE
  return (
    <MobileWrapper
      animate={showMobileMenu ? 'open' : 'closed'}
      initial="closed"
    >
      <AnimatePresence>
        {showMobileMenu && (
          <Backdrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0, transition: { delay: 0.5 } }}
            onClick={() => setMobileMenu(false)}
          />
        )}
      </AnimatePresence>
      <MenuToggle
        toggle={() => setMobileMenu(!showMobileMenu)}
        top={top}
        right={right}
        delay={0.5}
      />
      <MenuWrapper variants={mobileVariants}>
        <Nav variants={navVariant}>
          {(subNavItems || []).map((item, index) => (
            <MobileItem key={index}>
              <Link href={item.url} passHref>
                <A>{item.name}</A>
              </Link>
            </MobileItem>
          ))}
          <MobileSpacer />
          {user ? (
            isAdmin ? (
              <>
                <MobileItem>
                  <Link href={PAGEURL.DASHBOARD} passHref>
                    <A>View My Perks</A>
                  </Link>
                </MobileItem>
                <MobileItem>
                  <Link href={PAGEURL.ADMIN_DASHBOARD} passHref>
                    <A>Admin View</A>
                  </Link>
                </MobileItem>
              </>
            ) : (
              <MobileItem>
                <Link href={PAGEURL.DASHBOARD} passHref>
                  <A>View My Perks</A>
                </Link>
              </MobileItem>
            )
          ) : (
            <MobileItem>
              <Link href={PAGEURL.LOGIN} passHref>
                <A>
                  {' '}
                  Login to Perks <FaArrowRight />{' '}
                </A>
              </Link>
            </MobileItem>
          )}
        </Nav>
      </MenuWrapper>
    </MobileWrapper>
  )
}

const MobileWrapper = styled(motion.div)`
  display: none;

  ${(props) => props.theme.media.tablet`
    display: block;
  `}
`

const MenuWrapper = styled(motion.div)`
  display: none;

  ${(props) => props.theme.media.tablet`
    z-index: 10;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: space-between;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 100%;
  `}
`

const MobileItem = styled((props) => (
  <motion.li variants={linkVariants} {...props} />
))`
  flex: 0 1 auto;
`

const MobileSpacer = styled.li`
  flex: 1 0 auto;
`

const A = styled.a`
  transition: all 0.25s;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 5px;
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.25s;
  }

  &:hover {
    transition: all 0.25s;
    text-decoration: none;

    &:after {
      width: 100%;
      transition: all 0.25s;
    }
  }
`

const ButtonStyle = css`
  transition: all 0.25s;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  position: relative;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 15px 25px;
  text-decoration: none;
`

const ML = styled.a`
  ${ButtonStyle}

  font-size: 1rem;
  justify-content: flex-start;
  height: auto;
`

const Backdrop = styled(motion.div)`
  background-color: ${(props) => props.theme.colors.black};
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
`

const Nav = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1.2rem;
  height: 100%;
  margin: 0;
`

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke={'#000'}
    strokeLinecap="round"
    {...props}
  />
)

const MenuToggle = ({ toggle, top, right, delay = 0 }) => (
  <ToggleButton onClick={toggle} top={top} right={right}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        opacity={1}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: {
            d: 'M 2 2.5 L 20 2.5',
            stroke: '#000',
            transition: { delay }
          },
          open: { d: 'M 3 16.5 L 17 2.5', stroke: '#000' }
        }}
      />
      <Path
        opacity={1}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1, stroke: '#000', transition: { delay } },
          open: { opacity: 0, stroke: '#000' }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        opacity={1}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: {
            d: 'M 2 16.346 L 20 16.346',
            stroke: '#000',
            transition: { delay }
          },
          open: { d: 'M 3 2.5 L 17 16.346', stroke: '#000' }
        }}
      />
    </svg>
  </ToggleButton>
)

const ToggleButton = styled.button`
  display: none;
  top: ${(props) => props.top || '24px'};
  right: ${(props) => props.right || '20px'};
  z-index: 1002;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: transparent;

  ${(props) => props.theme.media.tablet`
    display: block;
  `}
`
