import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NextImageWithFallback } from '../../NextImageWithFallback'

export function interpolate(siteData, value) {
  const whitelist = ['home_title', 'home_description', 'phone', 'name']
  let matches
  if (value) {
    matches = value.match(/{{.+?}}/g)
  }
  if (matches) {
    matches.forEach((m) => {
      const key = m.replace(/{|}/g, '').trim()
      let v = ''
      if (whitelist.includes(key)) {
        v = siteData.get(key) || ''
      }
      value = value.replace(m, v)
    })
  }
  return value
}

export const NavbarPadding = styled.div`
  padding-top: var(--chalice-navbar-height);
`

export const FormWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    margin: 0 0 3px 0;
  }
`

export const Container = styled.div`
  background-color: ${(props) => props.bgColor};
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 0;
  margin-left: 0;

  @media (min-width: 768px) {
    max-width: 100%;
    width: 750px;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 992px) {
    max-width: 100%;
    padding: 0;
    width: 970px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 1200px) {
    max-width: 100%;
    padding: 0;
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => props.theme.media.tablet`
    flex-direction: column-reverse;
    justify-content: center;
  `}

  ul {
    margin: 0 0 20px 0;
    padding-left: 20px;
  }
`

export const VerticalPad = styled.div`
  padding: 50px 0;
`

export const ChaliceImg = (props) => {
  return <NextImageWithFallback {...props} />
}

ChaliceImg.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.string
}

export const ProductH2 = styled.h3`
  margin: 30px 0 20px 0;
  color: ${(props) => props.theme.colors.black};
`

export const ProductH3 = styled.h3`
  margin: 30px 0;
  color: ${(props) => props.theme.colors.black};
  font-size: 2rem;
`

export const radial = (color) => {
  return `radial-gradient(circle at left, ${color}ff 0%, ${color}cc 70%);`
}
