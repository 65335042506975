import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { PAGEURL } from '../../../constants'
import { MembershipBadge } from './MembershipBadge'
import { DropDown } from '../drop-down/DropDown'
import { MdPersonOutline } from 'react-icons/md'

import styled from 'styled-components'

const SUPER_ADMIN_ROLE = 2
const PORTAL_ALL_ACCESS_ROLE = 2
const ENTERPRISE_ADMIN_ROLE = 3

export const UserMenu = ({
  siteData,
  isLoading,
  user,
  organization,
  allowBilling = true
}) => {
  const submenu = []
  const [currentUser, setCurrentUser] = useState(user)
  useEffect(() => {
    setCurrentUser(user)
  }, [user])
  if (currentUser && !isLoading) {
    const isAdmin =
      currentUser.role_id === SUPER_ADMIN_ROLE ||
      currentUser.role_id === ENTERPRISE_ADMIN_ROLE
    const canUpgrade =
      (currentUser.all_plan_ids || []).length < (siteData.plans || []).length
    if (siteData.allow_dashboard) {
      submenu.push(
        <li className="mb-10" key="dashboard">
          <UserA key="profile" href="/dashboard/" className="dropdown-item">
            <Span>Dashboard</Span>
          </UserA>
        </li>
      )
    }
    if (siteData.allow_profile) {
      submenu.push(
        <li className="mb-10" key="profile">
          <UserA key="profile" href="/profile" className="dropdown-item">
            <Span>Profile</Span>
            <MembershipBadge
              siteData={siteData}
              user={currentUser}
              organization={organization}
            />
          </UserA>
        </li>
      )
    }
    if (!isAdmin) {
      if (currentUser.can_access_billing && allowBilling && !canUpgrade) {
        submenu.push(
          <li className="mb-10" key="billing">
            <UserA
              key="subscription"
              href={PAGEURL.BILLING_SUBSCRIPTION}
              className="dropdown-item"
            >
              <Span>Billing</Span>
            </UserA>
          </li>
        )
      } else if (currentUser.can_access_billing && allowBilling) {
        submenu.push(
          <li className="mb-10" key="subscribe">
            <UserA
              key="subscription"
              href={PAGEURL.SUBSCRIBE}
              className="dropdown-item"
            >
              <Span>Upgrade</Span>
            </UserA>
          </li>
        )
      }
    }
    if (isAdmin) {
      submenu.push(
        <li className="mb-10" key="adminportal">
          <UserA href="/admin/dashboard/" className="dropdown-item">
            <Span>Admin Portal</Span>
          </UserA>
        </li>
      )
    }
    if (
      siteData.is_business_member &&
      (currentUser.partner_id ||
        currentUser.role_id === PORTAL_ALL_ACCESS_ROLE ||
        currentUser.role_id === ENTERPRISE_ADMIN_ROLE)
    ) {
      submenu.push(
        <li className="mb-10" key="portal">
          <UserA href="/partner/portal/" className="dropdown-item">
            <Span>Partner Portal</Span>
          </UserA>
        </li>
      )
    }
    submenu.push(
      <li className="mb-10" key="logout">
        <UserA href="/idp/logout" className="dropdown-item change-pointer">
          <Span>Sign Out</Span>
        </UserA>
      </li>
    )

    return (
      <DropDown
        alignRight={true}
        displayComponent={
          <li className="nav-item nav-user-image dropdown simple-dropdown">
            <a href="#" className="nav-link-no-style dropdown-toggle">
              <ProfileImg>
                {!user?.profile_media?.media && <MdPersonOutline />}
                {user?.profile_media?.media && (
                  <img src={user?.profile_media?.media} alt="Headshot" />
                )}
              </ProfileImg>
            </a>
          </li>
        }
        returnLink={
          <div className="return-link d-block d-lg-none">
            <a className="mb-20" href="#">
              User
            </a>
          </div>
        }
        dropDownClass="dropdown-menu1 nav-image-dropdown"
      >
        <UserUl>{submenu}</UserUl>
      </DropDown>
    )
  } else {
    return null
  }
}

UserMenu.propTypes = {
  isLoading: PropTypes.bool,
  user: PropTypes.object
}

const UserA = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;
  margin-bottom: 10px;

  &:hover {
    text-decoration: none;
  }
`

const UserUl = styled.ul`
  list-style: none;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 0;

  ${(props) => props.theme.media.tablet`
    padding: 0 54px;
  `}
`

const ProfileImg = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  border: 0 solid #ddd;
  overflow: hidden;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  svg {
    max-width: 100%;
    min-width: 30px;
    min-height: 30px;
  }
`

const Span = styled.span`
  margin: 0 !important;

  &:before {
    background-color: ${(props) =>
      props.theme.colors.primary || '#0d70cc'} !important;
  }
`
