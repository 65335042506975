import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { PageContentLoader } from '../../../../loaders'

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link'
]

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
}

const QuillNoSSRWrapper = dynamic(import('react-quill'), {
  ssr: false,
  loading: PageContentLoader
})

export const HtmlInput = ({
  value,
  name,
  label,
  setCurrentComponent,
  height,
  onChange = (content, delta, source, editor) => {
    setCurrentComponent((prev) => {
      const temp = { ...prev }
      temp.props[name] = content
      return temp
    })
  }
}) => {
  return (
    <QuillWrapper height={height}>
      <p>{label}</p>
      <QuillNoSSRWrapper
        height="100px"
        value={value || ''}
        onChange={onChange}
        theme="snow"
        formats={formats}
        modules={modules}
      />
    </QuillWrapper>
  )
}

const QuillWrapper = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  .ql-editor {
    height: ${(props) => props.height};
  }
`
