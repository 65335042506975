import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ChromePicker } from 'react-color'
import styled from 'styled-components'

export const ColorPicker = ({ value, name, setCurrentComponent, label }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const handleChange = (color) => {
    if (color !== value) {
      setCurrentComponent((prev) => {
        const temp = { ...prev }
        temp.props[name] = color.hex
        return temp
      })
    }
  }

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev)
  }

  return (
    <Wrapper>
      <p>{label}</p>
      <Swatch onClick={handleClick}>
        <Color color={value} />
      </Swatch>
      {displayColorPicker && (
        <Popover>
          <Cover>
            <ChromePicker disableAlpha color={value} onChange={handleChange} />
          </Cover>
        </Popover>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`

const Swatch = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`

const Color = styled.div`
  width: 250px;
  max-width: 100%;
  height: 16px;
  background: ${(props) => props.color};
`

const Popover = styled.div`
  position: absolute;
  top: -200px;
  z-index: 2;
`

const Cover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

ColorPicker.propTypes = {
  activeColor: PropTypes.string,
  setActiveColor: PropTypes.func,
  label: PropTypes.string,
  field: PropTypes.string
}
