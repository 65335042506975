import { observable } from 'mobx'
import dayjs from 'dayjs'

export default class {
  id = null;
  @observable created_at = null;
  @observable question = null;
  @observable order = null;
  @observable survey_answers = [];

  constructor (json) {
    this.id = json.id
    this.created_at = dayjs(json.created_at).format('MM/DD/YYYY')
    this.question = json.question
    this.order = json.order
    this.survey_answers = json.survey_answers
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
