import { observable } from 'mobx'

export default class {
  id = null;
  @observable name = null;
  @observable crm_account_id = null;
  @observable contactName = null;
  @observable contactEmail = null;
  @observable contactCalendarLink = null;
  @observable alternate_email1 = null;
  @observable alternate_email2 = null;
  @observable website = null;
  @observable slug = null;
  @observable description = null;
  @observable logo = null;
  @observable enterprise = null;

  constructor (json) {
    this.id = json.id
    this.name = json.name
    this.crm_account_id = json.crm_account_id
    this.contactName = json.primary_contact_name
    this.contactEmail = json.primary_contact_email
    this.contactCalendarLink = json.primary_contact_calendar_link
    this.alternate_email1 = json.alternate_email1
    this.alternate_email2 = json.alternate_email2
    this.website = json.website
    this.slug = json.slug
    this.description = json.description
    this.logo = json.logo ? json.logo : ''
    this.enterprise = json.enterprise.name ? json.enterprise.name : ''
    this.is_deleted = json.is_deleted ? json.is_deleted : 0
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
