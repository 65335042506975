import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import { CONSTS } from '/constants'
import { inject, observer } from 'mobx-react'
import { PAGEURL } from '../../constants'
import { AdminLink as StyledLink } from '/components/theme/admin/AdminButton'

export const DelinquencyAlert = inject('store')(
  observer(({ store }) => {
    const { siteData } = store
    const { userData } = store.sessionData

    // Site is current
    if (!siteData.get('is_delinquent')) {
      return null
    }
    // Is the profile still loading?
    if (store.profileLoading) {
      return null
    }
    // User is not an admin
    if (!CONSTS.ADMINS.includes(userData.role_id)) {
      return null
    }

    return (
      <Absolute>
        <Message>
          You do not have an active subscription!&nbsp;
          <HideSmall>
            Only you can view this site at this time. Please update your
            subscription in order to publish your site.
          </HideSmall>
        </Message>
        <Link href={PAGEURL.ADMIN_SETTINGS_BILLING} passHref>
          <StyledLink>Update Plan</StyledLink>
        </Link>
      </Absolute>
    )
  })
)

const HideSmall = styled.span`
  ${(props) => props.theme.media.tablet`
    display: none;
  `}
`

const Absolute = styled.div`
  position: fixed;
  z-index: 1000;
  padding: 10px 20px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between;
  color: #fff;
  background-color: ${(props) => props.theme.colors.adminDelinquent};
`

const Message = styled.div`
  color: #fff;
  font-weight: 500;
`
