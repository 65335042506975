import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { inject, observer } from 'mobx-react'
import { PAGEURL } from '../../../constants'
import classNames from 'classnames'
import { SortableElement } from 'react-sortable-hoc'
// import { Img } from 'react-image'
import styles from '../Tiles.module.scss'
import styled from 'styled-components'
import {
  TileWrapper,
  TileStyle,
  BottomDescription,
  BottomDiscount,
  TileName,
  TileIcon,
  TileTitle,
  LoadingText,
  SavedDecorator,
  OwnerContainer
} from './TileStyles'
import { toast } from 'react-toastify'

import { FaPlus } from 'react-icons/fa'

import { VisbilityContainer, CommonPose } from '../../home/Animations'
import { calcExpires } from '/components/utils'

toast.configure()

const ExpireLabel = ({ product }) => {
  const [expires, setExpires] = useState(calcExpires(product.expires))
  useEffect(() => {
    if (!product.expires) {
      return
    }
    const id = setInterval(() => {
      let s = calcExpires(product.expires)
      setExpires(s)
    }, 1000)
    return () => {
      clearInterval(id)
    }
  }, [product.expires, setExpires])
  return <Expires>Expires: {expires}</Expires>
}

const Tile = inject('store')(
  observer(
    ({
      store,
      in_onboarding_flow,
      id,
      product,
      saved,
      explore,
      index = 0,
      tileHeight,
      tileWidth,
      includeDescription = true
    }) => {
      const url = PAGEURL.PRODUCT_DETAILS + id
      let media_url = ''
      // blame steven for this
      if (product.product_media?.length > 0) {
        const filtered = product.product_media.filter((p) => p.is_deleted === 0)
        media_url = filtered[0]?.media_center?.media
          ? filtered[0].media_center.media
          : filtered[0]?.media_url
          ? filtered[0].media_url
          : filtered[0]?.media
          ? filtered[0].media
          : null
      } else if (product.logo_url) {
        media_url = product.logo_url
      } else if (product.tile_media) {
        media_url = product.tile_media.media
      } else if (product.marketplace_image_url) {
        media_url = product.marketplace_image_url
      }

      const topTier = store.topTier()
      if (product.plans?.length > 0) {
        product.access_tier = product.plans[0].order
      }
      if (!product) {
        return null
      }

      return (
        <TileWrapper tileWidth={tileWidth} tileHeight={tileHeight}>
          <ConditionalWrapper
            condition={!explore}
            wrapper={(children) => (
              <Link
                href={explore ? '' : '/marketplace/[slug]'}
                as={in_onboarding_flow ? '#' : url}
              >
                <a>{children}</a>
              </Link>
            )}
          >
            <TileStyle
              saved={saved}
              className={classNames(
                product.product_name,
                `marketplace-tile-${index}`
              )}
            >
              <TileTitle>
                <VisbilityContainer lazyLoad={true}>
                  <CommonPose className={classNames(styles.front, styles.bg)}>
                    <StyledImg src={media_url} loading="lazy" />
                  </CommonPose>
                </VisbilityContainer>
              </TileTitle>
              <BottomDescription>
                <TileName>{product.product_name}</TileName>
                {includeDescription && (
                  <p>
                    {product.description_short || product.product_description}
                  </p>
                )}
              </BottomDescription>
              {product.expires && <ExpireLabel product={product} />}
              {product.savings && (
                <BottomDiscount>{product.savings}</BottomDiscount>
              )}
              {explore && saved ? (
                <SavedDecorator>SAVED</SavedDecorator>
              ) : (
                explore && (
                  <>
                    {/* Spacer so owner doesn't overlap text */}
                    <div style={{ height: '90px' }} />
                    <OwnerContainer>
                      <BottomDiscount>View Details</BottomDiscount>
                    </OwnerContainer>
                  </>
                )
              )}
            </TileStyle>
          </ConditionalWrapper>
        </TileWrapper>
      )
    }
  )
)

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const PlaceholderTile = () => (
  <TileWrapper>
    <Link href={'#'}>
      <TileStyle className={classNames(styles.boxxed)}>
        <TileTitle></TileTitle>
        <BottomDescription>
          <TileName>&nbsp;</TileName>
          <div>
            <LoadingText>&nbsp;</LoadingText>
            <LoadingText>&nbsp;</LoadingText>
            <LoadingText>&nbsp;</LoadingText>
            <LoadingText>&nbsp;</LoadingText>
            <LoadingText>&nbsp;</LoadingText>
          </div>
        </BottomDescription>
      </TileStyle>
    </Link>
  </TileWrapper>
)

const AddProductTile = inject('store')(
  observer(({ store }) => {
    const { userData } = store.sessionData
    const user = userData.email ? userData : null
    if (user && (user.role_id === 2 || user.role_id === 3)) {
      return (
        <TileWrapper>
          <Link passHref href={PAGEURL.EXPLORE}>
            <TileStyle className={classNames(styles.boxxed)}>
              <TileTitle>
                <TileIcon>
                  <FaPlus />
                </TileIcon>
              </TileTitle>
              <BottomDescription>
                <TileName>Add More Products</TileName>
                <div>
                  You can start adding products to your marketplace right now.
                  Search our catalog of best in class products and services.
                  Find what best suites you. Click and add them to your
                  marketplace.
                </div>
              </BottomDescription>
            </TileStyle>
          </Link>
        </TileWrapper>
      )
    } else {
      return null
    }
  })
)

const DraggableTile = SortableElement((props) => (
  <>
    <Tile {...props} />
  </>
))

const StyledImg = styled.img`
  object-fit: contain;
`

const Expires = styled.small`
  text-align: center;
  flex: 0 1 auto;
  width: 100%;
  font-weight: 600;
`

export { Tile, DraggableTile, PlaceholderTile, AddProductTile }
