import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FaSpinner } from 'react-icons/fa'
import { GrMenu } from 'react-icons/gr'
import { AiOutlineClose } from 'react-icons/ai'

import Link from 'next/link'
import { UserMenu } from './UserMenu'
import { DropDown } from '../drop-down/DropDown'

import styled, { keyframes } from 'styled-components'
import { SearchBar } from './SearchBar'

let pageYOffset = 0

const MembershipMenu = () => (
  <DropDown
    displayComponent={
      <div className="nav-item dropdown active mega-parent">
        <a className="nav-link dropdown-toggle" href="#">
          <Span>Membership</Span>
        </a>
      </div>
    }
    returnLink={
      <div className="return-link d-block d-lg-none">
        <a className="mb-20" href="#">
          Membership
        </a>
      </div>
    }
    dropDownClass={'dropdown-menu1 mega-menu1'}
  >
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <MegaHeader>
              The Holy Grail for Business Owners Just Like You
            </MegaHeader>
            <MegaP>
              What if there was a way to increase your earnings, decrease
              expenses and enhance the value of your business all in one place?
              That’s why we’ve built the Chalice Network—a membership platform
              that connects you with everything you need to compete, grow and
              thrive as an independent business owner.
            </MegaP>
          </div>
          <div className="col-md-5">
            <ul className="list-unstyled">
              <li className="menu-card">
                <Link passHref href="/marketplace/">
                  <div className="card-title">
                    <img alt="laptop icon" src="/images/home/Marketplace.png" />
                    <span>Marketplace</span>
                    <span className="card-desc">
                      Saving you money on benefits, technology, products and
                      services.
                    </span>
                  </div>
                </Link>
              </li>
              <li className="menu-card">
                <a href="/succession-link/">
                  <div className="card-title">
                    <img
                      alt="money arrow pointing up"
                      src="/images/home/MA_Platform.png"
                    />
                    <span>M&A Platform</span>
                    <span className="card-desc">
                      Communicate directly with buyers and sellers with our
                      platform.
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <MegaMore>
              <MegaH5>More Benefits</MegaH5>
              <DropdownDiv href="https://jobs.chalicenetwork.com">
                Chalice Careers
              </DropdownDiv>
              {/*
              <DropdownDiv href="https://chalicefintechinstitute.com/">
                Fintech Institute
              </DropdownDiv>
              */}
              <DropdownDiv href="https://www.chalicepay.com">
                Chalice Pay
              </DropdownDiv>
              <DropdownDiv href="/member-recognition/">
                Member Recognition
              </DropdownDiv>
            </MegaMore>
          </div>
        </div>
      </div>
    </div>
  </DropDown>
)

const DropdownDiv = ({ href, children }) => (
  <div>
    <a className="dropdown-item" href={href}>
      <Span>{children}</Span>
    </a>
  </div>
)

const DropdownItem = ({ href, children }) => (
  <li className="mb-10">
    <a className="dropdown-item" href={href}>
      <Span>{children}</Span>
    </a>
  </li>
)

const ResourcesMenu = () => (
  <DropDown
    displayComponent={
      <div className="nav-item dropdown simple-dropdown mega-parent-after">
        <a className="nav-link dropdown-toggle" href="#">
          <Span>Resources</Span>
        </a>
      </div>
    }
    returnLink={
      <div className="return-link d-block d-lg-none">
        <a className="mb-20" href="#">
          Resources
        </a>
      </div>
    }
    dropDownClass="dropdown-menu1"
  >
    <ul className="list-unstyled resources">
      <DropdownItem href="/partners/">Partner Program</DropdownItem>
      <DropdownItem href="/roi-calculator/">Savings Calculator</DropdownItem>
      <DropdownItem href="/blog/">Blog</DropdownItem>
      <DropdownItem href="/news/">News</DropdownItem>
      <DropdownItem href="/events/">Events</DropdownItem>
      <DropdownItem href="/press/">Press</DropdownItem>
    </ul>
  </DropDown>
)

export const ChaliceHeader = ({
  user,
  organization,
  logo,
  isLoading,
  siteData,
  loginUrl = '/login',
  loginText = 'Login',
  signupUrl = '/signup',
  signupText = 'Join the Network',
  containerClassName = 'container'
}) => {
  const myRef = React.createRef()

  const renderNavs = () => {
    const userMenu = user && (
      <UserMenu
        user={user}
        organization={organization}
        isLoading={isLoading}
        siteData={siteData}
      />
    )
    const loginMenu = !user && (
      <Link passHref href={loginUrl}>
        <LoginCTA>{loginText}</LoginCTA>
      </Link>
    )
    const joinMenu = !user && (
      <Link passHref href={signupUrl}>
        <LoginCTA>90-Day Free Trial</LoginCTA>
      </Link>
    )
    return (
      <div className="nav-list justify-content-end">
        <ul className="navbar-nav mr-auto align-items-center">
          <li className="hidden-lg nav-item mb-8" style={{ width: '100%' }}>
            <a className="nav-link close-item">
              <AiOutlineClose
                size={20}
                onClick={() => myRef.current.toggle()}
              />
            </a>
          </li>
          <li className="nav-item">
            <Link href="/marketplace/">
              <a className="nav-link">
                <Span>Marketplace</Span>
              </a>
            </Link>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pricing/">
              <Span>Pricing</Span>
            </a>
          </li>
          <ResourcesMenu />
          <li className="nav-item">
            <a className="nav-link" href="/about/">
              <Span>About</Span>
            </a>
          </li>

          <div key="usermenu" className="nav-item nav-relative">
            {userMenu}
          </div>
          <li key="join" className="join-network nav-item">
            {joinMenu}
          </li>
          <li key="login" className="join-network nav-item">
            {loginMenu}
          </li>
          <li className="hidden-sm">
            <SearchBar />
          </li>
        </ul>
      </div>
    )
  }

  const navs = renderNavs()
  return (
    <header
      id="chalice-header"
      className="attop"
      style={{ boxShadow: '3px 0 15px 0 rgb(0 0 0 / 5%)' }}
    >
      <div className="site-search display-none">
        <div className={containerClassName}>
          <div className="form-inline my-2 my-lg-0">
            <input
              className="form-control mr-sm-2"
              type="text"
              placeholder="Search"
              aria-label="Search"
            ></input>
            <button className="btn btn-outline-success my-2 my-sm-0">
              Search
            </button>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg">
        <div className={classNames(containerClassName, 'd-flex')}>
          <div className="logo-wrap">{logo}</div>
          <div className="nav-button-container">
            <div
              style={{
                marginTop: '5px',
                marginRight: '25px'
              }}
            >
              <SearchBar mobile={true} />
            </div>
            <DropDown displayComponent={<GrMenu size={20} />} ref={myRef}>
              {navs}
            </DropDown>
          </div>
          <div className="nav-container">{navs}</div>
        </div>
      </nav>
    </header>
  )
}

ChaliceHeader.propTypes = {
  logo: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  siteData: PropTypes.object,
  containerClassName: PropTypes.string
}

const MegaHeader = styled.h4`
  line-height: 1.34615;
`

const MegaP = styled.p`
  font-weight: 400;
  padding: 10px 0;
`

const MegaMore = styled.div`
  @media only screen and (max-width: 990px) {
    padding-top: 50px;
    a {
      padding: 0;
      span {
        margin: 7.5px 0;
      }
    }
  }
`

const MegaH5 = styled.h5`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`

const Span = styled.span`
  &:before {
    background-color: ${(props) =>
      props.theme.colors.primary || '#0d70cc'} !important;
  }
`

const LoginCTA = styled.a`
  background-color: ${(props) =>
    props.theme.colors.primary || '#0d70cc'} !important;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  padding: 20px 21px;
  font-size: 14px;
  transition: all 0.25s;
  line-height: 20px;
  @media only screen and (max-width: 768px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.9;
    outline: 0;
    color: #fff;
  }
`
