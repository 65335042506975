import { observable } from 'mobx'

/**
 * Author: Hanamantraya R
 * Class Name: ObjCRMWidgets
 * Description: Observation Class to create object of class for membership plan.
 */
export default class {
  id = null;
  @observable custom_widget_name = null;
  @observable custom_widget_size = null;
  @observable custom_widget_height = null;
  @observable custom_widget_description = null;

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: Object(params)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (params) {
    this.id = params.id
    this.custom_widget_name = params.custom_widget_name
    this.custom_widget_size = params.custom_widget_size
    this.custom_widget_height = params.custom_widget_height
    this.custom_widget_description = params.custom_widget_description
  }

  /**
   * @Author: Hanamantraya R
   * @Method: jsonToArray
   * @Param: Object(json)
   * @Return: Array
   * Description: Return array of objects
   */
  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(json)
   * @Return: Object
   * Description: Return  objects
   */
  static constructFromJSON (json) {
    return new this(json)
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(entries)
   * @Return: Object
   * Description: Update the details of particular record
   */
  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
