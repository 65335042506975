import { observable } from 'mobx'

export default class {
  @observable date = null;
  @observable count = null;

  constructor (json) {
    this.date = json.date
    this.count = json.count
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.date] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
