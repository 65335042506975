// this component extends the next image component to allow for broken src inputs
import React, { useState } from 'react'
import Image from 'next/image'
import styled from 'styled-components'

export const NextImageWithFallback = ({
  src,
  fallbackSrc = '/assets/images/placeholder.jpeg',
  alt = 'image for enterprise',
  opacity = 1,
  layout = 'fill',
  width,
  height,
  background = false,
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(src)
  if (background) {
    return (
      <BackgroundImageContainer opacity={opacity}>
        <Image
          {...rest}
          alt={alt}
          src={imgSrc || fallbackSrc}
          layout={layout}
          width={width}
          height={height}
          className="next-image"
          onError={() => {
            setImgSrc(fallbackSrc)
          }}
        />
      </BackgroundImageContainer>
    )
  }
  return (
    <ImageContainer opacity={opacity}>
      <Image
        {...rest}
        alt={alt}
        src={imgSrc || fallbackSrc}
        layout={layout}
        width={width}
        priority={true}
        height={height}
        className="next-image"
        onError={() => {
          setImgSrc(fallbackSrc)
        }}
      />
    </ImageContainer>
  )
}

// this styling is to mimic the behavior of react-image
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  & > div {
    position: unset !important;
    width: 100%;
  }

  .next-image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
    opacity: ${(props) => props.opacity};
  }
`

const BackgroundImageContainer = styled.div`
  .next-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    opacity: ${(props) => props.opacity};
  }

  @media (max-width: 999px) {
    /* override next image defaults*/
    .next-image {
      padding-top: 120px !important;
      width: auto !important;
      height: 100% !important;
      min-width: none !important;
      max-width: none !important;
      min-height: none !important;
      max-height: none !important;
    }
  }
`

// EXAMPLE USAGE (key is necessary to trigger rerenders)
{
  /* <NextImageWithFallback
    key={videoId}
    layout="fill"
    alt="image of bird"
    src={`https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`}
    fallbackSrc={`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`}
/> */
}
