import React from 'react'
import styled from 'styled-components'

const Inline = styled.span`
  padding: 20px 0;
  position: inline;
  padding-left: 20px;

  img {
    height: 30px;
    width: 30px;
  }
`

const InlineLoader = () => (
  <Inline>
    <img alt={'Page container loader'} src='/assets/images/loader.gif' />
  </Inline>
)

export default InlineLoader
