import React from 'react'
import Link from 'next/link'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import Slider from 'react-slick'

import InlineLoader from '/components/loaders/InlineLoader'
import { Tile } from '/components/marketplace/Tile/Tile'
import services from '/components/services'
import PageTitle from '/components/common/PageTitle'
import { APIURL, PAGEURL } from '/constants'
import {
  TopOffset,
  PageWrapper,
  ContentWrapper
} from '/components/theme/dashboard'
import { H1, H2, H3 } from './theme'

const ProductCarousel = ({ products }) => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: Math.min(4, products.length),
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(4, products.length)
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: Math.min(2, products.length)
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <SliderWrapper {...settings}>
      {products.map((p, i) => (
        <Product key={i} product={p} />
      ))}
    </SliderWrapper>
  )
}

const Product = ({ product }) => (
  <Link passHref href={PAGEURL.PRODUCT(product.slug)}>
    <ProductWrapper>
      <Icon>{product.logo_url && <Image src={product.logo_url} alt="" />}</Icon>
      <ProductName>{product.product_name}</ProductName>
    </ProductWrapper>
  </Link>
)

const Dashboard = inject('store')(
  observer(({ store }) => {
    const { siteData } = store
    const userData = store.sessionData.userData
    const { data } = useQuery('dashboard', () =>
      services.get_v2(APIURL.API_DASHBOARD)
    )
    const recommendations = data?.response?.recommendations || []
    const favorites = data?.response?.favorites || []
    const orders = data?.response?.orders || []
    const views = data?.response?.recent_views || []

    const renderHello = () => {
      const hello = userData.first_name
        ? `Welcome ${userData.first_name}!`
        : 'Welcome!'
      return (
        <div>
          <H1>{hello}</H1>
          <div
            dangerouslySetInnerHTML={{
              __html: siteData.welcome_message
            }}
          />
        </div>
      )
    }

    const renderRecommendations = () => {
      const settings = {
        dots: true,
        speed: 500,
        slidesToShow: Math.min(4, recommendations.length),
        slidesToScroll: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: Math.min(4, recommendations.length)
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: Math.min(2, recommendations.length)
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }

      return (
        <div>
          <H2>Top recommendations for you</H2>
          <TileTray>
            <SliderWrapper {...settings}>
              {recommendations.map((p, i) => (
                <Tile
                  id={p.slug}
                  product={p}
                  key={p.id}
                  index={i}
                  tileWidth="100%"
                  includeDescription={false}
                />
              ))}
            </SliderWrapper>
            {recommendations.length <= 0 && (
              <div>No recommendations right now</div>
            )}
          </TileTray>
        </div>
      )
    }

    const renderFavorites = () => {
      return (
        <div>
          <H3>Favorites</H3>
          <ProductTray>
            <ProductCarousel products={favorites} />
            {favorites.length <= 0 && <div>No favorites</div>}
          </ProductTray>
        </div>
      )
    }

    const renderOrders = () => {
      return (
        <div>
          <H3>Orders / Consultations</H3>
          <ProductTray>
            <ProductCarousel products={orders} />
            {orders.length <= 0 && <div>No orders</div>}
          </ProductTray>
        </div>
      )
    }

    const renderRecentViews = () => {
      return (
        <div>
          <H3>Recent Views</H3>
          <ProductTray>
            <ProductCarousel products={views} />
            {views.length <= 0 && <div>No views</div>}
          </ProductTray>
        </div>
      )
    }

    if (!data) {
      return (
        <TopOffset>
          <PageWrapper>
            <PageTitle title={`Member Dashboard`} />
            <div className="container-fluid">
              <Loading>
                <InlineLoader />
              </Loading>
            </div>
          </PageWrapper>
        </TopOffset>
      )
    }

    return (
      <TopOffset>
        <PageWrapper>
          <PageTitle title={`Member Dashboard`} />
          <div className="container-fluid">
            <ContentWrapper>
              <Grid>
                <Section>{renderHello()}</Section>
                <Section>{renderRecommendations()}</Section>
                <Section>{renderFavorites()}</Section>
                <Section>{renderOrders()}</Section>
                <Section>{renderRecentViews()}</Section>
              </Grid>
            </ContentWrapper>
          </div>
        </PageWrapper>
      </TopOffset>
    )
  })
)

export default Dashboard

const ProductName = styled.div`
  flex: 1 0 auto;
  padding: 0 10px;
  text-align: center;
`

const TileTray = styled.div``

const TileWrap = styled.div`
  display: flex;
  align-items: center;
`

const ProductTray = styled.div``

const ProductWrapper = styled.a`
  padding: 0;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.25s;
  overflow: hidden;
  box-shadow: none;
  width: 220px;
  flex: 0 0 220px;
  text-decoration: none;

  &:hover {
    transition: all 0.25s;
    text-decoration: none;
    opacity: 0.7;
    /* box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.15); */
  }
`

const Icon = styled.div`
  flex: 0 1 100px;
  width: 220px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
  border-radius: 50%;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Section = styled.div`
  margin-bottom: 20px;
`

const SliderWrapper = styled(Slider)`
  ${(props) => props.theme.media.phone`
    margin: 0 20px;
  `}

  display: flex;

  .slick-list {
    display: flex;
  }

  .slick-dots {
    display: block;
  }

  .slick-track {
    flex: 1 0 auto;
    display: flex;
    flex-flow: nowrap;
    flex-direction: row;
    align-items: stretch;
    display: flex;
    justify-content: center;
    padding: 10px 0;

    ${(props) => props.theme.media.phone`
      margin: 0 auto;
    `}

    & > div {
      flex: 1 1 auto;
      display: flex;
      height: 100%;
    }

    & > div > div {
    }

    .slick-slide {
      display: flex;
      flex-direction: column;
      float: none;
      flex: 1 0 auto;
      height: 100%;
      transition: all 0.25s;

      & > div {
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        height: 100%;
      }

      &:hover {
        transform: scale(1.01);
        transition: all 0.25s;
      }

      img {
        flex: 1 0 auto;
        width: 100%;
      }
    }
  }

  .slick-prev,
  .slick-next {
    color: ${({ theme }) => theme.colors.black};

    &:before {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .slick-prev {
    /* left: -50px; */
  }

  .slick-next {
    /* right: -50px; */
  }

  &:hover {
    .slick-prev,
    .slick-next {
      background-color: rgba(#fff, 0.75);
    }
  }
`

const Loading = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
`
