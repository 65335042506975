import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import { VisuallyHidden } from '@reach/visually-hidden'

import { AiOutlinePlus, AiOutlineDown } from 'react-icons/ai'
import useModal from '../../../hooks/useModal'
import { Modal } from '../Modal'
import AddMediaForm from './AddMediaForm'
import { RiCloseFill } from 'react-icons/ri'
import ChooseMedia from './ChooseMedia'

const MediaInput = ({
  onlyImages = true,
  multiple = false,
  isPrivate = false,
  handleChange,
  text = 'Add Media',
  current = undefined,
  currentUrl,
  suggestedDimensions = '550x450',
  showDimensions = true,
  border = true,
  noDetails = false,
  handleClose,
  previewHeight = '250px',
  previewWidth = '300px'
}) => {
  const { visible: addVisible, toggle: addToggle } = useModal()
  const { visible, toggle } = useModal()
  const [currentMedia, setCurrentMedia] = useState(current)
  const [currentU, setCurrentU] = useState(currentUrl)

  useEffect(() => {
    if (currentMedia) {
      handleChange(currentMedia)
    }
  }, [current, currentUrl, currentMedia])

  const handleCloseButton = () => {
    setCurrentMedia(null)
    setCurrentU(null)
    if (handleChange) {
      handleChange(null)
    }
    if (handleClose) {
      handleClose()
    }
  }

  const getPreviewImage = () => {
    if (currentMedia?.extension === 'pdf') {
      return '/assets/images/pdf.png'
    }
    return currentMedia?.media_thumbnail
  }

  const topBarColor = '#0d70cc'

  return (
    <Wrapper border={border}>
      <Modal
        visible={addVisible}
        toggle={addToggle}
        title="Add media"
        topBarColor={topBarColor}
      >
        <AddMediaForm
          toggle={addToggle}
          reload={null}
          noDetails={noDetails}
          setCurrentMedia={setCurrentMedia}
          handleChange={handleChange}
          onlyImages={onlyImages}
          multiple={multiple}
          isPrivate={isPrivate}
        />
      </Modal>
      <Modal
        visible={visible}
        toggle={toggle}
        title="Add existing media"
        topBarColor={topBarColor}
      >
        <ChooseMedia
          setCurrentMedia={setCurrentMedia}
          toggle={toggle}
          handleChange={handleChange}
          onlyImages={onlyImages}
        />
      </Modal>
      {currentMedia ? (
        <Preview
          imageUrl={getPreviewImage}
          width={previewWidth}
          height={previewHeight}
        >
          <CloseButton type="button" onClick={() => handleCloseButton()}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>
              <RiCloseFill size={30} />
            </span>
          </CloseButton>
        </Preview>
      ) : currentU ? (
        <Preview
          imageUrl={currentU}
          width={previewWidth}
          height={previewHeight}
        >
          <CloseButton type="button" onClick={() => handleCloseButton()}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>
              <RiCloseFill size={30} />
            </span>
          </CloseButton>
        </Preview>
      ) : (
        <>
          <Menu>
            <AddMediaButton>
              <AiOutlinePlus />
              {text}
              <AiOutlineDown />
            </AddMediaButton>
            <Dropdown>
              <DropdownItem onClick={() => addToggle()}>Add New</DropdownItem>
              <DropdownItem onClick={() => toggle()}>Use Existing</DropdownItem>
            </Dropdown>
          </Menu>
          {showDimensions && (
            <Dimensions>
              {suggestedDimensions} are the suggested dimensions
            </Dimensions>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default MediaInput

// STYLING
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  border-radius: 5px;
  border: 2px dotted ${(props) => props.theme.colors.lightGrey};
`

const Dimensions = styled.p`
  margin-top: 10px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey};
`

const AddMediaButton = styled(MenuButton)`
  padding: 7px 20px;
  font-weight: 700;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid hsl(0, 0%, 75%);
  font-size: 14px;
`

const Dropdown = styled(MenuList)`
  width: 169px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
`

const DropdownItem = styled(MenuItem)`
  font-size: 14px;
  color: hsl(0, 0%, 40%);
  padding: 10px 15px;

  &:hover {
    background-color: hsl(0, 0%, 96%);
    color: hsl(0, 0%, 40%);
  }
`

const Preview = styled.div`
  position: relative;
  width: ${(props) => props.width || '300px'};
  height: ${(props) => props.height || '250px'};
  overflow: hidden;
  background-image: url(${(props) => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const CloseButton = styled.button`
  display: flex;
  position: absolute;
  right: 0;
  top: 1px;
  background-color: transparent;
  border: none;
  padding: 5px;
  color: ${(props) => props.theme.colors.white};
  filter: drop-shadow(2px 0px 0px hsla(0, 0%, 0%, 0.8));

  &:hover {
    filter: drop-shadow(2px 0px 5px hsla(0, 0%, 0%, 0.4));
  }
`
