import { observable } from 'mobx'

/**
 * Author: Hanamantraya R
 * Class Name: ObjRedtailTasks
 * Description: Observation Class to create object of class for redtail activities.
 */
export default class {
  RecID = null;
  @observable TypeID = null;
  @observable StartDate = null;
  @observable EndDate = null;
  @observable AllDayEvent = null;
  @observable Subject = null;
  @observable CategoryID = null;
  @observable ClientID = null;
  @observable RecAddUser = null;
  @observable Priority = null;
  @observable Importance = null;
  @observable ReminderDate = null;

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: Object(params)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (json) {
    this.RecID = json.RecID
    this.TypeID = json.TypeID
    this.StartDate = json.StartDate
    this.EndDate = json.EndDate
    this.AllDayEvent = json.AllDayEvent
    this.Subject = json.Subject
    this.CategoryID = json.CategoryID
    this.ClientID = json.ClientID
    this.RecAddUser = json.RecAddUser
    this.Priority = json.Priority
    this.Importance = json.Importance
    this.ReminderDate = json.ReminderDate
  }

  /**
   * @Author: Hanamantraya R
   * @Method: jsonToArray
   * @Param: Object(json)
   * @Return: Array
   * Description: Return array of objects
   */
  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.RecID] = new this(curr)
      return acc
    }, {})
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(json)
   * @Return: Object
   * Description: Return  objects
   */
  static constructFromJSON (json) {
    return new this(json)
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(entries)
   * @Return: Object
   * Description: Update the details of particular record
   */
  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
