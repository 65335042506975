import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import Link from 'next/link'
import ReactTooltip from 'react-tooltip'
import { FiHelpCircle } from 'react-icons/fi'

import { Link as ThemeLink } from '../button/Button'
import { UserMenu } from './UserMenu'
import { DropDown } from '../drop-down/DropDown'

import { MenuToggle } from './menutoggle'
import { NavMenu } from './navmenu'
import styled from 'styled-components'
import { SearchBar } from './SearchBar'

const navItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

export const EnterpriseHeader = ({
  user,
  organization,
  logo,
  isLoading,
  pricingTab,
  siteData,
  loginUrl = '/idp/login',
  loginText = 'Login',
  signupUrl = '/signup',
  signupText = 'Join the Network',
  allowSignup = true,
  allowBilling = true,
  navItems = [],
  subNavItems = [],
  containerClassName = 'container'
}) => {
  const [isOpen, setOpen] = useState(false)

  const itemFilter = (item) => {
    if (item.visibility == 'no_auth') {
      return user === null
    } else if (item.visibility == 'no_active') {
      return !(organization && organization.plan_name)
    } else if (item.visibility == 'require_auth') {
      return user !== null
    } else {
      return true
    }
  }
  const defaultItems = [
    {
      url: '/marketplace/',
      name: siteData.exchange_link_text || 'Member Benefits'
    }
  ]
  if (pricingTab) {
    defaultItems.push({ url: '/pricing/', name: 'Pricing' })
  }

  const menuItems = (
    (navItems || []).length > 0 ? navItems : defaultItems
  ).filter(itemFilter)

  const filteredSubnav = (subNavItems || []).filter(itemFilter)
  const renderNavs = () => {
    const userMenu = user && (
      <UserMenu
        user={user}
        isLoading={isLoading}
        allowBilling={allowBilling}
        siteData={siteData}
        organization={organization}
      />
    )
    const loginMenu = !user && (
      <ThemeLink href={loginUrl}>{loginText}</ThemeLink>
    )
    const joinMenu = !user && allowSignup && (
      <ThemeLink href={signupUrl}>{signupText}</ThemeLink>
    )
    const buildItems = () => {
      return menuItems.map((i) => {
        if (!i.children || i.children.length === 0) {
          return (
            <MobileNavLi
              key={i.name}
              className="nav-item"
              variants={navItemVariants}
            >
              <Link passHref href={i.url}>
                <NavLink onClick={() => setOpen(false)}>
                  <Span>{i.name}</Span>
                </NavLink>
              </Link>
            </MobileNavLi>
          )
        } else {
          return (
            <MobileNavLi key={i.name} variants={navItemVariants}>
              <DropDown
                key={`dropdown-${i.name}`}
                alignRight={true}
                displayComponent={
                  <div className="nav-item dropdown simple-dropdown mega-parent-after">
                    <NavLink className="dropdown-toggle">
                      <Span>{i.name}</Span>
                    </NavLink>
                  </div>
                }
                returnLink={
                  <div className="return-link d-block d-lg-none">
                    <a className="link mb-20" href="#">
                      {i.name}
                    </a>
                  </div>
                }
                dropDownClass={'dropdown-menu1'}
              >
                <MobileUl className="list-unstyled">
                  {i.children.map((s) => (
                    <li key={s.name} className="mb-10">
                      <Link href={s.url}>
                        <a
                          className="link dropdown-item"
                          onClick={() => setOpen(false)}
                        >
                          <Span>{s.name}</Span>
                        </a>
                      </Link>
                    </li>
                  ))}
                </MobileUl>
              </DropDown>
            </MobileNavLi>
          )
        }
      })
    }
    return (
      <NavList className="nav-list justify-content-end">
        <NavbarNav className="navbar-nav mr-auto align-items-center">
          {user && user.approval_status !== 'approved' && (
            <>
              <ReactTooltip place="bottom" type="dark" effect="solid" />
              <MemberStatus
                data-multiline={true}
                data-tip={`
                  Your account has not been approved yet. Once an administrator
                  approves your account, you will be able to access all the
                  benefits and services available.
                `}
              >
                Pending Approval <FiHelpCircle />
              </MemberStatus>
            </>
          )}
          {buildItems()}
          <motion.li
            variants={navItemVariants}
            key="usermenu"
            className="nav-item nav-relative"
          >
            {userMenu}
          </motion.li>
          <motion.li
            variants={navItemVariants}
            key="join"
            className="join-network nav-item"
          >
            {joinMenu}
          </motion.li>
          <motion.li
            variants={navItemVariants}
            key="login"
            className="join-network nav-item"
          >
            {loginMenu}
          </motion.li>
          {siteData.search_enabled && (
            <DesktopNav>
              <SearchBar />
            </DesktopNav>
          )}
        </NavbarNav>
      </NavList>
    )
  }
  const navs = renderNavs()
  const animate = isOpen ? 'open' : 'closed'
  return (
    <Header id="chalice-header">
      <Navbar className="navbar-expand-lg">
        <NavContainer className={containerClassName}>
          <LogoWrap>{logo}</LogoWrap>
          <MobileNav initial="closed" animate={animate}>
            <MenuToggle toggle={() => setOpen((prev) => !prev)} />
            <NavMenu>{navs}</NavMenu>
          </MobileNav>
          <div className="nav-container">{navs}</div>
        </NavContainer>
      </Navbar>
      {filteredSubnav && (
        <CollectionsWrapper>
          <NavContainer className={containerClassName} style={{ padding: 0 }}>
            <Collections>
              {filteredSubnav.map((item, index) => (
                <Collection key={index} href={item.url} passHref>
                  <A>{item.name}</A>
                </Collection>
              ))}
            </Collections>
          </NavContainer>
        </CollectionsWrapper>
      )}
    </Header>
  )
}

EnterpriseHeader.propTypes = {
  logo: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  navItems: PropTypes.array,
  siteData: PropTypes.object,
  isWhiteLabel: PropTypes.bool,
  containerClassName: PropTypes.string
}

export default EnterpriseHeader

const MobileNavLi = styled(motion.li)`
  margin-bottom: 0;

  @media only screen and (max-width: 990px) {
    margin-bottom: 10px;
  }
`

const Span = styled.span`
  &:before {
    background-color: ${(props) =>
      props.theme.colors.primary || '#0d70cc'} !important;
  }
`

const MobileUl = styled.ul`
  @media only screen and (max-width: 990px) {
    padding: 0 54px;
  }
`

const NavList = styled.div`
  display: flex !important;
  flex-basis: auto;
  padding: 0;

  @media only screen and (max-width: 990px) {
    margin-top: 100px;
  }
`

const NavbarNav = styled.ul`
  list-style: none;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
    background-color: #f7f8fa;
    padding-top: 40px !important;
  }
`

const Header = styled.header`
  box-shadow: 3px 0 15px 0 rgb(0 0 0 / 5%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  transition: all 0.5s ease-in-out;
`

const Navbar = styled.nav`
  background: ${(props) =>
    props.theme.colors.nav_background_color || props.theme.colors.white};
  color: ${(props) => props.theme.colors.nav_color || props.theme.colors.black};
  z-index: 10;
  padding: 5px 0;
  margin-bottom: 0px !important;
  display: flex;
  height: var(--chalice-top-navbar-height);
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;

  @media only screen and (max-width: 990px) {
    padding: 5px 20px;
  }
`

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  z-index: 1002;
  white-space: nowrap;
  color: ${(props) =>
    props.theme.colors.nav_color || props.theme.colors.darkBlue};
  font-size: 14px;
  font-weight: 600;

  a {
    text-decoration: none;
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.primary};
    transition: all 0.25s;

    &:hover {
      text-decoration: none;
      transition: all 0.25s;
      color: ${(props) =>
        props.theme.colors.nav_color || props.theme.colors.black};
    }
  }

  img {
    height: var(--chalice-logo-height);
  }
`

const MobileNav = styled(motion.li)`
  display: none;

  @media (max-width: 990px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const DesktopNav = styled(motion.li)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 990px) {
    display: none;
  }
`

const NavLink = styled.a`
  color: ${(props) => props.theme.colors.nav_color || props.theme.colors.black};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  display: block;
  padding: 0.5rem 2rem 0.5rem 0;

  &:visited {
    color: ${(props) =>
      props.theme.colors.nav_color || props.theme.colors.black};
  }

  &:hover {
    text-decoration: none;
    color: ${(props) =>
      props.theme.colors.nav_color || props.theme.colors.black};
    opacity: 0.8;
  }

  ${(props) => props.theme.media.phone`
    padding: 0.5rem 0 0.5rem 2rem;
  `}
`

const MemberStatus = styled.button`
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  margin-right: 10px;
  padding: 5px 20px;
  border: none;
  font-size: 0.7rem;
  background-color: ${(props) => props.theme.colors.secondary};
  color: #fff;

  svg {
    margin-left: 5px;
  }
`

const CollectionsWrapper = styled.div`
  border: 0 solid ${(props) => props.theme.colors.borderGrey};
  border-bottom-width: 1px;
  border-top-width: 1px;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  background: ${(props) =>
    props.theme.colors.nav_background_color || props.theme.colors.white};

  ${(props) => props.theme.media.tablet`
    display: none;
  `}
`

const Collections = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  gap: 1rem;
  width: 100%;
`

const A = styled.a`
  transition: all 0.25s;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  position: relative;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.25s;
  }

  &:focus {
    text-decoration: none !important;
  }

  &:hover {
    transition: all 0.25s;
    text-decoration: none;

    &:after {
      width: 100%;
      transition: all 0.25s;
    }
  }
`

const Collection = styled(Link)``
