import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { useRouter } from 'next/router'
import { inject, observer } from 'mobx-react'
import { FiSearch } from 'react-icons/fi'
import { useQuery } from 'react-query'
import { DebounceInput } from 'react-debounce-input'

import { APIURL } from '/constants'
import services from '/components/services'
import { VisbilityContainer } from '/components/home/Animations'
import { Container, H1, HeroSub } from './theme'

const ElMarketplaceHero = inject('store')(
  observer(
    ({
      title = 'Explore the <em>Benefits</em>',
      subtitle = 'Discover all the perks of being a <strong>member</strong>',
      category,
      setSearch,
      search
    }) => {
      const router = useRouter()
      const { data: productCategoriesData } = useQuery(
        'product-categories',
        () => services.get_v2(APIURL.API_CATEGORIES + '?populated=1')
      )
      const categoryOptions = productCategoriesData?.response?.map(
        (category) => ({
          value: category.slug,
          label: category.name
        })
      )

      return (
        <VisbilityContainer>
          <Container>
            <Hero>
              <H1 center dangerouslySetInnerHTML={{ __html: title || '' }} />
              <HeroSub
                center
                dangerouslySetInnerHTML={{ __html: subtitle || '' }}
              />
              <Form>
                <SelectWrap>
                  <Select
                    options={categoryOptions}
                    isClearable={true}
                    isSearchable={false}
                    placeholder="Category"
                    value={categoryOptions?.find((c) => c.value === category)}
                    onChange={(e) => {
                      router.push(
                        `/marketplace/?q=${search}&c=${e?.value || ''}`
                      )
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 50,
                        minHeight: 50,
                        border: '#e6e6e6 solid 1px',
                        borderRadius: '10px'
                      })
                    }}
                  />
                </SelectWrap>
                <InputWrapper>
                  <DebounceInput
                    minLength={2}
                    placeholder="Search the Marketplace"
                    onChange={(e) => {
                      router.push(
                        `/marketplace/?q=${e.target.value || ''}&c=${category}`
                      )
                    }}
                    value={search}
                  />
                  <SearchButton>
                    <FiSearch />
                  </SearchButton>
                </InputWrapper>
              </Form>
            </Hero>
          </Container>
        </VisbilityContainer>
      )
    }
  )
)

export default ElMarketplaceHero

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: 50px 0;
  gap: 20px;

  ${(props) => props.theme.media.phone`
    padding: 50px;
  `}
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  ${(props) => props.theme.media.phone`
    flex-direction: column;
  `}
`

const InputWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderGrey};
  border-radius: 10px;
  width: 600px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1 0 auto;

  ${(props) => props.theme.media.tablet`
    width: 300px;
  `}

  input {
    border: 0;
    border-radius: 0;
    outline: 0;
    padding: 10px 20px;
    flex: 1 0 auto;
  }
`

const SelectWrap = styled.div`
  width: 300px;
  height: 50px;

  ${(props) => props.theme.media.phone`
    width: 300px;
  `}
`

const SearchButton = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) =>
    props.theme.colors.primary_text || props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  svg {
    width: 20px;
    height: 20px;
  }
`
