import * as React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke={'#fff'}
    strokeLinecap="round"
    {...props}
  />
)

export const MenuToggle = ({ toggle }) => (
  <ToggleButton onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        opacity={1}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5', stroke: '#000' },
          open: { d: 'M 3 16.5 L 17 2.5', stroke: '#000' }
        }}
      />
      <Path
        opacity={1}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1, stroke: '#000' },
          open: { opacity: 0, stroke: '#000' }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        opacity={1}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346', stroke: '#000' },
          open: { d: 'M 3 2.5 L 17 16.346', stroke: '#000' }
        }}
      />
    </svg>
  </ToggleButton>
)

const ToggleButton = styled.button`
  top: 24px;
  right: 20px;
  z-index: 1002;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: transparent;
`
