import * as Sentry from '@sentry/browser'

const REPORT_TO_SENTRY = process.env.NODE_ENV === 'production'

export const logInit = () => {
  if (REPORT_TO_SENTRY) {
    Sentry.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
    })
  }
}

export const logError = (error, info) => {
  if (REPORT_TO_SENTRY) {
    Sentry.captureException(error)
  }
  console.error(error, info)
}

export const logInfo = (message) => {
  if (REPORT_TO_SENTRY) {
    Sentry.captureMessage(message)
  }
  console.info(message)
}
