import React, { useState } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import {
  FiPlusCircle,
  FiMinusCircle,
  FiDollarSign,
  FiClock,
  FiSearch,
  FiSun
} from 'react-icons/fi'
import { motion, AnimatePresence } from 'framer-motion'
import { useQuery } from 'react-query'
import Slider from 'react-slick'

import services from '/components/services'
import { APIURL, PAGEURL } from '/constants'
import { Link as ChaliceLink } from '/components/theme/button/Button'
import { VisbilityContainer, FADE_VARIANTS } from '/components/home/Animations'
import { radial } from '/components/theme/default/base'
import { Container, H1, HeroSub } from './theme'

export const TopDeals = ({
  title = "The numbers don't lie",
  subtitle = 'Check out some of our top deals'
}) => {
  const { data } = useQuery(
    ['products'],
    () => services.get_v2(`${APIURL.API_MARKETPLACE_SEARCH}?limit=4`),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )
  return (
    <Section>
      <Container>
        <SectionHeader center>{title}</SectionHeader>
        <SectionSubtitle center>{subtitle}</SectionSubtitle>
        <VendorGrid>
          {(data?.response || []).slice(0, 4).map((item, index) => (
            <VendorWrap key={index}>
              <Link href={PAGEURL.PRODUCT(item.slug)} passHref key={index}>
                <Vendor>
                  <TileImage product={item} />
                  <VendorDeal>{item.deal}</VendorDeal>
                </Vendor>
              </Link>
            </VendorWrap>
          ))}
        </VendorGrid>
      </Container>
    </Section>
  )
}

export const HowItWorks = ({
  title = 'How it Works',
  card_1_title = 'Search the Marketplace',
  card_1_description = 'Create your account or sign in with your existing account',
  card_2_title = 'Claim your Discount',
  card_2_description = 'Save money on all your favorite products',
  card_3_title = 'Enjoy!',
  card_3_description = 'We’ve done the hard work finding the best discounts. Enjoy your extra time.',
  cta_text = 'Explore Marketplace',
  cta_url = PAGEURL.MARKETPLACE
}) => {
  return (
    <Section grey>
      <VisbilityContainer partialVisibility={false}>
        <Container>
          <SectionHeader
            center
            variants={FADE_VARIANTS}
            style={{ marginBottom: '50px' }}
          >
            {title}
          </SectionHeader>
          <Split>
            <Col width="33%">
              <Card shadow variants={FADE_VARIANTS}>
                <CardIcon>
                  <FiSearch />
                </CardIcon>
                <H3>{card_1_title}</H3>
                <p>{card_1_description}</p>
              </Card>
            </Col>
            <Col width="33%">
              <Card shadow variants={FADE_VARIANTS}>
                <CardIcon>
                  <FiDollarSign />
                </CardIcon>
                <H3>{card_2_title}</H3>
                <p>{card_2_description}</p>
              </Card>
            </Col>
            <Col width="33%">
              <Card shadow variants={FADE_VARIANTS}>
                <CardIcon>
                  <FiSun />
                </CardIcon>
                <H3>{card_3_title}</H3>
                <p>{card_3_description}</p>
              </Card>
            </Col>
          </Split>
          <CtaRow>
            <ChaliceLink href={cta_url}>{cta_text}</ChaliceLink>
          </CtaRow>
        </Container>
      </VisbilityContainer>
    </Section>
  )
}

export const SplitBlock = ({
  title = 'Feature Title',
  description = 'Feature Description',
  image,
  reverse = false,
  bg_color = '#fff',
  cta_text = 'Explore Marketplace',
  cta_url = PAGEURL.MARKETPLACE
}) => {
  return (
    <Section bgColor={bg_color}>
      <Container>
        <Split reverse={reverse}>
          <Col>
            <SectionHeader>{title}</SectionHeader>
            <p>{description}</p>
            <div>
              <ChaliceLink href={cta_url || PAGEURL.MARKETPLACE}>
                {cta_text || 'Explore Marketplace'}
              </ChaliceLink>
            </div>
          </Col>
          <Col>
            {image?.media_center?.media && (
              <img src={image?.media_center?.media} alt={image?.name} />
            )}
          </Col>
        </Split>
      </Container>
    </Section>
  )
}

export const CtaBlock = ({
  title = 'CTA Title',
  subtitle = 'CTA Description',
  cta_text = 'Explore the Marketplace',
  cta_url = PAGEURL.MARKETPLACE
}) => (
  <Section>
    <SectionHeader center>{title}</SectionHeader>
    {subtitle && <SectionSubtitle center>{subtitle}</SectionSubtitle>}
    <ChaliceLink href={cta_url}>{cta_text}</ChaliceLink>
  </Section>
)

export const Faqs = ({ title, description, questions }) => {
  return (
    <Container>
      <Section>
        <SectionHeader id="faqs">{title}</SectionHeader>
        {description && <SectionSubtitle>{description}</SectionSubtitle>}
        {(questions || []).map((q, i) => (
          <Faq key={i} faq={q} />
        ))}
      </Section>
    </Container>
  )
}

const Faq = ({ faq }) => {
  const [show, setShow] = useState(false)
  const toggle = () => setShow(!show)
  return (
    <FaqWrapper>
      <FaqQuestion onClick={toggle}>
        {show ? <FiMinusCircle size={20} /> : <FiPlusCircle size={20} />}
        {faq?.question}
      </FaqQuestion>
      {show && (
        <FaqAnswer
          dangerouslySetInnerHTML={{
            __html: faq?.answer || ''
          }}
        />
      )}
    </FaqWrapper>
  )
}

const FaqWrapper = styled.div`
  width: 100%;
`

const FaqQuestion = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  background-color: #fff;
  font-size: 1.125rem;
  padding: 0.75rem;
  border: #c8c9c7 solid 2px;
  border-bottom-width: 4px;
  border-radius: 0.375rem;
  width: 100%;
  color: #57585c;
  gap: 10px;
`

const FaqAnswer = styled.div`
  text-align: left;
  background-color: rgb(242 242 242);
  color: rgb(87 88 92);
  line-height: 1.5;
  padding: 0.75rem;
  padding-left: 2.5rem;
  border-color: #c8c9c7;
  border-width: 2px;
  border-top-width: 0;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
`

const CtaRow = styled.div`
  padding: 20px 0;
  text-align: center;
`

const Section = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  ${(props) =>
    props.bgColor &&
    css`
      background-color: ${(props) => props.bgColor};
    `}

  ${(props) =>
    props.coloredBg &&
    `
    background-color: ${props.theme.colors.secondary};
    background: ${radial(props.theme.colors.secondary)};
    padding: 50px 0;
    color: #fff;
  `}

  ${(props) =>
    props.grey &&
    `
    background-color: #f2f2f2;
  `}

  ${(props) => props.theme.media.tablet`
    padding: 100px 10px;
  `}
`

const SectionHeader = styled(motion.h2)`
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  color: ${(props) =>
    props.white ? props.theme.colors.white : props.theme.colors.secondary};
  font-weight: 700;
  font-size: 32px;
  padding: 10px 0;
  margin: 0;
`

const SectionSubtitle = styled(motion.h3)`
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  font-weight: 400;
  font-size: 18px;
  padding: 10px 0;
  margin: 0;
`

const Split = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}

  ${(props) => props.theme.media.tablet`
    flex-direction: column;
  `}
`

const Col = styled(motion.div)`
  flex: 1 1 ${(props) => props.width || 'auto'};
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;

  ${(props) => props.theme.media.tablet`
    padding: 20px;
  `}

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.15);
    `}

  img {
    border-radius: 10px;
    width: auto;
    height: 400px;

    ${(props) => props.theme.media.tablet`
      width: 100%;
      height: auto;
    `}
  }
`

const Card = styled(motion.div)`
  color: ${(props) => props.theme.colors.black};
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  margin: 10px;
  box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.15);
  position: relative;
`

const CardIcon = styled.div`
  top: -32px;
  height: 64px;
  width: 64px;
  position: absolute;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.primary_text};

  svg {
    height: 32px;
    width: 32px;
  }
`

const H3 = styled.h3`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 700;
  font-size: 24px;
  text-align: center;
`

const VendorGrid = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => props.theme.media.tablet`
    flex-direction: column;
  `}
`

const VendorWrap = styled.div`
  flex: 0 0 25%;
  display: flex;

  ${(props) => props.theme.media.tablet`
    flex: 0 0 100%;
  `}
`

const Vendor = styled.a`
  color: ${(props) => props.theme.colors.black};
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    transition: all 0.1s;
    box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.15);
  }
`

const VendorDeal = styled.h3`
  font-weight: 400;
  font-size: 1.2rem;
  text-align: center;
`

const Parade = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: 50px 0 20px 0;
  justify-content: center;
  transition: all 0.25s;

  &:hover {
    .parade-icon-wrapper {
      opacity: 0.7;
      transition: all 0.25s;
    }
  }
`

const ParadeIconWrapper = styled.a`
  width: 220px;
  height: 100px;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.25s;
  align-items: center;
  justify-content: center;

  &:hover {
    transition: all 0.25s;
    opacity: 1 !important;
    transform: scale(1.05);
  }
`

const ParadeImg = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`

const ParadeIcon = ({ product }) => (
  <Link passHref href={PAGEURL.PRODUCT(product.slug)}>
    <ParadeIconWrapper className="parade-icon-wrapper">
      <ParadeImg src={product.logo_url} alt={product.product_name} />
    </ParadeIconWrapper>
  </Link>
)

const TileImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`

const TileImage = ({ product }) => (
  <TileImageWrapper>
    <img src={product.logo_url} alt="production-icon" />
  </TileImageWrapper>
)

const SliderWrapper = styled(Slider)`
  display: flex;
  padding: 20px 0;

  .slick-list {
    display: flex;
    max-width: 90vw;
    overflow: hidden;
    width: 100%;
  }

  .slick-dots {
    display: block;
  }

  .slick-track {
    flex: 1 0 auto;
    display: flex;
    flex-flow: nowrap;
    flex-direction: row;
    align-items: stretch;
    display: flex;
    justify-content: center;
    padding: 10px 0;

    ${(props) => props.theme.media.phone`
      margin: 0 auto;
    `}

    & > div {
      flex: 1 1 auto;
      display: flex;
      height: 100%;
    }

    & > div > div {
    }

    .slick-slide {
      display: flex;
      flex-direction: column;
      float: none;
      flex: 1 0 auto;
      height: 100%;
      transition: all 0.25s;

      & > div {
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        height: 100%;
      }

      &:hover {
        transform: scale(1.01);
        transition: all 0.25s;
      }

      img {
        flex: 1 0 auto;
        width: 100%;
      }
    }
  }
`
