import React from 'react'
import styled, { css } from 'styled-components'

import { Container } from './common'
import { WPENGINE_HOST, PUBLIC_HOST } from '/components/theme/ffr/theme'

export const FfrFooter = () => {
  return (
    <Footer id="footer">
      <FooterArea>
        {/*
        <MainFooter>
          <Container>
            <Row>
              <MainCol>
                <FooterLeft>
                  <WidgetArea>
                    <a href={WPENGINE_HOST} className="footer-logo">
                      <img
                        src={`${WPENGINE_HOST}/wp-content/themes/bc/images/footer-logo.png`}
                        alt="footer logo"
                        style={{ maxWidth: '100%' }}
                      />
                    </a>
                    <FooterAddress className="footer-com-add">
                      100 Spectrum Center Dr. <br />
                      Ste. 400 Irvine, CA 92618
                    </FooterAddress>
                    <FooterSocial>
                      <a href="mailto:marketing@ffrllc.com">
                        <svg
                          className="svg-inline--fa fa-envelope"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="envelope"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M64 96c-17.7 0-32 14.3-32 32v39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9V128c0-17.7-14.3-32-32-32H64zM32 207.6V384c0 17.7 14.3 32 32 32H448c17.7 0 32-14.3 32-32V207.6L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                          ></path>
                        </svg>
                      </a>{' '}
                      &nbsp;
                      <a
                        href="https://www.linkedin.com/company/first-financial-resources-llc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          className="svg-inline--fa fa-linkedin-in"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="linkedin-in"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                          ></path>
                        </svg>
                      </a>
                    </FooterSocial>
                  </WidgetArea>
                </FooterLeft>
              </MainCol>
              <OtherCol>
                <WidgetArea>
                  <WidgetTitle>
                    <h4>Company</h4>
                  </WidgetTitle>
                  <FooterMenu>
                    <li>
                      <a
                        href="https://wpengine.com/#about-section"
                        target="_blank"
                        rel="noreferrer"
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wpengine.com/#top-carriers"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Carriers
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wpengine.com/#news-insight"
                        target="_blank"
                        rel="noreferrer"
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wpengine.com/#contact-outer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Get in Touch
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wpengine.com/#contact-outer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Join Us
                      </a>
                    </li>
                  </FooterMenu>
                </WidgetArea>
              </OtherCol>
              <OtherCol>
                <WidgetArea>
                  <WidgetTitle>
                    <h4>Resources</h4>
                  </WidgetTitle>
                  <FooterMenu className="footer-menu">
                    <li>
                      <a
                        href="https://wpengine.com/#contact-outer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://portal.ffrmembers.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Financial Dashboard
                      </a>
                    </li>
                    <li>
                      <a href="/logout">Logout of Account</a>
                    </li>
                  </FooterMenu>
                </WidgetArea>
              </OtherCol>
            </Row>
          </Container>
        </MainFooter>
        */}
        <Copyright>
          <Container>
            <Row>
              <CopyCol center>
                © 2023, First Financial Resources <br />
                100 Spectrum Center Dr. Ste. 400 Irvine, CA 92618
              </CopyCol>
              <CopyCol>
                <FooterTextLeft>
                  <a
                    href={`${PUBLIC_HOST}/privacy-policy/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  &nbsp; &nbsp;
                  <a
                    href={`${PUBLIC_HOST}/tos/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                  &nbsp; &nbsp;
                  <a
                    href={`${PUBLIC_HOST}/acceptable-use-policy/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Acceptable Use Policy
                  </a>
                  &nbsp; &nbsp;
                  <a
                    href={`${PUBLIC_HOST}/dcma/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    DMCA
                  </a>
                </FooterTextLeft>
              </CopyCol>
            </Row>
          </Container>
        </Copyright>
      </FooterArea>
    </Footer>
  )
}

const Footer = styled.footer`
  font-family: Inter, sans-serif;
  font-weight: 400;
`

const FooterArea = styled.div`
  position: relative;
  background: #053143;

  h4 {
    color: #cbe9ff;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`

const MainFooter = styled.div`
  padding: 60px 0;
  color: #fff;
`

const FooterLeft = styled.div`
  text-align: center;

  img {
    width: 120px;
    margin-bottom: 15px;
  }
`

const FooterAddress = styled.div`
  text-align: center;
  color: #fff;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 15px;
`

const FooterSocial = styled.div`
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 1.5;

  a {
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }

  svg {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
`

const FooterMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${(props) => props.theme.media.phone`
    text-align: center;
  `}

  li {
    display: block;
    padding: 5px 0;
    font-size: 14px;
    line-height: 1.3;
    transition: all 0.5s ease-in;

    &:hover {
      padding-left: 10px;
      transition: all 0.5s ease-in;

      a {
        color: #175d98;
      }
    }

    a {
      color: #dfe1e7;
      transition: all 0.5s ease-in;

      &:hover {
        text-decoration: none;
      }
    }
  }
`

const Copyright = styled.div`
  padding: 15px 0;
  background-color: #011d29;
  border-top: 1px solid #afafaf;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 1.2;

  img {
    width: 130px;
  }

  a {
    color: #fff;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  ${(props) =>
    props.center &&
    css`
      align-items: center;
    `}
`

const MainCol = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;

  ${(props) => props.theme.media.phone`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    flex: 0 0 100%;
    max-width: 100%;
  `}
`

const WidgetArea = styled.div`
  margin-bottom: 25px;
`

const WidgetTitle = styled.div`
  ${(props) => props.theme.media.phone`
    text-align: center;
  `}
`

const OtherCol = styled.div`
  flex: 0 0 25%;
  max-width: 25%;

  ${(props) => props.theme.media.phone`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    flex: 0 0 100%;
    max-width: 100%;
  `}
`

const CopyCol = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  ${(props) => props.theme.media.phone`
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    flex: 0 0 100%;
    max-width: 100%;
  `}
`

const ColSmall = styled.div`
  flex: 0 0 16.666667%;
  max-width: 16.666667%;

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  ${(props) => props.theme.media.phone`
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    flex: 0 0 100%;
    max-width: 100%;
  `}
`

const FooterTextLeft = styled.div`
  padding-top: 6px;

  ${(props) => props.theme.media.phone`
    margin-bottom: 5px;
  `}
`
