import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { BASE_HOST } from '/components/theme/houstonfirst/theme'

import { Container } from './common'

const DropDown = ({ text, children, expanded = false }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })
  const [open, setOpen] = useState(false)
  const props = isMobile
    ? { onClick: () => setOpen(!open) }
    : { onMouseOver: () => setOpen(true), onMouseOut: () => setOpen(false) }

  return (
    <NavItem {...props}>
      <span></span>
      <NavLink href="#">{text}</NavLink>
      <DropDownWrapper expanded={expanded} open={open}>{children}</DropDownWrapper>
    </NavItem>
  )
}

export const HoustonFirstHeader = ({
  user,
  organization,
  logo,
  isLoading,
  pricingTab,
  siteData,
  loginUrl = '/idp/login',
  loginText = 'Login',
  signupUrl = '/signup',
  signupText = 'Join the Network',
  allowSignup = true,
  allowBilling = true,
  navItems = [],
  containerClassName = 'container'
}) => {
  return (
    <Header>
      <Container>
        <Logo href="/" id="logo" aria-label="HoustonFirst Logo" />
        <Navbar>
          <NavbarNav>
            <DropDown text="Information" expanded>
              <SubMenu first>
                <li>
                  <H5>About Us</H5>
                  <P>Houston First Corporation is leading the effort to promote Houston as one of the great cities of the world. Our employees operate the city's finest convention, arts and entertainment venues.</P>
                  <P><LearnMore href={`${BASE_HOST}/information/about/about-us/`}>learn more &gt;</LearnMore></P>
                </li>
              </SubMenu>
              <SubMenu>
                <li>
                  <H5>About</H5>
                  <ul>
                    <li><InfoLink href={`${BASE_HOST}/informatino/about-us/`}>About Us</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/board-of-directors/`}>Board of Directors</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/president-and-executive-staff/`}>Senior Leadership Team</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/in-the-community/`}>In the Community</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/career-opportunities/`}>Careers</InfoLink></li>
                  </ul>
                </li>
                <li>
                  <H5>Documents</H5>
                  <ul>
                    <li><InfoLink href={`${BASE_HOST}/information/meetings-agendas/`}>Meetings &amp Agendas</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/financial-reports/`}>Financial Reports</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/corporate-documents/`}>Corporate Documents</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/master-plans/`}>Master Plans</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/logos-and-branding/`}>Logos &amp; Branding</InfoLink></li>
                  </ul>
                </li>
              </SubMenu>
              <SubMenu>
                <li>
                  <H5>Miscellaneous</H5>
                  <ul>
                    <li><InfoLink href={`${BASE_HOST}/information/live-video/`}>Live Video</InfoLink></li>
                    <li><InfoLink href={`${BASE_HOST}/information/houston-augmented-reality/`}>Houston Augmented Reality</InfoLink></li>
                  </ul>
                </li>
              </SubMenu>
            </DropDown>
            <NavItem>
              <span></span>
              <NavLink href={`${BASE_HOST}/do-business/`}>
                Do Business
              </NavLink>
            </NavItem>
            <DropDown text="HOT">
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/hot/welcome/`}>
                  Welcome
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/hot/make-a-payment/`}>
                  Make a Payment
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/hot/filing-and-remittance/`}>
                  Filing and Remittance
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/hot/forms/`}>
                  Forms
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/hot/no-tax-due-certificate/`}>
                  No Tax Due Certificate
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/hot/audits/`}>
                  Audits
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/hot/faqs/`}>
                  FAQs
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/contact-us/`}>
                  Contact Us
                </DropDownLink>
              </DropDownItem>
            </DropDown>
            <NavItem>
              <span></span>
              <NavLink href="/venues/"> Venues</NavLink>
            </NavItem>
            <DropDown text="News">
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/news/in-the-news/`}>
                  Houston In the News
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink href={`${BASE_HOST}/news/press-releases/`}>
                  Press Releases &amp; Blogs
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink
                  href={`${BASE_HOST}/news/houston-first-news-network/`}
                >
                  Videos
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink
                  href={`${BASE_HOST}/news/in-the-community/community-impact/`}
                >
                  Community Impact &amp; Outreach
                </DropDownLink>
              </DropDownItem>
              <DropDownItem>
                <DropDownLink
                  href={`${BASE_HOST}/news/cultural-heritage-awareness/`}
                >
                  Cultural Heritage Awarness
                </DropDownLink>
              </DropDownItem>
            </DropDown>
            <NavItem>
              <span></span>
              <NavLink href="/contact-us/"> Contact Us</NavLink>
            </NavItem>
            <NavItem>
              <SearchForm
                id="search"
                action="/search/"
                role="search"
                autoComplete="off"
              >
                <SearchInput
                  aria-label="Search"
                  type="text"
                  name="q"
                  value=""
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  enableBackground="new 0 0 16 16"
                >
                  <path d="m15.9 13.9l-4.2-4.2c.6-1 1-2.1 1-3.4-.1-3.5-2.9-6.3-6.4-6.3-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3c1.2 0 2.3-.3 3.2-.9l4.3 4.3 2.1-2.1m-13.5-7.6c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4c-2.3 0-4-1.8-4-4"></path>
                </svg>
                <div className="border"></div>
              </SearchForm>
            </NavItem>
          </NavbarNav>
        </Navbar>
      </Container>
    </Header>
  )
}

const Header = styled.header`
  font-family: Raleway, Arial, sans-serif;
  font-weight: 400;
  background: #fff;
  border-bottom: 4px solid #dedede;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;

  * {
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    text-decoration: none;
    letter-spacing: 0.5px;
  }
`

const Logo = styled.a`
  background: url(${BASE_HOST}/static/images/logo.png?041012dea626) no-repeat center;
  background-size: contain;
  display: block;
  height: 50px;
  width: 200px;
  position: absolute;
  top: 25px;
  left: 13px;
`

const DropDownWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  background: #f4f4f4;
  position: absolute;
  top: 100px;
  left: 0;
  width: 230px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 50%);
  text-transform: none;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;

  ${ props => props.expanded && css`
    padding: 30px;
    left: 235.5px;
    width: 1055px;
    position: fixed;
  `}
`

const SubMenu = styled.ul`
  ${ props => !props.first && css`
    border-left: 1px solid #ddd;
  `}
  padding: 0 20px;
  ${ props => props.first && css`
    padding-left: 0;
  `}
  width: 33%;
  display: table-cell;
  list-style: none;
  margin: 0;
  margin-top: 5px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const DropDownItem = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
}
`

const DropDownLink = styled.a`
  display: block;
  padding: 15px 20px;
  transition: all 0.2s;
  text-decoration: none;

  &:hover {
    opacity: 1;
    background: #000055;
    color: white;
    text-decoration: none;
  }
`

const Navbar = styled.nav`
  float: right;
  min-height: 100px;
`

const NavbarNav = styled.ul`
  list-style: none;
  margin: 0;

  ${(props) => props.theme.media.phone`
    display: none;
  `}

`

const NavItem = styled.li`
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  padding: 20px 16px 10px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s;

  span {
    background: #000055;
    display: block;
    width: 100%;
    height: 10px;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    transition: all 0.2s;
  }

  &:hover {
    span {
      top: 0;
      transition: all 0.2s;
    }
    & > ul {
      visibility: visible;
      opacity: 1;
    }
  }
`

const NavLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const SearchForm = styled.form`
  margin-top: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  svg {
    vertical-align: middle;
    position: absolute;
    top: 3px;
    left: 0;
  }
`

const SearchInput = styled.input`
  position: relative;
  z-index: 1;
  width: 0;
  height: auto;
  border: 0;
  border-radius: 0;
  background: 0;
  margin: 0;
  padding: 0 0 4px 24px;
  font-weight: normal;
  appearance: none;
  transition: all 0.5s;
  cursor: pointer;
`

const H5 = styled.h5`
  color: rgb(229, 27, 36) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding-left: 5px !important;
  margin-top: 10px !important;
`

const P = styled.p`
  font-weight: 400;
  padding: 5px;
`

const LearnMore = styled.a`
  width: 200px;
  border: 0;
  border-radius: 3px;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  color: #fff !important;
  appearance: none;

  display: block;
  padding: 10px;
  color: inherit;
  background: rgb(229, 27, 36);
`

const InfoLink = styled.a`
  display: block;
  padding: 5px;
  color: inherit;
  text-decoration: none;

  &:hover {
    opacity: 1;
    background-color: #000055;
    color: white;
    text-decoration: none;
  }
`

