import React, { useState, useRef } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { BiSearch } from 'react-icons/bi'
import axios from 'axios'

import styled, { keyframes } from 'styled-components'
import services from '../../services'
import { SearchBarModal } from './SearchBarModal'
import { useSearch } from '/components/context/search'

export const SearchBar = ({ showButton = true, mobile = false }) => {
  const [productList, setProductList] = useState([])
  const [loading, setLoading] = useState(false)
  // const [search, setSearch] = useState('')
  // const { visible, toggle } = useModal()
  const { showSearch, setShowSearch, search, setSearch } = useSearch()
  const toggleSearch = () => setShowSearch(!showSearch)

  const source = useRef(null)

  const onSearch = async (e) => {
    try {
      const value = e.target.value

      setSearch(value)
      setLoading(true)
      setProductList([])

      if (source.current) {
        source.current.cancel()
      }
      source.current = axios.CancelToken.source()

      const searchResults = await services.get_v2('api/marketplace/search/', {
        params: { search: value },
        cancelToken: source.current.token
      })
      if (searchResults && searchResults.response) {
        setLoading(false)
        setProductList(searchResults.response)
      } else {
        // setLoading(false)
        // setProductList([])
      }
    } catch (err) {
      console.error(err)
    }
  }

  const searchResults = []

  if (search) {
    if (!productList || (productList && productList.length === 0)) {
      if (loading) {
        searchResults.push(
          <ProductBoxEmpty key="no-new-updates">
            <Rotate />
          </ProductBoxEmpty>
        )
      } else {
        searchResults.push(
          <ProductBoxEmpty key="no-new-updates">
            No Search Results
          </ProductBoxEmpty>
        )
      }
    } else {
      for (const i in productList) {
        searchResults.push(
          <div key={'search-result-' + i}>
            <ProductBox>
              <a href={`/marketplace/${productList[i].slug}/`}>
                <ProductImageContainer>
                  <ProductImage src={productList[i].logo_url} />
                </ProductImageContainer>
                <ProductName>{productList[i].product_name}</ProductName>
                <ProductDescription>
                  {productList[i].description_short}
                </ProductDescription>
              </a>
            </ProductBox>
          </div>
        )
      }
    }
  }

  return (
    <SearchWrapper>
      {showButton && (
        <SearchButton onClick={toggleSearch}>
          <BiSearch size={25} />
        </SearchButton>
      )}
      <SearchBarModal
        onSearch={onSearch}
        search={search}
        visible={showSearch}
        toggle={toggleSearch}
        searchResults={searchResults}
        productList={productList}
        mobile={mobile}
      />
    </SearchWrapper>
  )
}

const SearchButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`

const ProductBoxEmpty = styled.div`
  padding: 43px 10px;
  font-size: 1.4em;
  font-weight: 500;
  color: black;
  margin: 0 auto;
  background-color: white;
  width: 100%;
  svg {
    animation: spin 2s linear infinite;
  }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

const Rotate = styled(FaSpinner)`
  animation: ${spin} 2s linear infinite;
`

const ProductBox = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.white};
  img {
    max-width: 100%;
  }
  border-bottom: 1px #eee solid;
  a {
    padding: 10px;
  }
`

const ProductImageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white} !important;
  text-align: center;
`

const ProductImage = styled.img`
  height: 72.5px;
`

const ProductName = styled.div`
  font-size: 1.4em;
  font-weight: 500;
  color: rgb(29, 41, 63);
  padding: 10px 0;
`

const ProductDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
`

const SearchContainer = styled.div`
  position: fixed;
  top: 48px;
  border: ${(props) => (props.isHidden ? 'none' : '1px solid #00172b')};
  border-right: none;
  background-color: rgba(#000, 0.25);
  color: black !important;
  overflow: auto;
  max-height: 100%;
  width: ${(props) => (props.isEmpty ? '100%' : 'auto')};

  @media only screen and (max-width: 990px) {
    width: 100%;
    top: 120px;
    border: none;
    border-bottom: 1px solid black;
    padding-bottom: 200px;
    bottom: 0;
  }
`
