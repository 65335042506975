import styled from 'styled-components'

export const StandardInput = ({
  value = '',
  name,
  type = 'text',
  setCurrentComponent,
  label,
  minHeight,
  onChange = (e) => {
    setCurrentComponent((prev) => {
      const temp = { ...prev }
      temp.props[name] = e.target.value
      return temp
    })
  }
}) => {
  if (type === 'textarea') {
    return (
      <Wrapper minHeight={minHeight}>
        <p>{label}</p>
        <textarea name={name} value={value} onChange={onChange} />
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <p>{label}</p>
      <input name={name} type={type} value={value} onChange={onChange} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  p {
    margin: 0 0 3px 0;
  }
  input,
  textarea {
    min-height: ${(props) => props.minHeight};
    padding: 7px 10px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 2.2px;
    width: 100%;
  }
`
