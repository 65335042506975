import { useStaticRendering } from 'mobx-react'
import Shop from './store'

const isServer = typeof window === 'undefined'
useStaticRendering(isServer)

let store = null

export default function initializeStore () {
  if (isServer) {
    return new Shop()
  }
  if (store === null) {
    store = new Shop()
  }
  return store
}
