import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Img } from 'react-image'

export const SelaxHeader = ({
  user,
  organization,
  logo,
  isLoading,
  pricingTab,
  siteData,
  loginUrl = '/idp/login',
  loginText = 'Login',
  signupUrl = '/signup',
  signupText = 'Join the Network',
  allowSignup = true,
  allowBilling = true,
  navItems = [],
  containerClassName = 'container'
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  const branding = siteData.logo_media_center ? (
    <Img src={siteData.logo_media_center.media} alt={siteData.name} />
  ) : (
    <span>{siteData.name}</span>
  )
  return (
    <Header>
      <SectionWrap>
        <Section>
          <ElementContainer>
            <Row>
              <LogoCol>
                <LogoWrap>{branding}</LogoWrap>
              </LogoCol>
              <NavCol>
                <ColumnWrap>
                  <WidgetWrap>
                    <Element>
                      <WidgetContainer>
                        <Nav>
                          <NavList>
                            <Item>
                              <A
                                href="https://selaxbrokerage.com/"
                                className="elementor-item"
                              >
                                HOME
                              </A>
                            </Item>
                            <Item>
                              <A
                                href="https://selaxbrokerage.com/why-sba/"
                                className="elementor-item"
                              >
                                WHY SBGA
                              </A>
                            </Item>
                            <Item>
                              <A
                                href="https://selaxbrokerage.com/product-access/"
                                className="elementor-item"
                              >
                                PRODUCT ACCESS
                              </A>
                            </Item>
                            <Item>
                              <A
                                href="https://selaxbrokerage.com/partners/"
                                className="elementor-item"
                              >
                                PARTNERS
                              </A>
                            </Item>
                            <Item>
                              <A
                                href="https://surelc.surancebay.com/sbweb/login.jsp?branchEditable=off&amp;branchRequired=off&amp;branch=SELAX%20Brokerage&amp;branchVisible=on&amp;cc=jturner%40selaxbrokerage.com&amp;gaId=27&amp;gaName=ECA%20Marketing"
                                className="elementor-item"
                              >
                                CONTRACTING
                              </A>
                            </Item>
                            <Item>
                              <A
                                href="https://selaxbrokerage.com/illustration-request/"
                                className="elementor-item"
                              >
                                REQUEST ILLUSTRATION
                              </A>
                            </Item>
                            <Item>
                              <A
                                href="https://selaxbrokerage.com/contact/"
                                aria-current="page"
                                className="elementor-item elementor-item-active"
                              >
                                CONTACT
                              </A>
                            </Item>
                          </NavList>
                        </Nav>
                        {/*
                        <Toggle onClick={() => toggle()}>
                          <i aria-hidden="true" role="presentation" className="elementor-menu-toggle__icon--open eicon-menu-bar"></i>
                          <i aria-hidden="true" role="presentation" className="elementor-menu-toggle__icon--close eicon-close"></i>
                          <span className="elementor-screen-only">Menu</span>
                        </Toggle>
                        <NavMobile open={open}>
                          <NavList>
                            <Item><A href="https://selaxbrokerage.com/" className="elementor-item" tabindex="-1">HOME</A></Item>
                            <Item><A href="https://selaxbrokerage.com/why-sba/" className="elementor-item" tabindex="-1">WHY SBGA</A></Item>
                            <Item><A href="https://selaxbrokerage.com/product-access/" className="elementor-item" tabindex="-1">PRODUCT ACCESS</A></Item>
                            <Item><A href="https://selaxbrokerage.com/partners/" className="elementor-item" tabindex="-1">PARTNERS</A></Item>
                            <Item><A href="https://surelc.surancebay.com/sbweb/login.jsp?branchEditable=off&amp;branchRequired=off&amp;branch=SELAX%20Brokerage&amp;branchVisible=on&amp;cc=jturner%40selaxbrokerage.com&amp;gaId=27&amp;gaName=ECA%20Marketing" className="elementor-item" tabindex="-1">CONTRACTING</A></Item>
                            <Item><A href="https://selaxbrokerage.com/illustration-request/" className="elementor-item" tabindex="-1">REQUEST ILLUSTRATION</A></Item>
                            <Item><A href="https://selaxbrokerage.com/contact/" aria-current="page" className="elementor-item elementor-item-active" tabindex="-1">CONTACT</A></Item>
                          </NavList>
                        </NavMobile>
                        */}
                      </WidgetContainer>
                    </Element>
                  </WidgetWrap>
                </ColumnWrap>
              </NavCol>
            </Row>
          </ElementContainer>
        </Section>
      </SectionWrap>
    </Header>
  )
}

const Header = styled.header`
  font-family: Arial, sans-serif;
  position: relative;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  z-index: 100;

  div,
  nav,
  ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    box-sizing: border-box;
  }
`

const SectionWrap = styled.div``

const Section = styled.section`
  position: fixed;
  width: 100vw;
  margin-top: 0px;
  margin-bottom: 0px;
  top: 0px;

  border-style: solid;
  border-width: 4px 0px 5px 0px;
  border-color: #2b6777;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: -90px;
  padding: 0px 0px 0px 0px;
  z-index: 9999;
  box-sizing: border-box;
  background-color: #f2f2f2;

  ${(props) => props.theme.media.phone`
    width: 100vw;
    padding: 0;
  `}
`

const ElementContainer = styled.div`
  max-width: 1443px;
  min-height: 0px;
  margin-right: auto !important;
  margin-left: auto !important;
  position: relative;
  align-items: center;

  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

  ${(props) => props.theme.media.phone`
    width: 90%;
    align-items: center;
  `}
`

const Row = styled.div`
  width: 100%;
  display: flex;
`

const LogoCol = styled.div`
  position: relative;
  min-height: 1px;
  display: flex;
  width: 17.5%;
  text-align: center;

  ${(props) => props.theme.media.phone`
    width: 50%;
  `}
`

const LogoWrap = styled.div`
  margin: 0 auto !important;

  img {
    width: 125px !important;
    max-width: 100% !important;
    height: 103px !important;
    object-fit: contain !important;

    ${(props) => props.theme.media.phone`
      width: 60px !important;
      max-width: 100% !important;
      height: 51px !important;
    `}
  }
`

const NavCol = styled.div`
  position: relative;
  min-height: 1px;
  display: flex;
  width: 82.5%;

  ${(props) => props.theme.media.phone`
    width: 50%;
  `}
`

const ColumnWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`

const WidgetWrap = styled.div`
  align-content: center;
  align-items: center;
  padding: 0px 20px 0px 0px;
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;

  ${(props) => props.theme.media.phone`
    padding: 0px 0px 0px 20px;
  `}
`

const Element = styled.div`
  width: 100%;
  position: relative;
`

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const Nav = styled.nav`
  display: flex;
  box-sizing: border-box;
  width: 100%;

  ${(props) => props.theme.media.phone`
    display: none;
  `}
`

const Toggle = styled.div`
  color: #2b6777;
  margin-left: auto;
  background-color: rgba(255, 255, 255, 0);
  font-size: 25px;
  border-width: 0px;
  border-radius: 0px;
  padding: 0.25em;
  cursor: pointer;
  border: 0 solid;
  display: none;

  ${(props) => props.theme.media.phone`
    display: flex;
  `}
`

const NavMobile = styled.nav`
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transform: scaleY(0);
  max-height: 0;
  position: absolute;
  z-index: 9997;
  transform-origin: top;
  overflow: auto;
  display: none;

  ${(props) => props.theme.media.phone`
    display: ${(props) => (props.open ? 'block' : 'none')};
    top: 40.5px;
    width: 100vw;
  `}
`

const NavList = styled.ul`
  justify-content: flex-end;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
`

const Item = styled.li`
  display: flex;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
`

const A = styled.a`
  color: #2b6777;
  fill: #2b6777;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  font-size: 13px;
  text-transform: uppercase;
  font-style: normal;
  line-height: 0.1em;
  letter-spacing: 0px;
  margin-inline-start: calc(37px / 2);
  margin-inline-end: calc(37px / 2);
  white-space: nowrap;
  flex-grow: 1;
  text-decoration: none;

  &:after {
    background-color: #2b6777;
    height: 0;
    content: '';
    bottom: 10px;
    width: 100%;
    left: 0;
    z-index: 2;
    transform: scale(1);
    display: block;
    position: absolute;
    transition: 0.3s;
    transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
  }

  &:hover {
    text-decoration: none;

    &:after {
      height: 2px;
      bottom: 0;
      transition: 0.3s;
    }
  }
`
