import { observable } from 'mobx'

/**
 * Author: Hanamantraya R
 * Class Name: ObjRoles
 * Description: Observation Class to create object of class for Roles.
 */
export default class {
  id = null;
  @observable name = null;
  @observable description = null;
  @observable role_product_map = null;
  @observable role_widget_map = null;
  @observable is_default = false;

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: Object(params)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (json) {
    this.id = json.id
    this.name = json.name
    this.description = json.description
    this.role_product_map = json.role_product_map
    this.role_widget_map = json.role_widget_map
    this.is_default = json.is_default
  }

  /**
   * @Author: Hanamantraya R
   * @Method: jsonToArray
   * @Param: Object(json)
   * @Return: Array
   * Description: Return array of objects
   */
  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(json)
   * @Return: Object
   * Description: Return  objects
   */
  static constructFromJSON (json) {
    return new this(json)
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(entries)
   * @Return: Object
   * Description: Update the details of particular record
   */
  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
