import React from 'react'
import styled from 'styled-components'

import { Container } from './common'
import { BASE_HOST } from '/components/theme/houstonfirst/theme'

export const HrFooter = () => {
  const d = new Date()
  return (
    <Footer id="footer">
      <Container>
        <Left>
          <span>
            © {d.getFullYear()} Houston First Corporation - 701 Avenida de las
            Americas Houston, TX 77010
          </span>
          <LeftA href={`${BASE_HOST}/privacy`}>Privacy Statement</LeftA>
          <LeftA href={`${BASE_HOST}/terms`}>Terms Of Use</LeftA>
          <LeftA href={`${BASE_HOST}/information/career-opportunities`}>
            Careers
          </LeftA>
        </Left>
        <Right>
          <Social>
            <Twitter
              href="https://twitter.com/HoustonFirst"
              target="_blank"
              ariaLabel="Twitter"
            ></Twitter>
            <LinkedIn
              href="https://www.linkedin.com/company/houston-first-corporation"
              target="_blank"
              ariaLabel="LinkedIn"
            ></LinkedIn>
          </Social>
        </Right>
      </Container>
    </Footer>
  )
}

const Footer = styled.footer`
  font-family: Raleway, Arial, sans-serif;
  background: #000026;
  padding: 20px 0;
  font-size: 11px;
  font-weight: 500;
  z-index: 2;
  position: relative;
  color: #fff !important;
  text-decoration: none;
`

const Left = styled.div`
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  letter-spacing: 0.5px;
  font-size: 12px;
  transition: all 0.25s;

  &:hover {
    a {
      opacity: 0.5;
      transition: all 0.25s;
    }
  }
`

const LeftA = styled.a`
  vertical-align: middle;
  text-transform: uppercase;
  margin: 0 0 0 12px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #fff;
  text-decoration: none;
  transition: all 0.25s;

  &:hover {
    transition: all 0.25s;
    opacity: 1 !important;
    text-decoration: none;
  }
`

const Right = styled.div`
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  text-align: right;
`

const Social = styled.div`
  a {
    margin: 0 0 0 14px;
    display: inline-block;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -9999px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
`

const Twitter = styled.a`
  background-image: url(${BASE_HOST}/static/images/social-twitter.svg?041012dea626);
`

const LinkedIn = styled.a`
  background-image: url(${BASE_HOST}/static/images/social-linkedin.svg?041012dea626);
`
