import React from 'react'
import styled from 'styled-components'
import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaYoutubeSquare,
  FaInstagramSquare,
  FaEnvelopeSquare,
  FaPhoneSquare
} from 'react-icons/fa'
import { BannersContainer } from '../banner-ads/BannersContainer'

import { VisbilityContainer, CommonPose, LinkPose } from '../../home/Animations'
import { Container } from '../../theme/default/base'

const phoneLink = (phone) => {
  if (phone) {
    return `tel:+1${phone.replace(/\D+/g, '')}`
  } else {
    return ''
  }
}

const renderLink = (link) => {
  if (link.type === 'intercom') {
    const intercom = () => {
      if (typeof window.Intercom !== 'undefined') {
        window.Intercom('show')
      }
    }
    return <a onClick={intercom}>{link.name}</a>
  } else {
    return <a href={link.url}>{link.name}</a>
  }
}

export const EnterpriseFooter = (props) => {
  const now = new Date()
  const year = now.getFullYear()
  let { footerItems } = props
  if (footerItems) {
    footerItems = JSON.parse(footerItems.content)
  }

  const renderWithItems = () => {
    if (!footerItems || footerItems.length === 0) {
      return
    }
    return (
      <VisbilityContainer>
        <Footer>
          <FooterMain>
            <Container>
              <BannersContainer banners={props.banners} placement="Footer" />
              <FooterItems>
                {footerItems.map((i) => (
                  <FooterItem key={i.name}>
                    <strong>{i.name}</strong>
                    <FooterItemList>
                      {i.children.map((s) => (
                        <li key={s.name}>{renderLink(s)}</li>
                      ))}
                    </FooterItemList>
                  </FooterItem>
                ))}
              </FooterItems>
              <BottomRow>
                <BottomCol>{renderSocial('center')}</BottomCol>
              </BottomRow>
            </Container>
          </FooterMain>
          <SubFooterCenter>
            <Container>
              <CommonPose>
                <SubFooterP>
                  {props.name} © {year}. All rights reserved.
                </SubFooterP>
              </CommonPose>
              {props.disclaimer && (
                <Disclaimer
                  dangerouslySetInnerHTML={{ __html: props.disclaimer }}
                />
              )}
            </Container>
          </SubFooterCenter>
        </Footer>
      </VisbilityContainer>
    )
  }

  const renderWithoutItems = () => {
    if (footerItems && footerItems.length >= 0) {
      return
    }
    return (
      <Footer>
        <FooterMain>
          <div className="container">
            <div>
              <Center>
                {props.address} {address2}
                <br />
                <Phone href={phoneLink(props.phone)}>{props.phone}</Phone>
              </Center>
              <BannersContainer banners={props.banners} placement="Footer" />
              {props.displayBranding && (
                <Branding>
                  <a
                    href="https://www.chaliceconnect.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Powered by Chalice Connect"
                      load="lazy"
                      src="/connect/powered-by-chalice-color.png"
                      height="100"
                      width="100"
                    />
                  </a>
                </Branding>
              )}
              <div>{renderSocial('center')}</div>
              <div>{renderTerms('center')}</div>
            </div>
          </div>
        </FooterMain>
        <SubFooterCenter>
          <div className="container">
            <div>
              <SubFooterP>
                {props.name} © {year}. All rights reserved.
              </SubFooterP>
            </div>
            {props.disclaimer && (
              <Disclaimer
                dangerouslySetInnerHTML={{ __html: props.disclaimer }}
              />
            )}
          </div>
        </SubFooterCenter>
      </Footer>
    )
  }
  const renderSocial = (alignment) => (
    <Socials alignment={alignment}>
      {props.support_email && (
        <LinkPose
          href={`mail:${props.support_email}`}
          target="_blank"
          rel="noreferrer"
        >
          <FaEnvelopeSquare />
        </LinkPose>
      )}
      {props.phone && (
        <LinkPose href={`tel:${props.phone}`} target="_blank" rel="noreferrer">
          <FaPhoneSquare />
        </LinkPose>
      )}
      {props.facebook_url && (
        <LinkPose href={props.facebook_url} target="_blank" rel="noreferrer">
          <FaFacebookSquare />
        </LinkPose>
      )}
      {props.twitter_url && (
        <LinkPose href={props.twitter_url} target="_blank" rel="noreferrer">
          <FaTwitterSquare />
        </LinkPose>
      )}
      {props.instagram_url && (
        <LinkPose href={props.instagram_url} target="_blank" rel="noreferrer">
          <FaInstagramSquare />
        </LinkPose>
      )}
      {props.linkedin_url && (
        <LinkPose href={props.linkedin_url} target="_blank" rel="noreferrer">
          <FaLinkedin />
        </LinkPose>
      )}
      {props.youtube_url && (
        <LinkPose href={props.youtube_url} target="_blank" rel="noreferrer">
          <FaYoutubeSquare />
        </LinkPose>
      )}
    </Socials>
  )
  const renderTerms = (alignment) => (
    <Legal alignment={alignment}>
      {props.terms_link && (
        <LinkPose href={props.terms_link} target="_blank" rel="noreferrer">
          Terms
        </LinkPose>
      )}
      {props.privacy_link && (
        <LinkPose href={props.privacy_link} target="_blank" rel="noreferrer">
          Privacy
        </LinkPose>
      )}
    </Legal>
  )

  let address2 = ''
  if (props.city) {
    address2 = `${props.city}, ${props.state}, ${props.zip}`
  }

  if (footerItems && footerItems.length >= 0) {
    return renderWithItems()
  } else {
    return renderWithoutItems()
  }
}

const Footer = styled.footer`
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const FooterMain = styled.div`
  padding: 20px 0 10px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const FooterItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${(props) => props.theme.media.phone`
    justify-content: center;
  `}
`

const Socials = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: ${(props) => props.alignment};
  align-items: center;
  margin: ${(props) =>
    props.alignment === 'left' ? '15px 0 15px -10px' : '15px 0 15px 0'};

  a {
    color: ${(props) => props.theme.colors.black};
    display: block;
    text-decoration: none;
    padding: ${(props) => (props.alignment === 'left' ? '0 10px' : '0 20px')};
    transition: all 0.25s;
    transform: scale(1);

    &:hover {
      transition: all 0.25s;
      transform: scale(1.1);
    }

    svg {
      height: 40px;
      width: 40px;
    }
  }
`

const Legal = styled.div`
  display: flex;
  justify-content: ${(props) => props.alignment};
  align-items: center;
  font-size: 0.8rem;

  a {
    color: ${(props) => props.theme.colors.black};
    display: block;
    text-decoration: none;
    padding: ${(props) => (props.alignment === 'left' ? '0 10px' : '0 20px')};
    transition: all 0.25s;
    transform: scale(1);

    &:hover {
      transition: all 0.25s;
      transform: scale(1.1);
    }

    svg {
      height: 40px;
      width: 40px;
    }
  }
`

const FooterItem = styled(CommonPose)`
  flex: 1 1 auto;
  padding-bottom: 20px;
  min-width: 25%;

  ${(props) => props.theme.media.phone`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  `}
`

const FooterItemList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 10px 0;

  li {
    padding: 5px 0;
  }

  a {
    color: ${(props) => props.theme.colors.black};
  }

  ${(props) => props.theme.media.phone`
    text-align: center;
  `}
`

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.media.phone`
    flex-direction: column;
  `}
`

const BottomCol = styled.div`
  flex: 1 1 auto;
`

const Phone = styled.a`
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    text-decoration: underline;
  }
`

const SubFooterCenter = styled.div`
  /* background-color: ${(props) => props.theme.colors.secondary}; */
  padding: 10px 0 30px;
  text-align: center;
`

const SubFooterP = styled.p`
  margin-bottom: 0;
  font-size: 0.75rem !important;
`

const Center = styled.div`
  text-align: center;
`

const Branding = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Disclaimer = styled(CommonPose)`
  font-size: 0.75rem;
  text-align: center;
  margin-top: 10px;
`
