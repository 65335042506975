import { useState, useEffect } from 'react'

function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true)
  const script = document.createElement('script')
  script.id = 'marketoFormId'
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null)
  document.body.appendChild(script)
}

function useMarketo ({ baseUrl, munchkinId, formId, isLoadedCallback, successCallback }) {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (scriptLoaded) {
      isLoadedCallback()
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, function(form) {
        form.onSuccess(function() {
          successCallback()
        })
      })
      return
    }
    appendScript(baseUrl, setScriptLoaded)
  }, [scriptLoaded, baseUrl, munchkinId, formId])
}

export default useMarketo
