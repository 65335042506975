const isClient = typeof window !== 'undefined'
const baseUrl = isClient
  ? process.env.NEXT_PUBLIC_API_URL
  : process.env.NEXT_PUBLIC_SERVER_API_URL
const baseUrl_v2 = isClient ? window.location.hostname : process.env.CUSTOM_HOST

const URLS = {
  BASE_URL: baseUrl,
  BASE_URL_V2: baseUrl_v2,
  API_USER_LOGIN_AUTH: 'login',
  PROFILE_IMG: `${baseUrl}images/profile_images/`,
  API_LOGOUT: 'api/logout',

  API_CALCULATOR: (id) => `api/calculator/${id}/`,

  API_CALCULATE_LEAD_CREATE: 'api/calculatorLead',
  API_CONTACT_US: 'api/contact-us/',
  API_FORGOT_PASSOWRD_MAIL: 'forgotPasswordMail',
  API_FORGOT_PASSOWRD_UPDATE: 'forgotPasswordUpdate',
  API_USER_SIGNUP: 'api/userSignup/',
  API_SET_PASSOWRD: 'setPassword',
  API_VALIDATE_SIGNUP_TOKEN: 'api/validateSignupToken',
  API_SEND_VERIFICATION_EMAIL: (user_id) =>
    `api/sendVerificationEmail/${user_id}/`,
  // API_VERIFY_SIGNUP_TOKEN: 'api/verifySignupToken',
  API_USER_VERIFY_CAPTCHA: 'api/verifyCaptcha',

  API_LAZY_LINK_SEND: `api/lazylink/send/`,
  API_MAGIC_LINK_SEND: `api/magiclink/send/`,
  API_MAGIC_VERIFY_PIN: `api/magiclink/verify-pin/`,
  API_MAGIC_LINK_VERIFY: (code) => `api/magiclink/verify/${code}/`,

  API_CREATE_ATTENDEE_CHARGE: 'api/createAttendeeCharge',
  API_REGISTER_ATTENDEE: 'api/registerAttendee',

  API_STRIPE_DELETE_CARD: 'api/deletecard/',
  API_STRIPE_ADD_CARD: 'api/addcard/',
  API_STRIPE_SET_DETAULT_CARD: 'api/makecarddefault/',
  API_STRIPE_GET_CARD: 'api/usercardlist/',

  // API_STRIPE_DELETE_CARD: 'api/stripe/deleteCard',
  // API_STRIPE_ADD_CARD: 'api/stripe/addCard',
  // API_STRIPE_SET_DETAULT_CARD: 'api/stripe/makeCardDefault',
  // API_STRIPE_GET_CARD: 'api/stripe/userCardList',

  API_STRIPE_SUBSCRIBE_MEMB_PLAN: 'api/subscribemembplan/',
  API_STRIPE_SUBSCRIBE_FREE_PLAN: 'api/subscribefreeplan/',
  API_STRIPE_USER_PAYMENT_HISTORY: 'api/getuserpaymenthistory/',
  API_STRIPE_CHECK_COUPON: 'api/checkcoupon/',
  API_STRIPE_APPLY_COUPON: 'api/applycoupon/',

  API_GET_ACTIVE_SUBSCRIPTION: 'api/getactivesubscription/',
  API_GET_SL_SUBSCRIPTION: 'api/getslsubscription/',

  API_GET_ADVISOR_PRODILE_DATA: 'api/getAdvisorProfileData',
  API_SAVE_USER_PROD_HIDE_DATA: 'api/saveUserProductHideData',
  API_USER_CHANGE_PASSWORD: 'api/changepassword/',
  API_USER_RESET_PASSWORD: 'api/resetpassword/',
  API_USER_LOGIN: 'api/login/',
  API_USER_LOGOUT: 'api/logout/',
  API_USER_FORGOT_PASSWORD: 'api/forgotpassword/',
  API_USER_CHANGE_EMAIL: 'api/changeemail/',
  API_UPDATE_USER_PROFILE: 'api/updateProfile',
  API_ACCOUNT_SETUP: 'api/accountSetup/',
  API_UPDATE_ACCOUNT: 'api/updateuserprofile/',
  API_UPDATE_ACCOUNT_IMAGE: 'api/updateprofileimage/',
  API_UPDATE_USER_PROFILE_IMAGE: 'api/updateProfileImage',
  API_GET_MEMBERSHIP_PROOF: 'api/proof-of-membership/',
  API_GET_MEMBERSHIP_PAGE: (id) => `api/proof-of-membership/${id}/`,
  API_GET_RSS_FEED_CONTENT: 'api/getRSSFeedContent',
  API_ADD_PAGE_VIEW: 'api/pageview/',
  API_GET_SURVEY_QUES: (product_id) => `api/getSurveyQuestion/${product_id}`,
  API_SAVE_SURVEY_ANSWER: 'api/answerProductSurvey',

  API_GET_BLOGS: 'api/getBlogs/',
  API_GET_BLOG_BY_ID: (blog_id) => `api/getBlogById/${blog_id}`,
  API_GET_BLOG_BY_SLUG: 'api/getBlogBySlug',
  API_GET_BLOGS_BY_TAG: 'api/getBlogsByTag/',
  API_GET_BLOG_TAGS: 'api/getBlogTags/',

  API_GET_POSTS: 'api/getPosts/',
  API_POSTS_VIEWED: 'api/posts/viewed/',
  API_POSTS_REACT: 'api/posts/react/',

  API_GET_USERS_IN_LAST_12_MONTHS: 'admin/api/getUsersInLast12Months',
  API_GET_REQ_CONSULTATION_IN_LAST_12_MONTHS: (product_id) =>
    `admin/api/getReqConsultationInLast12Months/${product_id}`,
  API_GET_PAGE_VIEW_COUNT_IN_LAST_12_MONTHS: (product_id, membership_status) =>
    `admin/api/getPageViewCountInLast12Months/${product_id}/${membership_status}`,

  API_GET_USER_OPTIONS: 'admin/api/getUserOptions',
  API_GET_ADMIN_USER_DATA: 'admin/api/getUserData',
  API_ADMIN_SAVE_USER_DATA: 'admin/api/saveUserData',
  API_ADMIN_CREATE_USER: 'admin/api/adminCreateUser',
  API_ADMIN_DEL_USER_DATA: 'admin/api/deleteUserData',
  API_ADMIN_RESET_USER_PASSWORD: 'admin/api/resetUserPassword',
  API_REFRESH_TOKEN_SEND_TO_USER: 'admin/api/refreshTokenSendToUser',
  API_GENERATE_SET_PASSWORD_LINK: 'admin/api/genEmailLinkOrSetPass',
  API_GET_ADMIN_USER_HISTORY: 'admin/api/history',
  API_ADMIN_SAVE_REVIEW_DATA: 'admin/api/saveProductsReview',
  API_ADMIN_SAVE_SURVEY_DATA: 'admin/api/saveSurveyQuestion',
  API_ADMIN_GET_REVIEW_DATA: 'admin/api/getProductsReview',
  API_ADMIN_DELETE_REVIEW_DATA: 'admin/api/deleteProductsReview',
  API_ADMIN_DELETE_SURVEY_DATA: 'admin/api/deleteSurveyQuestion',
  API_ADMIN_GET_FAQ_DATA: 'admin/api/getProductsFaq',
  API_ADMIN_DELETE_FAQ_DATA: 'admin/api/deleteProductsFaq',
  API_ADMIN_SAVE_FAQ_DATA: 'admin/api/saveProductsFaq',
  API_ADMIN_UPDATE_ACCOUNT: 'admin/api/updateuserprofile/',

  API_GET_ADMIN_ENTERPRISES: `admin/api/enterprise`,
  API_GET_ADMIN_ENTERPRISE: (id) => `admin/api/enterprise/${id}`,

  API_GET_ADMIN_CONSULTATION_DATA: 'admin/api/getConsultationData',
  API_SAVE_ADMIN_CONSULTATION_DATA: 'admin/api/saveConsultationData',
  API_DELETE_ADMIN_CONSULTATION_DATA: 'admin/api/deleteConsultationData',

  API_ACCEPT_TERMS_COND: 'api/userAcceptTermsAndCond',

  API_AUTHENTICATE_OAUTH: 'api/authenticateOAuth',

  API_SITE_DATA: 'api/site/',
  API_SITE_MAP: 'api/sitemap/',
  API_LEGAL: (slug) => `api/legal/${slug}/`,
  API_LEGAL_ACCEPT: (slug) => `api/legal/${slug}/accept/`,
  API_PAGE_DATA: (slug) => `api/page/${slug}/`,
  API_MARKETPLACE_DATA: 'api/marketplace',
  API_PRODUCT_REVIEWS: (product_id) => `api/product/${product_id}/reviews`,
  API_PRODUCT_FAQS: (product_id) => `api/product/${product_id}/faqs`,
  API_PRODUCT_MEDIA: (product_id) => `api/product/${product_id}/media`,
  API_GET_USER_PROFILE: 'api/getuserprofile/',
  API_ADMIN_GET_USER_PROFILE: 'admin/api/getUserProfile',

  API_EXPORT_USERS_DETAILS: 'admin/api/exportAllUserDetails',
  API_EXPORT_REQUEST_CONSULTATION_DETAILS:
    'admin/api/exportRequestConsultationDetails',

  API_PRODUCT_DOCUMENTS: (product_id) =>
    `admin/api/getProductDocumentsData/${product_id}`,
  API_ADMIN_HIDE_PRODUCT_FROM_EXCHANGE: 'admin/api/hideProductFromExchange',
  API_ADMIN_GET_RELATED_PRODUCTS: 'admin/api/getRelatedProducts',
  API_ADMIN_SAVE_PRODUCT_BANNER: 'admin/api/saveProductBanner',
  API_ADMIN_DELETE_BANNER_DATA: 'admin/api/deleteProductsBanner',

  // Get
  API_ADMIN_GET_PRODUCT_DATA: 'admin/api/getProductData',
  // Post for create Put for update
  API_ADMIN_SAVE_PRODUCT_DATA: 'admin/api/saveProductData',
  // Delete
  API_ADMIN_DEL_PRODUCT_DATA: 'admin/api/deleteProductData',

  // Get
  API_ADMIN_PRODUCT_MEDIA_DATA: (product_id) =>
    `admin/api/getProductMediaData/${product_id}`,
  // Post
  API_ADMIN_SAVE_PRODUCT_MEDIA_DATA: 'admin/api/saveProductMediaData',

  API_AUTHENTICATE_PROSITE_SSO: 'admin/api/authenticatePrositeSSO',

  API_ADMIN_GET_SSO_AUTNENTICATE_DATA: 'admin/api/getSSOAuthenicateList',
  API_ADMIN_SAVE_SSO_AUTNENTICATE_DATA: 'admin/api/saveSSOAuthenticatesData',
  API_ADMIN_GET_ACT_SSO_AUTH: 'admin/api/activateSSOAuthenticate',
  API_ADMIN_DEL_ACT_SSO_AUTH: 'admin/api/deleteSSOAuthenticatesData',

  API_ENROLL_REQ_CONSULTATION: 'api/enrollRequestConsultation/',
  API_ENROLL_EXTERNAL: 'api/enrollexternal/',
  API_CREATE_OASIS_ACCOUNT: 'api/enrollsubrequest/',
  API_GET_PROSITES_FIRM_INFO: 'api/getPrositeFirmInfo',

  API_ADMIN_GET_RSS_FEED_DATA: 'admin/api/getRSSFeedList',
  API_ADMIN_SAVE_RSS_FEED_DATA: 'admin/api/saveRssFeedData',
  API_ADMIN_DEL_RSS_FEED_DATA: 'admin/api/deleteRssFeedData',
  API_ADMIN_ACT_DEACT_RSS_FEED: 'admin/api/activeDeactiveRssFeed',

  API_ADMIN_GET_WIDGET_DATA: 'admin/api/getWidgetData',
  API_ADMIN_SAVE_WIDGET_DATA: 'admin/api/saveWidgetData',
  API_ADMIN_DEL_WIDGET_DATA: 'admin/api/deleteWidgetData',

  // API_ADMIN_GET_WIDGET_DATA: 'admin/api/getWidgetData',
  API_ADMIN_SAVE_CUSTOM_WIDGET_DATA: 'admin/api/saveCustomidgetData',
  // API_ADMIN_DEL_WIDGET_DATA: 'admin/api/deleteWidgetData',

  API_ADMIN_ADD_REQ_CALENDLY: 'admin/api/addNewRequestToCalendly',
  API_ADMIN_GET_MEMB_PLAN_LIST: 'admin/api/getMembershipPlanList',
  API_ADMIN_SAVE_MEMB_PLAN_LIST: 'admin/api/saveMembershipPlanData',
  API_ADMIN_DEL_MEMB_PLAN_LIST: 'admin/api/deleteMembershipPlanData',

  API_ADMIN_SET_DEFAULT_ROLE: 'admin/api/setDefaultRole',
  API_ADMIN_GET_ROLE_DATA: 'admin/api/getRoleData',
  API_ADMIN_ROLE_ROLE_DATA: 'admin/api/saveRoleData',
  API_ADMIN_DEL_ROLE_DATA: 'admin/api/deleteRoleData',

  API_ADMIN_CALC_LEAD_DATA: 'admin/api/getCalculatorData',
  API_ADMIN_DEL_CALC_DATA: 'admin/api/deleteCalculatorData',

  API_ADMIN_GET_PAGE_DATA: 'admin/api/getPageList',
  API_ADMIN_SAVE_PAGE_DATA: 'admin/api/savePageData',
  API_ADMIN_DEL_PAGE_DATA: 'admin/api/deletePageData',
  API_ADMIN_SAVE_PAGE_ORDER_DATA: 'admin/api/savePageOrderData',
  API_ADMIN_DELETE_PRODUCT_MEDIA_DOCUMENTS:
    'admin/api/deleteProductMediaDocuments',

  API_ADMIN_SAVE_PARTNER_DATA: 'admin/api/savePartnerData',
  API_ADMIN_GET_PARTNER_DATA: 'admin/api/getPartnerData',
  API_ADMIN_DEL_PARTNER_DATA: 'admin/api/deletePartnerData',

  API_ADMIN_SAVE_PRODUCT_TAG_DATA: 'admin/api/saveProductTagData',
  API_ADMIN_GET_PRODUCT_TAG_DATA: 'admin/api/getProductTagData',
  API_ADMIN_DEL_PRODUCT_TAG_DATA: 'admin/api/deleteProductTagData',

  API_ADMIN_SAVE_EVENT_DATA: 'admin/api/saveEventData',
  API_ADMIN_GET_EVENT_DATA: 'admin/api/getEventData',
  API_ADMIN_DEL_EVENT_DATA: 'admin/api/deleteEventData',

  API_GETREDTAIL_TODAYS_TASK: 'api/getRedtailsCRMActivities',
  API_RESEND_REDTAIL_OTP: 'api/resendRedtailOTP',
  API_ADMIN_GET_CUSTOM_WIDGET_DATA: 'admin/api/getCustomWidgetData',
  API_VALIDATE_REDTAIL_CREDENTIAL: 'admin/api/validateRedtailCredential',
  API_VERIFY_REDTAIL_OTP: 'admin/api/verifyRedtailOTP',
  API_REDTAIL_CONNECT: 'api/connectRedtailSSOLogin',
  API_VALIDATE_PROSITE_CREDENTIAL: 'api/authenticatePrositeUser',
  API_GET_QUICKBOOKS_DATA: 'api/getQuickBooksData',
  API_GET_SUCCESSION_LISTING_DATA: 'api/successionlink/saved/searches',
  API_GET_QB_PROFIT_LOSS_DATA: 'api/getQBProfitLossData',
  API_GET_QB_VENDOR_EXPENSES: 'api/getQBVendorExpenses',
  API_UPDATE_SSO_STATUS: 'api/updateSSOStatus',
  API_COMP_REDTAIL_TODAYS_TASK: 'api/completeRedtailsTodaysTask',
  API_GET_REDTAIL_CRM_CONTACTS: 'api/getRedtailCRMContacts',
  API_GET_REDTAIL_CRM_REMINDERS: 'api/getRedtailsCRMAReminders',
  API_VERIFY_VENTURE_CLIENTID: 'api/verifyVentureClientID',
  API_VERIFY_CLIENTID: 'api/verifyClientID',
  API_GET_BENEFITHUB_DATA: 'api/benefithub',
  API_CONNECT_ORION_SSO: 'api/connectOrionSSO',
  API_GET_ORION_DATA: 'api/getOrionUserData',
  API_GET_COALITION_QUOTE: 'api/coalition/quotation',
  API_GET_LATEST_QUOTES: 'api/coalition/latest/quotes',
  API_COALITION_BIND: 'api/coalition/bind',
  API_GET_PACKAGE_STATUS: 'api/coalition/package/status',
  API_CREATE_INVOICE: 'api/coalition/create/invoice',
  API_GET_INDUSTRIES: 'api/coalition/industries',
  API_GET_PRODUCT_BANNERS: 'api/getProductBanner/',
  API_GET_BANNERS: 'api/banners/',
  API_BANNER_CLICK: (banner_id) => `api/banner/click/${banner_id}/`,
  API_BANNER_VIEW: (banner_id) => `api/banner/view/${banner_id}/`,
  API_SAVE_TILE_ARRANGEMENTS: 'admin/api/saveTileArrangements',
  API_GET_CONSULTATIONS: 'api/consultation/requests',
  API_GET_CONSULTATIONS_NOTES: 'api/consultation/notes',
  API_GET_PARTNER_NAME: 'api/partner/name/',
  API_ADD_NOTE: 'api/consultation/note/create/',
  API_EDIT_NOTE: 'api/consultation/note/edit/',
  API_DELETE_NOTE: 'api/consultation/note/delete/',
  API_UNDO_DELETE_NOTE: 'api/consultation/note/undo/',
  API_UPDATE_DYNAMICS_STATUS: 'api/dynamics/update/status/',
  API_GET_CONSULTATIONS_UPDATE: (consultation_id) =>
    `api/consultation/requests/${consultation_id}/`,
  API_USER_REQUEST_CONSULTATIONS: 'api/consultationrequests',
  API_USER_REQUEST_CONSULTATIONS_UPDATE: (consultation_id) =>
    `api/consultationrequests/${consultation_id}/`,
  API_EMAIL_CHECK: 'api/email/check/',
  API_ADD_CONTRIBUTOR: 'api/edit/contributor/',
  API_PORTAL_INBOX: 'api/portal-inbox',
  API_PORTAL_INBOX_UPDATE: (message_id) => `api/portal-inbox/${message_id}/`,

  API_ADD_REFERRAL: 'api/referral/add/',
  API_REFERRAL_LOOKUP: 'api/referral/lookup/',
  API_GIVE_REFERRAL_CREDITS: 'api/referral/give/credits/',
  API_REMOVE_REFERRAL_CREDITS: 'api/referral/remove/credits/',
  API_UPDATE_REFERRAL: 'api/referral/update/',
  API_SLACK_CLOSED_WON: 'api/slack/closed/won/',
  API_RETREIVE_PRORATE_COST: 'api/retreiveproratecost/',
  API_GET_PARTNER_USERS: 'api/partner/users/',
  API_ORGANIZATION_INVITE_DELETE: 'api/organization/invite/delete/',
  API_ADD_EMAIL_TO_ORGANIZATION: 'api/organization/add/email/',
  API_GET_ORGANITION_INVITES: 'api/organization/invites',
  API_GET_ORGANIZATION_USERS: 'api/organization/users',
  API_UPDATE_ORGANIZATION_USERS: (user_id) =>
    `api/organization/users/${user_id}/`,
  API_ORGANIZATION_INVITE: 'api/organization/invite/',
  API_GET_ORGANITION: 'api/retreive/organization/',
  API_GET_ORGANIZATION_BY_INVITE_ID: 'api/organization/invite_id',
  API_ORGANIZATION_REMOVE_MEMBER: 'api/organization/remove/member/',
  API_ORGANIZATION_EDIT: 'api/organization/edit/',
  API_ADMIN_INVENTORY: 'admin/api/inventory/',
  API_ADMIN_INVENTORY_DETAILS: (id) => `admin/api/inventory/${id}/`,
  API_ADMIN_INVENTORY_UPDATE_SIMPLE: (id) =>
    `admin/api/inventory/${id}/updateSimple/`,
  API_ADMIN_INVENTORY_EXPORT: 'admin/api/inventory/export_admin_product_list/',
  API_ADMIN_PRODUCT: 'admin/api/product/',
  API_ADMIN_PRODUCT_EXPLORE: 'admin/api/productexplore/',
  API_ADMIN_PRODUCT_EXPLORE_SAVE: () => 'admin/api/productexplore/save/',
  API_ADMIN_PRODUCT_EXPLORE_CATEGORIES: 'admin/api/explorecategories/',
  API_ADMIN_PRODUCT_DETAIL: (product_id) => `admin/api/product/${product_id}/`,
  API_ADMIN_PRODUCT_STATUS: (product_id) =>
    `admin/api/product/${product_id}/status/`,
  API_ADMIN_PRODUCT_COPY: (product_id) =>
    `admin/api/product/${product_id}/copyproduct/`,
  API_ADMIN_PRODUCT_HIDE: (product_id, group_id) =>
    `admin/api/producthide/${product_id}/${group_id}/`,
  API_ADMIN_PRODUCT_RANKING: (product_id) =>
    `admin/api/product/${product_id}/ranking/`,
  API_ADMIN_PRODUCT_EXPORT: 'admin/api/product/export_admin_product_list/',
  API_ADMIN_PRODUCT_RANKING_CHECK: 'admin/api/product/rank_check/',
  API_ADMIN_PRODUCT_NUMBERS: 'admin/api/product/numbers',
  API_ADMIN_AUTH_TYPES: 'admin/api/authservices',
  API_ADMIN_PRODUCT_CATEGORIES: 'admin/api/productcategories',
  API_ADMIN_PRODUCT_PROXY_DETAIL: (product_id) =>
    `admin/api/product/${product_id}/proxy/`,
  API_ADMIN_CHECK_LINKED: (product_id) =>
    `admin/api/product/${product_id}/linked/`,

  API_ADMIN_PRODUCT_ASSOC: 'admin/api/productassoc/',
  API_ADMIN_PRODUCT_ASSOC_DETAIL: (product_id) =>
    `admin/api/productassoc/${product_id}/`,

  API_ADMIN_PRODUCT_FAQ: 'admin/api/faq/',
  API_ADMIN_PRODUCT_FAQ_DETAIL: (faq_id) => `admin/api/faq/${faq_id}/`,

  API_ADMIN_PRODUCT_REVIEW: 'admin/api/review/',
  API_ADMIN_PRODUCT_REVIEW_DETAIL: (review_id) =>
    `admin/api/review/${review_id}/`,

  API_ADMIN_PRODUCT_MEDIA: 'admin/api/media/',
  API_ADMIN_PRODUCT_MEDIA_DETAIL: (media_id) => `admin/api/media/${media_id}/`,

  API_ADMIN_PRODUCT_METADATA: 'admin/api/productmetadata/',
  API_ADMIN_PRODUCT_METADATA_DETAIL: (product_id) =>
    `admin/api/productmetadata/${product_id}/`,

  API_ADMIN_PRODUCT_CAROUSEL_MEDIA: 'admin/api/carouselmedia/',
  API_ADMIN_PRODUCT_CAROUSEL_MEDIA_DETAIL: (media_id) =>
    `admin/api/carouselmedia/${media_id}/`,
  API_ADMIN_PRODUCT_CAROUSEL_MEDIA_UPDATE: (product_id) =>
    `admin/api/carouselmedia/${product_id}/`,
  API_ADMIN_PRODUCT_CAROUSEL_MEDIA_SORT: (product_id) =>
    `admin/api/carouselmedia/${product_id}/sort/`,

  API_ADMIN_PRODUCT_CTA: (product_id) => `admin/api/product/${product_id}/cta/`,
  API_ADMIN_PRODUCT_CTA_DETAIL: (cta_id) => `admin/api/cta/${cta_id}/`,
  API_ADMIN_PRODUCT_CTA_UPDATE: (cta_id) => `admin/api/cta/${cta_id}/`,

  API_ADMIN_PRODUCT_PROXY_CTA: (product_id) =>
    `admin/api/proxy/${product_id}/cta/`,

  API_ADMIN_COUPON: 'admin/api/coupon/',
  API_ADMIN_COUPON_DETAIL: (coupon_id) => `admin/api/coupon/${coupon_id}/`,

  API_ADMIN_ORGANIZATION: 'admin/api/organization/',
  API_ADMIN_ORGANIZATION_DETAIL: (organization_id) =>
    `admin/api/organization/${organization_id}/`,

  API_PARTNER_ACCOUNT: 'partner/api/account/',

  API_PARTNER_ENTERPRISES: 'partner/api/portal/enterprises/',
  API_PARTNER_PRODUCTS: 'partner/api/portal/products/',

  API_ADMIN_PARTNER: 'admin/api/partner/',
  API_ADMIN_PARTNER: 'admin/api/partner/',
  API_ADMIN_PARTNER_DETAIL: (partner_id) => `admin/api/partner/${partner_id}/`,
  API_ADMIN_PARTNER_USERS: (partner_id) =>
    `admin/api/partner/${partner_id}/users`,
  API_ADMIN_VENDOR_ADD_USER: (partner_id) =>
    `admin/api/partner/${partner_id}/add_user/`,
  API_ADMIN_VENDOR_REMOVE_USER: (user_id) =>
    `admin/api/partner/${user_id}/remove_user/`,

  API_ADMIN_LEAD_CSV: 'admin/api/lead/export/',
  API_ADMIN_MEMBER: 'admin/api/member/',
  API_ADMIN_INVITE: `admin/api/member/invite/`,
  API_ADMIN_LEAD: 'admin/api/lead/',
  API_ADMIN_MEMBER_DETAIL: (member_id) => `admin/api/member/${member_id}/`,
  API_ADMIN_MEMBER_SWITCH: (member_id) =>
    `admin/api/member/${member_id}/switch/`,
  API_ADMIN_MEMBER_CSV: 'admin/api/member/export_admin_user_list/',
  API_ADMIN_MEMBER_HISTORY: (member_id) =>
    `admin/api/member/${member_id}/history`,
  API_ADMIN_USER_SET_PASSWORD: (member_id) =>
    `admin/api/member/${member_id}/set_password/`,
  API_ADMIN_USER_CONSULTATIONS: (member_id) =>
    `admin/api/member/${member_id}/consultations/`,
  API_ADMIN_USER_CHANGE_EMAIL: (member_id) =>
    `admin/api/member/${member_id}/change_email/`,

  API_TOKEN_SET_PASSWORD: 'api/tokenSetPassword/',
  API_ADMIN_TAG: 'admin/api/tag/',
  API_ADMIN_TAG_DETAIL: (tag_id) => `admin/api/tag/${tag_id}/`,
  API_ADMIN_CAN_DELETE_TAG: 'admin/api/tag/candeletetag/',

  API_ADMIN_COLLECTION: 'admin/api/collection/',
  API_ADMIN_COLLECTION_DETAIL: (id) => `admin/api/collection/${id}/`,
  API_ADMIN_CAN_DELETE_COLLECTION: 'admin/api/collection/candeletetag/',

  API_ADMIN_PAGE: 'admin/api/page/',
  API_ADMIN_PAGE_DETAIL: (page_id) => `admin/api/page/${page_id}/`,

  API_ADMIN_CALC: 'admin/api/calculator/',
  API_ADMIN_CALC_DETAIL: (page_id) => `admin/api/calculator/${page_id}/`,

  API_ADMIN_BLOG: 'admin/api/blog/',
  API_ADMIN_BLOG_TAGS: 'admin/api/blogtags/',
  API_ADMIN_BLOG_DETAIL: (blog_id) => `admin/api/blog/${blog_id}/`,

  API_ADMIN_POST: 'admin/api/post/',
  API_ADMIN_POST_DETAIL: (post_id) => `admin/api/post/${post_id}/`,

  API_ADMIN_ENTERPRISE: 'admin/api/group/',
  API_ADMIN_ENTERPRISE_DETAIL: (group_id) => `admin/api/group/${group_id}/`,
  API_ADMIN_ENTERPRISE_NOTIFICATIONS: (group_id) =>
    `admin/api/group/notifications/`,
  API_ADMIN_ENTERPRISE_SUBSCRIPTION: 'admin/api/group/subscription/',
  API_ADMIN_ENTERPRISE_THEMES: `admin/api/theme/`,

  API_ADMIN_SETTINGS_MENU: 'admin/api/menu/',
  API_ADMIN_SETTINGS_MENU_DETAIL: (menu_id) => `admin/api/menu/${menu_id}/`,

  API_ADMIN_GROUP_AUTO: 'admin/api/groupauto/',
  API_ADMIN_GROUP: 'admin/api/group/',
  API_ADMIN_GROUP_DETAIL: (group_id) => `admin/api/group/${group_id}/`,

  API_ADMIN_CONSULTATION: 'admin/api/consultation/',
  API_ADMIN_CONSULTATION_DETAIL: (consultation_id) =>
    `admin/api/consultation/${consultation_id}/`,
  API_ADMIN_CONSULTATION_CSV:
    'admin/api/consultation/export_admin_consultation_list/',

  API_ADMIN_PRODUCT_AUTO: 'admin/api/productauto/',
  API_ADMIN_MEMBER_AUTO: 'admin/api/memberauto/',
  API_ADMIN_PARTNER_AUTO: 'admin/api/partnerauto/',

  API_ADMIN_BANNER: 'admin/api/banner/',
  API_ADMIN_BANNER_DETAIL: (banner_id) => `admin/api/banner/${banner_id}/`,

  API_ADMIN_API_KEY: 'admin/api/apikey/',
  API_ADMIN_API_KEY_DETAIL: (api_key_id) => `admin/api/apikey/${api_key_id}`,

  API_ADMIN_INTEGRATION: 'admin/api/integration/',
  API_ADMIN_INTEGRATION_DETAIL: (integration_id) =>
    `admin/api/integration/${integration_id}/`,

  API_ADMIN_WEBHOOK: 'admin/api/webhook/',
  API_ADMIN_WEBHOOK_DETAIL: (webhook_id) => `admin/api/webhook/${webhook_id}/`,

  API_ADMIN_MEDIA_CENTER: 'admin/api/mediacenter/',
  API_ADMIN_MEDIA_CENTER_DETAIL: (media_id) =>
    `admin/api/mediacenter/${media_id}/`,

  API_ADMIN_EVENTS: 'admin/api/events/',
  API_ADMIN_EVENT_DETAILS: (event_id) => `admin/api/events/${event_id}/`,

  API_ADMIN_SLUG: 'admin/api/slug/',
  API_ADMIN_NAME_CHECK: 'admin/api/namecheck/',

  API_ADMIN_ADMIN_DASHBOARD_USERS: 'admin/api/dashboard/',
  API_ADMIN_ADMIN_DASHBOARD_CONSULTS: (product_id) =>
    `admin/api/dashboard/${product_id}/`,
  API_ADMIN_ADMIN_DASHBOARD_VIEWS: (product_id, membership_status) =>
    `admin/api/dashboard/${product_id}/${membership_status}/`,

  API_ADMIN_ADMIN_RECENT_ACTIVITY: 'admin/api/dashboard/activity/',
  API_ADMIN_ADMIN_MEMBER_METRICS: 'admin/api/dashboard/statistics/',

  API_CONSULTATION_EXPORT: 'api/consultation/requests/export/',

  API_CATEGORIES: 'api/categories/',
  API_MARKETPLACE_SEARCH: 'api/marketplace/search/',
  API_COLLECTION: (slug) => `api/collection/${slug}/`,
  API_DASHBOARD: 'api/dashboard/',
  API_RECOMMENDATIONS: 'api/recommendations/',
  API_MARKETPLACE_INTEGRATION_DATA: 'api/marketplace/integration/',
  API_MARKETPLACE_SHORT: (product_id) => `api/marketplace/short/${product_id}/`,
  API_MARKETPLACE_DETAIL: (product_id) =>
    `api/marketplace/detail/${product_id}/`,
  API_MARKETPLACE_SURVEY: (slug, answer, otp) =>
    `api/marketplace/detail/${slug}/survey/${answer}/?otp=${otp}`,
  API_EXPLORE_DETAIL: (product_id) => `api/explore/detail/${product_id}/`,
  API_MARKETPLACE_BANNERS: 'api/marketplace/banners/',
  API_MARKETPLACE_MEDIA: (product_id) =>
    `api/marketplace/detail/${product_id}/media/`,
  API_MARKETPLACE_RELATED: (product_id) =>
    `api/marketplace/detail/${product_id}/related/`,
  API_MARKETPLACE_CTA: (slug) => `api/marketplace/detail/${slug}/cta/`,

  API_PRODUCT_USER_INFO: (product_id) =>
    `api/marketplace/${product_id}/userdata/`,
  API_PRODUCT_FAVORITE: (product_id) =>
    `api/marketplace/${product_id}/favorite/`,

  API_GET_ADD_ONS: 'api/add-ons/',
  API_SKIP_CART: 'api/skip-cart/',
  API_CART_CREATE_OPPS: 'api/create-opps/',

  API_ROI_CALCULATOR: 'api/roi/calculator/',
  API_ROI_CALC_COLLECT_EMAIL: 'api/roi/calculator/email/',

  API_LEAD_CAPTURE: 'api/lead-capture/',

  API_USER_INTEGRATIONS: 'api/integrations/',
  API_MARKETPLACE_INTEGRATION: (slug) => `api/marketplace/${slug}/integration/`,

  API_ADMIN_PLAN: 'admin/api/plan/',
  API_ADMIN_PLAN_DETAIL: (plan_id) => `admin/api/plan/${plan_id}/`,
  API_ADMIN_PLAN_PRICE_FETCH: (plan_id) => `admin/api/plan/${plan_id}/price`,
  API_ADMIN_PLAN_PRODUCTS: 'admin/api/plan/products',
  API_ADMIN_PLAN_ORDER: 'admin/api/plan/order/',

  API_ADMIN_PRICE: 'admin/api/price/',
  API_ADMIN_PRICE_DETAIL: (price_id) => `admin/api/price/${price_id}/`,
  API_ADMIN_PRICE_PREEXISTING: 'admin/api/price/preexisting_price/',

  API_ADMIN_INSIGHTS: 'api/analytics/',

  API_ANALYTICS: 'api/record-analytic/',
  API_PAGE_VIEW: 'api/record-pageview/',

  API_INTEGRATION_TOKENS: 'api/integration/tokens/',
  API_INTEGRATION_CALENDLY_EVENTS: 'api/calendly/events/',

  API_ADMIN_ENTERPRISE_DOMAIN: 'admin/api/domain/',
  API_ADMIN_ENTERPRISE_DOMAIN_DETAIL: (domain_id) =>
    `admin/api/domain/${domain_id}/`,
  API_ADMIN_ENTERPRISE_SET_PRIMARY_DOMAIN: (domain_id) =>
    `admin/api/domain/${domain_id}/set_primary/`,
  API_ADMIN_ENTERPRISE_GET_SSL_DATA: (domain_id) =>
    `admin/api/domain/${domain_id}/get_ssl_data/`,
  API_ADMIN_ENTERPRISE_CHECK_DOMAIN_STATUS: (domain_id) =>
    `admin/api/domain/${domain_id}/check_status/`,

  API_REPORT_STATUS: 'api/report-status/',
  API_REPORT_STATUS_DETAIL: (task_id) => `api/report-status/${task_id}/`,

  ADMIN_CHECK_AUTH: 'admin/api/auth-check/',

  API_ADMIN_CONNECT_SIGNUP: 'api/connect/signup/',
  API_ADMIN_CONNECT_LOGIN: 'api/connect/login/'
}

export default URLS
