// 3rd party imports
import React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { VisuallyHidden } from '@reach/visually-hidden'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { RiCloseFill } from 'react-icons/ri'

// My imports

const MotionDialogContent = motion(DialogContent)

export const Modal = ({
  visible,
  toggle,
  title,
  children,
  windowWidth = 50,
  maxWidth,
  overflow = 'hidden',
  includeTopBar = true,
  topBarColor,
  TopBarComponent,
  closeColor,
  zIndex = 100002
}) => {
  return (
    <AnimatePresence>
      {visible ? (
        <DialogOverlay isOpen={visible} onDismiss={toggle} style={{ zIndex }}>
          <DialogContentStyled
            aria-label="Modal"
            intial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            overflow={overflow || 'hidden'}
            maxWidth={maxWidth}
            windowWidth={windowWidth}
          >
            { includeTopBar && (
              <TopBar topBarColor={topBarColor}>
                {TopBarComponent ? TopBarComponent : <h3>{title}</h3>}
                <CloseButton onClick={toggle} closeColor={closeColor}>
                  <VisuallyHidden>Close</VisuallyHidden>
                  <span aria-hidden>
                    <RiCloseFill size={30} />
                  </span>
                </CloseButton>
              </TopBar>
            )}
            <div>{children}</div>
          </DialogContentStyled>
        </DialogOverlay>
      ) : null}
    </AnimatePresence>
  )
}

// STYLING

const DialogContentStyled = styled(MotionDialogContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0;
  width: ${(props) => `${props.windowWidth}vw`};
  ${ (props) => props.maxWidth ? `max-width: ${props.maxWidth};` : '' }
  overflow: ${(props) => props.overflow || 'hidden'};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 15px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;

  ${(props) => props.theme.media.phone`
    width: 90vw;
    margin: 2vh auto;
    padding: 0;
    top: 0;
  `}
`

const TopBar = styled.div`
  background-color: ${(props) =>
    props.topBarColor || props.theme.colors.primary};
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px 15px;
  color: ${(props) => props.theme.colors.white};
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-item: centers;
`

const CloseButton = styled.button`
  flex: 0 1 auto;
  display: flex;
  background-color: transparent;
  color: hsl(0, 0%, 16%);
  border: none;
  padding: 15px;
  color: ${(props) => props.closeColor || props.theme.colors.white};

  &:hover {
    filter: drop-shadow(1px 1px 5px hsla(0, 0%, 0%, 0.2));
  }
`
