import { observable } from 'mobx'

export default class {
  id = null;
  @observable name = null;
  @observable firm_name = null;
  @observable email = null;
  @observable entries = null;
  @observable createdAt = null;
  @observable updatedAt = null;

  constructor (json) {
    this.id = json.id
    this.name = json.name
    this.firm_name = json.firm_name
    this.email = json.email
    this.entries = json.entries
    this.createdAt = json.createdAt
    this.updatedAt = json.updatedAt
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
