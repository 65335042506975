import React from 'react'
import styled from 'styled-components'

export const ElBizFooter = () => {
  const now = new Date()
  const year = now.getFullYear()
  return (
    <div className="elementor-kit-12">
      <Footer id="footer">
        <div className="footer-wrap pt-120 pb-70">
          <div className="container">
            <div className="row g-0">
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div
                  id="custom_html-1"
                  className="widget_text footer-widget footer-col-1 mb-50 widget_custom_html"
                >
                  <div className="textwidget custom-html-widget">
                    <p>
                      <strong>EarthLink Business</strong>
                    </p>
                    <p>
                      980 Hammond Drive, Ste 400
                      <br />
                      Atlanta, GA 30328
                    </p>
                    <ul>
                      <li>
                        <a
                          href="https://www.earthlink.net/tcs/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms &amp; Conditions
                        </a>
                        <br />
                      </li>
                      <li>
                        <a
                          href="https://www.earthlink.net/tcs/privacy-policy/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div
                  id="custom_html-11"
                  className="widget_text footer-widget footer-col-2 mb-50 widget_custom_html"
                >
                  <div className="textwidget custom-html-widget">
                    <p>
                      <strong>Marketing</strong>
                    </p>
                  </div>
                </div>
                <div
                  id="nav_menu-1"
                  className="footer-widget footer-col-2 mb-50 widget_nav_menu"
                >
                  <div className="menu-marketing-container">
                    <ul id="menu-marketing" className="menu">
                      <li
                        id="menu-item-84688"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84688"
                      >
                        <a href="https://business.earthlink.net/digital-marketing/">
                          Digital Marketing
                        </a>
                      </li>
                      <li
                        id="menu-item-84690"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84690"
                      >
                        <a href="https://business.earthlink.net/digital-marketing/custom-branding/">
                          Custom Branding
                        </a>
                      </li>
                      <li
                        id="menu-item-84689"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84689"
                      >
                        <a href="https://business.earthlink.net/digital-marketing/find-my-business/">
                          Business Listings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div
                  id="custom_html-12"
                  className="widget_text footer-widget footer-col-3 mb-50 widget_custom_html"
                >
                  <div className="textwidget custom-html-widget">
                    <p>
                      {' '}
                      <strong>Services</strong>{' '}
                    </p>
                  </div>
                </div>
                <div
                  id="nav_menu-2"
                  className="footer-widget footer-col-3 mb-50 widget_nav_menu"
                >
                  <div className="menu-services-container">
                    <ul id="menu-services" className="menu">
                      <li
                        id="menu-item-85498"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-85498"
                      >
                        <a href="https://business.earthlink.net/services/">
                          Website Services
                        </a>
                      </li>
                      <li
                        id="menu-item-84692"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84692"
                      >
                        <a href="https://business.earthlink.net/services/domain-registration/">
                          Domain Registration
                        </a>
                      </li>
                      <li
                        id="menu-item-84691"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84691"
                      >
                        <a href="https://business.earthlink.net/services/website-development/">
                          Website Development
                        </a>
                      </li>
                      <li
                        id="menu-item-84693"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84693"
                      >
                        <a href="https://business.earthlink.net/services/website-security/">
                          Website Security
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div
                  id="custom_html-13"
                  className="widget_text footer-widget footer-col-4 mb-50 widget_custom_html"
                >
                  <div className="textwidget custom-html-widget">
                    <p>
                      <strong>Resources</strong>
                    </p>
                  </div>
                </div>
                <div
                  id="nav_menu-6"
                  className="footer-widget footer-col-4 mb-50 widget_nav_menu"
                >
                  <div className="menu-resources-container">
                    <ul id="menu-resources" className="menu">
                      <li
                        id="menu-item-85248"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-85248"
                      >
                        <a href="https://business.earthlink.net/blog/">Blog</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  id="custom_html-7"
                  className="widget_text footer-widget footer-col-4 mb-50 widget_custom_html"
                >
                  <div className="textwidget custom-html-widget">
                    <p>
                      <strong>Contact</strong>
                    </p>
                    <ul>
                      <li>
                        Call - <a href="tel:(844) 356-5249">(844) 356-5249</a>
                      </li>
                      <li>
                        Email - <a href="/contact/">Contact Form</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <FooterSection className="row">
              <div className="col-md-4">
                <div className="footer-logo">
                  <a href="https://business.earthlink.net/">
                    <img
                      width="284"
                      height="55"
                      src="https://business.earthlink.net/wp-content/uploads/2023/05/elnk-logo-reverse-285x55-1.svg"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-8">
                <div className="copyright-text text-center">
                  <p>Copyright © {year} EarthLink LLC · All Rights Reserved</p>
                </div>
              </div>
            </FooterSection>
          </div>
        </div>
      </Footer>
    </div>
  )
}

const Footer = styled.footer`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .footer-widget ul li a {
    color: ${(props) => props.theme.colors.primary};
    font-size: 18px;
  }
  .footer-wrap {
    padding: 60px 15px;
  }

  .footer-wrap {
    padding: 30px 15px;
  }
  .footer-wrap {
    background: #fefefe;
    border-top: 1px solid #e1e6ee;
  }

  .copyright-wrap {
    padding-top: 33px;
    padding-bottom: 90px;
    background-color: ${(props) => props.theme.colors.primary};
  }

  .footer-logo img {
    aspect-ratio: auto 177 / 34;
  }

  .footer-logo img {
    max-width: 177px;
  }
  img {
    transition: 0.3s;
  }
  img {
    height: auto;
  }
  a {
    transition: all 0.3s ease-out 0s;

    &:hover {
      transition: all 0.3s ease-out 0s;
      text-decoration: none;
    }
  }
  .footer-widget.widget_nav_menu {
    margin-bottom: 24px;
  }
  .footer-widget ul li a {
    &:hover {
      padding-left: 5px;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .copyright-text p {
    color: #fff;
    margin-bottom: 0;
  }

  .copyright-text p {
    color: #e1e6ee;
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .text-md-end {
    ${(props) => props.theme.media.tablet`
      text-align: right !important;
    `}
  }
`

const FooterSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.media.tablet`
    flex-direction: column;
    gap: 10px;
  `}
`
