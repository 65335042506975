import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { Img } from 'react-image'
import styled, { css } from 'styled-components'
import { FaArrowRight, FaUserAstronaut, FaChevronDown } from 'react-icons/fa'

import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button'
import '@reach/menu-button/styles.css'

import { PAGEURL, CONSTS } from '/constants'
import { MobileMenu } from './MobileMenu'

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`

export const ElBizHeader = ({ user, siteData, subNavItems }) => {
  const [currentUser, setCurrentUser] = useState(user)
  useEffect(() => {
    setCurrentUser(user)
  }, [user])

  const isAdmin =
    currentUser?.role_id === CONSTS.SUPER_ADMIN_ROLE ||
    currentUser?.role_id === CONSTS.ENTERPRISE_ADMIN_ROLE

  return (
    <Header id="chalice-header">
      <MenuArea>
        <Container>
          <div className="">
            <div className="">
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <LogoWrap
                      href="/"
                      className="custom-logo-link"
                      rel="home"
                      aria-current="page"
                    >
                      <Img
                        src={siteData.logo_media_center.media}
                        alt={siteData.name}
                        className="custom-logo"
                      />
                    </LogoWrap>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul id="menu-header-menu-desktop" className="navigation">
                      <li
                        id="menu-item-1567"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1567 nav-item"
                      >
                        <a
                          title="Internet"
                          href="https://business.earthlink.net/internet/"
                          className="nav-link"
                        >
                          Business Internet{' '}
                          <Subarrow>
                            <FaChevronDown size={12} />
                          </Subarrow>
                        </a>
                        <ul className="sub-menu" role="menu">
                          <li
                            id="menu-item-1560"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1560 nav-item"
                          >
                            <a
                              title="Fixed Wireless"
                              href="https://business.earthlink.net/business-internet/fixed-wireless/"
                              className="dropdown-items"
                            >
                              Fixed Wireless
                            </a>
                          </li>
                          <li
                            id="menu-item-1563"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1563 nav-item"
                          >
                            <a
                              title="Business Broadband Internet"
                              href="https://business.earthlink.net/business-internet/business-broadband-internet/"
                              className="dropdown-items"
                            >
                              Business Broadband Internet
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        id="menu-item-1568"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown has-dropdown menu-item-1568 nav-item"
                      >
                        <a title="Marketing" href="#" className="nav-link">
                          Digital Marketing{' '}
                          <Subarrow>
                            <FaChevronDown size={12} />
                          </Subarrow>
                        </a>
                        <ul className="sub-menu" role="menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1563 nav-item">
                            <a
                              title="Custom Branding"
                              href="https://business.earthlink.net/digital-marketing/custom-branding/"
                              className="dropdown-items"
                            >
                              Custom Branding
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1563 nav-item">
                            <a
                              title="SEO Success Coach"
                              href="https://business.earthlink.net/digital-marketing/seo-success/"
                              className="dropdown-items"
                            >
                              SEO Success Coach
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1564 nav-item">
                            <a
                              title="Business Listings"
                              href="https://business.earthlink.net/digital-marketing/find-my-business/"
                              className="dropdown-items"
                            >
                              Business Listings
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1564 nav-item">
                            <a
                              title="Business Reputation Manager"
                              href="https://business.earthlink.net/digital-marketing/business-reputation-manager/"
                              className="dropdown-items"
                            >
                              Business Reputation Manager
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down"></span>
                        </div>
                      </li>
                      <li
                        id="menu-item-1569"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown has-dropdown menu-item-1569 nav-item"
                      >
                        <a
                          title="Website Services"
                          href="#"
                          className="nav-link"
                        >
                          Website Services{' '}
                          <Subarrow>
                            <FaChevronDown size={12} />
                          </Subarrow>
                        </a>
                        <ul className="sub-menu" role="menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1565 nav-item">
                            <a
                              title="Website Development"
                              href="https://business.earthlink.net/services/website-development/"
                              className="dropdown-items"
                            >
                              Website Development
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1561 nav-item menu-last">
                            <a
                              title="Website Security"
                              href="https://business.earthlink.net/services/website-security/"
                              className="dropdown-items"
                            >
                              Website Security
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1562 nav-item">
                            <a
                              title="Domain Registration"
                              href="https://business.earthlink.net/services/domain-registration/"
                              className="dropdown-items"
                            >
                              Domain Registration
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down"></span>
                        </div>
                      </li>
                      <li
                        id="menu-item-1570"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown has-dropdown menu-item-1570 nav-item menu-last"
                      >
                        <a title="Resources" href="#" className="nav-link">
                          Resources{' '}
                          <Subarrow>
                            <FaChevronDown size={12} />
                          </Subarrow>
                        </a>
                        <ul className="sub-menu" role="menu">
                          <li
                            id="menu-item-85247"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-85247 nav-item menu-last"
                          >
                            <a
                              title="Blog"
                              href="https://business.earthlink.net/blog/"
                              className="dropdown-items"
                            >
                              Blog
                            </a>
                          </li>
                          <li
                            id="menu-item-85888"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-85888 nav-item menu-last"
                          >
                            <a
                              title="News"
                              href="https://business.earthlink.net/news/"
                              className="dropdown-items"
                            >
                              News
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down"></span>
                        </div>
                      </li>
                    </ul>{' '}
                  </div>
                  <div className="header-action">
                    {user ? (
                      isAdmin ? (
                        <Menu>
                          <MB className="btn transparent-btn s-border-btn">
                            <FaUserAstronaut /> <span>Profile</span>
                          </MB>
                          <MenuList>
                            <Link href={PAGEURL.DASHBOARD} passHref>
                              <ML>View My Perks</ML>
                            </Link>
                            <Link href={PAGEURL.ADMIN_DASHBOARD} passHref>
                              <ML>
                                <FaUserAstronaut /> Admin View
                              </ML>
                            </Link>
                          </MenuList>
                        </Menu>
                      ) : (
                        <Link href={PAGEURL.DASHBOARD} passHref>
                          <a className="btn transparent-btn s-border-btn">
                            <FaUserAstronaut /> <span>View My Perks</span>
                          </a>
                        </Link>
                      )
                    ) : (
                      <Link href={PAGEURL.LOGIN} passHref>
                        <a className="btn transparent-btn s-border-btn">
                          Login to Perks <FaArrowRight />
                        </a>
                      </Link>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </Container>
      </MenuArea>
      <MobileMenu
        user={user}
        subNavItems={subNavItems}
        top="18px"
        right="12px"
      />
      {subNavItems && (
        <CollectionsWrapper>
          <Container>
            <Collections>
              {subNavItems.map((item, index) => (
                <Collection key={index} href={item.url} passHref>
                  <A>{item.name}</A>
                </Collection>
              ))}
            </Collections>
          </Container>
        </CollectionsWrapper>
      )}
    </Header>
  )
}

const MenuArea = styled.div`
  ${(props) => props.theme.media.tablet`
    padding: 25px 0;
  `}
`

const Header = styled.header`
  position: fixed;
  background-color: #fff;
  padding: 0px 0;
  transition: 0.4s;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0px 0;
  transition: 0.4s;

  .menu-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  .navbar-wrap {
    flex-grow: 1;
    display: flex;
  }

  .navbar-wrap ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
  }

  .navbar-wrap ul li {
    display: block;
    position: relative;

    &:hover {
      .sub-menu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .navbar-wrap ul li a {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--heading-text-color);
    display: inline-flex;
    line-height: 1;
    position: relative;
    z-index: 1;

    text-transform: none;
    padding: 35px 15px;
    line-height: 25.2px;
    letter-spacing: -0.27px;
  }

  .navbar-wrap ul li:focus-within > .sub-menu {
    opacity: 1;
    visibility: visible;
  }
  .navbar-wrap ul li:hover > .sub-menu,
  .navbar-wrap ul li:focus-within > .sub-menu {
    padding: 0;
    border-radius: 0 0 10px 10px;
  }

  .navbar-wrap ul li .sub-menu {
    position: absolute;
    left: 0;
    border-radius: 0;
    display: block;
    padding: 18px 0;
    visibility: hidden;
    min-width: 10em;
    margin: 0;
    border: 1px solid #f5f5f5;
    background: #fff;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 30px 70px 0 rgba(137, 139, 142, 0.15);
    opacity: 0;
    z-index: 9;
    overflow: hidden;
  }

  .navbar-wrap ul li .sub-menu li {
    padding: 0;
    margin: 0;
  }

  .navbar-wrap ul li .sub-menu li a {
    display: block;
    padding: 0 15px 0 30px;
    line-height: 2.6;
    font-weight: 500;
    color: var(--heading-text-color);
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    padding: 0 15px;
    font-weight: 600;
    text-transform: none;
    border-top: 1px solid #f3f3f3;
    transition: all 0.25s;

    &:hover {
      text-decoration: none;
      transition: all 0.25s;
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
    }
  }

  .main-menu .navigation li.menu-item-has-children .dropdown-btn {
    display: none;
  }

  ${(props) => props.theme.media.tablet`
    .main-menu {
      display: none;
    }
    .header-action {
      display: none;
    }
  `}

  .header-action > ul {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  ul,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .btn {
    user-select: none;
    background: ${(props) => props.theme.colors.primary} none repeat scroll 0 0;
    border: medium none;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.2rem;
    margin-bottom: 0;
    padding: 20px 34px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: break-spaces;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      transition-duration: 800ms;
      width: 200%;
      height: 200%;
      top: 110%;
      left: 50%;
      background: ${(props) => props.theme.colors.secondary};
      transform: translateX(-50%);
      border-radius: 50%;
      z-index: -1;
    }

    &:hover {
      &:before {
        top: -40%;
      }
    }
  }

  .transparent-btn {
    background: transparent;
    border: 2px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    padding: 15px 25px;
  }

  .btn.transparent-btn.s-border-btn {
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }

  .btn.transparent-btn.s-border-btn:hover {
    color: #fff;
  }

  .header-action .header-btn .transparent-btn.s-border-btn {
    &:before {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
  .mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
    border-radius: 0;
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
  }
`

const LogoWrap = styled.a`
  img {
    max-width: 191px;
    height: auto;

    ${(props) => props.theme.media.tablet`
      width: 150px;
    `}
  }
`

const CollectionsWrapper = styled.div`
  border: 0 solid ${(props) => props.theme.colors.borderGrey};
  border-bottom-width: 1px;
  border-top-width: 1px;

  ${(props) => props.theme.media.tablet`
    display: none;
  `}
`

const Collections = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  gap: 1rem;
`

const A = styled.a`
  transition: all 0.25s;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 5px;
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.25s;
  }

  &:hover {
    transition: all 0.25s;
    text-decoration: none;

    &:after {
      width: 100%;
      transition: all 0.25s;
    }
  }
`

const ButtonStyle = css`
  transition: all 0.25s;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  position: relative;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 15px 25px;
  text-decoration: none;
`

const MB = styled(MenuButton)`
  ${ButtonStyle}
`

const ML = styled(MenuLink)`
  ${ButtonStyle}

  font-size: 1rem;
  justify-content: flex-start;
  height: auto;
`

const Collection = styled(Link)``

const Subarrow = styled.span`
  line-height: 1;
  padding: 10px 0 10px 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  display: flex;
  align-items: center;
`
