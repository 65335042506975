import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const backgroundVariants = {
  open: {
    opacity: 1,
    display: 'flex',
    transition: {
      stiffness: 400,
      restDelta: 40
    }
  },
  closed: {
    opacity: 0,
    transition: {
      stiffness: 400,
      damping: 40
    },
    transitionEnd: {
      display: 'none'
    }
  }
}

const navVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

export const NavMenu = ({ children }) => (
  <Background variants={backgroundVariants}>
    <Navigation className="container" variants={navVariants}>
      {children}
    </Navigation>
  </Background>
)

NavMenu.propTypes = {
  children: PropTypes.object
}

const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: transparent;
  z-index: 1001;

  box-shadow: -2px 5px 4px -4px #999;
  display: none;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 108px;

  @media (max-width: 768px) {
    height: 100%;
  }
`

const Navigation = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
