import axios from 'axios'
import { observable, action } from 'mobx'
import services from './../components/services'
import { APIURL, PAGEURL, HTTP, CONSTS } from './../constants'
import { toast } from 'react-toastify'
import jwtDecode from 'jwt-decode'
import {
  openInNewTab,
  loginEvent,
  registerEvent,
  rcEvent,
  paymentEvent
} from './../components/utils'
import { Mixpanel } from './../components/analytics/mixpanel'
import {
  ObjPaymentHistory,
  ObjWidgets,
  ObjRssFeeds,
  ObjProducts,
  ObjUsers,
  ObjEnterprise,
  ObjConsultations,
  ObjMembershipPlans,
  ObjSSO,
  ObjPages,
  ObjRoles,
  ObjRedtailTasks,
  ObjCustomWidgets,
  ObjCalcLead,
  ObjPartners,
  ObjProductTags,
  ObjProductMedia,
  ObjEvents,
  ObjReview,
  ObjSurvey,
  ObjFaq,
  ObjBanner,
  ObjDashboard
} from './../objervable'
import ChangePasswordValidate from '../components/validators/ChangePasswordValidate'

class Store {
  @observable inputData = null
  @observable formInputs = null
  @observable tutorial = {
    run: false,
    continuous: true
  }
  // onboarding Signup Flow
  @observable in_onboarding_flow = false
  @observable req_consultation_selection_data = {
    data: []
  }

  // Data Table variable
  user_data = observable.map({})

  // flash message
  @observable flashMessage = {
    statusCode: '',
    message: '',
    status: '',
    show: false
  }

  @observable navbar = {
    className: '',
    showHeader: true,
    showFooter: true,
    useContainer: true
  }

  @observable sessionData = {
    isLoggedIn: false,
    access_token: null,
    userData: {},
    idpData: {},
    isPreLoadData: true,
    pageLoader: true,
    profileLoading: true,
    profileImageUploading: false,
    actionLoader: false,
    pageContentLoader: false,
    activeMenu: '',
    activeSubscription: null,
    slSubscription: null,
    rssFeeds: null,
    rssFeedLoading: false,
    toggleRSSRight: false,
    default_role_id: 0,
    show_login_modal: false,
    show_signup_modal: false,
    registration_product: null,
    show_toc_modal: false,
    show_pp_modal: false,
    eligible_for_trial: true
  }

  @observable organization_data = {
    stripe_customer_id: null,
    billing_email: null,
    memb_start_from: null,
    memb_end_at: null,
    membership_number: null,
    organization_name: null,
    organization_id: null,
    plan: null,
    plan_id: null
  }

  @observable prod_sub = {
    redtail: {
      sso_login_connect: null,
      access_token_expire_at: CONSTS.SSO_REDTAIL_INTERVAL,
      action_type: 'redtail_crm_login',
      return_url: null,
      is_subscribed: false,
      activity_list: observable.map({}),
      reminders: {
        range: '',
        reminderData: [],
        totalRecords: 0
      },
      contacts: [],
      flash_msg_data: { type: '', msg: '' },
      showFlashMsg: false
    },
    benefithub: {
      action_type: '',
      sub_data: null,
      load_widget_type: 'benefithub_loading'
    },
    orion: {
      action_type: '',
      sub_data: null,
      load_widget_type: 'orion_loading'
    },
    stonecastle: {
      action_type: '',
      sub_data: null
    },
    gladstone: {
      action_type: '',
      sub_data: null
    },
    lifeyield: {
      action_type: '',
      sub_data: null
    },
    integratedjourney: {
      action_type: '',
      sub_data: null
    },
    'legal-shield': {
      action_type: '',
      sub_data: null
    },
    'real-estate': {
      action_type: '',
      sub_data: null
    },
    'timbo-law': {
      action_type: '',
      sub_data: null
    },
    prosites: {
      action_type: '',
      sub_data: null
    },
    quick_books: {
      action_type: '',
      sub_data: null,
      is_subscribed: false,
      load_widget_type: 'quickbook_loading',
      quick_books_data: null
    },
    prosites_firm_info: {
      action_type: 'prosite_action_login',
      sub_data: null,
      data: [],
      visits: 0,
      unique_visits: 0,
      page_per_visit: 0,
      avg_visit: 0,
      flash_msg_data: { type: '', msg: '' },
      showFlashMsg: false
    },
    succession_link: {
      action_type: 'loading',
      data: {
        saved_search: null,
        listing_leads: null
      },
      saved_search_id: null
    }
  }

  @observable data_table = {
    data: [],
    filteredData: [],
    fields: {},
    colLength: 0,
    sortColumn: {},
    activePage: 1,
    perPage: 1000,
    totalItemsCount: 0,
    pageRangeDisplayed: 5
  }

  @observable redtail_activity_loader = false

  @observable selectedPage = 0
  @observable pageNotFound = false

  // @observable loaderFlashMsg = null

  @observable showFlashMsg = false
  @observable flash_msg_data = { type: '', msg: '' }

  @observable inialdataLoaded = false
  @observable route = null
  @observable user_poduct_hide_data = []
  @observable user_card_list = []
  @observable broker_dealer_opts = []
  @observable custodian_opts = []
  @observable member_details = []

  @observable consultations = {}
  @observable product_details = {
    review_count: 0,
    faq_count: 0,
    avg_stars: 0
  }
  @observable product_media = {
    documents: []
  }
  @observable dashboard_count_data = {
    users_data: {},
    req_consultation_data: [],
    page_view_count: {}
  }

  @observable isWhiteLabel = false

  product_banner_list = observable.map({})
  faq_list = observable.map({})
  review_list = observable.map({})
  survey_list = observable.map({})
  widget_list = observable.map({})
  custom_widget_list = observable.map({})
  payment_history = observable.map({})
  client_solutions = observable.map({})
  rss_feed_list = observable.map({})
  product_list = observable.map({})
  siteData = observable.map({})
  calc_lead_list = observable.map({})
  user_list = observable.map({})
  enterprise_list = observable.map({})
  consultation_list = observable.map({})
  consultations = observable.map({})
  memb_plan_list = observable.map({})
  sso_list = observable.map({})
  page_list = observable.map({})
  role_list = observable.map({})
  redtail_today_task = observable.map({})
  prosite_today_task = observable.map({})
  partner_list = observable.map({})
  product_tag_list = observable.map({})
  product_groups = observable.map({})
  product_media_list = observable.map({})
  event_list = observable.map({})

  // appIdentify = async (userData, identify) => {
  //   const name = (
  //     (userData.first_name || '') + ' ' + (userData.last_name || '')
  //   ).trim()
  //   if (typeof window !== 'undefined' && window.Intercom) {
  //     const data = {
  //       app_id: CONSTS.INTERCOM_APP,
  //       email: userData.email,
  //       name: name,
  //       membership_number: userData.membership_number,
  //       phone: userData.phone
  //     }
  //     window.Intercom('boot', data)
  //   }
  //   if (identify) {
  //     Mixpanel.identify(userData.id)
  //   } else {
  //     Mixpanel.alias(userData.id)
  //   }
  //   Mixpanel.people.set({
  //     $email: userData.email,
  //     $name: name,
  //     $phone: userData.phone,
  //     'Membership Number': userData.membership_number
  //   })
  // }

  @action
  updateNavbar(className) {
    this.navbar.className = className
  }

  @action
  updateNav(showHeader, showFooter, useContainer = true) {
    this.navbar.showHeader = showHeader
    this.navbar.showFooter = showFooter
    this.navbar.useContainer = useContainer
  }

  @action
  exportUserData() {
    if (typeof window !== 'undefined') {
      services.getFile(APIURL.API_EXPORT_USERS_DETAILS).then((response) => {
        const url = window.URL.createObjectURL(new window.Blob([response]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'UserDetails.csv')
        document.body.appendChild(link)
        link.click()
      })
    }
  }

  @action
  exportReqConsultationData() {
    if (typeof window !== 'undefined') {
      services
        .getFile(APIURL.API_EXPORT_REQUEST_CONSULTATION_DETAILS)
        .then((response) => {
          const url = window.URL.createObjectURL(new window.Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Request Consultation Details.csv')
          document.body.appendChild(link)
          link.click()
        })
    }
  }

  @action
  async fetchSiteData(host) {
    return services.get_v2(APIURL.API_SITE_DATA, {}, host)
  }

  @action
  async preloadSiteData() {
    try {
      const siteData = await services.get_v2(APIURL.API_SITE_DATA, {})
      if (parseInt(siteData.statusCode) === HTTP.STATUS_302) {
        // We are on the wrong domain...time to redirect
        if (typeof window !== 'undefined') {
          window.location.href = siteData.response.url
        }
      } else if (parseInt(siteData.statusCode) === HTTP.STATUS_200) {
        this.siteData.replace(siteData.response)
      }
    } catch (e) {
      console.error(e)
    }
  }

  @action
  async preloadMarketData() {
    const resData = await services.get_v2(
      APIURL.API_MARKETPLACE_INTEGRATION_DATA,
      {}
    )
    if (parseInt(resData.statusCode) === 200) {
      try {
        this.product_list = resData.response
        // this.product_list.replace(ObjProducts.jsonToArray(resData.response))
      } catch (e) {
        console.error(e)
      }
    } else {
      if (
        resData.status === CONSTS.FAILURE &&
        parseInt(resData.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.isAppError = true
        this.setActionLoader()
        this.setPageContentLoader()
      } else {
      }
    }
  }

  @action
  async enrollExternal(product_id, company_id, product_name, cta_id = null) {
    this.sessionData.actionLoader = true
    try {
      const res = await services.post_v2(APIURL.API_ENROLL_EXTERNAL, {
        product_id,
        company_id,
        cta_id
      })
      if (res.status === 'success') {
        this.sessionData.actionLoader = false
        rcEvent(product_name, product_id)
        return {
          url: res.response.action_url,
          error: null
        }
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.sessionData.actionLoader = false
        return {
          url: null,
          error: 'You dont have the ability to access this feature'
        }
      } else if (res.status === CONSTS.FAILURE) {
        this.sessionData.actionLoader = false
        return {
          url: null,
          error: 'Were sorry! We ran into an issue processing this request'
        }
      }
    } catch (e) {
      console.error(e)
      this.sessionData.actionLoader = false
      this.isAppError = true
    }
  }

  @action
  enrollRequestConsultation() {
    const productObject = this.productBySelectionReqConsultation()
    this.sessionData.actionLoader = true
    services
      .post(APIURL.API_ENROLL_REQ_CONSULTATION, {
        productObject: JSON.stringify(productObject)
      })
      .then((resData) => {
        if (
          resData.status === CONSTS.FAILURE &&
          parseInt(resData.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.sessionData.actionLoader = false
          this.isAppError = true
          this.routePage(PAGEURL.SIGNUP_SUCCESS)
        } else if (parseInt(resData.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.sessionData.actionLoader = false
          this.signSessionExpiredOut()
          this.routePage(PAGEURL.SIGNUP_SUCCESS)
        } else if (resData.status === 'success') {
          this.sessionData.actionLoader = false
          this.routePage(PAGEURL.SIGNUP_SUCCESS)
        } else {
        }
      })
  }

  @action
  preloadRoute(route) {
    this.route = route
  }

  @action
  preLoadAdvisor = async (route, requireAuthentication = true, next = null) => {
    try {
      if (typeof window !== 'undefined') {
        const { localStorage } = window
        this.route = route
        const access_token = localStorage.getItem('access_token')
        const unique_code = localStorage.getItem('unique_code')
        if (access_token) {
          this.sessionData.access_token = access_token
          const res = await services.get_v2(APIURL.API_GET_USER_PROFILE, {})
          if (
            res.status === CONSTS.FAILURE &&
            parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
          ) {
            this.isAppError = true
            this.setActionLoader()
            this.setPageContentLoader()
          } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
            if (requireAuthentication) {
              this.signSessionExpiredOut(next)
            } else {
              // Thats okay if authentication is not required...
              // just reset the session
              this.resetSessionData()
              this.routePage(route.history.location.pathname)
            }
          } else if (res.status === 'success') {
            this.sessionData.isLoggedIn = true
            this.sessionData.access_token = access_token
            this.sessionData.unique_code = unique_code
            this.organization_data = res.response.organization
            const activeSubscription =
              res.response.subscription === null ||
              res.response.subscription === 'null'
                ? null
                : res.response.subscription
            this.sessionData.activeSubscription = activeSubscription
            if (
              this.sessionData.activeSubscription &&
              this.sessionData.activeSubscription.is_active === false
            ) {
            }
            const slSubscription =
              res.response.sl_subscription === null ||
              res.response.sl_subscription === 'null'
                ? null
                : res.response.sl_subscription
            this.sessionData.slSubscription = slSubscription
            this.sessionData.userData = res.response.userData
            this.sessionData.idpData = res.response.idpData
            // this.appIdentify(res.response.userData, true)
          } else {
          }
        } else if (requireAuthentication) {
          if (next) {
            this.route.history.push(next)
          } else {
            this.route.history.push('/')
          }
        } else {
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  @action
  async fetchAdvisor(host, cookie) {
    const res = await services.get_v2(
      APIURL.API_GET_USER_PROFILE,
      {},
      host,
      cookie
    )
    return res
  }

  @action
  loadAdvisor(res) {
    this.sessionData.isLoggedIn = true
    this.organization_data = res.response.organization
    const activeSubscription =
      res.response.subscription === null || res.response.subscription === 'null'
        ? null
        : res.response.subscription
    this.sessionData.activeSubscription = activeSubscription
    if (
      this.sessionData.activeSubscription &&
      this.sessionData.activeSubscription.is_active === false
    ) {
    }
    const slSubscription =
      res.response.sl_subscription === null ||
      res.response.sl_subscription === 'null'
        ? null
        : res.response.sl_subscription
    this.sessionData.slSubscription = slSubscription
    this.sessionData.userData = res.response.userData
    // this.appIdentify(res.response.userData, true)
  }

  //   // let current_page = page
  //   switch (page) {
  //     case PAGEURL.DASHBOARD:
  //       this.getAdvisorProfileData()
  //       break
  //     case PAGEURL.ADMIN_DASHBOARD:
  //       this.sessionData.activeMenu = PAGEURL.ADMIN_DASHBOARD
  //       break
  //     case PAGEURL.DASHBOARD_MANAGEMENT:
  //       this.sessionData.activeMenu = PAGEURL.DASHBOARD_MANAGEMENT
  //       break
  //     case PAGEURL.ADMIN_ENTERPRISE:
  //     case PAGEURL.NEW_ENTERPRISE:
  //     case PAGEURL.ADMIN_ENTERPRISE_UPDATE(this.route.match.params.id):
  //     case PAGEURL.ENTERPRISE_DETAILS(this.route.match.params.id):
  //     case PAGEURL.ENTERPRISE_LINKS(this.route.match.params.id):
  //     case PAGEURL.ENTERPRISE_ASSOCIATION(this.route.match.params.id):
  //       // Turn off loader...components will handle fetching data
  //       this.setPageContentLoader()
  //       this.sessionData.activeMenu = PAGEURL.ADMIN_ENTERPRISE
  //       break
  //     case PAGEURL.PROFILE:
  //     case PAGEURL.SAVED_PAYMENT_METHOD:
  //       this.sessionData.activeMenu = page
  //       this.getAdvisorProfileData()
  //       this.getUserPaymentHistory()
  //       break
  //     case PAGEURL.ADMIN_MY_PROFILE:
  //       this.sessionData.activeMenu = PAGEURL.ADMIN_MY_PROFILE
  //       this.getAdvisorProfileData()
  //       break
  //     case PAGEURL.ADVISOR_PAYMENT_HISTORY:
  //       this.sessionData.activeMenu = PAGEURL.ADVISOR_PAYMENT_HISTORY
  //       this.getUserPaymentHistory()
  //       break
  //     case PAGEURL.CHANGE_PASSWORD:
  //     case PAGEURL.ADMIN_CHANGE_PASSWORD:
  //       this.sessionData.activeMenu = page
  //       this.setPageContentLoader()
  //       break
  //     case PAGEURL.USER_MGMT:
  //     case PAGEURL.NEW_USER:
  //     case `${PAGEURL.USER_HISTORY}${this.route.match.params.user_id}`:
  //     case `${PAGEURL.ADVISOR_UPDATE}${this.route.match.params.user_id}`:
  //       this.sessionData.activeMenu = PAGEURL.USER_MGMT
  //       this.setPageContentLoader()
  //       break
  //     case PAGEURL.CONSULTATION_MGMT:
  //     case PAGEURL.NEW_CONSULTATION:
  //       this.sessionData.activeMenu = PAGEURL.CONSULTATION_MGMT
  //       break
  //     case PAGEURL.BANNER_MGMT:
  //     case PAGEURL.NEW_BANNER:
  //     case `${PAGEURL.NEW_BANNER}/banner_id`:
  //       this.sessionData.activeMenu = PAGEURL.BANNER_MGMT
  //       break
  //     case PAGEURL.PRODUCT_MGNT:
  //     case PAGEURL.NEW_PRODUCT_DETAILS:
  //       try {
  //         this.sessionData.activeMenu = PAGEURL.PRODUCT_MGNT
  //         this.setPageContentLoader()
  //         this.setPageLoader()
  //       } catch (e) {
  //         console.error(e)
  //       }
  //       break
  //     case PAGEURL.USER_MGNT:
  //     case `${PAGEURL.UPDATE_USER}${this.route.match.params.user_id}`:
  //       this.sessionData.activeMenu = PAGEURL.USER_MGNT
  //       break
  //     case `${PAGEURL.PRODUCT_MEDIA_EDIT}${this.route.match.params.product_id}`:
  //       this.sessionData.activeMenu = PAGEURL.PRODUCT_MGNT
  //       break
  //     case PAGEURL.CALC_LEAD_MGMT:
  //     case PAGEURL.CALC_LEAD_ADD:
  //     case `${PAGEURL.CALC_LEAD_EDIT}${this.route.match.params.lead_id}`:
  //       this.sessionData.activeMenu = PAGEURL.CALC_LEAD_MGMT
  //       this.getGenericModules(APIURL.API_ADMIN_CALC_LEAD_DATA, 'calc_lead_list', ObjCalcLead)
  //       break
  //     case PAGEURL.MEMBERSHIP_PLAN_MGNT:
  //     case PAGEURL.NEW_MEMBERSHIP_PLAN:
  //     case `${PAGEURL.UPDATE_MEMBERSHIP_PLAN}${this.route.match.params.plan_id}`:
  //       this.sessionData.activeMenu = PAGEURL.MEMBERSHIP_PLAN_MGNT
  //       this.getMembershipPlanList()
  //       break
  //     case PAGEURL.PAGE_MGNT:
  //     case PAGEURL.RE_ARRANGE_PAGE_ORDER:
  //     case PAGEURL.NEW_PAGE:
  //     case `${PAGEURL.UPDATE_PAGE}${this.route.match.params.page_id}`:
  //       this.sessionData.activeMenu = PAGEURL.PAGE_MGNT
  //       this.getPageList()
  //       break
  //     case PAGEURL.SSO_MGNT:
  //     case PAGEURL.NEW_SSO:
  //     case `${PAGEURL.UPDATE_SSO}${this.route.match.params.sso_id}`:
  //       this.sessionData.activeMenu = PAGEURL.SSO_MGNT
  //       this.getGenericModules(APIURL.API_ADMIN_GET_SSO_AUTNENTICATE_DATA, 'sso_list', ObjSSO)
  //       break
  //     case PAGEURL.RSS_FEED_MGNT:
  //     case PAGEURL.NEW_RSS_FEED:
  //     case `${PAGEURL.UPDATE_RSS_FEED}${this.route.match.params.rss_feed_id}`:
  //       this.sessionData.activeMenu = PAGEURL.RSS_FEED_MGNT
  //       this.getGenericModules(APIURL.API_ADMIN_GET_RSS_FEED_DATA, 'rss_feed_list', ObjRssFeeds)
  //       break
  //     case PAGEURL.WIDGET_MGNT:
  //     case PAGEURL.NEW_WIDGET:
  //     case `${PAGEURL.UPDATE_WIDGET}${this.route.match.params.widget_id}`:
  //       this.sessionData.activeMenu = PAGEURL.WIDGET_MGNT
  //       this.getWidgetData()
  //       break
  //     case PAGEURL.ROLE_MGNT:
  //     case PAGEURL.NEW_ROLE:
  //     case `${PAGEURL.UPDATE_ROLE}${this.route.match.params.role_id}`:
  //       this.sessionData.activeMenu = PAGEURL.ROLE_MGNT
  //       this.getRoleData()
  //       break
  //     case PAGEURL.REDTAIL_DASHBOARD:
  //       this.sessionData.activeMenu = PAGEURL.REDTAIL_DASHBOARD
  //       this.getRedtailActivities()
  //       break
  //     case PAGEURL.CUSTOM_WIDGET:
  //     case PAGEURL.CUSTOM_NEW_WIDGET:
  //     case `${PAGEURL.CUSTOM_EDIT_WIDGET}${this.route.match.params.custom_widget_id}`:
  //       this.sessionData.activeMenu = PAGEURL.CUSTOM_WIDGET
  //       this.getGenericModules(APIURL.API_ADMIN_GET_CUSTOM_WIDGET_DATA, 'custom_widget_list', ObjCustomWidgets)
  //       break
  //     case PAGEURL.CHANGE_EMAIL:
  //     case PAGEURL.ADMIN_CHANGE_EMAIL:
  //       this.sessionData.activeMenu = page
  //       this.setPageContentLoader()
  //       break
  //     case PAGEURL.PARTNER_MGNT:
  //     case PAGEURL.NEW_PARTNER:
  //     case `${PAGEURL.UPDATE_PARTNER}${this.route.match.params.partner_id}`:
  //       this.sessionData.activeMenu = PAGEURL.PARTNER_MGNT
  //       this.setPageContentLoader()
  //       break
  //     case PAGEURL.PRODUCT_TAGS_MGNT:
  //     case PAGEURL.NEW_PRODUCT_TAGS:
  //     case PAGEURL.UPDATE_PRODUCT_TAGS(this.route.match.params.product_tag_id):
  //       this.sessionData.activeMenu = PAGEURL.PRODUCT_TAGS_MGNT
  //       this.setPageContentLoader()
  //       break
  //     case PAGEURL.EVENT_MGNT:
  //     case PAGEURL.NEW_EVENT:
  //     case `${PAGEURL.UPDATE_EVENT}${this.route.match.params.event_id}`:
  //       this.sessionData.activeMenu = PAGEURL.EVENT_MGNT
  //       this.getGenericModules(APIURL.API_ADMIN_GET_EVENT_DATA, 'event_list', ObjEvents)
  //       break
  //     case `${PAGEURL.REVIEW_MGMT}${this.route.match.params.product_id}`:
  //     case `${PAGEURL.UPDATE_REVIEW}${this.route.match.params.product_id}`:
  //     case `${PAGEURL.UPDATE_REVIEW}${this.route.match.params.product_id}/${this.route.match.params.review_id}`:
  //       this.sessionData.activeMenu = PAGEURL.PRODUCT_MGNT
  //       break
  //     case `${PAGEURL.SURVEY_MGMT}${this.route.match.params.product_id}`:
  //     case `${PAGEURL.UPDATE_SURVEY}${this.route.match.params.product_id}`:
  //     case `${PAGEURL.UPDATE_SURVEY}${this.route.match.params.product_id}/${this.route.match.params.survey_id}`:
  //       this.sessionData.activeMenu = PAGEURL.PRODUCT_MGNT
  //       this.getProductAdminData(APIURL.API_GET_SURVEY_QUES(this.route.match.params.product_id), 'survey_list', ObjSurvey)
  //       break
  //     case `${PAGEURL.FAQ_MGMT}${this.route.match.params.product_id}`:
  //     case `${PAGEURL.UPDATE_FAQ}${this.route.match.params.product_id}`:
  //       this.sessionData.activeMenu = PAGEURL.PRODUCT_MGNT
  //       break
  //     default:break
  //   }
  //   const res = [
  //     new RegExp(`(${PAGEURL.UPDATE_PRODUCT})(\\d+)/?$`),
  //     new RegExp(`(${PAGEURL.UPDATE_PRODUCT_DETAILS})(\\d+)/?$`),
  //     new RegExp(`(${PAGEURL.UPDATE_PRODUCT_DESCRIPTION})(\\d+)/?$`),
  //     new RegExp(`(${PAGEURL.UPDATE_PRODUCT_ASSOCIATION})(\\d+)/?$`)]
  //   for (let i = 0; i < res.length; ++i) {
  //     const r = page.match(res[i])
  //     if (r && r.length > 1) {
  //       this.sessionData.activeMenu = PAGEURL.PRODUCT_MGNT
  //       this.getProducts(r[2])
  //     }
  //   }

  //   if (is_history_updated === true) {
  //     this.route.history.push(page)
  //   }
  // }

  @action
  getGenericModules(apiURL, key_name, objType, cb = services.post) {
    this.setPageContentLoader(true)
    cb(apiURL, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this[key_name].replace(objType.jsonToArray(res.response))
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  getDataForDashboard(apiURL, key_name) {
    services.get(apiURL).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        if (key_name === 'page_view_count') {
          this.dashboard_count_data.page_view_count = {
            allViewCount: ObjDashboard.jsonToArray(res.response.allViewCount),
            uniqueViewCount: ObjDashboard.jsonToArray(
              res.response.uniqueViewCount
            )
          }
        } else {
          this.dashboard_count_data[key_name] = {
            activeUserCount: ObjDashboard.jsonToArray(
              res.response.activeUserCount
            ),
            registeredUserCount: ObjDashboard.jsonToArray(
              res.response.registeredUserCount
            )
          }
        }
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  setProductMedia(apiURL, key_name, objType, params = {}) {
    // this.setPageContentLoader(true)
    return services.post(apiURL, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this[key_name] = objType.constructFromJSON(res.response)
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  getProductAdminData(apiURL, key_name, objType) {
    this.setPageContentLoader(true)
    services.get(apiURL, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this[key_name].replace(objType.jsonToArray(res.response))
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  getEnterpriseData() {
    this.setPageContentLoader(true)
    services.get(APIURL.API_GET_ADMIN_ENTERPRISES).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        if (this.isEnterpriseAdmin())
          this.enterprise_list.set(
            res.response.enterprises.id,
            ObjEnterprise.constructFromJSON(res.response.enterprises)
          )
        else
          this.enterprise_list.replace(
            ObjEnterprise.jsonToArray(res.response.enterprises)
          )
        this.product_tag_list.replace(
          ObjProductTags.jsonToArray(res.response.productTagData)
        )
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  getProducts(product_id = null) {
    this.setPageContentLoader(true)
    return services
      .get_v2(APIURL.API_ADMIN_PRODUCT, { product_id })
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          if (product_id) {
            this.product_list.set(
              product_id,
              ObjProducts.constructFromJSON(res.response.productData)
            )
          } else {
            this.product_list.replace(
              ObjProducts.jsonToArray(res.response.productData)
            )
          }
          this.product_tag_list.replace(
            ObjProductTags.jsonToArray(res.response.productTagData)
          )
          if (this.isEnterpriseAdmin())
            this.enterprise_list.set(
              res.response.groupData.id,
              ObjEnterprise.constructFromJSON(res.response.groupData)
            )
          else
            this.enterprise_list.replace(
              ObjEnterprise.jsonToArray(res.response.groupData)
            )
          this.partner_list.replace(
            ObjPartners.jsonToArray(res.response.partnerList)
          )
          this.setPageContentLoader()
        } else {
          this.setPageContentLoader()
        }
      })
  }

  @action
  getUserPaymentHistory() {
    return services
      .post_v2(APIURL.API_STRIPE_USER_PAYMENT_HISTORY, {})
      .then((resData) => {
        if (
          resData.status === CONSTS.FAILURE &&
          parseInt(resData.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.isAppError = true
        } else if (parseInt(resData.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (resData.status === 'success') {
          return resData.response
        }
      })
  }

  @action
  loadQuickBooksPLAndExpensesFilter(filter_type, quickbooks_type, key_type) {
    const { quick_books_data } = this.prod_sub.quick_books
    quick_books_data[quickbooks_type].loader_type = 'data_loading'
    const apiURL =
      quickbooks_type === 'profit_loss'
        ? APIURL.API_GET_QB_PROFIT_LOSS_DATA
        : APIURL.API_GET_QB_VENDOR_EXPENSES
    services.post(apiURL, { time_period: filter_type }).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        quick_books_data[quickbooks_type].filter_type = filter_type
        quick_books_data[quickbooks_type].loader_type = 'show_data'
        quick_books_data[quickbooks_type][key_type] = res.response
      } else if (res.status === 'failure') {
        quick_books_data[quickbooks_type].loader_type = 'show_data'
      } else {
        quick_books_data[quickbooks_type].loader_type = 'show_data'
      }
    })
  }

  @action
  getSuccessionListing(saved_search_id = null) {
    this.prod_sub.succession_link.action_type = 'loading'
    services
      .post(APIURL.API_GET_SUCCESSION_LISTING_DATA, { saved_search_id })
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.prod_sub.succession_link.action_type = res.response.action_type
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.prod_sub.succession_link.action_type = res.response.action_type
          const { saved_search, listing_leads } = res.response.content
          this.prod_sub.succession_link.data = { saved_search, listing_leads }
        } else {
          this.prod_sub.succession_link.action_type = res.response.action_type
          this.prod_sub.succession_link.data = {
            saved_search: null,
            listing_leads: null
          }
        }
      })
  }

  @action
  getQuickBooksData() {
    this.prod_sub.quick_books.load_widget_type = 'quickbook_loading'
    services.post(APIURL.API_GET_QUICKBOOKS_DATA, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.quick_books.load_widget_type =
          res.response.load_widget_type
        this.prod_sub.quick_books.quick_books_data =
          res.response.quick_books_data
      } else if (res.status === 'failure') {
        this.prod_sub.quick_books.load_widget_type =
          res.response.load_widget_type
      } else {
        this.prod_sub.quick_books.load_widget_type = 'quickbook_error'
      }
    })
  }

  @action
  connectToQuickBooks(params) {
    if (typeof window !== 'undefined') {
      const { localStorage } = window
      params['quick_books_auth_url'] = localStorage.getItem(
        'quick_books_auth_url'
      )
      services.post('api/connectQBSSO', params).then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          window.location = localStorage.getItem('quick_books_redirect_url')
          localStorage.removeItem('quick_books_redirect_url')
          localStorage.removeItem('quick_books_auth_url')
          this.prod_sub.quick_books.is_subscribed = true
          this.setPageContentLoader()
        } else {
          this.setPageContentLoader()
        }
      })
    }
  }

  @action
  disConnectToQuickBooks(params) {
    this.prod_sub.quick_books.load_widget_type = 'data_loading'
    services.post(APIURL.API_UPDATE_SSO_STATUS, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.quick_books.load_widget_type = 'quickbook_sso_connect'
        this.prod_sub.quick_books.is_subscribed = true
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  getBenefitHubData() {
    if (typeof window !== 'undefined') {
      this.prod_sub.benefithub.load_widget_type = 'benefithub_loading'
      services.get(APIURL.API_GET_BENEFITHUB_DATA, {}).then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.prod_sub.benefithub.load_widget_type = 'benefithub_sso_connected'
          this.prod_sub.benefithub.sub_data = res.response

          const eventForm = document.createElement('form')
          eventForm.className = 'hide'
          eventForm.method = 'POST'
          eventForm.action = res.response.entityEndpoint

          const el1 = document.createElement('input')
          el1.type = 'hidden'
          el1.name = res.response.type
          el1.value = res.response.context
          eventForm.appendChild(el1)

          if (res.response.relayState) {
            const el1 = document.createElement('input')
            el1.type = 'hidden'
            el1.name = 'RelayState'
            el1.value = res.response.relayState
            eventForm.appendChild(el1)
          }
          const button = document.createElement('button')
          button.type = 'submit'
          button.innerHTML = 'Submit'
          eventForm.appendChild(button)

          document.body.appendChild(eventForm)
          eventForm.submit()
        } else if (res.status === 'failure') {
          this.prod_sub.benefithub.load_widget_type = 'benefithub_sso_connect'
        } else {
          this.prod_sub.benefithub.load_widget_type = 'benefithub_error'
        }
      })
    }
  }

  @action
  openOrionSSO() {
    this.getOrionData(true)
  }

  @action
  getOrionData(updated = false) {
    if (typeof window !== 'undefined') {
      this.prod_sub.orion.load_widget_type = 'orion_loading'
      services.post(APIURL.API_GET_ORION_DATA, {}).then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.prod_sub.orion.load_widget_type = 'orion_sso_connected'
          this.prod_sub.orion.sub_data = res.response
          if (updated)
            window.open(
              `${CONSTS.ORION_ADVISOR_URL}?t=${res.response.access_token}`,
              '_blank'
            )
        } else if (res.status === 'failure') {
          this.prod_sub.orion.load_widget_type = 'orion_sso_connect'
        } else {
          this.prod_sub.orion.load_widget_type = 'orion_error'
        }
      })
    }
  }

  @action
  connectToOrion(params) {
    if (typeof window !== 'undefined') {
      const { localStorage } = window
      params['orion_auth_url'] = localStorage.getItem('orion_auth_url')
      services.post(APIURL.API_CONNECT_ORION_SSO, params).then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          window.location = localStorage.getItem('orion_redirect_url')
          localStorage.removeItem('orion_redirect_url')
          localStorage.removeItem('orion_auth_url')
          this.setPageContentLoader()
        } else {
          this.setPageContentLoader()
        }
      })
    }
  }

  @action
  disConnectToProsite(params) {
    this.prod_sub.prosites_firm_info.load_widget_type = 'prosite_action_loading'
    services.post(APIURL.API_UPDATE_SSO_STATUS, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.prosites_firm_info.action_type = 'prosite_action_login'
        this.prod_sub.prosites_firm_info.is_subscribed = true
        this.prod_sub.prosites_firm_info.data = null
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  disconnectSSORedtail(params) {
    this.prod_sub.redtail.action_type = 'redtail_crm_login_processing'
    services.post(APIURL.API_UPDATE_SSO_STATUS, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.redtail.action_type = 'redtail_crm_login'
        this.prod_sub.redtail.is_subscribed = false
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  resendRedtailOTP() {
    this.prod_sub.redtail.action_type = 'redtail_crm_login_processing'
    this.showFlashMsg = false
    services.post(APIURL.API_RESEND_REDTAIL_OTP, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.redtail.action_type = 'redtail_crm_opt_verification'
        this.flash_msg_data = { type: 'success', msg: res.message }
        this.showFlashMsg = true
        this.inputData.one_time_password.required = true
        this.inputData.one_time_password.value = ''
        this.inputData.username.required = false
        this.inputData.password.required = false
      } else {
        this.flash_msg_data = { type: 'failure', msg: res.message }
        this.showFlashMsg = true
        this.setPageContentLoader()
        this.prod_sub.redtail.action_type = 'redtail_crm_opt_verification'
      }
    })
  }

  @action
  validateRedtailCredential(params) {
    this.prod_sub.redtail.action_type = 'redtail_crm_login_processing'
    services
      .post(APIURL.API_VALIDATE_REDTAIL_CREDENTIAL, params)
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.prod_sub.redtail.action_type = 'redtail_crm_activities'
          this.prod_sub.redtail.is_subscribed = true
        } else {
          this.prod_sub.redtail.flash_msg_data = {
            type: 'failure',
            msg: res.message
          }
          this.prod_sub.redtail.showFlashMsg = true
          this.setPageContentLoader()
          this.prod_sub.redtail.action_type = 'redtail_crm_login'
        }
      })
  }

  @action
  getRedtailActivities(activity_type = 1) {
    this.prod_sub.redtail.action_type = 'redtail_crm_loading'
    this.prod_sub.redtail.activity_list.clear()
    services
      .post(APIURL.API_GETREDTAIL_TODAYS_TASK, { activity_type })
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.prod_sub.redtail.activity_list.replace(
            ObjRedtailTasks.jsonToArray(res.response.redtailData)
          )
          this.prod_sub.redtail.is_subscribed = true
          this.prod_sub.redtail.action_type = 'redtail_crm_activities'
          this.prod_sub.redtail.return_url = res.response.return_url
          this.prod_sub.redtail.access_token_expire_at =
            res.response.access_token_expire_at
          this.setRedtailSSOLogin(true)
          this.setPageContentLoader()
        } else if (
          res.response &&
          res.response.status &&
          res.response.status === 'login_failed'
        ) {
          this.prod_sub.redtail.is_subscribed = false
          this.prod_sub.redtail.action_type = 'redtail_crm_login'
          this.setPageContentLoader()
        } else {
          this.setPageContentLoader()
          this.prod_sub.redtail.action_type = 'redtail_crm_login'
        }
      })
  }

  ssoRedtailLogin() {
    services.post(APIURL.API_REDTAIL_CONNECT, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setPageContentLoader()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.redtail.return_url = res.response.return_url
      } else {
        this.prod_sub.redtail.return_url = null
      }
    })
  }

  setRedtailSSOLogin(set_timer) {
    if (set_timer) {
      if (this.prod_sub.redtail.sso_login_connect === null) {
        const sso_login_connect = setInterval(() => {
          this.ssoRedtailLogin()
        }, CONSTS.SSO_REDTAIL_INTERVAL)
        this.prod_sub.redtail.sso_login_connect = sso_login_connect
        // this.prod_sub.redtail.sso_login_connect = sso_login_connect
      }
    } else if (this.prod_sub.redtail.sso_login_connect) {
      clearInterval(this.prod_sub.redtail.sso_login_connect)
    }
  }

  @action
  completeRedTailTask(activity_id) {
    this.setActionLoader(true)
    services
      .post(APIURL.API_COMP_REDTAIL_TODAYS_TASK, { activity_id })
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.prod_sub.redtail.activity_list.delete(activity_id)
          this.setActionLoader()
        } else {
          this.setActionLoader()
        }
      })
  }

  @action
  getRedtailContaccts() {
    services.post(APIURL.API_GET_REDTAIL_CRM_CONTACTS, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.redtail.contacts = res.response
      } else if (res.status === 'failure') {
        this.prod_sub.redtail.contacts = []
      }
    })
  }

  @action
  getRedTailReminders() {
    services.post(APIURL.API_GET_REDTAIL_CRM_REMINDERS, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.redtail.reminders = {
          range: res.response.Range,
          reminderData: res.response.Reminders,
          totalRecords: res.response.TotalRecords
        }
      }
    })
  }

  @action
  connectRedtailSSO(sso_type, item_id, event) {
    if (typeof window !== 'undefined') {
      const { return_url } = this.prod_sub.redtail
      if (return_url) {
        const action_url = `${return_url}&ep=${sso_type}&i=${item_id}`
        window.open(action_url, '_blank')
        event.preventDefault()
      }
    }
  }

  @action
  connectQBSSO(event) {
    if (typeof window !== 'undefined') {
      const { localStorage } = window
      localStorage.setItem('quick_books_redirect_url', window.location.href)
      localStorage.setItem(
        'quick_books_auth_url',
        `${APIURL.SITE_URL}quickbooks/authenticate`
      )
      window.open(
        `https://appcenter.intuit.com/connect/oauth2?client_id=${CONSTS.QUICKBOOKS_CLIENT_ID}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${APIURL.SITE_URL}quickbooks/authenticate&state=authorization_code`,
        '_self'
      )
    }
  }

  @action
  connectOrionSSO(event) {
    if (typeof window !== 'undefined') {
      const { localStorage } = window
      localStorage.setItem('orion_redirect_url', window.location.href)
      localStorage.setItem(
        'orion_auth_url',
        `${APIURL.SITE_URL}orion/authenticate`
      )
      window.open(
        `${CONSTS.ORION_URL}/oauth/?response_type=code&redirect_uri=${APIURL.SITE_URL}orion/authenticate&client_id=${CONSTS.ORION_CLIENT_ID}`,
        '_self'
      )
    }
  }

  @action
  launchQuickBook(event) {
    if (typeof window !== 'undefined') {
      const { company_id } = this.prod_sub.quick_books.quick_books_data
      const eventForm = document.createElement('form')
      eventForm.className = 'hide'
      eventForm.target = '_blank'
      eventForm.method = 'GET'
      eventForm.action = CONSTS.QUICKBOOKS_ACCOUNT_URL
      const element1 = document.createElement('input')
      element1.name = 'deeplinkcompanyid'
      element1.value = company_id
      eventForm.appendChild(element1)
      document.body.appendChild(eventForm)
      eventForm.submit()
      event.preventDefault()
    }
  }

  @action
  launchProsites(event) {
    if (typeof window !== 'undefined') {
      this.setActionLoader(true)
      services.post(APIURL.API_AUTHENTICATE_PROSITE_SSO, {}).then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          const eventForm = document.createElement('form')
          eventForm.className = 'hide'
          eventForm.target = '_blank'
          eventForm.method = 'GET'
          const element1 = document.createElement('input')
          element1.name = 'token'
          element1.value = res.response.token
          eventForm.appendChild(element1)
          eventForm.action = `${res.response.virtualURL}admin/index.php`
          document.body.appendChild(eventForm)
          eventForm.submit()
          this.setActionLoader()
        } else {
          this.setActionLoader()
        }
      })
    }
  }

  @action
  getPrositeFirmInfo() {
    this.prod_sub.prosites_firm_info.action_type = 'prosite_action_loading'
    services.post(APIURL.API_GET_PROSITES_FIRM_INFO, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.prod_sub.prosites_firm_info.action_type = 'action_error'
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.prod_sub.prosites_firm_info.action_type = res.response.action_type
        this.prod_sub.prosites_firm_info.data = res.response.data
        let avg_visit = 0
        let unique_visits = 0
        let page_per_visit = 0
        let visits = 0
        res.response.data.forEach((item) => {
          visits += Number(item.nb_visits)
          unique_visits += Number(item.nb_uniq_visitors)
          page_per_visit += Number(item.nb_actions)
          avg_visit += Number(item.sum_visit_length)
        })
        this.prod_sub.prosites_firm_info.visits = visits
        this.prod_sub.prosites_firm_info.unique_visits = unique_visits
        if (res.response.data.length > 0) {
          this.prod_sub.prosites_firm_info.page_per_visit = Math.ceil(
            page_per_visit / res.response.data.length
          )
          this.prod_sub.prosites_firm_info.avg_visit =
            avg_visit / res.response.data.length
        }
      } else if (res.status === 'failure') {
        this.prod_sub.prosites_firm_info.action_type = res.response.action_type
        this.prod_sub.prosites_firm_info.data = res.response.data
      } else {
        this.prod_sub.prosites_firm_info.action_type =
          'action_show_prosites_widget'
      }
    })
  }

  @action
  authenticatePrositeCredential(params) {
    this.prod_sub.prosites_firm_info.action_type = 'prosite_action_processing'
    services
      .post(APIURL.API_VALIDATE_PROSITE_CREDENTIAL, params)
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.prod_sub.prosites_firm_info.action_type =
            res.response.action_type
          this.prod_sub.prosites_firm_info.data = res.response.data
          this.prod_sub.prosites_firm_info.flash_msg_data = {
            type: '',
            msg: ''
          }
          this.prod_sub.prosites_firm_info.showFlashMsg = false
          this.getPrositeFirmInfo()
        } else {
          this.prod_sub.prosites_firm_info.flash_msg_data = {
            type: 'failure',
            msg: res.message
          }
          this.prod_sub.prosites_firm_info.showFlashMsg = true
          this.setPageContentLoader()
          this.prod_sub.prosites_firm_info.action_type =
            res.response.action_type
        }
      })
  }

  @action
  getMembershipPlanList() {
    this.setPageContentLoader(true)
    services.post(APIURL.API_ADMIN_GET_MEMB_PLAN_LIST, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.memb_plan_list.replace(
          ObjMembershipPlans.jsonToArray(res.response)
        )
        this.getGenericModules(
          APIURL.API_ADMIN_GET_PRODUCT_DATA,
          'product_list',
          ObjProducts
        )
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  addToCalendly(params) {
    services.post(APIURL.API_ADMIN_ADD_REQ_CALENDLY, params)
  }

  @action
  enrollSubRequest(params, action_type) {
    const { product_id, product_name } = params
    if (!this.consultations.has(product_id)) {
      this.consultations.set(product_id, {
        action_type: '',
        sub_data: ''
      })
    }
    this.consultations.set(product_id, {
      action_type,
      sub_data: ''
    })
    if (!params.hasOwnProperty('reengage')) {
      params.reengage = false
    }
    services.post_v2(APIURL.API_CREATE_OASIS_ACCOUNT, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.consultations.set(product_id, {
          action_type: 'oasis_request_account_form',
          sub_data: ''
        })
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
      }
      if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.consultations.set(product_id, res.response)
        rcEvent(product_name, product_id)
      } else if (res.status === 'failure') {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
        this.consultations.set(product_id, {
          action_type: 'oasis_request_account_form',
          sub_data: ''
        })
      } else {
        this.consultations.set(product_id, {
          action_type: 'oasis_request_account_form',
          sub_data: ''
        })
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
      }
    })
  }

  @action
  getSubscriptionData(action_type, product_id = null, company_id = null) {
    const params = {
      product_id,
      company_id
    }
    if (!this.consultations.has(product_id)) {
      this.consultations.set(product_id, {
        action_type: '',
        sub_data: ''
      })
    }
    this.consultations.set(product_id, {
      action_type,
      sub_data: ''
    })
    return services
      .post_v2('api/marketplace/consultdata/', params)
      .then((res) => {
        try {
          if (
            res.status === CONSTS.FAILURE &&
            parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
          ) {
            this.setActionLoader()
            this.setPageContentLoader()
            this.consultations.set(product_id, {
              action_type: '',
              sub_data: ''
            })
          } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
            this.signSessionExpiredOut()
          } else if (res.status === 'success') {
            const data = res.response
            data['action_type'] = 'exists'
            this.consultations.set(product_id, res.response)
          } else if (res.status === 'failure') {
            this.consultations.set(product_id, res.response)
          } else {
            this.consultations.set(product_id, {
              action_type: '',
              sub_data: ''
            })
          }
        } catch (e) {
          console.error(e)
        }
      })
  }

  setDefaultRole(default_role_id) {
    if (Number(this.sessionData.default_role_id) === Number(default_role_id)) {
      return
    }
    this.setActionLoader(true)
    const old_default_role_id = this.sessionData.default_role_id
    const params = { default_role_id, old_default_role_id }
    services.post(APIURL.API_ADMIN_SET_DEFAULT_ROLE, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        const role1 = this.role_list.get(old_default_role_id)
        if (role1) {
          role1.is_default = false
        }
        const role2 = this.role_list.get(default_role_id)
        role2.is_default = true
        this.sessionData.default_role_id = default_role_id
        this.flash_msg_data = { type: 'success', msg: res.message }
        this.showFlashMsg = true
        this.setActionLoader()
      } else {
        this.setActionLoader()
      }
    })
  }

  @action
  getWidgetData() {
    this.setPageContentLoader(true)
    services.post(APIURL.API_ADMIN_GET_WIDGET_DATA, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.widget_list.replace(ObjWidgets.jsonToArray(res.response))
        this.getGenericModules(
          APIURL.API_ADMIN_GET_CUSTOM_WIDGET_DATA,
          'custom_widget_list',
          ObjCustomWidgets
        )
      } else {
        this.setPageContentLoader()
      }
    })
  }

  @action
  getRoleData() {
    this.setPageContentLoader(true)
    services.post(APIURL.API_ADMIN_GET_ROLE_DATA, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        res.response.roleData
          .filter(
            (item) => item.is_default === true || item.is_default === 'true'
          )
          .map((item) => {
            this.sessionData.default_role_id = item.id
            // if(item.is_default === true || item.is_default === 'true') {
            // }
            return false
          })
        this.role_list.replace(ObjRoles.jsonToArray(res.response.roleData))
        this.product_list.replace(
          ObjProducts.jsonToArray(res.response.productData)
        )
        this.widget_list.replace(
          ObjWidgets.jsonToArray(res.response.widgetData)
        )
        this.setPageContentLoader()
      } else {
        this.setPageContentLoader()
      }
    })
  }

  getPageList(isLoadAdvisorData = false) {
    this.setPageContentLoader(true)
    services.post(APIURL.API_ADMIN_GET_PAGE_DATA, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.page_list.replace(ObjPages.jsonToArray(res.response.pageData))
        if (this.route.match.params.page_url) {
          const itemList = res.response.pageData.filter(
            (e) => e.page_url === this.sessionData.activeMenu
          )
          if (itemList.length > 0) {
            this.selectedPage = itemList[0].id
          }
        }
        if (isLoadAdvisorData) {
          this.getAdvisorProfileData()
        } else {
          this.setPageContentLoader()
        }
      } else {
        this.setPageContentLoader()
      }
    })
  }

  getCalcLeadData() {
    this.setPageContentLoader(true)
    services.post(APIURL.API_ADMIN_CALC_LEAD_DATA, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.calc_lead_list.replace(ObjCalcLead.jsonToArray(res.response))
      } else {
        this.setPageContentLoader()
      }
    })
  }

  setDetailsAndRedirect(userDetails) {
    if (typeof window !== 'undefined') {
      const { inputData } = this
      const companyAddress = `${userDetails.street_address1} ${userDetails.street_address2} ${userDetails.city} ${userDetails.state} ${userDetails.zip}`
      const prefillData =
        `name=${inputData.first_name.value} ${inputData.last_name.value}&email=${inputData.email.value}&a1=${inputData.phone.value}&a2=${userDetails.firm_name}&a3=${companyAddress}`.replace(
          /null/g,
          ''
        )
      window.localStorage.setItem(
        'ProductDetails',
        JSON.stringify({
          product_id: inputData.product_id,
          company_id: inputData.company_id,
          admin: true
        })
      )
      window.open(`${inputData.calendly_link.value}?${prefillData}`, '_blank')
    }
  }

  saveGenericModuleData(
    apiURL,
    params,
    key_name,
    objType,
    pageURL,
    sectionName = null,
    cb = services.post
  ) {
    this.setActionLoader(true)
    return cb(apiURL, params).then((res) => {
      try {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this[key_name].set(
            res.response.id,
            objType.constructFromJSON(res.response)
          )
          // This is just for adding/editing a product....lame
          // if (!/[0-9]+$/g.test(pageURL) && pageURL.indexOf('product') !== -1 && key_name !== 'review_list' && key_name !== 'survey_list') {
          if (
            !/[0-9]+$/g.test(pageURL) &&
            (pageURL.indexOf(PAGEURL.UPDATE_PRODUCT) !== -1 ||
              pageURL.indexOf(PAGEURL.UPDATE_PRODUCT_DETAILS) !== -1 ||
              pageURL.indexOf(PAGEURL.UPDATE_PRODUCT_DESCRIPTION) !== -1 ||
              pageURL.indexOf(PAGEURL.UPDATE_PRODUCT_ASSOCIATION) !== -1 ||
              pageURL.indexOf(PAGEURL.NEW_PRODUCT) !== -1 ||
              pageURL.indexOf(PAGEURL.NEW_PRODUCT_DETAILS) !== -1)
          ) {
            pageURL += res.response.id
          }

          const msg = sectionName
            ? `${sectionName} saved successfully.`
            : res.message
          this.flash_msg_data = { type: 'success', msg }
          this.showFlashMsg = true
          this.routePage(pageURL)
          this.setActionLoader()
        } else {
          this.flash_msg_data = { type: 'danger', msg: res.message }
          this.showFlashMsg = true
          this.setActionLoader()
        }
      } catch (error) {
        console.error(error)
      }
    })
  }

  @action
  createNewSignupToken(user_id) {
    this.setActionLoader(true)
    services
      .post(APIURL.API_REFRESH_TOKEN_SEND_TO_USER, { user_id })
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.flash_msg_data = { type: 'success', msg: res.message }
          this.showFlashMsg = true
          this.setActionLoader()
        } else {
          this.flash_msg_data = { type: 'danger', msg: res.message }
          this.showFlashMsg = true
          this.setActionLoader()
        }
      })
  }

  deleteGenericModuleData(
    apiURL,
    key_name,
    objType,
    id,
    params,
    cb = services.post
  ) {
    this.setActionLoader(true)
    cb(apiURL, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        if (this.data_table.filteredData.length === 1) {
          this.data_table.activePage = this.data_table.activePage - 1
        }
        this.flash_msg_data = { type: 'success', msg: res.message }
        this.showFlashMsg = true
        this[key_name].delete(id)
        // if (key_name === 'review_list' || key_name === 'faq_list') this.preloadMarketData()
        this.updateDatatableFilter(id)
        this.setActionLoader()
      } else {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
        this.setActionLoader()
      }
    })
  }

  @action
  acticateSSOAuthenticate(sso_id) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_GET_ACT_SSO_AUTH,
      { sso_id },
      'sso_list',
      ObjSSO,
      PAGEURL.SSO_MGNT
    )
  }

  @action
  savePageData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_PAGE_DATA,
      params,
      'page_list',
      ObjPages,
      PAGEURL.PAGE_MGNT
    )
  }

  @action
  saveSSOAuthenticatesData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_SSO_AUTNENTICATE_DATA,
      params,
      'sso_list',
      ObjSSO,
      PAGEURL.SSO_MGNT
    )
  }

  @action
  saveMembershipPlanData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_MEMB_PLAN_LIST,
      params,
      'memb_plan_list',
      ObjMembershipPlans,
      PAGEURL.MEMBERSHIP_PLAN_MGNT
    )
  }

  @action
  saveProductData(params, redirectLink, section) {
    return this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_PRODUCT_DATA,
      params,
      'product_list',
      ObjProducts,
      redirectLink,
      section
    )
  }

  @action
  saveProductMediaData(params, redirectLink) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_PRODUCT_MEDIA_DATA,
      params,
      'product_media_list',
      ObjProductMedia,
      redirectLink
    )
  }

  @action
  saveUserData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_USER_DATA,
      params,
      'user_list',
      ObjUsers,
      PAGEURL.USER_MGMT,
      null
    )
  }

  @action
  saveConsultationData(params) {
    return this.saveGenericModuleData(
      APIURL.API_SAVE_ADMIN_CONSULTATION_DATA,
      params,
      'consultation_list',
      ObjConsultations,
      PAGEURL.CONSULTATION_MGMT
    )
  }

  @action
  saveProductBanner(params) {
    return this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_PRODUCT_BANNER,
      params,
      'product_banner_list',
      ObjBanner,
      PAGEURL.AD_MGMT
    )
  }

  adminCreateUser(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_CREATE_USER,
      params,
      'user_list',
      ObjUsers,
      PAGEURL.USER_MGMT
    )
  }

  @action
  saveReviewData(params, redirectLink) {
    return this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_REVIEW_DATA,
      params,
      'review_list',
      ObjReview,
      redirectLink
    )
  }

  @action
  saveSurveyData(params, redirectLink) {
    return this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_SURVEY_DATA,
      params,
      'survey_list',
      ObjSurvey,
      redirectLink
    )
  }

  @action
  saveFAQData(params, redirectLink) {
    return this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_FAQ_DATA,
      params,
      'faq_list',
      ObjFaq,
      redirectLink
    )
  }

  @action
  saveRssFeddData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_RSS_FEED_DATA,
      params,
      'rss_feed_list',
      ObjRssFeeds,
      PAGEURL.RSS_FEED_MGNT
    )
  }

  @action
  savePartnerData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_PARTNER_DATA,
      params,
      'partner_list',
      ObjPartners,
      PAGEURL.PARTNER_MGNT,
      null
    )
  }

  @action
  fetchPartnerData(params) {
    this.getGenericModules(
      APIURL.API_ADMIN_GET_PARTNER_DATA,
      'partner_list',
      ObjPartners
    )
  }

  @action
  saveProductTagData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_PRODUCT_TAG_DATA,
      params,
      'product_tag_list',
      ObjProductTags,
      PAGEURL.PRODUCT_TAGS_MGNT
    )
  }

  @action
  saveEventData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_EVENT_DATA,
      params,
      'event_list',
      ObjEvents,
      PAGEURL.EVENT_MGNT
    )
  }

  @action
  saveRoleData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_ROLE_ROLE_DATA,
      params,
      'role_list',
      ObjRoles,
      PAGEURL.ROLE_MGNT
    )
  }

  @action
  saveWidgetData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_WIDGET_DATA,
      params,
      'widget_list',
      ObjWidgets,
      PAGEURL.WIDGET_MGNT
    )
  }

  @action
  saveCRMWidgetData(params) {
    this.saveGenericModuleData(
      APIURL.API_ADMIN_SAVE_CUSTOM_WIDGET_DATA,
      params,
      'custom_widget_list',
      ObjCustomWidgets,
      PAGEURL.CUSTOM_WIDGET
    )
  }

  @action
  deletePageMgnt(page_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_PAGE_DATA,
      'page_list',
      ObjPages,
      page_id,
      { page_id }
    )
  }

  @action
  deleteMembershipPlanData(plan_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_MEMB_PLAN_LIST,
      'memb_plan_list',
      ObjMembershipPlans,
      plan_id,
      { plan_id }
    )
  }

  @action
  deleteProductData(product_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_PRODUCT_DATA,
      'product_list',
      ObjProducts,
      product_id,
      { product_id }
    )
  }

  @action
  deleteProductMediaData(params) {
    this.setProductMedia(
      APIURL.API_ADMIN_DELETE_PRODUCT_MEDIA_DOCUMENTS,
      'product_media',
      ObjProductMedia,
      params
    )
  }

  @action
  deletePartnerData(partner_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_PARTNER_DATA,
      'partner_list',
      ObjPartners,
      partner_id,
      { partner_id }
    )
  }

  @action
  deleteProductTagData(product_tag_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_PRODUCT_TAG_DATA,
      'product_tag_list',
      ObjProductTags,
      product_tag_id,
      { product_tag_id }
    )
  }

  @action
  deleteUserMgnt(user_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_USER_DATA,
      'user_list',
      ObjUsers,
      user_id,
      { user_id }
    )
  }

  @action
  deleteRssFeedData(rss_feed_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_RSS_FEED_DATA,
      'rss_feed_list',
      ObjRssFeeds,
      rss_feed_id,
      { rss_feed_id }
    )
  }

  @action
  deleteRoleData(role_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_ROLE_DATA,
      'role_list',
      ObjRssFeeds,
      role_id,
      { role_id }
    )
  }

  @action
  deleteWedget(widget_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_WIDGET_DATA,
      'widget_list',
      ObjWidgets,
      widget_id,
      { widget_id }
    )
  }

  @action
  deleteSSOMgnt(sso_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_ACT_SSO_AUTH,
      'sso_list',
      ObjSSO,
      sso_id,
      { sso_id }
    )
  }

  @action
  deleteEventMgnt(event_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_EVENT_DATA,
      'event_list',
      ObjEvents,
      event_id,
      { event_id }
    )
  }

  @action
  deleteReviewMgnt(review_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DELETE_REVIEW_DATA,
      'review_list',
      ObjReview,
      review_id,
      { review_id }
    )
  }

  @action
  deleteSurveyMgnt(survey_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DELETE_SURVEY_DATA,
      'survey_list',
      ObjSurvey,
      survey_id,
      { survey_id }
    )
  }

  @action
  deleteFAQMgnt(faq_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DELETE_FAQ_DATA,
      'faq_list',
      ObjFaq,
      faq_id,
      { faq_id }
    )
  }

  @action
  deleteBannerData(params) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DELETE_BANNER_DATA,
      'product_banner_list',
      ObjBanner,
      params.id,
      params
    )
  }

  @action
  deleteCalcLead(lead_id) {
    this.deleteGenericModuleData(
      APIURL.API_ADMIN_DEL_CALC_DATA,
      'calc_lead_list',
      ObjCalcLead,
      lead_id,
      { lead_id }
    )
  }

  @action
  hideProductFromExchange(params) {
    this.setPageContentLoader(true)
    return services
      .post(APIURL.API_ADMIN_HIDE_PRODUCT_FROM_EXCHANGE, params)
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.product_list.set(
            params.product_id,
            ObjProducts.constructFromJSON(res.response)
          )
          this.setPageContentLoader()
        } else {
          this.setPageContentLoader()
        }
      })
  }

  @action
  savePageOrderData(pageData) {
    const params = JSON.stringify(pageData)
    this.setActionLoader(true)
    services
      .post(APIURL.API_ADMIN_SAVE_PAGE_ORDER_DATA, { pageData: params })
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          pageData.map((item) => {
            const page = this.page_list.get(item.page_id)
            if (page) {
              page['page_order'] = item.page_order
            }
            return false
          })
          this.flash_msg_data = { type: 'success', msg: res.message }
          this.showFlashMsg = true
          this.setActionLoader()
        } else {
          this.flash_msg_data = { type: 'danger', msg: res.message }
          this.showFlashMsg = true
          this.setActionLoader()
        }
      })
  }

  @action
  resetUserPassword(user_id) {
    this.setActionLoader(true)
    services
      .post(APIURL.API_ADMIN_RESET_USER_PASSWORD, { user_id })
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.isAppError = true
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.flash_msg_data = { type: 'success', msg: res.message }
          this.showFlashMsg = true
          this.setActionLoader()
        } else {
          this.flash_msg_data = { type: 'danger', msg: res.message }
          this.showFlashMsg = true
          this.setActionLoader()
        }
      })
  }

  @action
  saveSurveyAnswer(params) {
    services.post(APIURL.API_SAVE_SURVEY_ANSWER, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.flash_msg_data = { type: 'success', msg: res.message }
        this.showFlashMsg = true
        this.setActionLoader()
      } else {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
        this.setActionLoader()
      }
    })
  }

  @action
  async updateEventStatus(params) {
    this.setPageContentLoader(true)
    services.post(APIURL.API_ADMIN_SAVE_EVENT_DATA, params).then((res) => {
      if (res.status === 'success') {
        const event = this.event_list.get(params.event_id)
        event['event_published'] = params.event_published
        this.setPageContentLoader()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else {
        this.flash_msg_data = {
          type: 'danger',
          msg: res.message
        }
        this.showFlashMsg = true
        this.setPageContentLoader()
      }
    })
  }

  @action
  activeDeactiveRssFeed(params) {
    this.setActionLoader(true)
    services.post(APIURL.API_ADMIN_ACT_DEACT_RSS_FEED, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.rss_feed_list.set(
          res.response.id,
          ObjRssFeeds.constructFromJSON(res.response)
        )
        this.flash_msg_data = { type: 'success', msg: res.message }
        this.showFlashMsg = true
        this.setActionLoader()
      } else {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
        this.setActionLoader()
      }
    })
  }

  @action
  getOrganizationData = async () => {
    if (this.isAuthenticated()) {
      const res = await services.get_v2(APIURL.API_GET_ORGANITION, {})
      if (res.response) {
        this.organization_data = res.response
      }
    }
  }

  @action
  getAdvisorProfileData = async () => {
    // if (!this.isAuthenticated()) {
    //   return
    // }
    // this.setPageContentLoader(true)
    // const res = await services.post(APIURL.API_GET_ADVISOR_PRODILE_DATA, {})
    // if (res.status === CONSTS.FAILURE && parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500) {
    //   this.setActionLoader()
    //   this.setPageContentLoader()
    //   this.isAppError = true
    // } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
    //   this.signSessionExpiredOut()
    // } else if (res.status === 'success') {
    //   const cards_response = await services.get_v2(APIURL.API_STRIPE_GET_CARD, { 'params': {} })
    //   const cards = cards_response.response
    //   if (cards) {
    //     cards.sort((a, b) => (a.id > b.id) ? 1 : -1)
    //     this.user_card_list = cards
    //   }
    //   this.user_poduct_hide_data = res.response.userProductHideData
    //   this.member_details = res.response.memberDetails
    //   this.event_list.replace(ObjEvents.jsonToArray(res.response.eventList))
    //   this.setPageContentLoader()
    // } else {
    //   this.setPageContentLoader()
    // }
  }

  @action
  saveUserProductHideData(data) {
    const params = { productHideShow: JSON.stringify(data) }
    this.setActionLoader(true)
    services.post(APIURL.API_SAVE_USER_PROD_HIDE_DATA, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
        this.isAppError = true
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.user_poduct_hide_data = res.response
        this.setActionLoader()
      } else {
        this.setActionLoader()
      }
    })
  }

  @action
  async authenticateUser(params, next = null) {
    if (typeof window !== 'undefined') {
      const resData = await services.post(APIURL.API_USER_LOGIN_AUTH, params)
      if (parseInt(resData.statusCode) === HTTP.STATUS_200) {
        // await this.appIdentify(resData.response, true)
        await loginEvent()
      }
      await this.setupSession(params, resData, next)
      if (next) {
        if (window.location.pathname === next) {
        } else {
          this.route.history.push(next)
        }
      } else {
        if (this.isWhiteLabel) {
          this.route.history.push(PAGEURL.MARKETPLACE)
        } else {
          this.route.history.push(PAGEURL.DASHBOARD)
        }
      }
    }
  }

  @action
  userChangePassword(params, isAdminPage, isRoutePage = false) {
    if (typeof window !== 'undefined') {
      this.setActionLoader(true)
      services.post(APIURL.API_USER_CHANGE_PASSWORD, params).then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.isAppError = true
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          const { sessionStorage } = window
          sessionStorage.setItem('password', null)
          this.sessionData.userData.is_password_expired =
            CONSTS.PASSWORD_NOT_EXPIRED
          this.sessionData.userData.password_updated_on = new Date()
          this.resetChangePasswordData()
          toast('Password updated successfully')
          if (isRoutePage === false) {
            if (isAdminPage === true) {
              this.routePage(PAGEURL.ADMIN_MY_PROFILE)
            } else {
              this.routePage(PAGEURL.PROFILE)
            }
          }
        } else if (res.status === 'failure') {
          this.flash_msg_data = { type: 'danger', msg: res.message }
          this.showFlashMsg = true
        }
        this.setActionLoader()
      })
    }
  }

  @action
  userChangeEmail(params, isAdminPage, isRoutePage = false) {
    this.setActionLoader(true)
    services.post(APIURL.API_USER_CHANGE_EMAIL, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.sessionData.userData.email = params.new_email
        this.resetChangePasswordData()
        toast(res.message)
        if (isRoutePage === false) {
          if (isAdminPage === true) {
            this.routePage(PAGEURL.ADMIN_MY_PROFILE)
          } else {
            this.routePage(PAGEURL.PROFILE)
          }
        }
      } else if (res.status === 'failure') {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
      }
      this.setActionLoader()
    })
  }

  @action
  acceptedTermAndCond(params) {
    this.setActionLoader(true)
    services.post(APIURL.API_ACCEPT_TERMS_COND, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.isAppError = true
        this.setActionLoader()
        this.setPageContentLoader()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.sessionData.userData.accept_t_and_c = true
      } else if (res.status === 'failure') {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
      }
      this.setActionLoader()
    })
  }

  @action
  updateUserProfileImage(params) {
    this.setProfileImageUploading(true)
    services.post(APIURL.API_UPDATE_USER_PROFILE_IMAGE, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setProfileImageUploading()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.setProfileImageUploading()
        this.sessionData.userData.profile_image = res.response.profile_image
      } else if (res.status === 'failure') {
        this.setProfileImageUploading()
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
      } else {
        this.setProfileImageUploading()
      }
    })
  }

  defaultDashboardPath = () => {
    if (this.isWhiteLabel) {
      return PAGEURL.MARKETPLACE
    } else {
      return PAGEURL.DASHBOARD
    }
  }

  @action
  accountSetup(params, router) {
    if (typeof window !== 'undefined') {
      this.setActionLoader(true)
      services.post(APIURL.API_ACCOUNT_SETUP, params).then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.setActionLoader()
          const nextUrl = window.localStorage.getItem('next')
            ? window.localStorage.getItem('next')
            : this.defaultDashboardPath()
          router.push(nextUrl)
          this.sessionData.userData = res.response
          this.flash_msg_data = { type: 'success', msg: res.message }
          this.showFlashMsg = true
        } else if (res.status === 'failure') {
          this.flash_msg_data = { type: 'danger', msg: res.message }
          this.showFlashMsg = true
        } else {
          this.setActionLoader()
        }
      })
    }
  }

  @action
  updateUserInfo(params, isAdminPage, is_action_loader = true) {
    this.setActionLoader(is_action_loader)
    services.post(APIURL.API_ACCOUNT_SETUP, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.setActionLoader()
        this.setPageContentLoader()
      } else if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        if (is_action_loader === true) {
          this.setActionLoader()
          if (isAdminPage === true) {
            const { modulesData, solutionData, auth_type, productData } =
              this.sessionData.userData
            this.sessionData.userData = {
              ...res.response,
              modulesData,
              solutionData,
              auth_type,
              productData
            }
            this.routePage(PAGEURL.ADMIN_MY_PROFILE)
          } else {
            this.routePage(PAGEURL.PROFILE)
            this.sessionData.userData = res.response
          }
          this.flash_msg_data = { type: 'success', msg: res.message }
          this.showFlashMsg = true
        } else {
          this.sessionData.userData.profile_image = res.response.profile_image
        }
      } else if (res.status === 'failure') {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
      } else {
        this.setActionLoader()
      }
    })
  }

  @action
  async processPayment(params) {
    if (typeof window !== 'undefined') {
      const res = await services.post_v2(
        APIURL.API_STRIPE_SUBSCRIBE_MEMB_PLAN,
        params
      )
      if (res.status !== 'success') {
        if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else {
          throw new Error(res.message)
        }
      }
      paymentEvent(params.stripe_plan_id)
      if (res.response.login_token) {
        window.localStorage.setItem('access_token', res.response.login_token)
        this.sessionData.access_token = res.response.login_token
      }
      if (res.response.unique_code) {
        window.localStorage.setItem('unique_code', res.response.unique_code)
      }
      return res
    }
  }

  @action
  async processFreePlan(params) {
    if (typeof window !== 'undefined') {
      return await services.post_v2(
        APIURL.API_STRIPE_SUBSCRIBE_FREE_PLAN,
        params
      )
    }
  }

  @action
  async processCharge(params) {
    if (typeof window !== 'undefined') {
      const res = await services.post(APIURL.API_CREATE_ATTENDEE_CHARGE, params)
      const { login_token, unique_code } = res.response
      if (login_token) {
        window.localStorage.setItem('access_token', login_token)
      }
      if (unique_code) {
        window.localStorage.setItem('unique_code', unique_code)
      }
      return res
    }
  }

  @action
  async processRegistration(params) {
    const res = await services.post(APIURL.API_REGISTER_ATTENDEE, params)
    return res
  }

  @action
  finishUserSubscription() {
    this.sessionData.activeSubscription.is_active = true
    this.sessionData.userData['accept_t_and_c'] = true
  }

  @action
  async getActiveSubscription() {
    const res = await services.get_v2(APIURL.API_GET_ACTIVE_SUBSCRIPTION)
    const activeSubscription =
      res.response === null || res.response === 'null' ? null : res.response
    this.sessionData.activeSubscription = activeSubscription
    return activeSubscription
  }

  @action
  async getActiveSlSubscription() {
    const res = await services.get_v2(APIURL.API_GET_SL_SUBSCRIPTION)
    const slSubscription =
      res.response === null || res.response === 'null' ? null : res.response
    this.sessionData.slSubscription = slSubscription
    return slSubscription
  }

  @action
  deletePaymentCard(params) {
    this.setActionLoader(true)
    return services
      .post_v2(APIURL.API_STRIPE_DELETE_CARD, params)
      .then((res) => {
        if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.user_card_list = this.user_card_list.filter(
            (e) => e.stripe_card_id !== params.stripe_card_id
          )
          this.setActionLoader()
        } else {
          this.flash_msg_data = { type: 'danger', msg: res.message }
          this.setActionLoader()
        }
      })
  }

  @action
  loadUserSavedCard() {
    this.setPageContentLoader(true)
    services.get_v2(APIURL.API_STRIPE_GET_CARD, {}).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.isAppError = true
        this.setActionLoader()
        this.setPageContentLoader()
      }
      if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        const cards = res.response
        if (cards) {
          cards.sort((a, b) => (a.id > b.id ? 1 : -1))
          this.user_card_list = cards
        }
        this.setPageContentLoader()
        this.showFlashMsg = false
      } else {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
        this.setPageContentLoader()
      }
    })
  }

  @action
  savePaymentCard(params) {
    this.setActionLoader(true)
    return services.post_v2(APIURL.API_STRIPE_ADD_CARD, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.isAppError = true
        this.setActionLoader()
        this.setPageContentLoader()
      }
      if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'failure') {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
        this.setActionLoader()
      } else {
        this.setActionLoader()
        this.getAdvisorProfileData()
        this.showFlashMsg = false
      }
    })
  }

  @action
  saveTileArrangements(params) {
    this.setActionLoader(true)
    services.post(APIURL.API_SAVE_TILE_ARRANGEMENTS, params).then((res) => {
      if (res.status === 'success') {
        this.flash_msg_data = { type: 'success', msg: res.message }
      } else {
        this.flash_msg_data = { type: 'danger', msg: res.message }
      }
      this.showFlashMsg = true
      this.setActionLoader()
    })
  }

  @action
  forgotPassword(params) {
    this.setActionLoader(true)
    services.post(APIURL.API_FORGOT_PASSOWRD_MAIL, params).then((res) => {
      if (res.status === 'success') {
        this.flash_msg_data = { type: 'success', msg: res.message }
      } else {
        this.flash_msg_data = { type: 'danger', msg: res.message }
      }
      this.showFlashMsg = true
      this.setActionLoader()
    })
  }

  @action
  generateEmailLink() {
    this.setActionLoader(true)
    services
      .post(APIURL.API_GENERATE_SET_PASSWORD_LINK, {
        user_id: this.inputData.user_id.value
      })
      .then((res) => {
        if (
          res.status === CONSTS.FAILURE &&
          parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.isAppError = true
          this.setActionLoader()
          this.setPageContentLoader()
        }
        if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.signSessionExpiredOut()
        } else if (res.status === 'success') {
          this.flash_msg_data = { type: 'success', msg: res.message }
          this.showFlashMsg = true
          this.inputData.update('email_link', res.response.signupLink)
          this.inputData.update('set_pwd_link', res.response.resetUrl)
          this.setActionLoader()
        } else if (res.status === 'failure') {
          this.flash_msg_data = { type: 'danger', msg: res.message }
          this.showFlashMsg = true
          this.setActionLoader()
        } else {
          this.setActionLoader()
        }
      })
  }

  @action
  forgotPasswordUpdate(params) {
    this.setActionLoader(true)
    services.post(APIURL.API_FORGOT_PASSOWRD_UPDATE, params).then((res) => {
      if (
        res.status === CONSTS.FAILURE &&
        parseInt(res.statusCode) >= CONSTS.HTTP_ERROR_500
      ) {
        this.isAppError = true
        this.setActionLoader()
        this.setPageContentLoader()
      }
      if (parseInt(res.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
        this.signSessionExpiredOut()
      } else if (res.status === 'success') {
        this.flash_msg_data = { type: 'success', msg: res.message }
        this.showFlashMsg = true
        this.route.history.push(PAGEURL.LOGIN)
        this.setActionLoader()
        this.prod_sub.oasis.action_type = res.response.action_type
      } else if (res.status === 'failure') {
        this.flash_msg_data = { type: 'danger', msg: res.message }
        this.showFlashMsg = true
        this.setActionLoader()
      } else {
        this.setActionLoader()
      }
    })
  }

  setupSession = async (params, resData, preload = true) => {
    if (typeof window !== 'undefined') {
      if (parseInt(resData.statusCode) === HTTP.STATUS_200) {
        const { localStorage } = window
        localStorage.setItem('access_token', resData.response.login_token)
        localStorage.setItem('unique_code', resData.response.unique_code)
        this.sessionData.isLoggedIn = true
        this.sessionData.access_token = resData.response.login_token
        this.sessionData.userData = resData.response.userData
        this.sessionData.idpData = resData.response.idpData
        if (this.sessionData.registration_product) {
          window.location.href = '?launchModal=true'
        }
        if (preload) {
          // await this.preloadMarketData()
          await this.preLoadAdvisor(this.route, false)
        }
        this.flash_msg_data = { type: '', msg: '' }
        this.showFlashMsg = false
        this.clearFlashMessage()
        this.setActionLoader(false)
      } else {
        throw new Error(resData.message)
      }
    }
  }

  @action
  async userSignup(params, preload = true) {
    const resData = await services.post_v2(APIURL.API_USER_SIGNUP, params)
    if (parseInt(resData.statusCode) === HTTP.STATUS_200) {
      // await this.appIdentify(resData.response, false)
      await registerEvent()
      await this.setupSession(params, resData, preload)
    }
    return resData
  }

  async handleAuthData(resData, router, next = null) {
    if (typeof window !== 'undefined') {
      const n = next || window.localStorage.getItem('next')
      await this.setupSession({}, resData, true)
      // await this.appIdentify(resData.response, false)
      if (resData.response.newUser) {
        await registerEvent()
      } else {
        await loginEvent()
      }
      if (window.localStorage.getItem('referralcode')) {
        const params = {
          referral_code: window.localStorage.getItem('referralcode')
        }
        const response = await services.post_v2(APIURL.API_ADD_REFERRAL, params)
        if (response.success === false) {
          toast.configure({ autoClose: 8000 })
          toast.error(
            'Your account is not eligible for the referral reward: ' +
              response.status
          )
        }
      }
      if (this.sessionData.userData.partner_id) {
        router.push(PAGEURL.PORTAL)
      } else if (this.userTier === 0) {
        router.push(PAGEURL.SIGNUP)
        /*
      } else if (
        !this.sessionData.userData.first_name ||
        !this.sessionData.userData.last_name ||
        !this.sessionData.userData.phone
      ) {
        router.push(PAGEURL.ACCOUNT_SETUP)
        */
      } else {
        if (n && n !== null) {
          router.push(n)
        } else {
          if (this.isWhiteLabel) {
            router.push(PAGEURL.MARKETPLACE)
          } else {
            router.push(PAGEURL.DASHBOARD)
          }
        }
      }
      window.localStorage.removeItem('next')
    }
  }

  @action
  async userOidcAuth(code, state, router) {
    const next = window.localStorage.getItem('next')
    if (typeof window !== 'undefined') {
      const resData = await services.get_v2(
        `auth/callback?code=${code}&state=${state}`,
        {}
      )
      if (parseInt(resData.statusCode) === HTTP.STATUS_302) {
        if (next) {
          router.push(
            resData.response.url + '?next=' + encodeURIComponent(next)
          )
        } else {
          router.push(resData.response.url)
        }
      } else if (parseInt(resData.statusCode) === HTTP.STATUS_200) {
        await this.handleAuthData(resData, router)
      } else {
        // If the login fails, just rediret home for now
        router.push(PAGEURL.HOME)
      }
      return resData
    }
  }

  async userOidcLogout(next = null) {
    if (typeof window !== 'undefined') {
      try {
        const res = await services.get_v2(`auth/logout`, {
          params: { next: next }
        })
        if (res.statusCode === 200) {
          window.localStorage.removeItem('access_token')
          window.localStorage.removeItem('unique_code')
          if (res.response && res.response.logout_url) {
            window.location.href = res.response.logout_url
            return
          }
        }
      } catch (e) {
        console.error(e)
      }
      // Logout *might* have failed, but let's clean up like its all gravy
      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('unique_code')
      window.location.href = '/'
    }
  }

  @action
  validateSignUpToken(params) {
    return services.post(APIURL.API_VALIDATE_SIGNUP_TOKEN, params)
  }

  @action
  async login(unique_code, access_token, userData) {
    if (typeof window !== 'undefined') {
      const { localStorage } = window
      this.sessionData.isLoggedIn = true
      localStorage.setItem('access_token', access_token)
      localStorage.setItem('unique_code', unique_code)
      this.sessionData.access_token = access_token
      this.sessionData.userData = userData
      this.clearFlashMessage()
      this.setActionLoader()

      await this.loadEverything()
    }
  }

  @action
  setPassword(params) {
    return services.post(APIURL.API_SET_PASSOWRD, params)
  }

  @action
  getRSSFeedContent(rssURL) {
    this.setActionLoader()
    services
      .post(APIURL.API_GET_RSS_FEED_CONTENT, { rssURL })
      .then((resData) => {
        if (
          resData.status === CONSTS.FAILURE &&
          parseInt(resData.statusCode) >= CONSTS.HTTP_ERROR_500
        ) {
          this.isAppError = true
          this.setActionLoader()
          this.setPageContentLoader()
        } else if (parseInt(resData.statusCode) === CONSTS.ERROR_FEBRIDDEN) {
          this.setPageContentLoader()
          this.signSessionExpiredOut()
        } else if (parseInt(resData.statusCode) === 503) {
          this.setActionLoader()
          this.setPageContentLoader()
          this.setPageLoader()
        } else {
          this.sessionData.rssFeedLoading = false
          this.sessionData.rssFeeds = resData.response
          this.setActionLoader()
        }
      })
  }

  resetMembershipData() {
    this.memb_pay = {
      selectedMembPlan: null,
      selectedCard: '',
      isCardSelected: false,
      step: CONSTS.PAYMENT_STEP1,
      payment_method: '',
      stripe_token: '',
      addition_prod_amt: 0,
      card_name: '',
      card_no: '',
      card_exp: '',
      card_cvv: '',
      paymentDetails: '',
      isAdditionalProductSub: false,
      add_payment_method: ''
    }
  }

  setDataTable(data_table) {
    this.data_table.data = data_table.data
    this.data_table.filteredData = []
    this.data_table.fields = data_table.fields
    this.data_table.colLength = data_table.colLength
    const start = (this.data_table.activePage - 1) * this.data_table.perPage
    const end = start + this.data_table.perPage
    const filteredData = this.data_table.data.slice(start, end)
    const sortColumn = {}
    const coln = { label: '', sortType: '' }
    for (const key in this.data_table.fields) {
      sortColumn[key] = coln
      sortColumn[key].label = this.data_table.fields[key]
      sortColumn[key]['sortType'] = ''
    }
    const totalItemsCount = this.data_table.data.length
    this.data_table.filteredData = filteredData
    this.data_table.totalItemsCount = totalItemsCount
    this.data_table.sortColumn = sortColumn
  }

  updateDatatableFilter(id) {
    this.data_table.data = this.data_table.data.filter(
      (e) => Number(e.id) !== Number(id)
    )
    this.data_table.filteredData = []

    const start = (this.data_table.activePage - 1) * this.data_table.perPage
    const end = start + this.data_table.perPage
    const filteredData = this.data_table.data.slice(start, end)

    const sortColumn = {}
    const coln = { label: '', sortType: '' }
    for (const key in this.data_table.fields) {
      sortColumn[key] = coln
      sortColumn[key].label = this.data_table.fields[key]
      sortColumn[key]['sortType'] = ''
    }
    const totalItemsCount = this.data_table.data.length
    this.data_table.filteredData = filteredData
    this.data_table.totalItemsCount = totalItemsCount
    this.data_table.sortColumn = sortColumn
  }

  resetDataTable() {
    this.data_table = {
      data: [],
      filteredData: [],
      fields: {},
      colLength: 0,
      sortColumn: {},
      activePage: 1,
      perPage: 1000,
      totalItemsCount: 0,
      pageRangeDisplayed: 5
    }
  }

  resetSessionData() {
    if (typeof window !== 'undefined') {
      const { localStorage } = window
      this.sessionData = {
        isLoggedIn: false,
        access_token: null,
        userData: {},
        isPreLoadData: true,
        pageLoader: true,
        pageContentLoader: false,
        rssFeeds: null,
        rssFeedLoading: false,
        toggleRSSRight: false,
        default_role_id: 0,
        show_login_modal: false,
        show_signup_modal: false
      }
      localStorage.clear()
    }
  }

  resetChangePasswordData() {
    this.changePasswordData = {
      old_password: new ChangePasswordValidate(
        'old_password',
        'Current Password'
      ),
      new_password: new ChangePasswordValidate('new_password', 'New Password'),
      confirm_password: new ChangePasswordValidate(
        'confirm_password',
        'Confirm Password'
      )
    }
  }

  resetChangeEmailData() {
    this.changeEmailData = {
      new_email: new ChangeEmailValidate('new_email', 'New Email'),
      password: new ChangeEmailValidate('password', 'Current Password')
    }
  }

  setUserField(key, value) {
    this.loginCredential[key].value = value
    this.loginCredential[key].validate()
  }

  validateUser() {
    let isValid = true
    for (const key in this.loginCredential) {
      this.loginCredential[key].validate()
      if (this.loginCredential[key].errors.length) {
        isValid = false
      }
    }
    return isValid
  }

  setInputField(key_name, key, value) {
    this[key_name][key].value = value
    this[key_name][key].validate()
  }

  validateFields(key_name) {
    let isValid = true
    for (const key in this[key_name]) {
      this[key_name][key].validate()
      if (this[key_name][key].errors.length) {
        isValid = false
      }
    }
    return isValid
  }

  validateChangePWDFields() {
    let isValid = true
    const new_password = this.changePasswordData['new_password'].value
    this.changePasswordData['confirm_password'].validate(new_password)
    if (this.changePasswordData['confirm_password'].errors.length > 0) {
      isValid = false
    }
    return isValid
  }

  signSessionExpiredOut(next = null) {
    if (typeof window !== 'undefined') {
      this.setRedtailSSOLogin()
      this.resetSessionData()
      if (next) {
        window.location = next
      } else {
        window.location = PAGEURL.LOGIN
      }
    }
  }

  signOut() {
    this.setActionLoader(true)
    this.setRedtailSSOLogin()
    services.post(APIURL.API_LOGOUT, {}).then((res) => {
      this.resetSessionData()
      this.route.history.push(PAGEURL.HOME)
    })
  }

  pageview(views) {
    return services.post_v2(APIURL.API_ADD_PAGE_VIEW, {
      views
    })
  }

  async sendPageviews() {
    try {
      if (typeof window !== 'undefined') {
        const views = JSON.parse(window.localStorage.getItem('views') || '[]')
        if (views.length) {
          await this.pageview(views)
          window.localStorage.removeItem('views')
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  createObject(ClassType) {
    this.inputData = new ClassType()
  }
  createFormObject(ClassType) {
    this.formInputs = new ClassType()
  }
  createPrositeFormObject(ClassType) {
    this.formPrositeInputs = new ClassType()
  }
  createSlFormObject(ClassType) {
    this.formSlInputs = new ClassType()
  }

  destroyObject(key_name) {
    this[key_name] = null
  }

  isUserLoggedIn() {
    return this.sessionData.isLoggedIn
  }
  isPreLoadedData() {
    return this.sessionData.isPreLoadData
  }

  isPageLoading() {
    return this.sessionData.pageLoader
  }
  isActionLoading() {
    return this.sessionData.actionLoader
  }
  isPageContentLoading() {
    return this.sessionData.pageContentLoader
  }
  setPageLoading(pageLoader = false) {
    this.sessionData.pageLoader = pageLoader
  }
  setProfileLoading(profileLoader = false) {
    this.sessionData.profileLoading = profileLoader
  }
  setPageContentLoader(pageContentLoader = false) {
    this.sessionData.pageContentLoader = pageContentLoader
  }
  setActionLoader(actionLoader = false) {
    this.sessionData.actionLoader = actionLoader
  }
  setProfileImageUploading(imageLoader = false) {
    this.sessionData.profileImageUploading = imageLoader
  }

  // showFlashMessage(message = '', statusCode = '', status = '', show = true) {
  //  this.flashMessage = {statusCode, message, status, show}
  // }

  pageRoute(page = null) {
    if (page) {
      this.route.history.push(page)
      this.clearFlashMessage()
    }
  }

  /**
   * Author: Hanamantray R
   * Function Name: clearFlashMessage
   * Created On: OCT 26, 2017
   * Created For: call the node api with react fetch to handle valid submit
   */
  clearFlashMessage() {
    if (this.loaderFlashMsg) {
      clearInterval(this.loaderFlashMsg)
    }
    this.loaderFlashMsg = setTimeout(() => {
      this.flash_msg_data = { type: '', msg: '' }
      this.showFlashMsg = false
      clearInterval(this.loaderFlashMsg)
      this.loaderFlashMsg = null
    }, CONSTS.FLASH_MSG_INTERVAL)
  }

  clearFlashData() {
    this.flash_msg_data = { type: '', msg: '' }
    this.showFlashMsg = false
  }

  @action
  flashDanger(message) {
    this.flash_msg_data = {
      type: 'danger',
      msg: message
    }
    this.showFlashMsg = true
  }

  pushSelectionData(id) {
    this.req_consultation_selection_data.data.push(id)
  }

  deleteSelectedData(id) {
    const index = this.req_consultation_selection_data.data.indexOf(id)
    this.req_consultation_selection_data.data.splice(index, 1)
  }

  productBySelectionReqConsultation() {
    return this.req_consultation_selection_data.data.map((product_id) => {
      const productDetails = this.productBySlug(product_id)
      return {
        prod_sub_type: productDetails.partner.slug,
        product_id: productDetails.id,
        company_id: productDetails.partner.id,
        enrollment_type: productDetails.enrollment_type
          ? productDetails.enrollment_type
          : null,
        product_name: productDetails.name
      }
    })
  }

  productByPartner(company) {
    return this.product_list.values().filter((p) => {
      let found = false
      const partners = p.fin_partners || []
      partners.forEach((c) => {
        if (c.id === company.id) {
          found = true
        }
      })
      return found
    })
  }

  productTags = () => {
    return this.product_tag_list.values().map((t) => ({
      id: t.id,
      name: t.name,
      slug: t.slug
    }))
  }

  productBySlug(slug, products = null) {
    let r = null
    if (products) {
      r = products.filter((p) => p.slug === slug)
    } else {
      r = this.product_list.filter((p) => p.slug === slug)
    }
    if (r && r.length === 1) {
      return r[0]
    } else {
      return null
    }
  }

  productMedia(id) {
    const r = this.product_list.values().filter((p) => p.id === id)
    if (r && r.length === 1) {
      return r[0].fin_product_media
    } else {
      return null
    }
  }

  filterByArchetype(archetype) {
    let r = this.filterByTag('archetype')
    if (r) {
      r = r.filter((e) => {
        return archetype === e.name || archetype === e.slug
      })
    }
    if (r && r.length > 0) {
      return r[0]
    } else {
      return null
    }
  }

  filterByPricingTier(pricing_tier) {
    const s = {}
    s.tiles = []
    if (pricing_tier === null) {
      this.product_list.values().forEach((product) => {
        s.tiles.push(product.slug)
      })
      return s
    }
    this.product_list.values().forEach((product) => {
      if (product.pricing_tier === pricing_tier) {
        s.tiles.push(product.slug)
      }
    })
    return s
  }

  filterByCategory(category, product_slug) {
    let r = this.filterByTag('related', product_slug)

    if (r) {
      r = r.filter((e) => {
        return category === e.slug
      })
    }
    if (r && r.length > 0) {
      return r[0]
    } else {
      return null
    }
  }

  filterByTag(tagGroup, product_slug = undefined, products = null) {
    const sections = {}
    if (products) {
      products.forEach((product) => {
        if (!product.tags) {
          return
        }
        product.tags.forEach((tag) => {
          if (tag.group === tagGroup) {
            let s = {}
            if (sections[tag.slug]) {
              s = sections[tag.slug]
            } else {
              s.id = tag.id
              s.name = tag.name
              s.slug = tag.slug
              s.order = []
              s.tiles = []
              sections[tag.slug] = s
            }
            if (product_slug) {
              if (product_slug !== product.slug) {
                s.tiles.push(product)
              }
            } else {
              s.tiles.push(product)
              if (tagGroup === 'marketplace' && product.arrangements) {
                const obj = product.arrangements.find(
                  (order) => order.category === tag.slug
                )
                s.order.push({
                  product: product.slug,
                  order: obj ? obj.order : 500,
                  tier: product.pricing_tier
                })
              }
            }
          }
        })
      })
    } else {
      this.product_list.forEach((product) => {
        if (!product.tags) {
          return
        }
        product.tags.forEach((tag) => {
          if (tag.group === tagGroup) {
            let s = {}
            if (sections[tag.slug]) {
              s = sections[tag.slug]
            } else {
              s.id = tag.id
              s.name = tag.name
              s.slug = tag.slug
              s.order = []
              s.tiles = []
              sections[tag.slug] = s
            }
            if (product_slug) {
              if (product_slug !== product.slug) {
                s.tiles.push(product)
              }
            } else {
              s.tiles.push(product)
              if (tagGroup === 'marketplace' && product.arrangements) {
                const obj = product.arrangements.find(
                  (order) => order.category === tag.slug
                )
                s.order.push({
                  product: product.slug,
                  order: obj ? obj.order : 500,
                  tier: product.pricing_tier
                })
              }
            }
          }
        })
      })
    }
    const r = []
    for (const k in sections) {
      r.push(sections[k])
    }
    return r.sort((a, b) => a.id - b.id)
  }

  // @action
  // connectOrionSSO (e) {
  //   services.post('api/connectOrionSSO', {}).then(data => {
  //     const URL_LINK = `https://rep.orionadvisor.com/sso.html?t=${data.response.access_token}`
  //     window.open(URL_LINK, '_blank')
  //   })
  // }

  @action
  startTutorial() {
    this.tutorial.run = true
  }

  @action
  stopTutorial() {
    this.tutorial.run = false
  }

  hasActiveSubscription() {
    return (
      this.sessionData.userData.role_id === CONSTS.ADVISOR_ROLE &&
      this.sessionData.activeSubscription !== null &&
      this.sessionData.activeSubscription !== undefined &&
      this.sessionData.activeSubscription.is_active === true
    )
  }

  hasActiveSlSubscription() {
    return (
      this.sessionData.slSubscription !== null &&
      this.sessionData.slSubscription !== undefined &&
      this.sessionData.slSubscription.is_active === true
    )
  }

  filterByMembershipStatus(events, membership) {
    if (membership.is_paid) {
      events = events.filter((e) => e.is_paid === true)
    } else if (membership.is_registered) {
      events = events.filter((e) => e.is_registered === true)
    } else {
      events = events.filter((e) => e.is_public === true)
    }
    return events
  }

  getMembershipStatus() {
    const is_registered = this.isAuthenticated()
    const is_paid = this.hasActiveSubscription()
    return { is_registered, is_paid }
  }

  isSuperAdmin() {
    return [CONSTS.SUPER_ADMIN_ROLE].includes(this.sessionData.userData.role_id)
  }

  isOrganizationAdmin() {
    return this.sessionData.userData.organization_admin
  }

  addEmailToOrganization(invite_id) {
    if (typeof window !== 'undefined') {
      const response = services.post_v2(APIURL.API_ADD_EMAIL_TO_ORGANIZATION, {
        invite_id: invite_id
      })
      if (response.statusCode === 200) {
        window.localStorage.removeItem('invite_id')
      } else {
        window.localStorage.setItem('invite_id', invite_id)
      }
    }
  }

  isEnterpriseAdmin() {
    return CONSTS.ENTERPRISE_ADMIN_ROLE === this.sessionData.userData.role_id
  }

  isAffiliate() {
    return (
      this.sessionData.userData.role_id === CONSTS.AFFILIATE_ROLE &&
      this.sessionData.activeSubscription !== null &&
      this.sessionData.activeSubscription.is_active === true
    )
    // return [
    //   CONSTS.AFFILIATE_ROLE,
    // ].includes(this.sessionData.userData.role_id)
  }

  isReadonly() {
    return [CONSTS.READONLY_ROLE].includes(this.sessionData.userData.role_id)
  }

  isAuthenticated() {
    return this.sessionData.isLoggedIn
  }

  /**
   * This is absolutely bizarro to me, but allow public members and paid members
   * to navigate the site, but don't allow registered members to navigate.
   */
  permitNavigation() {
    return true
  }

  requiresPayment() {
    return (
      !this.hasActiveSubscription() &&
      !this.isSuperAdmin() &&
      !this.isEnterpriseAdmin() &&
      !this.isAffiliate() &&
      !this.isReadonly()
    )
  }

  @action
  showReferModal(product = null) {
    this.sessionData.show_signup_modal = true
  }

  @action
  showLoginModal(product = null) {
    this.clearFlashData()
    this.sessionData.show_login_modal = true
    this.sessionData.show_signup_modal = false
    if (product) {
      this.sessionData.registration_product = product
    }
  }

  @action
  showSignupModal(product = null) {
    this.clearFlashData()
    this.sessionData.show_signup_modal = true
    this.sessionData.show_login_modal = false
    if (product) {
      this.sessionData.registration_product = product
    }
  }

  @action
  hideLoginModal() {
    this.sessionData.show_login_modal = false
    this.sessionData.registration_product = null
  }

  @action
  hideSignupModal() {
    this.sessionData.show_signup_modal = false
    this.sessionData.registration_product = null
  }

  @action
  showTocModal() {
    this.sessionData.show_toc_modal = true
  }

  @action
  hideTocModal() {
    this.sessionData.show_toc_modal = false
  }

  @action
  showPpModal() {
    this.sessionData.show_pp_modal = true
  }

  @action
  hidePpModal() {
    this.sessionData.show_pp_modal = false
  }

  @action
  snapshotEnabled() {
    return true
  }

  findBestPlan(plan_id = null) {
    const plans = this.availablePlans()
    let plan = null
    if (plan_id) {
      plan = plans.filter((plan) => plan.id === plan_id)[0]
    }
    if (!plan) {
      plan = plans.sort((a, b) => a.order - b.order)[0]
    }
    return plan
  }

  @action
  getUserPlan() {
    const plans = this.siteData.get('plans')
    const { plan_id } = this.organization_data
    if (plan_id) {
      return plans.filter(
        (plan) => plan.id.toString() === plan_id.toString()
      )[0]
    } else {
      return null
    }
  }

  getProductPlanName(pricing_tier) {
    const plans = this.siteData.get('plans')
    const plan = plans.length > pricing_tier ? plans[pricing_tier] : null
    if (plan) {
      return plan.name
    }
    return null
  }

  getStripePlanId(tier, freq) {
    if (tier === 0) {
      return 'free'
    } else if (tier === 1) {
      if (freq === 'monthly') {
        return 'cn-monthly-25'
      } else {
        return 'cn-yearly-240'
      }
    } else if (tier === 2) {
      if (freq === 'monthly') {
        return 'cn-monthly-50'
      } else {
        return 'cn-yearly-480'
      }
    } else if (tier === 3) {
      if (freq === 'monthly') {
        return 'cn-monthly-100'
      } else {
        return 'cn-yearly-960'
      }
    } else {
      return 'cn-monthly-25'
    }
  }

  // stripePlanCost = (stripe_plan_id) => {
  //   switch (stripe_plan_id) {
  //     case 'cn-monthly-100':
  //       return 100
  //     case 'cn-yearly-960':
  //       return 960
  //     case 'cn-monthly-50':
  //       return 50
  //     case 'cn-yearly-480':
  //       return 480
  //     case 'cn-monthly-25':
  //       return 25
  //     case 'cn-yearly-240':
  //       return 240
  //     default:
  //       return 0
  //   }
  // }

  calcBilling = (cost) => {
    const { valid, amount_off, percent_off } = this.subscription.coupon_data
    const subtotal = cost
    let total = subtotal
    let discount = 0
    if (valid) {
      if (percent_off) {
        discount = subtotal * (percent_off * 0.01)
      } else if (amount_off) {
        discount = amount_off * 0.01
      }
      total -= discount
    }
    return {
      subtotal,
      total,
      discount
    }
  }

  getProductMediaData(product_id) {
    return this.setProductMedia(
      APIURL.API_ADMIN_PRODUCT_MEDIA_DATA(product_id),
      'product_media',
      ObjProductMedia
    )
  }

  userPlanCount() {
    return (this.organization_data.all_plan_ids || []).length
  }

  planCount() {
    return (this.siteData.get('plans') || []).length
  }

  topTier() {
    try {
      let plans = this.siteData.get('plans')
      plans = plans.sort(function (a, b) {
        if (a.order > b.order) return 1
        else if (a.order < b.order) return -1
        else return 0
      })
      return plans[plans.length - 1].order
    } catch (e) {
      return 0
    }
  }

  async loadEverything(route) {
    try {
      await this.setPageLoading(false)
      await this.setProfileLoading(true)
      await this.preLoadAdvisor(route, false)
    } catch (e) {
      // show some
      await this.setPageLoading(false)
    }
    await this.setProfileLoading(false)
  }

  isEnterprise() {
    return this.siteData.get('layout_template') !== 'chalicenetwork'
  }

  availablePlans() {
    const plans = this.siteData.get('plans')
    const { userData } = this.sessionData
    if (!userData) {
      return plans
    }
    return plans.filter(
      (plan) => !(userData.all_plan_ids || []).includes(plan.id)
    )
  }
}

export default Store
