import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject('store')
@observer
class PageContentLoader extends Component {
  render () {
    return (
      <div className="loaderWrap">
        <img alt={'Page container loader'} className="fullpage-loader" src='/assets/images/loader.gif' />
      </div>
    )
  }
}

export default PageContentLoader
