import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import styled, { css } from 'styled-components'

const FormGroup = styled.div`
  margin-bottom: 15px;
`

const FormChunk = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.horizontal &&
    css`
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    `}
`

const Label = styled.label`
  font-size: 1rem;
  font-weight: 400;
`

class FormikField extends React.Component {
  render() {
    return (
      <FormGroup>
        <FormChunk horizontal={this.props.horizontal}>
          {this.props.label && (
            <Label className="chalice-label" htmlFor={this.props.name}>
              {this.props.label}
              <sup className="text-danger">
                {this.props.required && !this.props.hideAsterik && '*'}
              </sup>
            </Label>
          )}
          <div>{this.props.children}</div>
        </FormChunk>
        <div className="text-danger">
          <ErrorMessage name={this.props.error_name || this.props.name} />
        </div>
        {this.props.description}
      </FormGroup>
    )
  }
}

FormikField.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  hideAsterik: PropTypes.bool
}

export default FormikField
