import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Img } from 'react-image'
import { FaUserAstronaut } from 'react-icons/fa'
import { FiPieChart } from 'react-icons/fi'

import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button'
import '@reach/menu-button/styles.css'

import { PAGEURL, CONSTS } from '/constants'
import { tw, Container } from './theme'

import { MobileMenu } from './MobileMenu'

export const EarthlinkHeader = ({ user, siteData, subNavItems }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })

  const [currentUser, setCurrentUser] = useState(user)
  useEffect(() => {
    setCurrentUser(user)
  }, [user])

  const isAdmin =
    currentUser?.role_id === CONSTS.SUPER_ADMIN_ROLE ||
    currentUser?.role_id === CONSTS.ENTERPRISE_ADMIN_ROLE

  return (
    <Header id="chalice-header">
      <Container>
        <NavbarContent>
          <LeftNav>
            <LogoWrap href="/">
              <Img
                className="header-logo"
                src={siteData.logo_media_center.media}
                alt={siteData.name}
              />
            </LogoWrap>
            <MobileMenu
              user={user}
              subNavItems={subNavItems}
              top="10p"
              right="0"
            />
            {!isMobile && (
              <HomeUl>
                <HomeLi>
                  <Button as="a" href="https://www.earthlink.net/">
                    {' '}
                    Home{' '}
                  </Button>
                  <div>
                    <ul className="">
                      <li className="group text-16 w-full">
                        <a
                          id="Internet0"
                          className="title"
                          href="https://www.earthlink.net/internet/"
                        >
                          <span className="flex">
                            <span>Internet</span>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 1024 1024"
                              className="h-6 w-5 bold ml-2 inline-block"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path>
                            </svg>
                          </span>
                        </a>
                        <ul className="grid grid-cols-2 xl:block">
                          <li className="text-16">
                            <a
                              id="Fiber0"
                              className=""
                              href="https://www.earthlink.net/internet/fiber-internet/"
                            >
                              <span>Fiber</span>
                            </a>
                          </li>
                          <li className="text-16">
                            <a
                              id="Wireless1"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/internet/wireless-home-internet/"
                            >
                              <span>Wireless</span>
                            </a>
                          </li>
                          <li className="text-16">
                            <a
                              id="Satellite2"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/internet/satellite-internet/"
                            >
                              <span>Satellite</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="group hover:bg-elnk-blue-600 "></li>
                    </ul>
                    <ul className="">
                      <li className="group text-16 w-full">
                        <a
                          id="Find Internet Near You1"
                          className="title"
                          href="https://www.earthlink.net/internet-near-me/"
                        >
                          <span className="flex">
                            <span>Find Internet Near You</span>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 1024 1024"
                              className="h-6 w-5 bold ml-2 inline-block"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path>
                            </svg>
                          </span>
                        </a>
                        <ul className="grid grid-cols-2 xl:block">
                          <li className="text-16">
                            <a
                              id="Rural0"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/internet/rural-internet/"
                            >
                              <span>Rural</span>
                            </a>
                          </li>
                          <li className="text-16">
                            <a
                              id="Coverage Maps1"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/resources/coverage-maps/internet-coverage-map/"
                            >
                              <span>Coverage Maps</span>
                            </a>{' '}
                          </li>
                        </ul>
                      </li>
                      <li className="group hover:bg-elnk-blue-600 "></li>
                    </ul>
                    <ul className="">
                      <li className="group text-16 w-full">
                        <a
                          id="Safety2"
                          className="title"
                          href="https://www.earthlink.net/security-privacy/"
                        >
                          <span className="flex">
                            <span>Safety</span>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 1024 1024"
                              className="h-6 w-5 bold ml-2 inline-block"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path>
                            </svg>
                          </span>
                        </a>
                        <ul className="grid grid-cols-2 xl:block">
                          <li className="text-16">
                            <a
                              id="Internet Security0"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/security-privacy/cyber-safety/"
                            >
                              <span>Internet Security</span>
                            </a>
                          </li>
                          <li className="text-16">
                            <a
                              id="Remote IT Support1"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/security-privacy/remote-it-support/"
                            >
                              <span>Remote IT Support</span>
                            </a>
                          </li>
                          <li className="text-16">
                            <a
                              id="Data Backup2"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/security-privacy/data-backup/"
                            >
                              <span>Data Backup</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="group hover:bg-elnk-blue-600 "></li>
                    </ul>
                    <ul className="">
                      <li className="group text-16 w-full">
                        <a
                          id="Resources3"
                          className="title"
                          href="https://www.earthlink.net/resources/"
                        >
                          <span className="flex">
                            <span>Resources</span>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 1024 1024"
                              className="h-6 w-5 bold ml-2 inline-block"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path>
                            </svg>
                          </span>
                        </a>
                        <ul className="grid grid-cols-2 xl:block">
                          <li className="text-16">
                            <a
                              id="Blog0"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/blog/"
                            >
                              <span>Blog</span>
                            </a>
                          </li>
                          <li className="text-16">
                            <a
                              id="Data Calculator1"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/resources/bandwidth-calculator/"
                            >
                              <span>Data Calculator</span>
                            </a>
                          </li>
                          <li className="text-16">
                            <a
                              id="Fiber vs. Cable2"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/resources/fiber-vs-cable-internet/"
                            >
                              <span>Fiber vs. Cable</span>
                            </a>
                          </li>
                          <li className="text-16">
                            <a
                              id="Ultimate Guide to the Internet3"
                              className="xl:py-2 px-15 text-white hover:underline hover:text-white transition-all font-normal flex items-center "
                              href="https://www.earthlink.net/resources/ultimate-guide-to-the-internet/"
                            >
                              <span>Ultimate Guide to the Internet</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="group hover:bg-elnk-blue-600 "> </li>
                    </ul>
                  </div>
                </HomeLi>
                <li>
                  <Button
                    as="a"
                    href="https://www.earthlink.net/internet/business-internet/"
                  >
                    {' '}
                    Business Internet{' '}
                  </Button>
                </li>
              </HomeUl>
            )}
          </LeftNav>
          <RightNav>
            <HoverLi>
              {user ? (
                isAdmin ? (
                  <Menu>
                    <MB>
                      <FaUserAstronaut /> <span>Profile</span>
                    </MB>
                    <MenuList>
                      <Link href={PAGEURL.DASHBOARD} passHref>
                        <ML>
                          <FiPieChart /> View My Perks
                        </ML>
                      </Link>
                      <Link href={PAGEURL.ADMIN_DASHBOARD} passHref>
                        <ML>
                          <FaUserAstronaut /> Admin View
                        </ML>
                      </Link>
                    </MenuList>
                  </Menu>
                ) : (
                  <Link href={PAGEURL.DASHBOARD} passHref>
                    <Button black as="a">
                      <FaUserAstronaut /> <span>View My Perks</span>
                    </Button>
                  </Link>
                )
              ) : (
                <Link href={PAGEURL.LOGIN} passHref>
                  <Button black as="a">
                    <FaUserAstronaut /> <span>Login to Perks</span>
                  </Button>
                </Link>
              )}
            </HoverLi>
          </RightNav>
        </NavbarContent>
      </Container>
      {subNavItems && (
        <CollectionsWrapper>
          <Container>
            <Collections>
              {subNavItems.map((item, index) => (
                <Collection key={index} href={item.url} passHref>
                  <A>{item.name}</A>
                </Collection>
              ))}
            </Collections>
          </Container>
        </CollectionsWrapper>
      )}
    </Header>
  )
}

const Header = styled.header`
  ${tw}

  position: fixed;
  background-color: #fff;
  padding: 0px 0;
  transition: 0.4s;

  border: rgb(247, 141, 32) solid 0;
  border-top-width: 3px;

  left: 0;
  top: 0;
  width: 100%;
  padding: 0px 0;
  transition: 0.4s;
`

const NavbarContent = styled(motion.div)`
  // padding-left: 0.938rem;
  // padding-right: 0.938rem;

  height: 56px;
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
  align-items: center;
  justify-content: space-between;
`

const LogoWrap = styled.a`
  width: 150px;
  display: block;

  img {
    width: 100%;
    padding: 5px 0;
    object-fit: contain;
  }
`

const ButtonStyle = css`
  color: rgb(0, 117, 163);
  font-size: 1.125rem;
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  font-weight: 700;
  background-color: transparent;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  text-decoration: none;

  ${(props) =>
    props.black &&
    css`
      color: rgb(51, 51, 51);
      font-weight: 600;
      font-size: 1rem;
    `}

  svg {
    color: ${(props) => props.theme.colors.primary};
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 5px;
  }

  &:hover {
    color: #fff;
    background-color: rgb(0, 117, 163);
    text-decoration: none;

    svg {
      color: #fff;
    }
  }
`

const MB = styled(MenuButton)`
  ${ButtonStyle}
`

const ML = styled(MenuLink)`
  ${ButtonStyle}

  font-size: 1rem;
  justify-content: flex-start;
  height: auto;
`

const Button = styled.button`
  ${ButtonStyle}
`

const LeftNav = styled.div`
  display: flex;
`

const RightNav = styled.ul`
  display: flex;

  ${(props) => props.theme.media.tablet`
    display: none;
  `}
`

const HomeUl = styled.ul`
  display: flex;
  flex-direction: row;
`

const HomeLi = styled.li`
  & > div {
    position: absolute;
    display: none;
    flex-direction: row;
    row-gap: 0.5rem;
    width: auto;
    z-index: 20;
    border: #fff solid 0;
    border-color: rgb(0 163 224);
    border-bottom-width: 2px;

    & > ul {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border: #fff solid 0;
      border-color: rgb(0 163 224);
      border-bottom-width: 1px;
      border-right-width: 2px;

      &:last-child {
        border-right-width: 0;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    background-color: rgb(0 117 163);

    li {
      font-weight: 500;
    }

    a {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      color: rgb(255 255 255);
      padding-left: 0.938rem;
      padding-right: 0.938rem;
      border-width: 0;
      align-items: center;
      display: flex;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    a.title {
      font-weight: 600;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      svg {
        margin-left: 0.5rem;
        height: 1.5rem;
      }
    }
  }

  &:hover {
    background-color: rgb(0 117 163);
    & > a {
      color: #fff;
    }

    & > div {
      background-color: rgb(0 117 163);
      color: rgb(255 255 255 / var(--tw-text-opacity));
      display: flex;
    }
  }
`

const HoverLi = styled.li`
  position: relative;

  .padded {
    border: #fff solid 0px;
    padding-top: 0.625rem;
    border-top-color: rgb(0 163 224);
    border-top-width: 2px;
    margin-top: 0.625rem;
  }

  ul {
    display: none;
    width: 12rem;
    top: 3.5rem;
    right: 0;
    position: absolute;
    z-index: 20;
    background-color: rgb(0 117 163);
    border: #fff solid 0;
    border-bottom-width: 4px;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-bottom-color: rgb(0 163 224);

    li {
      font-weight: 600;
    }

    a {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      color: rgb(255 255 255);
      padding-left: 0.938rem;
      padding-right: 0.938rem;
      border-width: 0;
      align-items: center;
      display: flex;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    background-color: rgb(0 117 163 / var(--tw-bg-opacity));
    color: rgb(255 255 255 / var(--tw-text-opacity));

    ul {
      display: block;
    }
  }
`

const CollectionsWrapper = styled.div`
  border: 0 solid ${(props) => props.theme.colors.borderGrey};
  border-bottom-width: 1px;
  border-top-width: 1px;
  padding-left: 0.938rem;
  padding-right: 0.938rem;

  ${(props) => props.theme.media.tablet`
    display: none;
  `}
`

const Collections = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  gap: 1rem;
`

const A = styled.a`
  transition: all 0.25s;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  position: relative;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 5px;
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.25s;
  }

  &:focus {
    text-decoration: none !important;
  }

  &:hover {
    transition: all 0.25s;
    text-decoration: none;

    &:after {
      width: 100%;
      transition: all 0.25s;
    }
  }
`

const Collection = styled(Link)``
