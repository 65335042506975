import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const PlanBadge = styled.div`
  background-color: ${props => props.theme.colors.lightGrey};
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 5px;
  font-size: 0.8rem;
  float: right;
`

export const MembershipBadge = ({ organization }) => {
  if (organization && organization.plan_name) {
    return (
      <PlanBadge>{ organization.plan_name }</PlanBadge>
    )
  } else {
    return null
  }
}

MembershipBadge.propTypes = {
  organization: PropTypes.object
}
