import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../theme/button/Button'

const CancelButton = (props) => {
  let classNames = 'action-btn-grey'
  if (props.className) {
    classNames += className
  }
  return (
    <Button
      {...props}
      type="button"
      className={classNames}>{ props.children }</Button>
  )
}

CancelButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string
}

export default CancelButton
