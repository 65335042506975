import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import VisibilitySensor from 'react-visibility-sensor'

const notPrerender = () => {
  return typeof window !== 'undefined'
}

const SENSOR_VARIANTS = {
  show: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0
    }
  },
  hide: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1
    }
  }
}

const FADE_VARIANTS = {
  show: { y: 0, opacity: 1, duration: 20 },
  hide: { y: 0, opacity: 0, duration: 50 }
}

const VisbilityContainer = ({
  variants,
  children,
  lazyLoad = false,
  partialVisibility = true
}) => {
  const [animate, setAnimate] = useState('show')
  const show = (isVisible) => {
    if (isVisible) {
      setAnimate('show')
    }
  }
  const renderChildren = () => {
    if (lazyLoad) {
      return animate === 'show' ? (
        children
      ) : (
        <div style={{ height: '100px' }}></div>
      )
    } else {
      return children
    }
  }

  return (
    <VisibilitySensor
      onChange={show}
      active={notPrerender()}
      partialVisibility={partialVisibility}
    >
      <motion.div
        initial="hide"
        animate={animate}
        variants={variants || SENSOR_VARIANTS}
      >
        {renderChildren()}
      </motion.div>
    </VisibilitySensor>
  )
}

VisbilityContainer.propTypes = {
  children: PropTypes.any,
  lazyLoad: PropTypes.bool,
  variants: PropTypes.object
}

const HeaderPose = (props) => (
  <motion.h1 variants={FADE_VARIANTS} {...props}>
    {props.children}
  </motion.h1>
)

HeaderPose.propTypes = {
  children: PropTypes.any
}

// this is a dumb name...use DivPos going forward
const CommonPose = (props) => (
  <motion.div variants={FADE_VARIANTS} {...props}>
    {props.children}
  </motion.div>
)

CommonPose.propTypes = {
  children: PropTypes.any
}

const DivPose = (props) => (
  <motion.div variants={FADE_VARIANTS} {...props}>
    {props.children}
  </motion.div>
)

DivPose.propTypes = {
  children: PropTypes.object
}

const LinkPose = (props) => (
  <motion.a variants={FADE_VARIANTS} {...props}>
    {props.children}
  </motion.a>
)

LinkPose.propTypes = {
  children: PropTypes.any
}

const ScalePose = (props) => (
  <motion.div
    transition={{ duration: 0.7 }}
    variants={{
      show: { y: 0, opacity: 1, scale: 1, ease: 'easeIn' },
      hide: { y: 100, opacity: 0, scale: 0.8 }
    }}
    {...props}
  >
    {props.children}
  </motion.div>
)

ScalePose.propTypes = {
  children: PropTypes.object
}

export {
  FADE_VARIANTS,
  VisbilityContainer,
  CommonPose,
  DivPose,
  LinkPose,
  ScalePose,
  HeaderPose
}
