import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { inject, observer } from 'mobx-react'
import { ThemeProvider } from 'styled-components'

import Header from '/components/common/Header'
import services from '/components/services'
import { APIURL } from '/constants'
import { Footer } from '/components/theme/footer/Footer'
import { DelinquencyAlert } from '/components/admin/Billing'
import { AcceptTerms } from '/components/advisor/AcceptTerms'
import { Integration } from '/components/integration/LayoutHook'

import { buildTheme, buildGlobalStyle, buildHead } from '/styles/theme'

const DefaultLayout = inject('store')(
  observer(({ store, children, siteData }) => {
    const { data: bannersData, isLoading } = useQuery(
      'bannersData',
      () => services.get_v2(APIURL.API_GET_BANNERS),
      {
        retry: 1,
        refetchOnWindowFocus: false
      }
    )

    if (!siteData) {
      return null
    }

    const theme = buildTheme(siteData)
    const GlobalStyle = buildGlobalStyle(siteData)
    const head = buildHead(siteData)

    return (
      <ThemeProvider theme={theme}>
        {head}
        <GlobalStyle />
        <div key="mainLayout" id="mainLayout">
          {store.navbar.showHeader && (
            <Header key="header" siteData={siteData} />
          )}
          <div key="body" id="body">
            {children}
          </div>
          <DelinquencyAlert />
          <AcceptTerms />

          <Integration siteData={siteData} />
          {store.navbar.showFooter && !isLoading && (
            <Footer
              key="footer"
              footerItems={siteData.footer_items}
              siteData={siteData}
              name={siteData.name}
              address={siteData.address}
              city={siteData.city}
              state={siteData.state}
              zip={siteData.zip}
              phone={siteData.phone}
              support_email={siteData.support_email}
              disclaimer={siteData.disclaimer}
              facebook_url={siteData.facebook_url}
              twitter_url={siteData.twitter_url}
              instagram_url={siteData.instagram_url}
              linkedin_url={siteData.linkedin_url}
              youtube_url={siteData.youtube_url}
              terms_link={siteData.terms_link}
              privacy_link={siteData.privacy_link}
              isEnterprise={store.isEnterprise()}
              displayBranding={siteData.display_branding}
              banners={
                bannersData?.response?.length > 0 ? bannersData.response : []
              }
            />
          )}
        </div>
      </ThemeProvider>
    )
  })
)

DefaultLayout.propTypes = {
  children: PropTypes.any
}

export default DefaultLayout
