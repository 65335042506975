import React, { useState, useContext } from 'react'

export const SearchContext = React.createContext(null)
SearchContext.displayName = 'SearchContext'

export function useSearch() {
  return useContext(SearchContext)
}

export const SearchProvider = ({ children }) => {
  const [showSearch, setShowSearch] = useState(false)
  const [search, setSearch] = useState('')
  return (
    <SearchContext.Provider
      value={{
        showSearch,
        setShowSearch,
        search,
        setSearch
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
