import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Marketplace.module.scss'

const Section = (props) => {
  return (
    <div className={styles.section_tiles}>
      <section>
        <div
          className={classNames(styles.tiles_container)}
          data-title={props.name}
        >
          {props.children}
        </div>
      </section>
    </div>
  )
}

Section.propTypes = {
  store: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.any
}

export default Section
