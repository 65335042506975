import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  z-index: 10;
  overflow: visible;

  ${(props) => props.theme.media.phone`
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    overflow: hidden;
    position: relative;
  `}
`
