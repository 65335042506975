import styled, { keyframes } from 'styled-components'

const TileWrapper = styled.div`
  padding: 2px 10px 30px 10px;
  width: ${(props) => props.tileWidth || '33%'};
  height: ${(props) => props.tileHeight || 'auto'};
  position: relative;

  a {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    background-color: transparent;
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 992px) {
    padding: 2px 10px 30px 10px;
    width: 50%;
  }

  @media only screen and (max-width: 767px) {
    padding: 2px 0 30px 0;
    width: 100%;
  }
`

const TileStyle = styled.button`
  border-radius: 5px;
  transition: all 0.25s;
  border: #e6e6e6 solid 1px;
  box-shadow: none;
  padding: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  background: ${(props) =>
    props.theme.colors.nav_background_color || props.theme.colors.white};

  &:hover {
    transition: all 0.25s;
    box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.15);
  }
`

const BottomDescription = styled.div`
  flex: 1 1 auto;
  text-overflow: ellipsis;
  padding: 5px;
  margin-bottom: 15px;
  width: 100%;
`

const DiscountContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    width: 100%;
  }
`

const DiscountTag = styled.div`
  color: ${(props) => props.theme.colors.primar};
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.colors.primary} solid 1px;
  border-radius: 4px;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 20px 21px;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
`

const BottomDiscount = ({ children }) => {
  return (
    <DiscountContainer>
      <DiscountTag>{children}</DiscountTag>
    </DiscountContainer>
  )
}

const TileName = styled.div`
  padding: 5px 5px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
`

const LoadingTileSpacer = styled.div`
  width: 100%;
  height: 33%;
  min-height: 150px;
  max-height: 275px;

  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    flex: 1 1 auto;
    height: 100%;
  }
`

const TileTitle = styled(LoadingTileSpacer)`
  text-decoration: none;
  overflow: hidden;
  font-size: 5rem;
  color: ${(props) => props.theme.colors.success};
`

const TileIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const rotate = keyframes`
  from {
    background-position: 100% 100%;
  }

  to {
    background-position: -100% -100%;
  }
`

const LoadingText = styled.div`
  background: linear-gradient(90deg, #fafafa 35%, #eee 50%, #fafafa 65%);
  background-size: 200% 200%;
  animation: ${rotate} 1s ease-in-out infinite;
  font-size: inherit;
  margin-bottom: 5px;
`

const SavedDecorator = styled.div`
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 160px;
  height: 160px;
  clip-path: circle(50% at 50% 50%);
  background: #2289e482;
  z-index: 2;
  display: flex;
  transform: rotate(-30deg);
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  padding: 0 10px 20px 0;
`

const OwnerContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  p {
    font-size: 18px;
    color: ${(props) => props.theme.colors.grey};
  }
`

const ExploreButton = styled.button`
  border: none;
  padding: 20px;
  background-color: ${(props) => props.color};
  color: white;
  z-index: 2;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.color}aa;
  }
`

export {
  TileWrapper,
  TileStyle,
  BottomDescription,
  BottomDiscount,
  TileName,
  TileTitle,
  TileIcon,
  LoadingText,
  SavedDecorator,
  OwnerContainer,
  ExploreButton
}
