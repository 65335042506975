import { observable } from 'mobx'

export default class {
  id = null;
  @observable name = null;
  @observable crm_enterprise_id = null;
  @observable slug = null;
  @observable type = null;
  @observable domain = null;
  @observable logo_url = null;
  @observable address = null;
  @observable city = null;
  @observable state = null;
  @observable zip = null;
  @observable phone = null;
  @observable display_domain = null;
  @observable help_link = null;
  @observable home_title = null;
  @observable home_description = null;
  @observable facebook_url = null;
  @observable youtube_url = null;
  @observable twitter_url = null;
  @observable linkedin_url = null;
  @observable instagram_url = null;
  @observable default_coupon = null;
  @observable disclaimer = null;
  @observable exchange_link_text = null;
  @observable home_welcome_image_url = null;
  @observable home_explore_text = null;
  @observable home_show_calculator = null;
  @observable home_calendly_link = null;
  @observable powered_by_url = null;
  @observable login_modal_url = null;

  @observable product_ids = [];

  constructor (json) {
    this.id = json.id
    this.name = json.name
    this.crm_enterprise_id = json.crm_enterprise_id
    this.slug = json.slug
    this.domain = json.domain
    this.logo_url = json.logo_url
    this.type = json.type
    this.address = json.address
    this.city = json.city
    this.state = json.state
    this.zip = json.zip
    this.phone = json.phone
    this.display_domain = json.display_domain
    this.help_link = json.help_link
    this.home_title = json.home_title
    this.home_description = json.home_description
    this.facebook_url = json.facebook_url
    this.youtube_url = json.youtube_url
    this.twitter_url = json.twitter_url
    this.linkedin_url = json.linkedin_url
    this.instagram_url = json.instagram_url
    this.default_coupon = json.default_coupon
    this.disclaimer = json.disclaimer
    this.exchange_link_text = json.exchange_link_text
    this.home_welcome_image_url = json.home_welcome_image_url
    this.home_explore_text = json.home_explore_text
    this.home_show_calculator = json.home_show_calculator
    this.home_calendly_link = json.home_calendly_link
    this.powered_by_url = json.powered_by_url
    this.login_modal_url = json.login_modal_url
    this.product_ids = json.product_ids
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
