import { observable } from 'mobx'
import { ERROR_MSG, CONSTS } from '../../constants'

/**
 * Author: Hanamantraya R
 * Class Name: ChangePasswordValidate
 * Description: Validate the login inputs data
 */
export default class {
  @observable value = ''
  @observable errors = []

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: String(name)
   * @Param: String(label)
   * @Param: Boolean(required)
   * @Param: String(value)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (name, label, required = true, value = '') {
    this.name = name
    this.label = label
    this.required = required
    this.value = value
  }

  /**
   * @Author: Hanamantraya R
   * @Method: validate
   * @Param: Empty
   * @Return: Void
   * Description: Validates the user inputs if error return valid error message else nothing
   */
  validate (old_pwd = '') {
    this.errors.replace([])
    switch (this.name) {
      case 'old_password':
        if (!this.value.length && this.required) {
          this.errors.push(ERROR_MSG.REQ_LOGIN_PASSWORD)
        }
        break
      case 'new_password':
        if (!this.value.length && this.required) {
          this.errors.push(ERROR_MSG.ERR_CHANGE_PASSWORD_REQ)
        } else if (this.value.length < 6 || this.value.length > 60 || !CONSTS.PASSWORD_REGEXP.test(this.value)) {
          this.errors.push(ERROR_MSG.ERR_CHANGE_PASSWORD_NEW_PWD)
        }
        break
      case 'confirm_password':
        if (!this.value.length && this.required) {
          this.errors.push(ERROR_MSG.ERR_CHANGE_PASSWORD_CONFIRM_REQ)
        } else if (old_pwd && this.value && this.value !== old_pwd) {
          this.errors.push(ERROR_MSG.ERR_CHANGE_PASSWORD_NOT_MATCH)
        }
        break
      default:break
    }
  }
}
