import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { APIURL } from '/constants'
import { Tile } from '/components/marketplace/Tile/Tile'
import Section from '/components/marketplace/Section'
import { BannersContainer } from '/components/theme/banner-ads/BannersContainer'
import services from '/components/services'
import { FormWrapper } from '/components/theme/default/base'
import { HtmlInput } from '/components/admin/page/page_builder/form-inputs/Html'
import MarketplaceLoading from '/components/marketplace/MarketplaceLoading'

import ElMarketplaceHero from './ElMarketplaceHero'

export const ElMarketplaceDisplayForm = ({
  title,
  subtitle,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <HtmlInput
        value={title}
        name="title"
        label="Title"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={subtitle}
        name="subtitle"
        label="Subtitle"
        setCurrentComponent={setCurrentComponent}
      />
      <p>
        <small>
          Use &quot;Bold&quot; to change the text to the primary color and
          &quot;Italicise&quot; to add the underline style.
        </small>
      </p>
    </FormWrapper>
  )
}

const MarketplaceEmpty = () => <H2>No products here yet.</H2>

const ElMarketplaceDisplay = ({ title, subtitle }) => {
  const router = useRouter()
  const expires = router.query.expires || ''
  const [category, setCategory] = useState(router.query.c || '')
  const [search, setSearch] = useState(router.query.q || '')

  useEffect(() => {
    const c = router.query.c || ''
    if (category !== c) {
      setCategory(c)
    }
    const s = router.query.q || ''
    if (search !== s) {
      setSearch(s)
    }
  }, [category, search, router, setCategory, setSearch])

  const { data: bannersData } = useQuery('banners', () =>
    services.get_v2(APIURL.API_GET_BANNERS)
  )

  let url = `${APIURL.API_MARKETPLACE_SEARCH}?type=${category}&search=${search}`
  if (expires) {
    url += `&expires=1`
  }
  const { data: productData } = useSWR(url, services.get_v2, {
    keepPreviousData: true
  })
  const products = productData?.response

  const renderProducts = (products) => {
    return products?.map((p, i) => (
      <Tile id={p.slug} product={p} key={p.id} index={i} />
    ))
  }

  return (
    <div>
      <ElMarketplaceHero
        title={title}
        subtitle={subtitle}
        search={search}
        setSearch={setSearch}
        category={category}
      />
      <div>
        {bannersData && (
          <BannersContainer
            banners={bannersData.response}
            placement="Marketplace"
          />
        )}
        <div className="container-fluid">
          <Section>
            {!products ? (
              <MarketplaceLoading />
            ) : !products || products.length === 0 ? (
              <MarketplaceEmpty />
            ) : (
              renderProducts(products)
            )}
          </Section>
        </div>
      </div>
    </div>
  )
}

const H2 = styled.h2`
  text-align: center;
  margin: 0 auto;
`

export default ElMarketplaceDisplay
