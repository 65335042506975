import React from 'react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Uppy from '@uppy/core'
import PropTypes from 'prop-types'
import { Dashboard, useUppy } from '@uppy/react'

export const UppyFileUpload = ({
  onlyImages = false,
  multiple = false,
  maxFileSize = 10485760,
  handleChange
}) => {
  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize,
        maxNumberOfFiles: multiple ? null : 1,
        allowedFileTypes: onlyImages
          ? ['image/*', '.jpg', '.jpeg', '.png', '.gif']
          : null
      }
    }).on('error', () => {
      console.error('url')
    })
  })

  uppy.on('file-added', async (result) => {
    const data = result.data // is a Blob instance
    if (data.type.includes('image')) {
      const url = URL.createObjectURL(data)
      const image = new Image()
      image.src = url
      image.onload = () => {
        URL.revokeObjectURL(url)
        handleChange(result.data, image.width, image.height)
      }
    } else {
      handleChange(data)
    }
  })

  return (
    <div style={{ display: 'block' }}>
      <Dashboard
        hideUploadButton={true}
        proudlyDisplayPoweredByUppy={false}
        uppy={uppy}
        height={200}
        width={1100}
      />
    </div>
  )
}

UppyFileUpload.propTypes = {
  onlyImages: PropTypes.bool,
  multiple: PropTypes.bool,
  maxFileSize: PropTypes.number,
  handleChange: PropTypes.func
}
