import React, { useEffect } from 'react'
import PageTitle from './common/PageTitle'
import DefaultLayout from './layouts/DefaultLayout'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { inject, observer } from 'mobx-react'
import LoadingButton from './common/LoadingButton'
import services from './services'
import axios from 'axios'

const AuthWall = inject('store')(
  observer(({ store }) => {
    useEffect(() => {
      store.updateNav(false, false)
      return function cleanup() {
        store.updateNav(true, true)
      }
    })
    const { siteData } = store
    const title = `${siteData.get('name')} - Password Required`
    const renderTitle = () => {
      if (siteData.get('logo_url')) {
        return <img className="img-responsive" src={siteData.get('logo_url')} />
      } else {
        return <h1 className="text-center">{siteData.get('name')}</h1>
      }
    }
    const renderMessage = () => {
      if (siteData.get('password_enabled')) {
        return (
          <div className="text-center message">
            <div
              dangerouslySetInnerHTML={{
                __html: siteData.get('password_message')
              }}
            />
          </div>
        )
      } else {
        return <div className="text-center message">Coming soon!</div>
      }
    }
    return (
      <div className="authwall">
        <PageTitle title={title} />
        <div className="panel panel-primary">
          <div className="well-container authwall-container">
            <Formik
              initialValues={{ password: '' }}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                setSubmitting(true)
                try {
                  const response = await axios.post('/api/auth/', {
                    password: values.password
                  })
                  if (response.status === 200) {
                    window.location.reload()
                  }
                } catch (e) {
                  setErrors({ password: 'Invalid password' })
                  setSubmitting(false)
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  {renderTitle()}
                  {renderMessage()}
                  <h2 className="text-center">Enter using password</h2>
                  <div>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="form-control"
                      autoFocus
                    />
                    <ErrorMessage name="password" component="div" />
                  </div>
                  <div className="text-center">
                    <LoadingButton
                      text="Submit"
                      type="submit"
                      disabled={isSubmitting}
                      className="action-btn-gld ml-none"
                      isLoading={isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  })
)

AuthWall.Layout = DefaultLayout

export default AuthWall
