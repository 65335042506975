import React from 'react'
import styled, { css } from 'styled-components'

export const TopOffset = styled.div`
  padding-top: var(--chalice-navbar-height);
`

export const H1 = styled.h1`
  font-size: 2rem;
  font-weight: 600;

  strong {
    font-weight: 500;
  }
`

export const H2 = styled.h2`
  padding: 0 20px 10px 20px;
  ${(props) =>
    props.noPadding ? 'padding: 0 0 10px 0;' : 'padding: 0 20px 10px 20px;'}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) => (props.center ? 'justify-content: center;' : '')}
  align-items: center;
`

export const H3 = styled.h2`
  color: ${(props) =>
    props.theme.colors.font_color || props.theme.colors.black};
  font-size: 1.1rem;
`

export const Divider = styled.div`
  border: #e6e6e6 solid 1px;
  border-color: ${(props) => (props.color ? props.color : '#e6e6e6')};
  border-width: 1px 0 0 0;
  height: 1px;
`

export const Group = styled.div`
  margin: 0 0 40px 0;
  ${(props) =>
    props.navBarSticky &&
    css`
      position: sticky;
      top: 120px;
    `}
`

export const GroupContent = styled.div`
  padding: 20px;
  ${(props) => (props.center ? 'text-align: center;' : '')}
  ${(props) =>
    props.border &&
    css`
      border: #e6e6e6 solid 1px;
      border-radius: 5px;
    `}
`

export const Post = styled.div`
  &:not(:first-child) {
    margin-top: 10px;
  }
  padding-bottom: 10px;
`

export const UserRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ProfileImg = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  border: 0 solid #ddd;
  overflow: hidden;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  svg {
    max-width: 100%;
    min-width: 30px;
    min-height: 30px;
  }
`

export const PageWrapper = styled.div`
  padding: 0 0 30px 0;
`

export const ContentWrapper = styled.div`
  padding: 50px 0;
  background-color: ${(props) => props.theme.colors.white};
  /* box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.15); */
  z-index: 20;

  ${(props) => props.theme.media.phone`
    box-shadow: none;
    padding: 50px 0;
  `}
`

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${(props) => (props.top ? 'top' : 'center')};
  gap: 20px;

  ${(props) => props.theme.media.tablet`
    flex-direction: column;
  `}
`

export const Col = styled.div`
  ${(props) => (props.center ? 'text-align: center;' : '')}
  width: ${(props) => (props.width ? props.width : '50%')};

  ${(props) => props.theme.media.tablet`
    width: 100%;
  `}
`
