import React from 'react'
import styled from 'styled-components'

import { VisbilityContainer } from '/components/home/Animations'
import { WPENGINE_HOST } from '/components/theme/ffr/theme'

const Breadcrumb = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 300px;
  top: 70px;
  position: relative;
`

const BreadcrumbText = styled.div`
  margin: 20px auto 0;
  position: relative;
  width: 900px;
  max-width: 100%;
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
  text-align: center;
`

const H2 = styled.h2`
  color: #175d98;
  font-weight: 800;
  font-family: 'Inter', sans-serif !important;
  line-height: 1.2;
  font-size: 44px;
  padding: 0;
  margin: 0 0 25px;
  text-align: center;

  p {
    padding: 0;
    margin: 0;
  }
`

const List = styled.ul`
  margin-top: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    height: 15px;
    width: 15px;
  }

  li {
    color: #2870a3;
    font-family: 'pt-serif', sans-serif;
    font-size: 13px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      color: #2870a3;
      align-items: center;
    }

    small {
      padding: 0 10px;
    }
  }
`

const FfrMarketplaceHero = ({ title, image }) => {
  return (
    <VisbilityContainer>
      <Breadcrumb imageUrl={image?.media_center?.media} />
      <BreadcrumbText>
        <H2 dangerouslySetInnerHTML={{ __html: title }} />
        <List>
          <li>
            <a href={WPENGINE_HOST}>
              <svg
                className="svg-inline--fa fa-house"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="house"
                role="img"
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M567.5 229.7C577.6 238.3 578.9 253.4 570.3 263.5C561.7 273.6 546.6 274.9 536.5 266.3L512 245.5V432C512 476.2 476.2 512 432 512H144C99.82 512 64 476.2 64 432V245.5L39.53 266.3C29.42 274.9 14.28 273.6 5.7 263.5C-2.875 253.4-1.634 238.3 8.473 229.7L272.5 5.7C281.4-1.9 294.6-1.9 303.5 5.7L567.5 229.7zM144 464H192V312C192 289.9 209.9 272 232 272H344C366.1 272 384 289.9 384 312V464H432C449.7 464 464 449.7 464 432V204.8L288 55.47L112 204.8V432C112 449.7 126.3 464 144 464V464zM240 464H336V320H240V464z"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <small>/</small>
          </li>
          <li>Resources</li>
        </List>
      </BreadcrumbText>
    </VisbilityContainer>
  )
}

export default FfrMarketplaceHero
