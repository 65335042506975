import { observable } from 'mobx'

export default class {
  id = null;
  @observable product_name = null;
  @observable product_size = null;
  @observable can_user_hide = null;
  @observable sso_url = null;
  @observable auth_type = null;
  @observable product_price = null;
  @observable is_image_uploaded = null;
  @observable product_description = null;
  @observable is_custom_image = null;
  @observable product_logo = null;
  @observable product_slug_id = null;
  @observable product_height = null;
  @observable product_product_tag_maps = null;
  @observable product_product_review_maps = null;
  @observable product_product_faq_maps = null;
  @observable product_proxies = null;
  @observable fin_product_tags = null;
  @observable product_documents = null;
  @observable fin_partners = null;
  @observable is_deleted = null;
  @observable benefits = null;
  @observable chalice_advantage = null;
  @observable marketplace_image_url = null;
  @observable media_url = null;
  @observable description_short = null;
  @observable tagline = null;
  @observable deal = null;
  @observable slug = null;
  @observable register_text = null;
  @observable action_type = null;
  @observable action_url = null;
  @observable action_text = null;
  @observable published_status = null;
  @observable survey_questions = null;
  @observable arrangements = null;
  @observable enterprise_association = null;
  @observable enterprise = null;
  @observable pricing_tier = null;
  @observable approval_process = null;
  @observable integration_time = null;
  @observable action_tier = null;

  constructor (json) {
    this.id = json.id
    this.partner = json.partner
    this.product_name = json.product_name
    this.product_size = json.product_size
    this.can_user_hide = json.can_user_hide
    this.sso_url = json.sso_url ? json.sso_url : ''
    this.auth_type = json.auth_type ? json.auth_type : ''
    this.product_price = json.product_price
    this.is_image_uploaded = json.is_image_uploaded
    this.product_description = json.product_description
    this.is_custom_image = json.is_custom_image
    this.product_logo = json.product_logo
    this.product_slug_id = json.product_slug_id
    this.product_height = json.product_height ? json.product_height : '0px'
    this.product_product_tag_maps = json.product_product_tag_maps
    this.product_product_review_maps = json.product_product_review_maps
    this.product_product_faq_maps = json.product_product_faq_maps
    this.product_proxies = json.product_proxies
    this.fin_product_tags = json.fin_product_tags
    this.product_documents = json.product_documents
    this.fin_partners = json.fin_partners
    this.is_deleted = json.is_deleted
    this.benefits = json.benefits ? json.benefits : ''
    this.chalice_advantage = json.chalice_advantage ? json.chalice_advantage : ''
    this.marketplace_image_url = json.marketplace_image_url ? json.marketplace_image_url : ''
    this.media_url = json.media_url ? json.media_url : ''
    this.description_short = json.description_short ? json.description_short : ''
    this.tagline = json.tagline ? json.tagline : ''
    this.deal = json.deal ? json.deal : ''
    this.slug = json.slug ? json.slug : ''
    this.category = json.category ? json.category : ''
    this.register_text = json.register_text ? json.register_text : ''
    this.action_type = json.action_type ? json.action_type : ''
    this.action_url = json.action_url ? json.action_url : ''
    this.lead_route = json.lead_route ? json.lead_route : ''
    this.action_text = json.action_text ? json.action_text : ''
    this.published_status = json.published_status ? json.published_status : ''
    this.survey_questions = json.survey_questions
    this.arrangements = json.arrangements
    this.enterprise_association = json.enterprise_association
    this.enterprise_id = json.enterprise_id
    // WTF happened? I thought we switched to created_by_group_id
    this.enterprise = json.enterprise
    this.pricing_tier = json.pricing_tier
    this.approval_process = json.approval_process
    this.integration_time = json.integration_time
    this.action_tier = json.action_tier
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
