import { observable } from 'mobx'

export default class {
  id = null;
  @observable createdAt = null;
  @observable url = null;
  @observable key = null;
  @observable display = null;
  @observable product_id = null;
  @observable product = null;
  @observable banner_media_url = null;

  constructor (json) {
    this.id = json.id
    this.createdAt = new Date(json.createdAt)
    this.url = json.url
    this.key = json.key
    this.display = json.display
    this.product_id = json.product_id
    this.product = json.product
    this.banner_media_url = json.banner_media_url
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
