import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { inject, observer } from 'mobx-react'
import { Img } from 'react-image'

import { PAGEURL } from '../../constants'
import { Header } from '../theme/header/Header'

const LocalHeader = inject('store')(
  observer(({ store, siteData }) => {
    const renderLogo = () => {
      if (siteData) {
        const branding = siteData.logo_media_center ? (
          <Img
            className="header-logo"
            src={siteData.logo_media_center.media}
            alt={siteData.name}
          />
        ) : (
          <span>{siteData.name}</span>
        )
        if (siteData.layout_template === 'chalicenetwork') {
          return (
            <a href="/" className="logo-link">
              {branding}
            </a>
          )
        } else {
          if (siteData.home_link) {
            return (
              <a href={siteData.home_link} className="logo-link">
                {branding}
              </a>
            )
          } else {
            return (
              <Link href="/">
                <a href="/" className="logo-link">
                  {branding}
                </a>
              </Link>
            )
          }
        }
      } else {
        return null
      }
    }

    const { sessionData, organization_data } = store
    const { userData, idpData } = sessionData
    const isLoading = sessionData.pageLoader

    const logo = renderLogo()
    const user = userData.email ? userData : null
    let navItems = siteData.nav_items
    if (navItems) {
      navItems = JSON.parse(navItems.content)
    }
    let subNavItems = siteData.subnav_items
    if (subNavItems) {
      subNavItems = JSON.parse(subNavItems.content)
    }
    return (
      <Header
        key="header"
        logo={logo}
        isLoading={isLoading}
        navItems={navItems}
        subNavItems={subNavItems}
        user={user}
        idpData={idpData}
        organization={organization_data}
        siteData={siteData}
        loginUrl={siteData.login_url || PAGEURL.LOGIN}
        loginText={siteData.login_text || 'Login'}
        signupUrl={siteData.signup_url || PAGEURL.SIGNUP}
        signupText={siteData.signup_text || 'Join the Network'}
        pricingTab={siteData.pricing_tab_active}
        allowSignup={siteData.allow_signup}
        allowBilling={siteData.allow_billing}
        isEnterprise={siteData.layout_template !== 'chalicenetwork'}
        containerClassName={
          store.navbar.useContainer ? 'container' : 'admin-container'
        }
      />
    )
  })
)

LocalHeader.propTypes = {
  admin: PropTypes.bool,
  store: PropTypes.object,
  siteData: PropTypes.object
}

export default LocalHeader
