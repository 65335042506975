import React, { useState } from 'react'
import PropTypes from 'prop-types'
import services from '../../services'
import { inject, observer } from 'mobx-react'
import LoadingButton from '../../common/LoadingButton'
import CancelButton from '../../common/CancelButton'
import { APIURL } from '../../../constants'
import { Formik, Form, Field } from 'formik'
import FormikField from '../../forms/FormikField'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'
import { UppyFileUpload } from '../../form-inputs/UppyFileUpload'
import styled from 'styled-components'

toast.configure()

const AddMediaForm = inject('store')(
  observer(
    ({
      toggle,
      reload,
      setCurrentMedia,
      onlyImages = false,
      multiple = false,
      isPrivate = false,
      noDetails = false
    }) => {
      const [isLoading, setIsLoading] = useState(false)
      const [isImage, setIsImage] = useState(false)

      const queryClient = useQueryClient()

      const handleSave = async (values) => {
        try {
          const body = new window.FormData()
          setIsLoading(true)
          body.set('name', values.name)
          body.set('media', values.media)
          body.set('document', values.document)
          body.set('description', values.description)
          body.set('width', values.width)
          body.set('height', values.height)
          body.set('type', values.type)
          body.set('extension', values.extension)
          body.set('size', values.size)
          if (isPrivate) {
            body.set('is_private', true)
          }
          const res = await services.post_v2(
            APIURL.API_ADMIN_MEDIA_CENTER,
            body
          )
          if (res.statusCode === 200) {
            toast('Media successfully created')
            toggle()
            if (setCurrentMedia) {
              setCurrentMedia(res.response)
            }
            if (reload) {
              reload()
            }
          } else {
            toast.error('Media creation failed')
          }
          setIsLoading(false)
        } catch (e) {
          console.error(e)
        }
      }

      return (
        <Formik
          initialValues={{
            name: '',
            description: '',
            width: 0,
            height: 0,
            type: '',
            extension: '',
            size: 0,
            media: null,
            document: null
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required')
          })}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSave(values)
            setSubmitting(false)
          }}
          handleSubmit={(e) => e.preventDefault()}
        >
          {({ dirty, values, handleSubmit }) => {
            return (
              <MediaForm onSubmit={handleSubmit}>
                {!noDetails && (
                  <>
                    <FormikField name="name" label="Name" required={true}>
                      <InputField
                        className="form-control"
                        name="name"
                        type="text"
                      />
                    </FormikField>
                    <FormikField name="description" label="Description">
                      <Field
                        className="form-control"
                        name="description"
                        as="textarea"
                        style={{ borderRadius: '3px' }}
                      />
                    </FormikField>
                  </>
                )}
                <FormikField name="media" label="10 Mb max">
                  <Field className="form-control" name="media" type="text">
                    {({ field, form }) => (
                      <UppyFileUpload
                        multiple={multiple}
                        onlyImages={onlyImages}
                        handleChange={(e, width, height) => {
                          const [type, extension] = e.type.split('/')
                          field.onChange(field.name)
                          form.setFieldValue('type', type)
                          form.setFieldValue('extension', extension)
                          form.setFieldValue('size', e.size)
                          if (noDetails) {
                            form.setFieldValue('name', e.name)
                          }
                          if (type === 'image') {
                            setIsImage(true)
                            form.setFieldValue('media', e)
                            form.setFieldValue('width', width)
                            form.setFieldValue('height', height)
                          } else {
                            form.setFieldValue('document', e)
                          }
                        }}
                      />
                    )}
                  </Field>
                </FormikField>
                <div className="text-right clearfix">
                  <CancelButton type="button" onClick={() => toggle()}>
                    Close
                  </CancelButton>
                  <LoadingButton
                    text="Add"
                    className="action-btn-gld"
                    isLoading={isLoading}
                    disabled={!dirty}
                    type="submit"
                  />
                </div>
              </MediaForm>
            )
          }}
        </Formik>
      )
    }
  )
)

AddMediaForm.propTypes = {
  store: PropTypes.object
}

const MediaForm = styled(Form)`
  padding: 30px;
  label {
    color: hsl(0, 0%, 49%);
  }
`

const InputField = styled(Field)`
  border-radius: 3px;
`

export default AddMediaForm
