import { observable } from 'mobx'

/**
 * Author: Hanamantraya R
 * Class Name: ObjPages
 * Description: Observation Class of Pages.
 */
export default class {
  id = null;
  @observable page_name = null;
  @observable page_url = null;
  @observable left_panel_option_tab = null;
  @observable page_opens_in_new_tab = null;
  @observable page_order = null;
  @observable rss_feed_id = null;
  @observable page_product_widget_maps = null;

  /**
   * @Author: Hanamantraya R
   * @Method: constructor
   * @Param: Object(params)
   * @Return: Void
   * Description: Nothing return
   */
  constructor (json) {
    this.id = json.id
    this.page_name = json.page_name
    this.page_url = json.page_url
    this.left_panel_option_tab = json.left_panel_option_tab
    this.page_opens_in_new_tab = json.page_opens_in_new_tab
    this.page_order = json.page_order
    this.rss_feed_id = json.rss_feed_id
    this.page_product_widget_maps = json.page_product_widget_maps ? json.page_product_widget_maps : []
  }

  /**
   * @Author: Hanamantraya R
   * @Method: jsonToArray
   * @Param: Object(json)
   * @Return: Array
   * Description: Return array of objects
   */
  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(json)
   * @Return: Object
   * Description: Return  objects
   */
  static constructFromJSON (json) {
    return new this(json)
  }

  /**
   * @Author: Hanamantraya R
   * @Method: constructFromJSON
   * @Param: Object(entries)
   * @Return: Object
   * Description: Update the details of particular record
   */
  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
