import React from 'react'
import styled from 'styled-components'

import { tw } from './theme'

const FOOTER_IMAGE_PATH =
  'https://www.earthlink.net/_next/image/?url=%2Fimages%2Ffooter.webp&w=256&q=75'

export const EarthlinkFooter = () => {
  const now = new Date()
  const year = now.getFullYear()
  return (
    <Footer id="footer">
      <FooterContainer>
        <div className="flex flex-wrap justify-between sm-pt-desktop sm-pb-desktop pt-0 pb-30 sm-px-15 font-Montserrat px-30">
          <div className="smd-w-auto w-full">
            <div role="button" tabIndex="0">
              <p className=" mb-0 text-white font-semibold mt-6 sm-mt-30 smd-mt-0 flex justify-between items-center cursor-text text-18">
                <span>Internet</span>
                <svg
                  className="w-6 h-6 smd-hidden block transition-all  rotate-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
              <ul className="text-16 smd-block  el-hidden">
                <li>
                  <a
                    href="https://earthlink.net/internet/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Internet
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/internet/fiber-internet/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Fiber Internet
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/internet/wireless-home-internet/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Wireless Home Internet
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/internet/business-internet/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Business Internet
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/internet-near-me/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Internet Near Me
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/internet/satellite-internet/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Satellite Internet
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/internet/rural-internet/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Rural Internet
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="smd-w-auto w-full">
            <div role="button" tabIndex="0">
              <p className=" mb-0 text-white font-semibold mt-6 sm-mt-30 smd-mt-0 flex justify-between items-center cursor-text text-18">
                <span>Resources</span>
                <svg
                  className="w-6 h-6 smd-hidden block transition-all  rotate-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
              <ul className="text-16 smd-block  el-hidden">
                <li>
                  <a
                    href="https://earthlink.net/resources/internet-speed-test/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Internet Speed Test
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/resources/bandwidth-calculator/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Bandwidth Calculator
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/resources/fiber-vs-cable-internet/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Fiber vs. Cable
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/resources/ultimate-guide-to-the-internet/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Ultimate Guide to the Internet
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/blog/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/resources/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    All Resources
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="smd-w-auto w-full">
            <div role="button" tabIndex="0">
              <p className=" mb-0 text-white font-semibold mt-6 sm-mt-30 smd-mt-0 flex justify-between items-center cursor-text text-18">
                <span>EarthLink</span>
                <svg
                  className="w-6 h-6 smd-hidden block transition-all  rotate-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
              <ul className="text-16 smd-block  el-hidden">
                <li>
                  <a
                    href="https://earthlink.net/about-us/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/about-us/contact/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/media/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Media
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/about-us/partners/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Partners
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/about-us/careers/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Careers
                  </a>
                </li>
                <li>
                  <a
                    href="https://earthlink.net/tcs/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="smd-w-auto w-full">
            <div role="button" tabIndex="0">
              <p className=" mb-0 text-white font-semibold mt-6 sm-mt-30 smd-mt-0 flex justify-between items-center cursor-text text-18">
                <span>Help</span>
                <svg
                  className="w-6 h-6 smd-hidden block transition-all  rotate-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
              <ul className="text-16 smd-block  el-hidden">
                <li>
                  <a
                    href="https://help.earthlink.net/portal/en/home"
                    className="text-16px text-white underline pb-px inline-block"
                    rel="nofollow"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="smd-block el-hidden ">
              <h3 className=" text-18 text-white font-semibold mt-6 mb-2 flex justify-between items-center">
                <span>Follow EarthLink</span>
              </h3>
              <ul className="flex items-center">
                <li className="inline-flex justify-center items-center bg-white w-9 h-9 rounded-full mx-1">
                  <a href="https://www.facebook.com/earthlink">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-siteftblack fill-elnk-black-600 w-6 p-1"
                      viewBox="0 0 320 512"
                    >
                      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                    </svg>
                  </a>
                </li>
                <li className="inline-flex justify-center items-center bg-white w-9 h-9 rounded-full mx-1">
                  <a href="https://twitter.com/earthlink">
                    <svg
                      className="text-siteftblack fill-elnk-black-600 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"></path>
                    </svg>
                  </a>
                </li>
                <li className="inline-flex justify-center items-center bg-white w-9 h-9 rounded-full mx-1">
                  <a href="https://www.linkedin.com/company/earthlink/">
                    <svg
                      className="text-siteftblack fill-elnk-black-600 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                    </svg>
                  </a>
                </li>
                <li className="inline-flex justify-center items-center bg-white w-9 h-9 rounded-full mx-1">
                  <a href="https://www.youtube.com/c/EarthLinkOrbit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-siteftblack fill-elnk-black-600 w-5"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="smd-w-auto w-full">
            <div role="button" tabIndex="0">
              <p className=" mb-0 text-white font-semibold mt-6 sm-mt-30 smd-mt-0 flex justify-between items-center cursor-text text-18">
                <span>Account</span>
                <svg
                  className="w-6 h-6 smd-hidden block transition-all  rotate-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
              <ul className="text-16 smd-block  el-hidden">
                <li>
                  <a
                    href="https://myaccount.earthlink.net/"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    My Account
                  </a>
                </li>
                <li>
                  <a
                    href="https://webmail1.earthlink.net/login"
                    className="text-16px text-white underline pb-px inline-block"
                  >
                    WebMail
                  </a>
                </li>
                <li>
                  <a
                    href="https://my.earthlink.net/?_ga=2.162350972.1106406379.1653451767-495612541.1653451767"
                    className="text-16px text-white underline pb-px inline-block"
                    rel="nofollow"
                  >
                    My EarthLink
                  </a>
                </li>
              </ul>
            </div>
            <div className="smd-hidden block ">
              <h3 className=" text-18 text-white font-semibold mt-6 mb-2 flex justify-between items-center">
                <span>Follow EarthLink</span>
              </h3>
              <ul className="flex items-center">
                <SvgLi className="inline-flex justify-center items-center bg-white w-9 h-9 rounded-full mx-1">
                  <a href="https://www.facebook.com/earthlink">
                    <Svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-siteftblack fill-elnk-black-600 w-6 p-1"
                      viewBox="0 0 320 512"
                    >
                      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                    </Svg>
                  </a>
                </SvgLi>
                <SvgLi className="inline-flex justify-center items-center bg-white w-9 h-9 rounded-full mx-1">
                  <a href="https://twitter.com/earthlink">
                    <Svg
                      className="text-siteftblack fill-elnk-black-600 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"></path>
                    </Svg>
                  </a>
                </SvgLi>
                <SvgLi className="inline-flex justify-center items-center bg-white w-9 h-9 rounded-full mx-1">
                  <a href="https://www.linkedin.com/company/earthlink/">
                    <Svg
                      className="text-siteftblack fill-elnk-black-600 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                    </Svg>
                  </a>
                </SvgLi>
                <SvgLi className="inline-flex justify-center items-center bg-white w-9 h-9 rounded-full mx-1">
                  <a href="https://www.youtube.com/c/EarthLinkOrbit">
                    <Svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-siteftblack fill-elnk-black-600 w-5"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path>
                    </Svg>
                  </a>
                </SvgLi>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center flex-wrap sm-flex-nowrap sm-px-15 font-Montserrat px-30 pt-30 sm-pt-0 text-center sm-text-left bg-elnk-black-900 sm-bg-transparent ">
          <div className="sm-w-auto relative order-1 sm-order-none w-full">
            <p className="text-16 text-white inline-block">
              © {year} EarthLink, LLC · All rights reserved
            </p>
          </div>
          <div className="w-20 relative order-3 sm-order-none mx-auto sm-mx-0 mt-15 sm-mt-0">
            <div className="w-full flex">
              <img src={FOOTER_IMAGE_PATH} alt="" />
            </div>
          </div>
          <div className="sm-w-auto relative w-full order-2 sm-order-none">
            <p className="text-16 text-white inline-block">
              <a
                className="underline hover-text-elnk-white-600 text-white"
                href="https://earthlink.net/tcs/privacy-policy/"
              >
                Privacy Policy
              </a>
              <span className="px-2">·</span>
              <a
                className="underline hover-text-elnk-blue-600 text-white"
                href="https://earthlink.net/tcs/interest-based-ads/"
              >
                Interest-Based Ads
              </a>
            </p>
          </div>
        </div>
      </FooterContainer>
    </Footer>
  )
}

const Footer = styled.footer`
  ${tw}
`

const FooterContainer = styled.div`
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
`

const SvgLi = styled.li`
  --tw-bg-opacity: 1;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  display: inline-flex;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

`
const Svg = styled.svg`
  width: 1.25rem;
  display: block;
  vertical-align: middle;
  fill: #444;
`
