import { observable } from 'mobx'
import dayjs from 'dayjs'

export default class {
  id = null;
  @observable created_at = null;
  @observable first_name = null;
  @observable last_name = null;
  @observable email = null;
  @observable user_id = null;
  @observable star_rating = null;
  @observable tag_line = null;
  @observable description = null;
  @observable product_ids = null;

  constructor (json) {
    this.id = json.id
    this.created_at = dayjs(json.created_at).format('MM/DD/YYYY')
    this.first_name = json.fin_user.first_name
    this.last_name = json.fin_user.last_name
    this.email = json.fin_user.email
    this.star_rating = json.star_rating
    this.user_id = json.user_id
    this.tag_line = json.tag_line
    this.description = json.description
    this.product_ids = json.product_product_review_maps
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
