import React from 'react'
import styled, { css } from 'styled-components'

export const AdminButton = (props) => (
  <Button {...props} className={props.className}>
    {props.isLoading && 'Loading...'}
    {!props.isLoading && props.children}
  </Button>
)

export const AdminLink = (props) => (
  <Button as="a" {...props} className={props.className}>
    {props.children}
  </Button>
)

export const AdminCancel = (props) => (
  <Button {...props} background="#474747">
    {props.children}
  </Button>
)

const Button = styled.button`
  color: ${(props) => props.theme.colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 1.42857143;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border-radius: 4px;
  font-weight: 500;
  padding: 15px 20px;
  border: 0;
  font-size: 14px;
  line-height: 18px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${(props) => {
    if (props.danger) {
      return css`
        background-color: ${(props) => props.theme.colors.danger};
      `
    } else if (props.grey) {
      return css`
        background-color: ${(props) => props.theme.colors.grey};
      `
    } else if (props.info) {
      return css`
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.adminBlue};
        border: ${(props) => props.theme.colors.adminBlue} solid 1px;
      `
    } else {
      return css`
        background-color: ${(props) =>
          props.background || props.theme.colors.adminBlue};
      `
    }
  }}

  &:hover {
    text-decoration: none;
    background-color: ${(props) =>
      props.background + 'ed' || props.theme.colors.adminBlue + 'ed'};
    color: ${(props) => props.theme.colors.white};
    opacity: 0.85;

    ${(props) => {
      if (props.info) {
        return css`
          background-color: ${(props) => props.theme.colors.primary};
          color: ${(props) => props.theme.colors.black};
        `
      }
    }}
  }

  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0.5 !important;
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5 !important;
    `}

  ${(props) =>
    props.small &&
    css`
      padding: 5px 10px;
      font-size: 14px;
      line-height: 14px;
    `}
`

export const AdminStickyRow = styled.div`
  position: sticky;
  top: -20px;
  padding: 10px 0;
  z-index: 1000;
  background-color: #fff;
  padding-bottom: 20px;
`

export const AdminButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 10px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
  }

  ${(props) =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}
`
