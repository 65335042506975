import { observable } from 'mobx'

export default class {
  id = null;
  @observable createdAt = null;
  @observable first_name = null;
  @observable last_name = null;
  @observable email = null;
  @observable phone = null;
  @observable product_id = null;
  @observable company_id = null;
  @observable membership_status = null;

  constructor (json) {
    this.id = json.id
    this.createdAt = new Date(json.createdAt)
    this.first_name = json.first_name
    this.last_name = json.last_name
    this.email = json.email
    this.phone = json.phone
    this.product_id = json.product_id
    this.company_id = json.company_id
    this.membership_status = json.user_details.membership_status
  }

  static jsonToArray (json) {
    return json.reduce((acc, curr) => {
      acc[curr.id] = new this(curr)
      return acc
    }, {})
  }

  static constructFromJSON (json) {
    return new this(json)
  }

  update (entries) {
    for (const key in entries) {
      this[key] = entries[key]
    }
  }
}
