import FfrMarketplaceHero from './FfrMarketplaceHero'
import { FormWrapper } from '/components/theme/default/base'
import { HtmlInput } from '/components/admin/page/page_builder/form-inputs/Html'
import { MediaInputPB } from '/components/admin/page/page_builder/form-inputs/Media'

// export const PUBLIC_HOST = 'https://ffr2publicdev.wpengine.com'
// export const WPENGINE_HOST = 'https://ffr2privatedev.wpengine.com'
// export const PORTAL_HOST = 'https://dev01.ffrmembers.com'
// Production values
export const PUBLIC_HOST = 'https://ffrmembers.com'
export const WPENGINE_HOST = 'https://secure.ffrmembers.com'
export const PORTAL_HOST = 'https://portal.ffrmembers.com'

const HeroForm = ({ title = 'Resources', image, setCurrentComponent }) => {
  return (
    <FormWrapper>
      <HtmlInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={image}
        label="Image"
        name="image"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}

export const THEME = {
  marketplace_hero: {
    component: FfrMarketplaceHero,
    form: HeroForm,
    label: 'Marketplace Hero',
    type: ['parent'],
    new: {
      component: 'marketplace_hero',
      label: 'Marketplace Hero',
      props: {
        title: 'TITLE',
        subtitle: 'Subtitle',
        description: 'Description'
      }
    }
  }
}
